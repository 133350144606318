import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import {
  object_t,
  ServerService,
  FileUtilsService,
  Taxonomy,
  User,
} from '@pinacono/common';

import {
  ModalComponent,
} from '@pinacono/ui';

@Component({
  selector: 'page-opl-reports',
  templateUrl: 'reports.html',
  styleUrls: [ 'reports.scss' ]
})
export class OplReportsPage implements OnInit {

  @ViewChild('selectGroupsModal') selectGroupsModal!: ModalComponent;
  @ViewChild('selectMembersModal') selectMembersModal!: ModalComponent;

  // -- form parameters
  public selectedUsers: User[] = [];
  public selectedGroups: { [name: string]: Taxonomy[] } = {
    'report2': [],
  };

  // -- constructor
  constructor(
    protected server: ServerService,
    protected fileUtils: FileUtilsService
  ) {
  }

  public ngOnInit(): void {
  }

  // -- select user

  public removeSelectedUser(list: User[], user: User) {
    const i = list.findIndex( (u: User) => u.id == user.id );
    if ( i >= 0 ) list.splice(i, 1);
  }

  public selectUsers(list: User[]) {
    this.selectedUsers = list;
    this.selectMembersModal.show();
  }

  // -- select group

  public removeSelectedGroup(list: Taxonomy[], group: Taxonomy) {
    const i = list.findIndex( (t: Taxonomy) => t.id == group.id );
    if ( i >= 0 ) list.splice(i, 1);
  }

  public activeReport: string = '';
  public selectGroups(name: string) {
    this.activeReport = name;
    this.selectGroupsModal.show();
  }

  // -- params extraction
  public getSelectedUserIds(list: User[]): string[] {
    return list.map((u: User) => u.id.toString());
  }

  public getSelectedGroupIds(list: Taxonomy[]): string[] {
    return list.filter( (t:Taxonomy) => !!t.id )
               .map( (t: Taxonomy) => t.id!.toString() );
  }

  // -- report parameters

  public report3SelectedUsers: User[] = [];

  // -- report execution

  public async run(name: string, form: NgForm, additional: object_t = {}) {
    const params = Object.assign({}, additional, form.value);
    const res: object_t = await this.server.request(`opl.reports.${name}`, null, params);
    this.fileUtils.saveXLSX(name, res);
  }
}