<full-page *ngIf="doc !== null" [title]="doc.title">
  <div page-content class="selectable">
    <panel class="pad-all">

      <div panel-control>
        <div class="doc-status text-sm text-right">
          <span *ngIf="doc.status == 'deleted'"   class="label label-dark text-thin">{{ 'Deleted' | translate }}</span>
          <span *ngIf="doc.status == 'expired'"   class="label label-light text-thin">{{ 'Expired' | translate }}</span>
          <span *ngIf="doc.status == 'reserved'"  class="label label-default text-thin">{{ 'Reserved' | translate }}</span>
          <span *ngIf="doc.status == 'submitted'" class="label label-pink text-thin">{{ 'Submitted' | translate }}</span>
          <span *ngIf="doc.status == 'reviewed'"  class="label label-mint text-thin"    title="Approved at {{ doc.reviewed_at | datetime }}">{{ 'Approved' | translate }}</span>
          <span *ngIf="doc.status == 'published'" class="label label-success text-thin" title="Published at {{ doc.published_at | datetime }}">{{ 'Published' | translate }}</span>
          <span *ngIf="doc.is_memo" class="label label-info text-thin" title="This document is a memo">{{ 'Memo' | translate }}</span>
        </div>
      </div>

      <div panel-heading class="pad-all">

        <div class="doc-title">
          <i class="fa fa-hashtag text-sm"></i>{{ doc.id }} {{ doc.attr['title']['prefix'] }} {{ doc.title }} {{ doc.attr['title']['suffix'] }}
        </div>

        <div class="attributes row pad-top">
          <div class="col text-xs">
            <label class="text-bold">{{ ( doc.is_memo ? 'Form Code' : 'Document Code' ) | translate}}:</label>
            <span class="text-thin mar-rgt">{{ doc.doc_code }}</span>
          </div>

          <div class="col text-xs">
            <label class="text-bold">{{ 'Domain' | translate }}: </label>
            <span class="categories delimited">{{ doc.domain && doc.domain.name || 'n/a' }}</span>
          </div>

          <div class="col text-xs">
            <label class="text-bold">{{ 'Uploaded By' | translate }}: </label>
            <span class="text-thin">{{ doc.uploader?.fullname }} ({{ doc.uploader?.email }})</span>
          </div>
        </div>

        <!-- categories -->
        <div class="attributes row">
          <div class="col text-xs wrap">
            <label class="text-bold">{{ 'Allowed for' | translate }}: </label>
            <span *ngFor="let g of doc.categories" class="categories delimited">{{g.name}}</span>
          </div>
        </div>
      </div>

      <div panel-body class="pad-all">

        <!-- meta data -->
        <div id="meta" class="row bord-top pad-top pad-btm">

          <!-- published date -->
          <div class="col text-sm" title="{{ 'Published on' | translate }}">
            <label><i class="icon fa fa-fw fa-solid fa-check"></i> {{ 'Published On' | translate }}:</label>
            <div>{{ doc.published_at ? (doc.published_at | datetime) : 'n/a' }}</div>
          </div>

          <!-- updated date -->
          <div class="col text-sm" title="{{ 'Last Update' | translate }}">
            <label><i class="icon fa fa-fw fa-clock"></i> {{ 'Last Update' | translate }}:</label>
            <div>{{ doc.updated_at | datetime }}</div>
          </div>

          <!-- document type -->
          <div class="col text-sm" title="{{ 'Document Type' | translate }}">
            <label><i class="icon fa fa-fw fa-solid fa-tags"></i> {{ 'Document Type' | translate }}:</label>
            <div>{{ ( doc.code?.f || '-unknown-' ) | translate }}</div>
          </div>

          <!-- place -->
          <div class="col text-sm" title="{{ 'Building' | translate }}">
            <label><i class="icon fa fa-fw fa-building"></i> {{ 'Related Building' | translate }}:</label>
            <div *ngIf="! doc.attr['locations'] || doc.attr['locations'].length == 0">{{ 'N/A' | translate }}</div>
            <div id="building-list">
              <span *ngFor="let l of ( doc.attr['locations'] || [] )" class="delimited comma">{{ l | translate }}</span>
            </div>
          </div>

          <!-- doc date -->
          <div class="col text-sm" title="{{ 'Document Date' | translate }}">
            <label><i class="icon fa fa-fw pli-calendar-3"></i> {{ 'Document Approve Date' | translate }}:</label>
            <div *ngIf="!doc.attr['doc_date']">{{ 'N/A' | translate }}</div>
            <div *ngIf="!!doc.attr['doc_date']">{{ doc.attr['doc_date'] | date:'d MMM yyyy' }}</div>
          </div>

          <!-- expiration -->
          <div class="col text-sm" title="{{ 'Expiration Date' | translate }}">
            <!-- <label class="text-bold">{{ 'Expiration' | translate }}:</label> -->
            <label><i class="icon fa fa-fw fa-solid fa-hourglass-end"></i> {{ 'Expire Date' | translate }}:</label>
            <div>
              <span *ngIf="!doc.expire_date">{{ 'No Expiration' | translate }}</span>
              <span *ngIf="!!doc.expire_date">{{ doc.expire_date | date:'d MMM yyyy' }}</span>
              &nbsp;
              <span *ngIf="!!doc.expire_date" class="expiration text-xs text-thin">({{ expired_in(doc.expire_date) | number:'1.0-0' }} {{ 'days' | translate }})</span>
            </div>
          </div>

        </div>

        <!-- synopsis/content -->
        <div id="synopsis" class="row pad-top pad-btm">
          <p class="col-xs-12" [innerHTML]="doc.content | safe"></p>
          <div *ngIf="this.commonApi.hasModuleContent(doc)">
            <button class="download btn btn-labeled file-link" stop-propagation (click)="gotoContent()" title="{{ 'Click to go to document content page' | translate }}">
              <i class="fa-fw icon-fw btn-default icon-label text-success {{ api.getFileIcon('link') }}"></i>
              <span class="text-thin filename">{{ doc.title }}</span>
            </button>
          </div>
        </div>

        <!-- type specific information -->
        <div id="properties" *ngIf="has_type_specific_attributes" class="row pad-top pad-btm section">

          <div *ngIf="doc.code?.f == 'BOOK'" class="col-xs-12">
            <label class="text-bold pad-rgt">{{ 'Book Information' | translate }}:</label>
            <collapse-button
              [target]="'book'"
              [collapsed]="true"
            ></collapse-button>

            <div id="book" class="row">

              <div class="col-xs-6 col-sm-3 col-md-3 attribute">
                <label class="text-bold">{{ 'Type' | translate }}: </label>
                <span>{{ doc.attr['BOOK'].type || 'n/a' }}</span>
              </div>

              <div class="col-xs-6 col-sm-4 col-md-3 attribute">
                <label class="text-bold">{{ 'ISBN' | translate }}: </label>
                <span>{{ doc.attr['BOOK'].isbn || 'n/a' }}</span>
              </div>

              <!--
              <div class="col-xs-6 col-sm-4 col-md-3 attribute">
                <label class="text-bold">{{ 'Genres' | translate }}: </label>
                <span>{{ doc.attr['BOOK'].genres || 'n/a' }}</span>
              </div>
              -->

              <div class="col-xs-6 col-sm-4 col-md-3 attribute">
                <label class="text-bold">{{ 'Price' | translate }}: </label>
                <span>{{ ( doc.attr['BOOK'].price | number:'1.2-2' ) || 'n/a' }} THB.</span>
              </div>

              <div class="col-xs-6 col-sm-4 col-md-3 attribute">
                <label class="text-bold">{{ 'Author' | translate }}: </label>
                <span>{{ doc.attr['BOOK'].authors || 'n/a' }}</span>
              </div>

              <div class="col-xs-6 col-sm-4 col-md-3 attribute">
                <label class="text-bold">{{ 'Publisher' | translate }}: </label>
                <span>{{ doc.attr['BOOK'].publishers || 'n/a' }}</span>
              </div>

              <div class="col-xs-6 col-sm-4 col-md-3 attribute">
                <label class="text-bold">{{ 'Date' | translate }}: </label>
                <span>{{ ( doc.attr['BOOK'].publish_date | date ) || 'n/a' }}</span>
              </div>

            </div>
          </div>

          <div *ngIf="doc.code?.f == 'PRJ'" class="col-xs-12">
            <label class="text-bold pad-rgt">{{ 'Project Information' | translate }}:</label>
            <collapse-button
              [target]="'project'"
              [collapsed]="true"
            ></collapse-button>
            <div id="project" class="row">
              <div class="col-xs-12">
                Project's attribute here
              </div>
            </div>
          </div>

          <div *ngIf="doc.code?.f == 'DWG'" class="col-xs-12">
            <label class="text-bold pad-rgt">{{ 'Drawing Information' | translate }}:</label>
            <collapse-button
              [target]="'drawing'"
              [collapsed]="true"
            ></collapse-button>
            <div id="drawing" class="row">
              <div class="col-xs-12">
                Drawing's attribute here
              </div>
            </div>
          </div>
        </div>

        <!-- related documents (References) -->
        <div id="related-doc" class="row pad-top pad-btm section">
          <div class="col-xs-12">
            <label class="text-bold pad-rgt">{{ 'Related Documents' | translate }}:</label>
            <collapse-button
              [target]="'related-docs-' + doc.id"
              [collapsed]="true"
            ></collapse-button>
            <div id="related-docs-{{ doc.id }}">
              <div *ngFor="let code of split(doc.references!)" >
                <a class="btn-link delimited comma" href="#" stop-propagation (click)="view(code)">{{ code | refdoc | async }}</a>
              </div>
              <div *ngIf="! doc || ! doc.references || doc.references.length == 0"
                class="text-muted text-sm"
              >
                {{ 'No related document' | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- referenced_by documents -->
        <div id="references-by" class="row pad-top pad-btm section">
          <div class="col-xs-12">
            <label class="text-bold pad-rgt" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#referred-by-' + doc.id">{{ 'Referred By' | translate }}:</label>
            <collapse-button
              [target]="'referred-by-' + doc.id"
              [collapsed]="true"
            ></collapse-button>
            <div id="referred-by-{{ doc.id }}">
              <div *ngFor="let code of split(doc.referred_by!)">
                <a class="btn-link delimited comma" href="#" stop-propagation (click)="view(code)">{{ code | refdoc | async }}</a>
              </div>
              <div *ngIf="!doc || ! doc.referred_by || doc.referred_by.length == 0"
                class="text-muted text-sm"
              >
                {{ 'No document refer to this document' | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- document files -->
        <div id="doc-files" class="row pad-top pad-btm section">
          <div class="col-xs-12">
            <label class="text-bold pad-rgt">{{ 'Files' | translate }}:</label>

            <div *ngIf="doc.content_docs.length == 0" class="text-center">
              <span class="text-sm text-thin text-muted">- {{ 'no file available' | translate }}-</span>
            </div>

            <ol *ngIf="doc.content_docs.length > 0">
              <li *ngFor="let file of doc.content_docs">
                <label>{{ file.title }}</label>
                <p class="text-thin">{{ file.description }}</p>

                <table *ngIf="file.active_revisions && file.active_revisions.length > 0" id="revisions" class="table">
                  <thead>
                    <tr>
                      <th class="revision">{{ 'Revision' | translate }}</th>
                      <th class="updated">{{ 'Date' | translate }}</th>
                      <th class="description">{{ 'Description' | translate }}</th>
                      <!--
                      <th class="actions">&nbsp;</th>
                      -->
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let rev of file.active_revisions">

                      <tr id="rev-{{ rev.id }}">
                        <td class="revision">{{ rev.revision }}</td>
                        <td class="updated">{{ rev.updated_at | datetime }}</td>
                        <td class="description">{{ rev.description }}</td>
                        <!--
                        <td class="actions">
                          <button *ngIf="false && !rev.active" class="btn btn-icon btn-success" title="{{ 'Activate' | translate}}">
                            <i class="fa fa-thumbs-up"></i>
                          </button>
                          <button *ngIf="false && rev.active" class="btn btn-icon btn-warning" title="{{ 'Deactivate' | translate}}">
                            <i class="fa fa-thumbs-down"></i>
                          </button>
                          <button *ngIf="false && rev.attachments.length > 0" class="btn btn-icon btn-default" (click)="api.download(doc, rev, rev.attachments[0])" title="{{ 'Download' | translate}}">
                            <i class="fa fa-download"></i>
                          </button>
                          <collapse-button
                            [target]="'rev-' + rev.id"
                            [collapsed]="true"
                          ></collapse-button>
                        </td>
                        -->
                      </tr>

                      <!--
                      <tr *ngIf="this.commonApi.hasModuleContent(rev)">
                        <td colspan="3">
                          <div class="soft-copies">
                            <div class="soft-copy">
                              <button class="download btn btn-labeled file-link" stop-propagation (click)="goto(rev)" title="{{ 'Click to go to document content page' | translate }}">
                                <i class="fa-fw icon-fw fa-2x icon-label text-success {{ api.getFileIcon('link') }}"></i>
                              </button>
                              <div class="text-thin filename">{{ doc.title }}</div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      -->

                      <!--
                      <tr *ngIf=" ! commonApi.hasModuleContent(rev)" id="rev-{{ rev.id }}">
                      -->

                      <!-- old layout -->
                      <!--
                      <tr *ngIf="false" id="rev-{{ rev.id }}">
                        <td colspan="3">
                          <div class="soft-copies">

                            <div *ngFor="let f of rev.softcopies" class="soft-copy" title="{{ f.file_name }}">
                              <button class="download btn btn-labeled file-link" stop-propagation (click)="download(f)" title="{{ 'Click to download' | translate }}">
                                <i class="fa-fw icon-fw fa-2x icon-label {{ api.can_download(doc!) ? 'text-success' : 'text-muted' }} {{ api.getFileIcon(f.content_type) }}"></i>
                              </button>
                              <div class="text-thin filename">{{ f.file_name }}</div>
                              <div class="text-thin filesize">{{ f.file_size | memsize:'k' | number:'1.2-2' }} kB.</div>
                            </div>

                            <div  *ngIf="rev.softcopies.length == 0" class="text-thin text-muted text-center">
                              -{{ 'No downlodable avaialble.' | translate }}-
                            </div>

                          </div>
                        </td>
                      </tr>
                      -->

                      <!-- new layout -->
                      <tr>
                        <td colspan="3" class="ps-5">
                          <div *ngIf="rev.softcopies.length == 0" id="rev-{{ rev.id }}-empty" class="text-thin text-muted text-center">
                              -{{ 'No downlodable avaialble.' | translate }}-
                          </div>

                          <table *ngIf="rev.softcopies.length > 0" class="table">
                            <thead>
                              <tr>
                                <th class="no">No.</th>
                                <th class="filename">{{ 'File Name' | translate }}</th>
                                <th class="filesize">{{ 'File Size' | translate }}</th>
                                <th class="download">&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let f of rev.softcopies; let file_index = index" class="soft-copy" title="{{ f.file_name }}" class="table" id="rev-{{ rev.id }}-f-{{ file_index }}">
                                <td>
                                  {{ file_index+1 }}
                                </td>
                                <td>
                                  <div class="text-thin filename">{{ f.file_name }}</div>
                                </td>
                                <td>
                                  <div class="text-thin filesize">{{ f.file_size | memsize:'k' | number:'1.2-2' }} kB.</div>
                                </td>
                                <td>
                                  <button class="download btn btn-icon file-link" stop-propagation (click)="download(f)" title="{{ 'Click to download' | translate }}">
                                    <i class="fa-fw icon-fw fa-2x icon-label {{ api.can_download(doc) ? 'text-success' : 'text-muted' }} {{ api.getFileIcon(f.content_type) }}"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                    </ng-container>
                  </tbody>
                </table>

                <table *ngIf="file.active_revisions.length == 0" id="revisions" class="table">
                  <tbody>
                    <tr>
                      <td class="text-center text-thin">
                        <span>- No active revisions available-</span>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </li>
            </ol>

          </div>
        </div>

        <!-- permited user - for confidential doc only -->
         <div *ngIf="api.is_confidential(doc)" id="doc-permitted-users" class="row pad-top pad-btm section">
          <div class="col-xs-12">
            <label class="text-bold pad-rgt">{{ 'Permitted Users' | translate }}:</label>
            <div class="row">
              <user-card class="col" *ngFor="let user of doc.mandatory_permitted_users" [user]="user!"></user-card>
              <user-card class="col" *ngFor="let user of doc.optional_permitted_users" [user]="user!"></user-card>
            </div>
          </div>
         </div>

      </div>

      <div panel-footer>
        <div class="row">

          <!-- left -->
          <div class="col-xs-12 col-sm-6 footer-items pad-btm">
            <!-- keep as an example. to be removed
            <a class="btn-link" data-bs-toggle="collapse" [attr.data-bs-target]="'#comments-' + format + '-' + content.id" role="button" aria-expanded="false" aria-controls="collapseComment">
              <i class="pli-speech-bubble-5 icon-fw"></i>{{ content.comments.length }}
            </a>
            <a *ngIf="content.status == 'approved'" class="btn-link" data-bs-toggle="collapse" [attr.data-bs-target]="'#audiences-read-' + format + '-' + content.id" role="button" aria-expanded="false" aria-controls="collapseAudiences">
              <i class="fa fa-eye icon-fw"></i>{{ content.audiences.read.length }}
            </a>
            <a *ngIf="content.status == 'approved'" class="btn-link" data-bs-toggle="collapse" [attr.data-bs-target]="'#audiences-unread-' + format + '-' + content.id" role="button" aria-expanded="false" aria-controls="collapseAudiences">
              <i class="fa fa-eye-slash icon-fw"></i>{{ content.audiences.unread.length }}
            </a>
            <a *ngIf="content.status == 'approved'" class="btn-link" href="#" role="button" stop-propagation (click)="print.print()">
              <i class="fa fa-solid fa-print icon-fw"></i>
            </a>
            -->
          </div>

          <!-- right -->
          <div class="col-xs-12 col-sm-6 text-right footer-items">
            <!-- access request -->
            <!--
            <button *ngIf="!! request && ! request.access_code" stop-propagation (click)="grant()" title="{{ 'Grant Access' | translate }}" class="btn btn-default btn-labeled">
              <i class="btn-label icon-lg icon-fw fa fa-thumbs-up"></i> {{'Approve' | translate}}
            </button>
            <button *ngIf="!! request && ! request.access_code" stop-propagation (click)="deny()" title="{{ 'Deny Access' | translate }}" class="btn btn-default btn-labeled">
              <i class="btn-label icon-lg icon-fw fa fa-thumbs-down"></i> {{'Deny' | translate}}
            </button>
            -->
            <!-- workflow -->
            <!--
            <button *ngIf="can_approve" stop-propagation (click)="approve()" title="{{ 'Approve' | translate }}" class="btn btn-default btn-labeled">
              <i class="btn-label icon-lg icon-fw fa fa-thumbs-up"></i> {{'Approve' | translate}}
            </button>
            <button *ngIf="can_reject" stop-propagation (click)="reject()" title="{{ 'Reject' | translate }}" class="btn btn-default btn-labeled">
              <i class="btn-label icon-lg icon-fw fa fa-thumbs-down"></i> {{'Reject' | translate}}
            </button>
            <button *ngIf="can_review" stop-propagation (click)="review()" title="{{ 'Mark as Approved' | translate }}" class="btn btn-default btn-labeled">
              <i class="btn-label icon-lg icon-fw fa fa-flag"></i> {{'Review' | translate}}
            </button>
            <button *ngIf="can_publish" stop-propagation (click)="publish()" title="{{ 'Mark as Published' | translate }}" class="btn btn-default btn-labeled">
              <i class="btn-label icon-lg icon-fw fa fa-bullhorn"></i> {{'Publish' | translate}}
            </button>
            -->
            <!-- editing -->
            <button *ngIf="can_edit" stop-propagation (click)="edit()" title="{{ 'Edit' | translate }}" class="btn btn-default btn-labeled">
              <i class="btn-label icon-lg icon-fw fa fa-solid fa-pencil"></i> {{'Edit' | translate}}
            </button>
            <button type="button" class="btn btn-labeled btn-dark" data-bs-dismiss="modal" (click)="back()">
              <i class="btn-label fa fa-solid fa-circle-chevron-left"></i>{{ 'Back' | translate }}
            </button>
          </div>
        </div>

      </div>
    </panel>
  </div>
</full-page>