import {
  Component,
  AfterViewChecked,
  ViewChild
} from "@angular/core";

import {
  Router,
  ActivatedRoute
} from "@angular/router";

import {
  config,
  DateTimeUtilsService,
  NavigationService,
  object_t,
  ServerService,
  SessionService,
  User
} from '@pinacono/common';

import {
  LookupEvent,
  LookupItem,
  ModalComponent,
  UIService
} from "@pinacono/ui";

import {
  SlickGrid
} from '@slickgrid-universal/common';

import {
  GraphqlPaginatedResult
} from '@slickgrid-universal/graphql';

import {
  AngularGridInstance,
  AngularUtilService,
  Column,
  CompoundDateFilter,
  FieldType,
  GridOption,
  SingleSelectFilter
} from 'angular-slickgrid';

import {
  ComponentFormatter,
  DataGridButton,
  ExtendedFormatters,
  GraphQLServerService,
  GridUserComponent,
  LighthouseService
} from "@pinacono/slickgrid-extension";

import { BasePageComponent } from "src/app/classes/base-page.component";

import {
  CompetencyLevel,
  TrainingBatch,
  TrainingBatchStatus,
  TrainingCourse,
  TrainingCourseRequest,
  TrainingRecord,
  TrainingRecordStatus
} from '../types';

import {
  TrainingService
} from '../training.service';

declare let $: any;

@Component({
  selector: 'training-batch-page',
  templateUrl: 'view.html',
  styleUrls: [ 'view.scss' ],
  providers: [ AngularUtilService ]
})
export class TrainingBatchViewPage extends BasePageComponent implements AfterViewChecked {
  // data
  public batch: TrainingBatch|null = null;

  // new batch information
  @ViewChild('createBatchModal') createBatchModal!: ModalComponent;
  public selectedCourse: TrainingCourse|null = null;

  // add new attendee
  @ViewChild('addAttendeeModal') addAttendeeModal!: ModalComponent;

  // flags
  public user_is_mentor: boolean = false;

  // form models
  public courseName: string = '';
  /*
  public start = {
    date: '',
    time: ''
  };
  public finish = {
    date: '',
    time: ''
  }
  */

  public editable: boolean = true;

  public config = config;

  public statusCssClasses = {
    pending:    'info',
    open:       'success',
    full:       'warning',
    progress:   'mint',
    cancelled:  'dark',
    closed:     'default'
  }

  // grid
  protected gridInstance: AngularGridInstance|null = null;

  public gridOptions: GridOption|null = null;
  public columnDefinitions: Column[] = [];

  public records: TrainingRecord[] = [];

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    protected server: ServerService,
    protected ngUtilService: AngularUtilService,
    protected graphServer: GraphQLServerService,
    protected dateTimeUtils: DateTimeUtilsService,
    protected api: TrainingService
  ) {
    super(router, activatedRoute);
  }

  // -- lifecycle

  public override loadData(): Promise<void> {

    const associated = [
      'course',
      'course.levels',
      'course.levels.competency',
      'trainers',
      'attendees',
      'objectives',
      'records'
    ];

    const appends = [
      'competencies', 'trainer_ids'
    ];

    let id = this.activatedRoute.snapshot.paramMap.get('id') || null;
    let promise: Promise<number>;

    if ( ! id || id === 'new' ) {
      // if do not have batch, check requests
      this.user_is_mentor = true;
      let requests: TrainingCourseRequest[] = this.activatedRoute.snapshot.data['requests'] as TrainingCourseRequest[] || [];

      // also no request, just create empty batch
      if ( requests.length == 0 ) {
        return this.createBatchModal.show();
      }

      // we have one request, let open new batch with those requests
      promise = new Promise( (resolve) => {
        this.server.request('training.batch.open',null, {
          requests: requests.map( r => r.id ),
          batch: this.api.createTrainingBatch()
        })
        .then( (data: object_t) => {
          resolve(data['id']);
        });
      });

    }
    else {
      let _id = parseInt(id);
      if ( isNaN(_id) ) {
        this.ui.alert('Batch ID is invalid - ' + id);
        this.nav.back();
      }
      // request to edit existing batch
      promise = Promise.resolve(_id);
    }

    return promise.then( (id: number) => {
      this.server.rejectOnError().show('training/batches', id, {
        with: associated.join(','),
        appends: appends.join(',')
      })
      .then(
        (data: object) => {
          this.batch = this.api.createTrainingBatch(data);
          this.user_is_mentor = ( this.batch.trainer_ids?.length == 0 || !! this.batch.trainers.find( t => t.id == this.session.currentUser!.id ) || this.session.hasPermission(['core_admin', 'training_admin']));

          this.courseName = this.batch.course.name;

          /*
          this.start.date = this.dateTimeUtils.browser(this.batch.schedule_start_at);
          this.start.time = this.dateTimeUtils.format('HH:mm', this.batch.schedule_start_at);
          this.finish.date = this.dateTimeUtils.browser(this.batch.schedule_finish_at);
          this.finish.time = this.dateTimeUtils.format('HH:mm', this.batch.schedule_finish_at);
          */

          this.editable = (
            (
              // batch in status that is editable
              this.batch.status != TrainingBatchStatus.CANCELLED
              && this.batch.status != TrainingBatchStatus.CLOSED
            )
            &&
            (
              // new batch
              this.batch.trainer_ids?.length == 0
              // current user is a trainer of the batch
              || ( this.batch.trainer_ids && this.batch.trainer_ids.filter( id => id == this.session.currentUser!.id ).length > 0 )
              // current user is an admin
              || this.session.hasPermission(['training_admin', 'core_admin'])
            )
          );

          if ( !! this.gridInstance ) {
            this.silent = false;
            this.gridInstance.extensionService.refreshBackendDataset();
          }
          else {
            this.initGrid();
          }
        },
        (error) => {
          this.ui.alert('Cannot load batch #{{id}} - {{error}}', { id: id, error: error });
          this.nav.back();
        }
      );
    });
  }

  //private jq_plugins_initialized: boolean = false;
  public ngAfterViewChecked() {
    /*
    if ( this.jq_plugins_initialized ) {
      return;
    }

    let tp = $('.time-picker');
    if ( tp.length > 0 ) {
      tp.timepicker({
        minuteStep: 15,
        showSeconds: false,
        disableFocus: false,
        showMeridian: false,
        showInputs: true
      })
      .on('changeTime.timepicker', (e: Event) => {
        if ( e.target && (e.target as any)['name'] == 'start' ) {
          this.start.time = (e as any)['time'].value;
        }
        else if ( e.target && (e.target as any)['name'] == 'finish' ) {
          this.finish.time = (e as any)['time'].value;
        }
      });
      this.jq_plugins_initialized = true;
    }
    */
  }

  // -- grid interfaces
  protected silent: boolean = true;
  protected processGraphQLQuery(query: string): Promise<GraphqlPaginatedResult> {
    return new Promise( (resolve) => {
      const server = this.silent ? this.graphServer.silent() : this.graphServer;
      this.silent = true;

      server.sendQuery({query: query})
      .then(
        (res: object) => {
          // parse response
          let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
          this.records = re.data['trainingRecords'].nodes.map( (r: TrainingRecord) => {
            return this.api.createTrainingRecord(r);
          });
          resolve(re);
        },
        (error: any) => {
          this.ui.alert(error.message, undefined, 'Error!');
          console.log('GraphQL Error:', error);
        }
      );
    });
  }

  protected initGrid() {
    this.gridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.columnDefinitions,
          datasetName: 'trainingRecords',
          //filteringOptions: [
          persistenceFilteringOptions: [
            { field: 'batch_id', operator: 'EQ', value: (this.batch!.id!).toString() }
          ],
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: this.processGraphQLQuery.bind(this),
        //postProcess?: (response: GraphqlResult | any) => void;
      },
      enableSorting: true,
      rowHeight: 80,
      enableAutoResize: true,
      forceFitColumns: true,
      autoResize: {
        bottomPadding: 85,
        minHeight: 400
      },
      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true
    };

    this.columnDefinitions = [
      {
        id: 'id', name: 'Record ID',
        field: 'id',
        type: FieldType.number,
        cssClass: 'text-right', width: 10,
        sortable: true,
        filterable: true
      },

      {
        id: 'user', name: 'User',
        field: 'user',
        fields: ['user_id', 'user.id', 'user.fullname', 'user.email', 'user.avatar.thumb_url', 'user.profiles.staff_id' ],
        type: FieldType.object,
        cssClass: 'text-left', minWidth: 120 ,
        sortable: true,
        filterable: true,
        formatter: ComponentFormatter,
        params: {
          //component: GridUserComponent,
          factory: () => this.ngUtilService.createAngularComponent(GridUserComponent),
          config: {
            attribute: 'user'
          }
        }
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'text-center', width: 30,
        formatter: function(row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string {
          const labels: { [name: string]: { label: string, css: string } } = {
            pending:     { label: 'Pending',     css: 'info'    },
            in_progress: { label: 'In Progress', css: 'mint'    },
            evaluation:  { label: 'Evaluation',  css: 'primary' },
            cancelled:   { label: 'Cancelled',   css: 'dark'    },
            passed:      { label: 'Passed',      css: 'success' },
            failed:      { label: 'Failed',      css: 'danger'  }
          }
          let status = dataContext.status.toLowerCase();
          return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>`
        }.bind(this),
        sortable: true,
        filterable: true,
        filter: {
          model: SingleSelectFilter,
          collection: [
            { value: '',            label: 'Not Filter',  css: 'default' },
            { value: 'pending',     label: 'Pending',     css: 'info'    }, // user is requesting to attend the course and waiting for approval
            { value: 'in_progress', label: 'In Progress', css: 'mint'    }, // course is started and in progress
            { value: 'evaluation',  label: 'Evaluation',  css: 'primary' }, // trainer is evaluating the course's attendees
            { value: 'cancelled',   label: 'Cancelled',   css: 'dark'    }, // course is cancelled - end
            { value: 'passed',      label: 'Passed',      css: 'success' }, // user passed the course - end
            { value: 'failed',      label: 'Failed',      css: 'danger'  }  // user not passed (failed) - end
          ]
        }
      },

      {
        id: 'updated_at', name: 'Last Update',
        field: 'updated_at',
        fields: [ 'updated_at' ],
        type: FieldType.dateIso,
        cssClass: 'text-center', width: 40,
        formatter: ExtendedFormatters.dateTimeMoment,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        filter: {
          model: CompoundDateFilter
        }
      },

      {
        id: 'action', name: 'Actions',
        field: 'id',
        type: FieldType.object,
        cssClass: 'text-center', width: 10,
        sortable: false,
        filterable: false,
        formatter: ExtendedFormatters.buttons,
        params: {
          buttons: [
            {
              id: 'remove',
              title: 'Remove this user from batch',
              css: 'btn-primary',
              icon: 'fa fas fa-minus',
              disabled: (row: number, col: number, dataContext: any, column: Column, grid: SlickGrid) => {
                let record: TrainingRecord = this.records[row];
                return ( record.status == TrainingRecordStatus.CANCELLED );
              },
              click: (row: number, cell: number, value: object, config: DataGridButton, grid: SlickGrid) => {
                let record: TrainingRecord = this.records[row];
                this.ui.confirm('Remove {{ fullname }} from this batch?', {
                  fullname: record.user!.fullname
                }, () => {
                  this.server.request('training.batch.attendee.remove', null, {
                    batch_id: this.batch!.id,
                    user_id: record.user_id
                  })
                  .then( () => {
                    if ( !! this.gridInstance ) {
                      //this.gridInstance.pluginService.refreshBackendDataset();
                      this.refresh();
                    }
                  });
                });
              }
            }
          ]
        }
      }
    ];
  }

  // -- grid interfaces
  public onGridReady(event: Event) {
    this.gridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public onSelectRow(event: Event) {
    let record = this.records[(event as CustomEvent).detail.args['row']];
    //this.nav.push('training-record-page', {id: record.id}, null, this.navDone);
    this.nav.push([ '/training/records/view', record.id ]);
  }

  // -- template API

  public lookupCourseItems: LookupItem<TrainingCourse>[] = [];
  public lookupCourses(keyword: string) {
    this.server.lookup('training/courses', {
      keyword: keyword,
      //trainer: this.user.id // @TODO - re-enable this line!!
    }, 5)
    .then( (res: object[]) => {
      this.lookupCourseItems = res.map( c => {
        let course = this.api.createCourse(c);
        return {
          label: `${course.code}: ${course.name}`,
          value: course
        }
      });
    });
  }

  public selectCourse(event: LookupEvent<TrainingCourse>) {
    this.selectedCourse = event.value.value;
    this.courseName = this.selectedCourse === null ? '' : this.selectedCourse.name;
  }

  public back() {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('/');
    }
  }

  public cancel() {
    this.ui.confirm("Cancel this batch?", undefined, () => {
      this.server.request('training.batch.cancel', { batch_id: this.batch!.id })
      .then( () => {
        this.back();
      });
    });
  }

  public createNewBatch() {
    if ( ! this.selectedCourse ) {
      this.ui.alert('Course is required!');
      return;
    }

    this.createBatchModal.hide();
    this.batch = this.api.createTrainingBatch({
      course: this.selectedCourse,
      name: this.selectedCourse.name,
      capacity: 10, // default
      trainer_ids: [ this.session.currentUser!.id ]
    });

    this.saveBatch()
    .then(
      () => {
        this.nav.goto([ '/training/batches/view', this.batch!.id]);
      },
      (error) => {
        this.ui.alert('Cannot create new batch - {{error}}', {error: error});
      }
    );
  }

  public cancelCreateBatch() {
    this.createBatchModal.hide();
    this.back();
  }

  protected saveBatch(): Promise<void> {
    if ( ! this.batch ) return Promise.resolve();

    let data = {
      id: this.batch.id,
      course_id: this.batch.course.id,
      name: this.batch.name || 'unnamed batch',
      venue: this.batch.venue,
      trainer_ids: this.batch.trainer_ids || null,
      capacity: this.batch.capacity,
      //schedule_start_at: this.dateTimeUtils.ISO(`${this.start.date} ${this.start.time}`),
      //schedule_finish_at: this.dateTimeUtils.ISO(`${this.finish.date} ${this.finish.time}`),
      schedule_start_at: this.batch.schedule_start_at,
      schedule_finish_at: this.batch.schedule_finish_at,
      status: this.batch.status,
      note: this.batch.note,
      attachments: this.batch.attachments,
      attachment_defer_key: this.batch.attachment_defer_key,
      attr: this.batch.attr
    };

    return new Promise( (resolve, reject) => {
      if ( ! this.batch!.id ) {
        this.server.create('training/batches', data)
        .then(
          (data: object) => {
            this.batch = this.api.createTrainingBatch(data);
            resolve();
          },
          reject
        );
      }
      else {
        this.server.update('training/batches', this.batch!.id, data)
        .then(
          () => {
            resolve();
          },
          reject
        );
      }
    });
  }

  public save() {
    if ( ! this.batch ) return;

    let new_batch = !this.batch.id;
    this.ui.confirm("Save Changes?", undefined, () => {
      this.saveBatch()
      .then(
        () => {
          if ( new_batch ) {
            if ( !! this.gridInstance ) {
              this.silent = false;
              this.gridInstance.extensionService.refreshBackendDataset();
            }
            else {
              this.initGrid();
            }
          }
          else {
            this.back();
          }
        },
        (error) => {
          this.ui.alert('Cannot save batch - {{error}}', {error: error});
        }
      );
    });
  }

  // add attendee
  public newAttendee: User|null = null;
  public addAttendee() {
    if ( ! this.batch ) return;

    this.newAttendee = null;
    this.addAttendeeModal.show()
    .then( () => {
      if ( ! this.newAttendee ) {
        return;
      }

      this.server.request('training.batch.attendee.add', null, {
        batch_id: this.batch!.id,
        user_id: this.newAttendee.id
      })
      .then( () => {
        if ( !! this.gridInstance ) {
          this.silent = false;
          this.gridInstance.extensionService.refreshBackendDataset();
        }
      });

    });
  }

  // navigate to course
  public onCourseNameClick() {
    if ( ! this.batch ) return;
    //this.nav.push(TrainingCoursePage, { id: this.batch.course.id });
    this.nav.push([ '/training/courses/view', this.batch.course.id ]);
  }

  // navigate to competency
  public onCompetencyClick(level: CompetencyLevel) {
    //this.nav.push(TrainingCompetencyPage, { id: level.competency_id });
    this.nav.push([ '/training/competencies/view', level.competency_id ]);
  }
}