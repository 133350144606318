<full-page [title]="document.title">
  <div page-content>
    <panel class="doc-lib">
      <div panel-body class="fluid">

        <h4 class="pad-btm bord-btm">
          <i class="pli-file icon-fw v-middle"></i>
          <span class="text-semibold">
            {{ has_prefix ? document.attr['title']['prefix'] + ' ' : '' }}
            {{ document.title || 'New Document' }}
            {{ has_suffix ? document.attr['title']['suffix'] : ''}}
          </span>
          <span *ngIf="!! document.id" class="text-thin small"> - {{ document.template_code ? 'Form: ' + document.template_code : document.doc_code }}</span>
        </h4>

        <form #mainForm="ngForm">

          <!-- upper block -->
          <div class="row">
            <!-- Left Panel -->
            <div class="col-xs-12 col-sm-4" id="allowed-groups">
              <ng-container *ngIf="api.is_confidential(document)" >
                <div class="text-bold pad-btm">{{ 'Permitted Persons' | translate }}:</div>
                <validation-errors
                  id="allow-persons-error"
                  [errors]="errors['allow-persons']"
                >
                </validation-errors>
                <user-card *ngFor="let user of document.mandatory_permitted_users" [user]="user!"></user-card>
                <!--
                <div *ngIf="api.getTeamMembers(project.internal_project_roles, team.name, 'leader').length == 0" class="role-not-assigned">
                  - No any user has been assigned to this role -
                </div>
                -->
                <user-card *ngFor="let user of document.optional_permitted_users" [user]="user!" [buttons]="[{
                  class: 'btn-danger',
                  icon: 'fa-regular fa-circle-xmark',
                  label: 'Remove',
                  closure: user,
                  action: revokeUserWrapper
                }]"></user-card>
                <user-lookup
                  [delay]="3000"
                  (onSelect)="permitUser($event)"
                ></user-lookup>
              </ng-container>
              <ng-container *ngIf="! api.is_confidential(document)" >
                <div class="text-bold pad-btm">{{ 'Allowed Groups' | translate }}:</div>
                <validation-errors
                  id="categories-error"
                  [errors]="errors['categories']"
                >
                </validation-errors>
                <tree #categoriesTree
                  [options]="categories_options"
                  [data]="api.names['categories']"
                  [expand]="true"
                  [(selected)]="document.categories"
                  (selectedChange)="onCategoriesChanged()"
                >
                </tree>
              </ng-container>
            </div>

            <!-- Right Panel -->
            <div class="col-xs-12 col-sm-8">

              <!-- section 1 (Reserve Mode): id reservation / form locking -->
              <div *ngIf="document.status == 'draft'" class="panel panel-bordered panel-default">

                <div class="panel" id="doc-registration" aria-expanded="true">
                  <div class="panel-body">

                    <!-- common registration attributes -->
                    <div class="pad-top row" id="doc-type">
                      <!-- select type, doc or memo -->
                      <div class="col-sm-12 form-group">
                        <label class="control-label" for="has_doc_code">
                          <input #has_doc_code
                            type="checkbox"
                            (change)="(has_code = ! has_doc_code.checked)"
                            [attr.checked]="! has_code ? '' : null"
                            name="has_doc_code"
                            id="has_doc_code"
                          >
                          {{'ลงทะเบียนบันทึก' | translate}}
                        </label>
                      </div>
                    </div>

                    <div class="pad-top row" id="doc-code">
                        <!-- document code - locked on first save  -->
                      <div *ngIf="has_code" class="col-sm-12">
                        <!-- document code display -->
                        <div>
                          <label class="control-label text-bold">{{ 'Document Code' | translate }}:</label>
                          <span class="text-thin" #doccode>{{ api.getDocCode(document) }}</span>
                          <copy-button
                            [src]="doccode"
                            (copied)="onCodeCopied($event)"
                          ></copy-button>
                        </div>
                        <!-- document code inputs -->
                        <doc-code-editor *ngIf="document.code" [(model)]="document.code" (onChange)="docCodeChange($event)" (hasError)="docCodeHasError($event)">
                          <button *ngIf="can_reserve"
                            type="button"
                            class="pull-right btn btn-primary"
                            (click)="lockID()"
                            #reserveButton
                          >
                            {{ 'Reserve Code' | translate }}
                          </button>
                        </doc-code-editor>
                      </div>

                      <!-- note form -->
                      <div *ngIf="! has_code" class="col-sm-12 pad-no" id="form-id">
                        <div class="row">
                          <!-- form template name -->
                          <div class="form-group col-sm-10">

                            <!-- template label -->
                            <div>
                              <label class="control-label text-bold">{{ 'Form Template' | translate }}:</label>
                              <span class="text-thin">{{ document.template_code }}</span>
                            </div>

                            <!-- template lookup -->
                            <div id="form-lookup" class="input-group">
                              <input #searchForm
                                type="text"
                                placeholder="{{ 'Search' | translate }}..."
                                name="form-name"
                                class="form-control"
                                [attr.disabled]="!!document.template_id ? 1 : null"
                              >
                              <span *ngIf="!document.template_id" class="input-group-btn">
                                <button class="btn btn-default"
                                  type="button"
                                  (click)="lookupForm(document.template_code!)"
                                >
                                  {{ 'Search' | translate }}
                                </button>
                              </span>
                            </div>
                            <lookup class="lookup"
                              [input]="searchForm"
                              [minChar]="3"
                              [items]="forms_list"
                              (onLookup)="lookupForm($event)"
                              (onSelect)="selectForm($event)"
                            >
                            </lookup>
                          </div>

                          <!-- template lock button -->
                          <div class="col-sm-2 form-group" id="lock-form-button">
                            <button *ngIf="can_lock_form && ! document.template_id"
                              title="Reserve and lock the document code"
                              type="button"
                              class="btn btn-primary"
                              (click)="lockForm()"
                            >
                              {{ 'Lock Form' | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- content form to register: hard or soft  -->
                    <div class="pad-top row">

                      <!-- domain -->
                      <div class="col-xs-12 col-sm-6 form-group">
                        <label class="text-bold form-label">{{ 'Registered by' | translate }}:</label>
                        <ngx-select #domain="ngModel" id="domain"
                          name="domain"
                          [items]="api.names['doc_code_formatter'].c[document.code?.b || 'SPW']"
                          [(ngModel)]="doc_domain"
                          required
                          (selectionChanges)="setDomain()"
                        >
                          <ng-template ngx-select-option-selected let-option>
                            {{ option.data.value }}
                          </ng-template>
                          <ng-template ngx-select-option let-option>
                            {{ option.data.data.label }}
                          </ng-template>
                        </ngx-select>
                        <validation-errors
                          [errors]="errors['domain']"
                        ></validation-errors>
                      </div>

                    </div>

                    <!-- document title -->
                    <div class="pad-top row" id="doc-title">
                      <div class="col-xs-12 form-group">
                        <label class="control-label text-bold">{{ 'Document Title' | translate }}:</label>
                        <div class="row">

                          <!-- document prefix -->
                          <div *ngIf="has_prefix" id="title-prefix" class="col-xs-12 col-sm-4">
                            <div>
                              <ngx-select #prefix="ngModel" class="full-width"
                                name="doc-title-prefix"
                                title="{{ 'Select document title prefix' | translate }}"
                                required
                                [attr.disabled]="document.status == 'draft' ? null : 1"
                                [items]="api.names['doc_prefix'][document.code!.f] | objectvalues"
                                (selectionChanges)="markDirty()"
                                [(ngModel)]="document.attr['title']['prefix']"
                              >
                                <ng-template ngx-select-option-selected let-option>
                                  <pre style="display:none;">{{ option|json }}</pre>
                                  {{ option.value }}
                                </ng-template>
                                <ng-template ngx-select-option let-option>
                                  <pre style="display:none;">{{ option|json }}</pre>
                                  {{ option.value }}
                                </ng-template>
                              </ngx-select>

                              <validation-errors
                                [models]="prefix"
                                [errors]="errors['prefix']"
                                [messages]="{ required: 'Title prefix is required' }"
                              ></validation-errors>
                            </div>
                          </div>

                          <!-- document title -->
                          <div class="col-xs-12 pad-no"
                            [ngClass]="{
                              'col-sm-4'  : has_prefix && has_suffix,
                              'col-sm-12' : ! has_prefix && ! has_suffix,
                              'col-sm-8'  : ( has_prefix && ! has_suffix ) || ( ! has_prefix && has_suffix )
                            }"
                          >
                            <input #title="ngModel"
                              type="text"
                              class="form-control"
                              required
                              name="title"
                              (change)="markDirty()"
                              [(ngModel)]="document.title"
                            >

                            <validation-errors
                              [models]="title"
                              [errors]="errors['title']"
                              [messages]="{ required: 'Document title is required' }"
                            ></validation-errors>
                          </div>

                          <!-- document suffix -->
                          <div *ngIf="has_suffix" id="title-suffix" class="col-xs-12 col-sm-4">
                            <input #suffix="ngModel"
                              type="text"
                              class="form-control"
                              name="title-suffix"
                              [attr.disabled]="document.status == 'draft' ? null : 1"
                              (change)="markDirty()"
                              [(ngModel)]="document.attr['title']['suffix']"
                            >
                            <validation-errors
                            [models]="suffix"
                            [errors]="errors['suffix']"
                            [messages]="{ required: 'Title prefix is required' }"
                          ></validation-errors>
                        </div>

                        </div>
                        <validation-errors
                          [errors]="errors['duplicated_title']"
                          [dirty]="true"
                        ></validation-errors>

                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <!-- end of section 1 -->

            </div>
            <!-- end of right panel -->

          </div>
          <!-- end of upper block -->

        </form>

      </div>
    </panel>
  </div>
</full-page>
