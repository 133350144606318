/**
 * routes for 'opl' module.
 *
 * NOTE: Register this route in /src/app/app-routing.config.ts
 */

// types and constants
import { NamedRoutes } from '@pinacono/common';

// guards
import { AppRoutingGuard } from 'src/app/app-routing.guard';

// pages
import { DrawingMasterPage } from './drawings/master/master';
import { DrawingSearchPage } from './drawings/search/search';

import { ProjectLibBrowsePage } from './browse/browse';
import { ProjectLibContactsPage } from './contacts/contacts';
import { ProjectLibEditPage } from './edit/edit';
import { ProjectLibRegisterPage } from './register/register';
import { ProjectLibViewPage } from './view/view';

import { ProjectLibDocumentEditPage } from './view/document';
import { ProjectLibDrawingEditPage } from './view/drawing';
import { ProjectLibReleaseBrowsePage } from './reports/releases/releases';
import { ProjectLibHandOverEditPage } from './handover/edit';

// routes configurations { [name: string]: Route } - see https://angular.io/api/router/Route for more details about 'Route' type
export const ROUTES_REGISTRY: NamedRoutes = {
  drawings_search: {
    path: 'drawings',
    component: DrawingSearchPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  drawings_masters: {
    path: 'drawings/masters',
    component: DrawingMasterPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true,
          hasPermissions: [ 'master_drawing_manage' ]
        }
      }
    }
  },

  projlib_browse: {
    path: 'project/browse/:name',
    component: ProjectLibBrowsePage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  projlib_register: {
    path: 'project/register',
    component: ProjectLibRegisterPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  projlib_contact: {
    path: 'project/contact/browse/:name',
    component: ProjectLibContactsPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  projlib_view: {
    path: 'project/view/:id',
    component: ProjectLibViewPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  projectlib_edit: {
    path: 'project/edit/:id',
    component: ProjectLibEditPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  projectlib_document_edit: {
    path: 'project/document/edit/:id',
    component: ProjectLibDocumentEditPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  projectlib_drawing_edit: {
    path: 'project/drawing/edit/:id',
    component: ProjectLibDrawingEditPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  /*
  projectlib_drawings_list: {
    path: 'project/drawing/list/:id',
    component: DrawingsListComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  */
  projectlib_release_browse: {
    path: 'project/releases',
    component: ProjectLibReleaseBrowsePage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },
  projectlib_handover_edit: {
    path: 'project/handover/:id',
    component: ProjectLibHandOverEditPage,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        }
      }
    }
  },

};