import * as moment from 'moment';

import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';

import { NgForm } from '@angular/forms';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  config,
  NavigationService,
  object_t,
  ServerService,
  SessionService,
  User,
  UtilsService
} from '@pinacono/common';

import {
  LookupEvent,
  LookupItem,
  ModalComponent,
  UIService
} from '@pinacono/ui';

import {
  ExcelExportService
} from '@slickgrid-universal/excel-export';

import {
  GraphqlFilteringOption,
  GraphqlPaginatedResult
} from '@slickgrid-universal/graphql';

import {
  AngularGridInstance,
  AngularUtilService,
  Column,
  FieldType,
  Filters,
  GridOption
} from 'angular-slickgrid';

import {
  ButtonsFormatter,
  ComponentFormatter,
  DataGridButton,
  DatetimeMomentFormatter,
  GraphQLServerService,
  GridUserComponent,
  LighthouseService
} from '@pinacono/slickgrid-extension';

import { BasePageComponent } from 'src/app/classes/base-page.component';

import { LibraryService } from '../library.service';
import {
  Book,
  BookInstance,
  BookReservation,
} from '../types';
import { HardcopyStatus } from '../../documents/types';

@Component({
  selector: 'library-admin',
  templateUrl: 'admin.html',
  styleUrls: [ 'admin.scss' ],
  providers: [ AngularUtilService ]
})
export class LibraryAdminPage extends BasePageComponent implements OnInit {

  // reservations
  public data: BookReservation[] = [];

  /**
   * graphQL configuration
   */

  protected readonly dataSetName: string = 'book_reservations';
  protected filters: GraphqlFilteringOption[] = [
    //{ field: 'menu', operator: 'EQ', value: 'published' }
  ];
  protected trashed: 'WITH'|'WITHOUT'|'ONLY' = 'WITHOUT';

  // grid interface
  protected gridComponent: AngularGridInstance|null = null;
  protected selectedRow: number = 0;
  public gridOptions: GridOption|null = null;
  public columnDefinitions: Column[] = [];

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public api: LibraryService,
    protected ngUtilService: AngularUtilService,
    protected nav: NavigationService,
    protected ui: UIService,
    protected server: ServerService,
    protected graphqlServer: GraphQLServerService,
    protected session: SessionService,
    protected utils: UtilsService
  ) {
    super(router, activatedRoute);
  }

  public override ngOnInit(): void {
    this.initGrid();
    super.ngOnInit();
  }

  protected silent: boolean = true;
  protected override loadData(): Promise<void> {
    if ( !! this.gridComponent ) {
      this.silent = false;
      this.gridComponent.extensionService.refreshBackendDataset();
    }
    return Promise.resolve();
  }

  // -- grid interfaces

  protected createDataModel(data: object_t): BookReservation {
    return this.api.createBookReservation(data);
  }

  protected processGraphQLQuery(query: string): Promise<GraphqlPaginatedResult> {
    return new Promise( (resolve) => {
      this.graphqlServer
      .sendQuery({query: query})
      .then(
        (res: object_t) => {
          // parse response
          let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
          this.data = re.data[this.dataSetName].nodes.map( d => this.createDataModel(d) );
          resolve(re);
        },
        (error: any) => {
          this.ui.alert(error.message, undefined, 'Error!');
          console.error('GrqphQL error', error);
        }
      );
    });
  }

  public onGridReady(event: Event) {
    //grid: AngularGridInstance
    this.gridComponent = (event as CustomEvent).detail as AngularGridInstance;
  }

  protected initGrid() {
    /** @TODO - update colum definitions */
    // -- Grid columns definitions
    this.columnDefinitions = [
      {
        id: 'code', name: 'Book Code',
        field: 'book.code',
        fields: [ 'book.doc_code' ],
        type: FieldType.object,
        cssClass: 'doc-code text-left', minWidth: 180, maxWidth: 180,
        sortable: true,
        filterable: true,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: BookReservation, grid: any): string => {
          return dataContext.book.doc_code || 'n/a';
        }
      },
      {
        id: 'book_id',  name: 'Title',
        field: 'book.title',
        fields: [ 'book.id', 'book.title' ],
        type: FieldType.object,
        sortable: true,
        filterable: true,
        minWidth: 100,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: BookReservation, grid: any): string => {
          return dataContext.book.title || 'n/a';
        }
      },

      {
        id: 'staff', name: 'Requester',
        field: 'user',
        fields: [ 'user.id', 'user.avatar', 'user.avatar.thumb_url', 'user.email', 'user.fullname', 'user.profiles.staff_id' ],
        type: FieldType.object,
        cssClass: 'text-left',
        width: 100,
        filterable: true,
        formatter: ComponentFormatter,
        params: {
          //component: GridUserComponent,
          factory: () => this.ngUtilService.createAngularComponent(GridUserComponent),
          config: {
            attribute: 'user'
          }
        }
      },

      {
        id: 'bar_code',  name: 'Instance Code',
        field: 'instance.code',
        fields: [ 'instance.location', 'approved_by.id', 'approved_by.email', 'approved_by.fullname', 'approved_by.profiles.staff_id', 'approve_date' ],
        type: FieldType.object,
        cssClass: 'text-center',
        sortable: true,
        filterable: true,
        width: 50,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: BookReservation, grid: any): string => {
          return dataContext.instance && dataContext.instance.code || 'n/a';
        }
      },

      {
        id: 'instance-status', name: 'Instance Status',
        field: 'instance_status',
        type: FieldType.string,
        cssClass: 'text-center',
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: BookReservation, grid: any): string => {
          const labels: object_t = {
            damage: { label: 'Damage', css: 'warning' },
            ok:     { label: 'OK',     css: 'success' },
            lost:   { label: 'Lost',   css: 'danger'  },
            na:     { label: 'N/A',    css: 'default' },
          }
          const status = dataContext.instance_status?.toLowerCase() || 'na';
          return `<span class="badge badge-${labels[status].css || 'default'}">${labels[status].label || 'n/a'}</span>`
        },
        sortable: true,
        filterable: true,
        width: 40,
        filter: {
          model: Filters.singleSelect,
          collection: [
            { value: 'OK',     label: 'OK'     },
            { value: 'lost',   label: 'Lost'   },
            { value: 'damage', label: 'Damage' },
          ]
        }
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        fields: [ 'status', 'extended'],
        type: FieldType.string,
        cssClass: 'text-center',
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: BookReservation, grid: any): string => {
          const labels: object_t = {
            requesting: { label: 'Requesting', css: 'warning' },
            approved:   { label: 'Approved',   css: 'success' },
            rejected:   { label: 'Rejected',   css: 'danger' },
            delivered:  { label: 'Delivered',  css: 'info' },
            returned:   { label: 'Returned',   css: 'dark' },
            //extended:   { label: 'Extended',   css: 'purple' },
            cancelled:  { label: 'Cancelled',  css: 'default' }
          }
          const status = dataContext.status.toLowerCase();
          return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>` + ( dataContext.extended ? ' <span class="badge badge-purple">Extended</span>' : '' );
        },
        sortable: true,
        filterable: true,
        width: 40,
        filter: {
          model: Filters.singleSelect,
          collection: [
            { value: 'requesting', label: 'Requesting' },
            { value: 'approved',   label: 'Approved'   },
            { value: 'rejected',   label: 'Rejected'   },
            { value: 'delivered',  label: 'Delivered'  },
            { value: 'returned',   label: 'Returned'   },
            //{ value: 'extended',   label: 'Extended'   },
            { value: 'cancelled',  label: 'Cancelled'  }
          ]
        }
      },

      {
        id: 'request_date', name: 'Request Date',
        field: 'request_date',
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'require_before', name: 'Require Before',
        field: 'require_before',
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'deliver_date', name: 'Collecting Date',
        field: 'deliver_date',
        fields: [ 'delivered_by.id', 'delivered_by.email', 'delivered_by.fullname', 'delivered_by.profiles.staff_id' ],
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'return_befire', name: 'Return Before',
        field: 'return_before',
        fields: [ 'return_before' ],
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'return_date', name: 'Return Date',
        field: 'return_date',
        fields: [ 'return_date', 'returned_to.id', 'returned_to.email', 'returned_to.fullname', 'returned_to.profiles.staff_id' ],
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      }
    ];

    // -- Grid options
    this.gridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.columnDefinitions,
          datasetName: this.dataSetName,
          persistenceFilteringOptions: this.filters,
          paginationOptions: {
            first: 20
          },
          extraQueryArguments: [
            { field: 'trashed', value: this.trashed }
          ]
        },

        //preProcess: ():void => {},
        process: this.processGraphQLQuery.bind(this),
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      enableExcelExport: true,
      registerExternalResources: [
        new ExcelExportService()
      ],
      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'reservations'
      },

      enableSorting: true,

      rowHeight: 60,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#main-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,

      presets: {
      }
    };
  }

  public walkin_mode: boolean = false;
  @ViewChild('modalReservation') modalReservation!: ModalComponent;
  public reservation: BookReservation|null = null;
  public async onSelectRow(event: Event) {
    this.selectedRow = (event as CustomEvent).detail.args['row'];
    const res: object_t = await this.server.show('library/reservation/book', this.data[this.selectedRow].id!, { with: 'book,instance,approved_by,delivered_by,returned_to' });
    this.reservation = this.api.createBookReservation(res);
    this.is_late = !! this.reservation.return_before && this.utils.dateTime_utils.to_moment(this.reservation.return_before, 'YYYY-MM-DD').isBefore();
    this.calcFine();
    this.walkin_mode = false;
    this.modalReservation.show();
  }

  // -- template API

  @ViewChild('reservationForm') reservationForm!: NgForm;
  public async createNewReservation() {
    this.walkin_mode = true;
    this.reservation = this.api.createBookReservation({
      status: 'approved',
      request_date:  this.utils.dateTime_utils.browser(),
      require_before: this.utils.dateTime_utils.browser()
    });
    this.is_late = false;
    this.calcFine();
    this.modalReservation.show();
  }

  public async saveReservation() {
    if ( ! this.reservation ) return;
    this.deliver_instance_errors = ! this.reservation.instance ? [ 'This field is required' ] : []
    const errors = await this.ui.validateForm(this.reservationForm);
    if ( Object.keys(errors).length > 0 ) {
      await this.ui.alert('Information is not completed. Please recheck.');
      return;
    }
    if ( this.reservation.id ) {
      await this.server.update('library/reservation/book', this.reservation.id!, this.reservation);
    }
    else {
      const res = await this.server.create('library/reservation/book', this.reservation);
      this.reservation.id = res.id;
    }
    this.refresh();
  }

  public async approveReservation() {
    if ( ! this.reservation ) return;
    if ( await this.ui.confirm('Approve this request?') ) {
      this.server.action('library/reservation/book', this.reservation.id!, 'approve');
      this.modalReservation.hide();
      this.refresh();
    }
  }

  // requester in walkin mode
  public selectRequester(user: User) {
    if ( ! this.reservation ) return;
    this.reservation.user = user;
    this.reservation.user_id = user.id.toString();
    console.log('selecRequester: ', this.reservation);
  }

  // book title
  public bookLookupItems: LookupItem<Book>[] = [];

  public async lookupBook(keyword: string) {
    try {
      const items = (await this.server.lookup('library/book', {
        title: keyword.trim()
      }, 5));

      this.bookLookupItems = items.map( (item: object_t) => this.api.createBook(item) )
        .map( (item: Book) => {
          return {
            label: item.title,
            value: item
          }
        });
    }
    catch (e: any) {
      this.bookLookupItems = [];
      this.ui.alert('Error - ' + e.message, undefined, 'Error!')
    }
  }

  public async selectBook(event: LookupEvent<Book>) {
    if ( ! this.reservation ) return;
    if ( event.value.value !== null ) {
      this.reservation.book = event.value.value;
      this.reservation.book_id = this.reservation.book.id;
    }
    console.log('selecBook: ', this.reservation);
  }

  // book instance

  public bookInstanceLookupItems: LookupItem<BookInstance>[] = [];
  /*
  public bookInstaceCode: string = '';
  public async lookupBookInstanceBarcodeChange(keyword: string) {
    if ( keyword.length == 13 ) {
      return this.lookupBookInstance(keyword);
    }
  }
  */

  public async lookupBookInstance(keyword: string) {
    if ( ! this.reservation ) return;
    try {
      const items = (await this.server.lookup('library/book/instance', {
        code: keyword.trim()
      }, 5, { book_id: this.reservation.book_id || null }));

    this.bookInstanceLookupItems = items.map( (item: object_t) => this.api.createBookInstance(item) )
      .filter( (item: BookInstance) => item.status == HardcopyStatus.available )
      .map( (item: BookInstance) => {
        return {
          label: `${item.code} - ${ item.location.branch } ${ item.location.cabinet && 'ตู้หมายเลข ' + item.location.cabinet } ${ item.location.shelf && 'ชั้น ' + item.location.shelf }`,
          value: item
        }
      });
    }
    catch (e: any) {
      this.bookInstanceLookupItems = [];
      this.ui.alert('Error - ' + e.message, undefined, 'Error!')
    }
  }

  public selectedBookInstance: string = '';
  public selectBookInstance(event: LookupEvent<BookInstance>) {
    if ( ! this.reservation ) return;
    this.selectedBookInstance = event.value.label;

    if ( event.value.value !== null ) {
      this.reservation.instance = ( event.value.value as BookInstance );
      this.reservation.instance_id = this.reservation.instance.id;

      if ( !! event.value.value.book ) {
        this.reservation.book = this.api.createBook(event.value.value.book);
        this.reservation.book_id = this.reservation.book.id;
      }
    }
    this.deliver_instance_errors = [];
  }

  public deliver_instance_errors: string[] = [];
  public async deliverReservation() {
    if ( ! this.reservation ) return;
    await this.saveReservation();
    if ( await this.ui.confirm('Delivery this request?') ) {
      await this.server.action('library/reservation/book', this.reservation.id!, 'deliver', {
        instance: this.reservation.instance_id,
        return_before: this.utils.dateTime_utils.browser(this.reservation.return_before)
      });
      this.modalReservation.hide();
      this.refresh();
    }
  }

  // -- fine calculator
  public is_late: boolean = false;
  public fine_details: { desc: string, fine: number }[] = [];
  public calcFine() {
    if ( ! this.reservation ) return;

    if ( this.reservation.fine && this.reservation.fine > 0 ) return;

    const rules: object_t = config('client.library');

    this.reservation.fine = 0;
    this.fine_details = [];

    if ( this.is_late ) {
      const days = 0 - this.utils.dateTime_utils.to_moment(this.reservation.return_before, 'YYYY-MM-DD').diff(this.utils.dateTime_utils.to_moment(), 'days');
      const fine = rules['fine'] * days;

      this.reservation.fine += fine;
      this.fine_details.push({
        desc: 'Late for ' + this.utils.number_utils.format(days) +' day(s)',
        fine: fine
      });
    }

    if ( this.reservation.instance_status == 'lost' ) {
      const fine = ( this.reservation.book.attr['BOOK'] && this.reservation.book.attr['BOOK'].price ) || 0;

      this.reservation.fine += fine;
      this.fine_details.push({
        desc: 'Book lost, price is ' + this.utils.number_utils.format(fine),
        fine: fine
      });
    }

    if ( this.reservation.instance_status == 'damage' ) {
      const fine = ( this.reservation.book.attr['BOOK'] && this.reservation.book.attr['BOOK'].price ) || 0;

      this.reservation.fine += fine;
      this.fine_details.push({
        desc: 'Book damage, price is ' + this.utils.number_utils.format(fine),
        fine: fine
      });
    }
  }

  public async extendReservation() {
    /** @todo - revise to use workflow */
    if ( ! this.reservation ) return;
    if ( await this.ui.confirm('Extend this request?') ) {
      await this.server.post('library/reservation/book/extend/{id}', { id: this.reservation.id! }, {
        return_before: moment(this.reservation.return_before).add(1, 'week').format('YYYY-MM-DD')
      });
      this.modalReservation.hide();
      this.refresh();
    }
  }

  public async returnReservation () {
    if ( ! this.reservation ) return;
    if ( await this.ui.confirm('Return this request?') ) {
      await this.server.action('library/reservation/book', this.reservation.id!, 'return', {
        instance_id: this.reservation.instance_id,
        instance_status: this.reservation.instance_status,
        fine: this.reservation.fine,
        note: this.reservation.note
      });
      this.modalReservation.hide();
      this.refresh();
    }
  }

  public closeReservation() {
    this.modalReservation.hide();
    this.reservation = null;
  }

  // branch mgmt and check-in/out

  public current_branch: string = "";

  public setBranchStatus(branch: string, event: Event) {
    this.api.setBranchStatus(branch, (event.currentTarget as HTMLInputElement).checked);
  }

  //public checkInStaff: string = '';
  public async checkin(user: string) {
    if ( this.current_branch.length == 0 ) {
      this.ui.alert('Please select branch first');
      return;
    }

    try {
      const res = await this.server.post('library/checkin', null, {
        staff_id: user,
        location: this.current_branch
      });

      if ( res ) {
        this.ui.alert(res.user.fullname + ' check-in success.');
      }
    }
    catch ( $e: any ) {
      this.ui.alert('Staff id: ' +user + ' could not be found.');
    }
  }

  //public checkOutStaff: string = '';
  public async checkout(user: string) {
    if ( this.current_branch.length == 0 ) {
      this.ui.alert('Please select branch first');
      return;
    }
    try {
      const res = await this.server.rejectOnError(true).post('library/checkout', null, {
        staff_id: user,
        location: this.current_branch
      });

      if ( res ) {
        this.ui.alert(res.user.fullname + ' check-out success.');
      }
    }
    catch ( $e: any ) {
      this.ui.alert('Staff id: ' +user + ' was not checked-in.');
    }
  }
}