import {
  Component,
  Input,
  OnInit,
  OnChanges, SimpleChanges,
  Output, EventEmitter,
  ViewEncapsulation,
  ChangeDetectorRef,
} from "@angular/core";

import { ServerService, SessionService, Taxonomy, User } from "@pinacono/common";
import { Tree, UIService } from "@pinacono/ui";

@Component({
  selector: 'select-groups',
  templateUrl: 'select-groups.html',
  styleUrls: [ './select-groups.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class SelectGroupsComponent implements OnInit, OnChanges {
  @Input() selected: Taxonomy[] = [];
  @Output() selectedChange = new EventEmitter<Taxonomy[]>();

  public groups: Taxonomy[] = [];
  public options: Tree.Options = {
    core: {
      themes: {
        name: 'proton',
        icons: false,
        dots: true
      },
      multiple: true
    },
    checkbox: {
      three_state: false,
      cascade: 'up+down'
    }
  };

  // -- lifecycle

  constructor(
    public server: ServerService,
    public session: SessionService,
    public ui: UIService,
    protected cdr: ChangeDetectorRef,
  ) {
  }

  public async ngOnInit(): Promise<void> {
    this.groups = await this.server.show('terms', 1);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ( changes['selected'] ) {
      this.cdr.detectChanges();
    }
  }
}