import { Candidate } from './../types';
import {
  Component,
  ViewChild
} from "@angular/core";

import {
  Router,
  ActivatedRoute
} from "@angular/router";

import * as XLSX from 'xlsx';

import {
  FileUtilsService,
  NavigationService,
  object_t,
  ServerService,
  SessionService,
  Taxonomy,
  User
} from "@pinacono/common";

import {
  LookupEvent,
  LookupItem,
  ModalComponent,
  UIService
} from "@pinacono/ui";

import { BasePageComponent } from 'src/app/classes/base-page.component';
import { DocLibService } from "src/app/modules/documents/doclib.service";

import { TrainingService } from '../training.service';
import { Competency, JobDescription, TrainingCourse } from "../types";

// --

@Component({
  selector: 'training-reports-page',
  templateUrl: 'reports.html',
  styleUrls: [ 'reports.scss' ]
})
export class TrainingReportsPage extends BasePageComponent {

  @ViewChild('selectUsersFromListModal') selectUsersFromListModal!: ModalComponent;
  @ViewChild('selectUsersByGroupModal') selectUsersByGroupModal!: ModalComponent;

  public root: Taxonomy[] = [];
  public selectedUsers: User[] = [];
  public from: string|null = null;
  public to: string|null = null;

  public usersList: User[] = [];

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: TrainingService,
    public docApi: DocLibService,
    protected server: ServerService,
    protected fileUtils: FileUtilsService
  ) {
    super(router, activatedRoute);

    this.report2Skill = this.api.createCompetency();
    this.lookupCompetencyItems['report2'] = [];

    this.report3Skill = this.api.createCompetency();
    this.lookupCompetencyItems['report3'] = [];

    this.report4Job   = this.api.createJobDescription();
    this.lookupJobItems['report4'] = [];

    this.report5Skill = this.api.createCompetency();
    this.lookupCompetencyItems['report5'] = [];

    this.report6Skill = this.api.createCompetency();
    this.lookupCompetencyItems['report6'] = [];

    this.report7Skill = this.api.createCompetency();
    this.lookupCompetencyItems['report7'] = [];

    this.report8Job   = this.api.createJobDescription();
    this.lookupJobItems['report8'] = [];
  }

  public async run(name: string, params1: object_t, params2?: object_t) {

    // validate per report type
    if ( !! params1['error'] ) {
      this.ui.alert(params1['error']);
      return;
    }

    const params = { ...params1, ...params2 };
    /* for debugging
    console.log('run', name, params);
    return;
    */
    let res: any[] = await this.server.request(`training.reports.${name}`, null, params)

    // post processing
    switch ( name ) {

      case 'type4':

        /* keep as example - we can use 'local' interface definition
        interface Candidate {
          user_id: number,
          fullname: string,
          staff_id: string,
        };
        */

        const candidate_columns = [ 'actual', 'target', 'egap', 'tgap' ];
        let rows: string[][] = [];

        // parse headers
        const headers: string[][] = [];
        headers[0] = Object.keys(res[0]).filter( k => k !== 'candidates' );
        headers[1] = headers[0].map( k => ' ' );
        const common_columns = headers[0].slice();
        const candidates = JSON.parse(res[0].candidates);

        // add candidate headers
        for ( let cd of candidates ) {
          headers[0].push(cd.fullname, ' ', ' ', ' '); // skip cells to be merge
          for ( let k of candidate_columns ) {
            headers[1].push(k);
          }
        }
        rows = rows.concat(headers);

        // parse candidates, which is in JSON format
        res.forEach( (row: any, index: number) => {
          const candidates = JSON.parse(row.candidates);
          const r: string[] = [];

          for ( let k of common_columns ) {
            r.push(row[k]);
          }

          for ( let cd of candidates ) {
            r.push( cd.actual, cd.target, cd.egap, cd.tgap );
          }

          rows.push(r);
        });

        // create cell merging based on number of candidates in the query
        const cell_merge: XLSX.Range[] = []

        for ( let i = 0; i < common_columns.length; i++ ) {
          cell_merge.push( { s: { r: 0, c: i }, e: { r: 1, c: i } } );
        }
        //  5-actual1 / 6-target1 / 7-egap1 / 8-egap1 / 9-actual2 ...
        for ( let i = 0; i < candidates.length; i++ ) {
          cell_merge.push( { s: { r: 0, c: ( i * candidate_columns.length ) + common_columns.length }, e: { r: 0, c: ( i * candidate_columns.length ) + common_columns.length + candidate_columns.length - 1 } } );
        }

        const ws = XLSX.utils.aoa_to_sheet(rows);
        ws["!merges"] = cell_merge;
        return this.fileUtils.saveXLSX(name, rows, ws);
    }

    this.fileUtils.saveXLSX(name, res);
  }

  // -- course name lookup

  /*
  public lookupCourseItems: { [key: string]: LookupItem<TrainingCourse>[] } = {};
  public async lookupCourses(keyword: string, name: string) {
    const res: object_t[] = await this.server.lookup('training/courses', {
      keyword: keyword,
    }, 5);

    this.lookupCourseItems[name] = res.map( c => {
      const course = this.api.createCourse(c);
      return {
        label: `${course.code}: ${course.name}`,
        value: course
      }
    });
  }

  public selectCourse(event: LookupEvent<TrainingCourse>, model: TrainingCourse) {
    if ( event.value.value === null ) {
      return;
    }
    model.id   = event.value.value.id;
    model.name = event.value.value.name;
    model.code = event.value.value.code;
  }
  */

  // -- competency name lookup

  /** design note: need to store in an object, since JS does not support pass by ref */
  public lookupCompetencyItems: { [key: string]: LookupItem<Competency>[] } = {};
  public async lookupCompetencies(keyword: string, name: string) {
    const res: object_t[] = await this.server.lookup('training/competencies', {
      keyword: keyword,
    }, 5);

    this.lookupCompetencyItems[name] = res.map( c => {
      const skill = this.api.createCompetency(c);
      return {
        label: `${skill.code}: ${skill.name}`,
        value: skill
      }
    });
  }

  public selectCompetency(event: LookupEvent<Competency>, model: Competency) {
    if ( event.value.value === null ) {
      model.id = undefined;
      return;
    }
    model.id   = event.value.value.id;
    model.name = event.value.value.name;
    model.code = event.value.value.code;
  }

  // -- job title name lookup

  public lookupJobItems: { [key: string]: LookupItem<JobDescription>[] } = {};
  public async lookupJobs(keyword: string, name: string) {
    const res: object_t[] = await this.server.lookup('training/jobs', {
      keyword: keyword,
    }, 5);

    this.lookupJobItems[name] = res.map( c => {
      const job = this.api.createJobDescription(c);
      return {
        label: `${job.code}: ${job.title}`,
        value: job
      }
    });
  }

  public selectJob(event: LookupEvent<JobDescription>, model: JobDescription) {
    if ( event.value.value === null ) {
      model.id = undefined;
      return;
    }
    model.id    = event.value.value.id;
    model.title = event.value.value.title;
    model.code  = event.value.value.code;
  }

  // -- user selection
  public async selectUsers(list: User[]) {
    this.selectedUsers = list;
    this.selectUsersByGroupModal.show();
  }

  public removeSelectedUser(list: User[], user: User) {
    const i = list.findIndex( (u: User) => u.id === user.id );
    if ( i >= 0 )  list.splice(i,1);
  }

  // -- report 1

  public report1SelectedUsers: User[] = [];
  public parseReport1Params(): object_t {
    return {
      users: this.report1SelectedUsers.map( (u: User) => { return u.id.toString() }) || [],
      from: this.from,
      to: this.to
    };
  }

  // -- report 2

  public report2SelectedUsers: User[] = [];
  public report2Skill: Competency;
  public parseReport2Params(): object_t {
    return {
      users: this.report2SelectedUsers.map( (u: User) => { return u.id.toString() }) || [],
      from: this.from,
      to: this.to,
      skill: this.report2Skill && this.report2Skill.id
    };
  }

  // -- report 3

  public report3SelectedUsers: User[] = [];
  public report3Skill: Competency;
  public parseReport3Params(): object_t {
    return {
      users: this.report3SelectedUsers.map( (u: User) => { return u.id.toString() }) || [],
      skill: this.report3Skill && this.report3Skill.id
    };
  }

  // -- report 4

  public report4SelectedUsers: User[] = [];
  public report4Job: JobDescription;
  public report4LookupJobItems: LookupItem<JobDescription>[] = [];
  public parseReport4Params(): object_t {
    const users = this.report4SelectedUsers.map( (u: User) => { return u.id.toString() }) || [];
    if ( users.length === 0 ) {
      return {
        error: 'Please select at least one user.'
      }
    }

    return {
      users: users,
      job: this.report4Job && this.report4Job.id
    };
  }

  // -- report 5

  public report5SelectedUsers: User[] = [];
  public report5Skill: Competency;
  public async selectTrainers(list: User[]) {
    this.usersList = await this.server.post('training/trainers', null, {});
    this.selectedUsers = list;
    await this.selectUsersFromListModal.show();
  }

  public parseReport5Params(): object_t {
    return {
      trainers: this.report5SelectedUsers.map( (u: User) => { return u.id.toString() }) || [],
      skill_id: this.report5Skill && this.report5Skill.id,
      level: this.skill_level
    };
  }

  // -- report 6

  public report6Skill: Competency;
  public operator_options: { label: string, value: string }[] = [
    { label: '=',  value: 'eq' },
    { label: '>',  value: 'gt' },
    { label: '>=', value: 'ge' },
    { label: '<',  value: 'lt' },
    { label: '<=', value: 'le' }
  ];

  public operator: 'eq'|'gt'|'ge'|'lt'|'le' = 'eq';
  public skill_level: number = 0;

  public parseReport6Params(): object_t {
    return {
      skill_id: this.report6Skill && this.report6Skill.id,
      operator: this.operator,
      level: this.skill_level
    };
  }

  // -- report 7
  public report7Skill: Competency;
  public parseReport7Params(): object_t {
    return {
      skill_id: this.report7Skill && this.report7Skill.id
    };
  }

  // -- report 8
  public report8Job: JobDescription;
  public report8LookupJobItems: LookupItem<JobDescription>[] = [];
  public parseReport8Params(): object_t {
    return {
      job_id: this.report8Job && this.report8Job.id
    };
  }

}