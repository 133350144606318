import {
  AfterViewInit,
  Component,
} from "@angular/core";

import {
  Router,
  ActivatedRoute
} from "@angular/router";

import {
  NavigationService,
  object_t,
  ServerService,
  SessionService,
  User
} from "@pinacono/common";

import {
  UIService
} from "@pinacono/ui";

import { BasePageComponent } from 'src/app/classes/base-page.component';

import {
  Candidate,
  CompetencyProfile,
  JobCompetency,
  JobDescription
} from '../types';

import { TrainingService } from '../training.service';
import { ThemeService } from "src/app/themes/theme.service";

@Component({
  selector: 'training-gaps-comparator-page',
  templateUrl: 'comparator.html',
  styleUrls: [ 'comparator.scss' ]
})
export class TrainingGapsComparatorPage extends BasePageComponent implements AfterViewInit {

  public job_description: JobDescription|null = null;
  public candidates: Candidate[] = [];
  public candidates_count: number = 1;

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public server: ServerService,
    public ui: UIService,
    public theme: ThemeService,
    public api: TrainingService
  ) {
    super(router, activatedRoute);
  }

  // -- lifecycle
  public override loadData(): Promise<void> {
    let user_ids: string[]  = (this.activatedRoute.snapshot.paramMap.get('candidates') || '').split(',');
    let job_id: string|null = this.activatedRoute.snapshot.paramMap.get('job');

    if ( user_ids.length <= 0 || ! job_id )  {
      if ( this.nav.canGoBack() ){
        this.nav.pop();
      }
      else {
        this.nav.setRoot('/');
      }
      return Promise.resolve();
    }

    this.candidates_count = user_ids.length;

    return new Promise<void>( (resolve, reject) => {
      this.job_description = null;
      this.candidates = [];

      return this.server.show('training/jobs', parseInt(job_id!))
      .then( (res: object) => {
        this.job_description = this.api.createJobDescription(res);
      })
      .then( () => {
        let promises: Promise<void>[] = [];

        for ( let id of user_ids ) {
          let uid = parseInt(id);
          promises.push(
            this.server.show('users', uid)
            .then( (u: User) => {
              this.server.get('training.profile.list', { uid: uid })
              .then( (res: object_t[]) => {
                /*
                let profiles = res.map( (p : object) => this.api.createCompetencyProfile(p) );
                this.candidates.push({
                  staff: u,
                  profiles: profiles
                });
                */
                const profiles: CompetencyProfile[] = [];
                this.job_description!.competencies.forEach( (c: JobCompetency) => {
                  let p = res.find( (p) => this.api.createCompetencyProfile(p).competency_id == c.competency.id );

                  /** debugging */
                  //console.debug('compiling competency', c);
                  //if ( !! p ) console.debug('existing competency - ', p);
                  /** end of debugging */

                  if ( ! p ) {
                    p = {
                      competency_id: c.competency.id,
                      competency: c.competency,
                      user_id: u.id,
                      user: u,
                    };

                    /** debugging */
                    //console.debug('non-existing competency - ', p);
                    /** end of debugging */
                  }
                  profiles.push(this.api.createCompetencyProfile(p));
                });
                this.candidates.push({
                  staff: u,
                  profiles: profiles
                });
                //console.debug('comparator.ts', this.candidates);
              })
            })
          );
        }

        return Promise.all(promises);
      });
    });
  }

  public ngAfterViewInit() {
    this.theme.maximizeContentArea();
  }

  // -- template API
  public onCompetencyClick(competency_id: number) {
    //this.nav.push(TrainingCompetencyPage, { id: competency_id } );
    this.nav.push([ '/training/competencies/view', competency_id  ]);
  }

  public back() {
    if ( this.nav.canGoBack() ) {
      this.nav.pop();
    }
    else {
      this.nav.setRoot('/');
    }
  }
}