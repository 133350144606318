import {
  Component,
  ViewChild
} from '@angular/core';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  Attachment,
  MIMEUtilsService,
  NavigatableLink,
  NavigationService,
  object_t,
  ServerService,
  SessionService,
  Taxonomy
} from '@pinacono/common';

import {
  Tree,
  TreeComponent,
  UIService
} from '@pinacono/ui';

import { BasePageComponent } from 'src/app/classes/base-page.component';
import { AppCommonService } from 'src/app/common/app-common.service';

import moment from 'moment-timezone';

import { DocLibService } from '../doclib.service';
import { AccessRequest, DocFile, Document, Revision } from '../types';

@Component({
  selector: 'page-doclib-view',
  templateUrl: 'view.html',
  styleUrls: [ 'view.scss' ]
})
export class DocLibViewPage extends BasePageComponent {

  // data
  public doc: Document|null = null;
  public request: AccessRequest|null = null;

  // -- initialization and lifecycle

  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
    protected session: SessionService,
    protected server: ServerService,
    protected mimeUtils: MIMEUtilsService,
    protected ui: UIService,
    public api: DocLibService,
    public commonApi: AppCommonService
  ) {
    super(router, activatedRoute);
    this.doc = api.createDocument();
  }

  // -- overloading

  protected with = [
    'files',
    'uploader'
    //'files.revisions',
    //'files.revisions.hardcopies'
  ];
  protected override loadData(): Promise<any> {
    let id = this.activatedRoute.snapshot.paramMap.get('id');

    if ( id === null ) {
      this.nav.setRoot('/doc/browse');
      return Promise.resolve();
    }

    // process access request
    if ( id == '@' ) {
      // access
      const access_code = this.activatedRoute.snapshot.paramMap.get('accesscode') || this.activatedRoute.snapshot.queryParamMap.get('accesscode') || null;
      if ( access_code !== null ) {
        return this.server.request('doclib.access', { access_code: access_code, with: this.with.join(',') })
        .then( (doc: object_t) => {
          this.doc = this.api.createDocument(doc, {active: true});
        });
      }

      // request
      const request_id = this.activatedRoute.snapshot.paramMap.get('rid') || this.activatedRoute.snapshot.queryParamMap.get('rid') || null;
      if ( request_id !== null && this.session.hasPermission(['doc_access_grant']) ) {
        return this.server.rejectOnError(true)
        .request('doclib.access.info', { request_id: request_id, with: this.with.join(',') })
        .then( (request: AccessRequest) => {
          this.request = request;
          this.doc = this.api.createDocument(request.document);
        })
        .catch( (e: Error) => {
          this.ui.alert('Request id {{id}} could not be found!', { id: request_id });
          this.nav.setRoot('/doc/browse');
        });
      }

      // access by document code
      const doc_code = this.activatedRoute.snapshot.paramMap.get('doccode') || this.activatedRoute.snapshot.queryParamMap.get('doccode') || null;
      if ( doc_code !== null ) {
        return this.server.request('doclib.get.by_code', { code: doc_code, with: this.with.join(',') })
        .then( (doc: object_t) => {
          if ( ! doc['id'] )  {
            this.ui.alert('Cannot find document with code {{ code }}', { code: doc_code })
            .then( () => {
              this.back();
            });
          }
          else {
            this.doc = this.api.createDocument(doc, {active: true});
          }
        });
      }
    }

    return this.server.rejectOnError(true)
    .show('docs', parseInt(id), { with: this.with.join(',') } )
    .then( (doc: Document) => {
      // block confidential document
      /*
      if ( this.api.is_confidential(doc) && ! this.session.hasPermission(['doc_access_grant']) ) {
        this.ui.alert('You do not have permission to access this document.');
        this.nav.setRoot('/doc/browse');
      }
      else {
        this.doc = this.api.createDocument(doc, {active: true});
      }
      */
      this.doc = this.api.createDocument(doc, {active: true});
      this.doc.files.forEach( f => f.active_revisions.reverse() );
    })
    .catch( (e: Error) => {
      console.warn(e.message);
      if ( (e as any).code == 403 ) {
        this.ui.alert( 'Document id {{id}} is not accessible!', {id: id});
        this.nav.setRoot('/403');
      }
      else {
        this.ui.alert( 'Document id {{id}} could not be found!', {id: id});
        this.nav.setRoot('/doc/browse');
      }
    });
  }

  // -- permissions

  public get can_manage(): boolean {
    if ( this.doc && this.api.is_confidential(this.doc) ) {
      return this.api.can_edit_confidential(this.doc);
    }
    return this.session.hasPermission(['doc_review', 'doc_manage']);
  }

  public get can_approve(): boolean {
    if ( this.doc === null ) return false;

    if ( this.doc.status != 'submitted' || this.doc.deleted_at != null )  {
      return false;
    }

    if ( this.can_manage ) {
      return true;
    }

    if ( this.session.hasPermission(['core_manage_group'], this.doc.categories.map( (c:Taxonomy) => c.id || 0 ) ) ) {
      return true;
    }

    return false;
  }

  public get can_reject(): boolean {
    if ( this.doc === null ) return false;

    if ( this.doc.status == 'pending' || this.doc.status == 'published' || this.doc.deleted_at != null )  {
      return false;
    }

    if ( this.can_manage ) {
      return true;
    }
    if ( this.session.hasPermission(['core_manage_group'], this.doc.categories.map( c => c.id || 0 ) ) ) {
      return true;
    }

    return false;
  }

  public get can_review(): boolean {
    if ( this.doc === null ) return false;

    if ( this.doc.status != 'approved' || this.doc.deleted_at != null ) {
      return false;
    }

    return this.can_manage;
  }

  public get can_publish(): boolean {
    if ( this.doc === null ) return false;

    if ( this.doc.status != 'reviewed' || this.doc.deleted_at != null ) {
      return false;
    }

    return this.session.hasPermission(['doc_manage']);
  }

  public get can_edit(): boolean {
    if ( this.can_manage ) {
      return true;
    }
    if ( this.doc === null || this.doc.uploader_id === null ) return false;

    return ( ['draft', 'reserved'].indexOf(this.doc.status) >= 0 && this.doc.uploader_id == this.session.currentUser!.id );
  }

  protected typed_attributes: string[] = ['BOOK','PRJ','DWG'];
  public get has_type_specific_attributes(): boolean {
    if ( this.doc === null ) return false;
    return !! this.typed_attributes.find( s => s == this.doc?.code?.f);
  }

  // -- template utilities

  public expired_in(date: string): number {
    return moment(date).diff(moment(), 'days');
  }

  public split(s: string): string[] {
    if ( ! s ) return [];
    return s.split("\n");
  }

  // -- template API

  public getFileIcon(mime: string) {
    return this.mimeUtils.getFileIcon(mime);
  }

  public edit() {
    if ( this.doc !== null && this.can_edit ) {
      this.nav.push(['doc/edit', this.doc.id]);
    }
  }

  public approve() {
    if ( this.doc === null ) return;
    this.ui.confirm('Approve this document?', undefined, () => {
      this.server.request('doclib.approve', {id: this.doc!.id})
      .then( () => {
        this.back();
      });
    });
  }

  public reject() {
    if ( this.doc === null ) return;
    this.ui.confirm('Reject this document?', undefined, () => {
      this.server.request('doclib.reject', {id: this.doc!.id})
      .then( () => {
        this.refresh(); }
      );
    });
  }

  public review() {
    /*
    if ( this.doc.revisions.data.filter( r => r.active ).length == 0 ) {
      this.ui.alert('No active file(s)!');
      return;
    }
    this.ui.confirm('Mark this document as approved?', null, () => {
      this.server.request('doclib.review', {id: this.doc.id})
      .then( () => {
        this.refresh();
      });
    });
    */
  }

  public publish() {
    /*
    if ( this.doc.revisions.data.filter( r => r.active ).length == 0 ) {
      this.ui.alert('No active file(s)!');
      return;
    }
    this.ui.confirm('Publish this document?', null, () => {
      this.server.request('doclib.publish', {id: this.doc.id})
      .then( () => {
        this.refresh();
      });
    });
    */
  }

  public view(code: string) {
    this.server.get('doclib.get.by_code', {code: code})
    .then( (doc: object_t) => {
      if ( !! doc['id'] ) {
        this.nav.push(['/doc/view', doc['id']]);
      }
      else {
        this.ui.alert(`Cannot find document with code ${code}`);
      }
    });
  }

  public grant() {
    if ( this.request === null ) return;
    this.ui.confirm("Grant access on this file to {name}?", { name: this.request.requester.fullname }, () => {
      this.server.request('doclib.access.grant', {request_id: this.request!.id});
      this.back();
    });
  }

  public deny() {
    if ( this.request === null ) return;
    this.ui.confirm("Deny access on this file to {name}?", { name: this.request.requester.fullname }, () => {
      this.server.request('doclib.access.deny', {request_id: this.request!.id});
      this.back();
    });
  }

  public back() {
    this.nav.pop(false, 'doc/browse/default');
  }

  public gotoContent() {
    if ( this.doc && this.commonApi.hasModuleContent(this.doc) ) {
      this.nav.push(this.commonApi.generateLinkToModuleContent(this.doc));
    }
  }

  public download(file: Attachment) {
    this.server.request('doclib.file.download', { url: file.download_url });
  }

  /*
  public trash(doc: Document) {
    this.modal.hide();

    this.ui.confirm('Mark this document as deleted?', null, () => {
      this.server.destroy('docs', doc.id)
      .then(() => this.refresh(););
    });

  }

  public purge(doc: Document) {
    this.modal.hide();

    this.ui.confirm('Permanently delete this document? This will not be able restore anymore!', null, () => {
      this.server.purge('docs', doc.id)
      .then(() => this.refresh(););
    });

  }

  public restore(doc: Document) {
    this.modal.hide();

    this.ui.confirm('Undete this document?', null, () => {
      this.server.restore('docs', doc.id)
      .then(() => this.edit(doc););
    });
  }

  public remind(file: Document) {
    this.ui.confirm('Remind Document Manager to publish the "{{ title }}"?', { title: file.title }, () => {
      // @TODO - send reminder
      this.ui.alert('Reminder was successfully sent to Document Manager');
    });
  }
  */

  public loadPaginatedRevisions(file: DocFile, pageno: number) {
    this.api.loadPaginatedRevisions(file, {active: true}, { pageno: pageno, perpage: file.revisions.perpage });
  }

  public loadPaginatedHardcopies(rev: Revision, pageno: number) {
    this.api.loadPaginatedHardcopies(rev, {active: true}, { pageno: pageno, perpage: rev.hardcopies.perpage });
  }
}
