import { TranslateService } from '@ngx-translate/core';
import {
  Injectable
} from "@angular/core";


import {
  Attachment,
  config,
  NavigatableLink,
  NavigationService,
  object_t,
  PaginatedResults,
  Paging,
  ServerService,
  SessionService,
  Taxonomy,
  TaxonomyService,
  TimeStampedModel,
  User,
  UserService,
  UtilsService
} from "@pinacono/common";

import {
  Tree,
  UIService
} from "@pinacono/ui";

import { AppCommonService } from 'src/app/common/app-common.service';

import {
  GridFilterOption,
  AccessControlList,
  Document,
  DocFile,
  Hardcopy,
  Revision,
  Softcopy,
  HardcopyLocation,
  HardcopyStatus as HardcopyStatusType,
  DocCode,
  //DocScheduleAction,
  DocSchedule
} from './types';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';

declare let sprintf: any;  // to use sprintf-js

/**
 * wrapper for document service
 */
@Injectable()
export class DocLibService {

  public names: object_t = {};
  public libraries_locations: string[] = [];

  // -- initialization

  constructor(
    protected translate: TranslateService,
    protected nav: NavigationService,
    protected ui: UIService,
    protected session: SessionService,
    protected server: ServerService,
    protected taxonomy: TaxonomyService,
    protected userService: UserService,
    protected utils: UtilsService,
    protected app: AppCommonService
  ) {
    this.session.LOGGEDIN.subscribe( (u: User|null) => {
      if ( u === null ) return;
      // get library configurations
      this.server.getconfig('library')
      .then( (cfg: object_t) => {
        this.names['cabinets'] = cfg['locations'];
        //this.libraries_locations = cfg['locations'].sort( (a: string, b: string) => ( a > b) ? 1 : ( a < b) ? -1 : 0 );
        this.libraries_locations = Object.getOwnPropertyNames(cfg['locations']); // @todo determine with user should we sort? if yes, sort by the key
      }),

      this.loadDocumentAllowedGroups();

      this.names['doc_code_mappings']  = config('client.spw.doc_code_mappings', {});
      //this.names['status'] = config('client.doc.status', {}); // not used at the moment

      let doc_code = config('client.doc.code_options', {});
      let g_segment: object_t = {};
      for ( let name of Object.getOwnPropertyNames(doc_code['g']) ) {
        g_segment[name] = this.parseSelectOption(doc_code['g'][name]);
      }
      this.names['doc_code_formatter'] = {
        a: this.parseSelectOption(doc_code['a']),
        b: this.parseSelectOption(doc_code['b']),
        c: [],
        d: this.parseSelectOption(doc_code['d']),
        e: this.parseSelectOption(doc_code['e']),
        f: this.parseSelectOption(doc_code['f']),
        g: g_segment
      };

      Object.keys(doc_code['c']).forEach( (k: string) => {
        this.names['doc_code_formatter']['c'][k] = this.parseSelectOption(doc_code['c'][k])
      });

      // check config in /config/spw/modules/doc.php
      this.names['locations']      = Object.getOwnPropertyNames(config('client.doc.locations', {}));
      this.names['doc_prefix']     = config('client.doc.prefix', {});
      this.names['doc_suffix']     = config('client.doc.suffix', {});
      this.names['doc_attributes'] = config('client.doc.types', {}); // document specific attributes
    });
  }

  public async loadDocumentAllowedGroups() {
    this.names['categories'] = []; // default
    const res: Taxonomy = await this.server.request('doclib.categories');
    this.names['categories'] = res;
  }

  // see https://optimistex.github.io/ngx-select-ex/
  public parseSelectOption(options: object_t): GridFilterOption[] {
    let res: GridFilterOption[] = [];
    for ( let k in options ) {
      res.push({
        value: k,
        text:  k,
        label: k,
        disabled: false,
        data: {
          label: `${k} - ${options[k]}`
        }
      });
    }
    return res;
  }

  // -- API

  /**
   * does the title require prefix?
   */
  public has_prefix(document: Document): boolean {
    if ( ! document.code || ! document.code.f || !! document.template_id ) {
      return false;
    }
    let prefix = this.names['doc_prefix'][document.code.f];
    return  !! prefix && ! Array.isArray(prefix) && Object.getOwnPropertyNames(prefix).length > 0;
  }

  /**
   * does the title require suffix?
   */
   public has_suffix(document: Document): boolean {
    return document.code && document.code.f && this.names['doc_suffix'][document.code.f];
  }

  /**
   *
   */

  public get HardcopyStatus(): string[] {
    return Object.values(HardcopyStatusType);
    //return Object.keys(HardcopyStatusType).map( k => HardcopyStatusType[k] );
  }

  public getMostRecentDocSchdeule(doc: Document, action: string = 'expire'): Date|null {
    let schedules = doc.schedules
                    .filter( (s: DocSchedule) => s.action == action )
                    .map( (s: DocSchedule) => moment.parseZone(s.schedule) )
                    .sort( (a: moment.Moment, b: moment.Moment) => a.diff(b, 'days') );
    return schedules.length > 0 ? schedules[0].toDate() : null;
  }

  // -- Creators

  public createAccessControlList(d: object_t|null = null): AccessControlList {
    let acl : AccessControlList = {
      id: d && d['id'] || null,
      accessible_type: d && d['accessible_type'] || null,
      accessible_id:   d && d['accessible_id']   || null,

      accessor_id:   d && d['accessor_id']   || null,
      accessor_type: d && d['accessor_type'] || 'default',

      list:   d && d['list']   || false,
      create: d && d['create'] || false,
      read:   d && d['read']   || false,
      update: d && d['update'] || false,
      delete: d && d['delete'] || false,
      accessor: undefined
    };
    if ( acl.accessor_type == 'user' ) {
      acl.accessor = d && d['accessor'] || undefined;
    }
    if ( acl.accessor_type == 'taxonomy' ) {
      acl.accessor = d && this.taxonomy.create(d['accessor']) || undefined;
    }
    return this.server.createTimeStampedModel( acl as TimeStampedModel, d) as AccessControlList;
  }

  /**
   * Create Hardcopy's Location information
   */
  public createHardcopyLocation(l: object_t|null = null): HardcopyLocation {
    return {
      branch:  l && l['branch']  || null,
      cabinet: l && l['cabinet'] || null,
      shelf:   l && l['shelf']   || null
    };
  }

  /**
   * Create hardcopy object
   */
  public createHardcopy(h: object_t|null = null): Hardcopy {
    return this.server.createTimeStampedModel({
      id: ( h && h['id'] ) || null,

      location: this.createHardcopyLocation( (h && h['location']) || null ),
      code:   h && h['code']   || '', // barcode
      status: h && h['status'] || 'reserved',
      attr: _.merge({}, (h && h['attr']) || {}),

      revision_id: h && h['revision_id'] || null
    } as TimeStampedModel, h ) as Hardcopy;
  }

  /**
   * Load paginated data of hardcopies associated with a revision, and pagination information
   */
  public async loadPaginatedHardcopies(revision: Revision, filter: object_t|null = null, pagination: Paging|null = null): Promise<PaginatedResults<Hardcopy>> {
    /** @TODO - use GraphQL? So we can bind to slickgrid */
    const res: PaginatedResults<Hardcopy> = await this.server.silent().index('hardcopies', Object.assign({ revision_id: revision.id }, filter),
        {
          pageno: pagination?.pageno || 1,
          perpage: pagination?.perpage || 5,
          sorting: pagination?.sorting || { updated_at: 'DESC' }
        }
      );

    res.data = res.data.sort( (a, b) => b.id! - a.id!).map( (h: Hardcopy) => this.createHardcopy(h) );
    revision.hardcopies = res;
    return res;
  }

  public createSoftcopy(s: object_t|null = null): Softcopy {
    return {
      id:           ( s && s['id'] ) || null,
      file_name:    ( s && s['file_name'] ) || null,
      file_size:    ( s && s['file_size'] ) || 0,
      content_type: ( s && s['content_type'] ) || null,
      download_url: ( s && s['download_url'] ) || '/#'
    };
  }

  /**
   * Create revision object, and load paginated hardcopies list (first page)
   */
  public createRevision(r: object_t|null = null, hardcopiesFilter: object_t|null = null, hardcopiesPagination: Paging|null = null): Revision {
    let revision = this.server.createTimeStampedModel({
      id:           ( r && r['id'] )           || null,

      revision:     ( r && r['revision'] !== null ) ? r['revision'] : null,
      active:       ( r && r['active'] )       || false,
      description:  ( r && r['description'] )  || null,

      file_id: ( r && r['file_id'] ) || null,
      file:    ( r && r['file'] && this.createDocFile(r['file']) ) || null,

      attachment_defer_key:  (r && r['attachment_defer_key'] ) || this.utils.string_utils.random(16),
      attachments:  ( r && r['attachments'] && r['attachments'].map( (a: object_t) => this.server.createAttachment(a) ) ) || [],
      softcopies:   ( r && r['softcopies'] && r['softcopies'].map( (s: object_t) => this.createSoftcopy(s) ) ) || [],

      hardcopies: {
        total: 0,
        pageno: 0,
        perpage: 1,
        data: []
      },

      attr: _.merge({}, (r && r['attr']) || {} ),
    } as TimeStampedModel, r) as Revision;


    if ( r && r['id'] && r['hardcopies_count'] && r['hardcopies_count'] > 0 ) {
      this.loadPaginatedHardcopies(revision, hardcopiesFilter, hardcopiesPagination);
    }
    else {
      revision.hardcopies = {
        data: [],
        total: 0,
        pageno: 1,
        perpage: 5
      };
    }

    return this.server.createTimeStampedModel(revision as TimeStampedModel, r) as Revision;
  }

  /**
   * load paginated revisions associated with the doc file
   */
  public loadPaginatedRevisions(file: DocFile, filter: object_t|null = null, pagination: Paging|null = null, hardcopiesFilter: object_t|null = null, hardcopiesPagination: Paging|null = null): Promise<PaginatedResults<Revision>> {
    return new Promise( (resolve, reject) => {
      this.server.index('revisions', Object.assign({ file_id: file.id }, filter),
        {
          pageno: pagination?.pageno || 1,
          perpage: pagination?.perpage || 10,
          sorting: pagination?.sorting || {
            updated_at: 'DESC'
          }
        },
        { with: 'attachments' }
      )
      .then( (res: PaginatedResults<Revision>) => {
        res.data = res.data.map( (r: Revision) => this.createRevision(r, hardcopiesFilter, hardcopiesPagination) );
        file.revisions = res;
        resolve(res);
      });
    });
  }

  /**
   * create docfile object
   */
  public createDocFile(f: object_t|null = null, revisionsFilter: object_t|null = null, revisionPagination: Paging|null = null, hardcopiesFilter: object_t|null = null, hardcopiesPagination: Paging|null = null): DocFile {
    let file = this.server.createTimeStampedModel({
      id:    f && f['id']    || null,
      title: f && f['title'] || null,
      description: f && f['description'] || null,
      active_revisions: f && f['active_revisions'] && f['active_revisions'].map( (r: object_t) => this.createRevision(r) ) || [],
      acls:   f && f['acls'] && f['acls'].map( (a: object_t) => this.createAccessControlList(a) ) || [this.createAccessControlList()],
      revisions: {
        total: 0,
        pageno: 0,
        perpage: 1,
        data: []
      },
      attr:   _.merge({}, (f && f['attr']) || {}),
    } as TimeStampedModel, f) as DocFile;

    if ( f && f['id'] && f['revisions_count'] && f['revisions_count'] > 0 ) {
      this.loadPaginatedRevisions(file, revisionsFilter, revisionPagination, hardcopiesFilter, hardcopiesPagination);
    }

    return this.server.createTimeStampedModel(file as TimeStampedModel, f) as DocFile;
  }

  /**
   * create document schedule
   */
  public createDocSchedule(s: object_t|null = null): DocSchedule {
    return this.server.createTimeStampedModel({
      id:       s && s['id'] || null,
      doc_id:   s && s['doc_id'] || null,
      document: s && s['document'] && this.createDocument(s['document']) || null,
      schedule: s && this.utils.dateTime_utils.browser(s['schedule']) || this.utils.dateTime_utils.browser(),
      action:   s && s['action'] || []
    } as TimeStampedModel) as DocSchedule;
  }

  /**
   * create document object
   */
  public createDocument(d: object_t|null = null, revisionsFilter: object_t|null = null, revisionsPagination: Paging|null = null, hardcopiesFilter: object_t|null = null, hardcopiesPagination: Paging|null = null): Document {
    d = d || {};

    let doc: Document = {
      id: d['id'] || 0,
      title:      d['title']      || null,
      content:    d['content']    || null,
      status:     d['status'] || 'draft',

      document_date: d['document_date'] && this.utils.dateTime_utils.browser(d['document_date']) || this.utils.dateTime_utils.browser(),

      doc_code:   d['doc_code'] || null,
      code:       this.createDocCode( d['code'] || ( d['doc_code'] && this.parseDocCode(d['doc_code']) ) || null ),
      type:       d['type'] || null,

      is_memo:       !! d['template_id'],
      template_id:   d['template_id']   || null,
      template_code: d['template_code'] || null,

      domain_id: d['domain_id'] || null,
      domain:    d['domain'] && this.taxonomy.create(d['domain']) || null,

      attachment_defer_key: d['attachment_defer_key'] || this.utils.string_utils.random(16),
      attachments: d['attachments'] && d['attachments'].map( (f: object_t)  => this.server.createAttachment(f) ) || [],

      comments: d['comments'] && d['comments'].map( (c: object_t) => this.app.createComment(c)) || [],

      //attr: _.merge({}, {
      attr: Object.assign({}, {
        title: {
          prefix:    '',
          suffix:    '',
        },
        locations: [],
        doc_date:  null,
        lendable:  false
      }, d['attr'], this.createTypeSpecificAttribute(d) || {}),

      files: d['files'] && d['files'].map( (f: object_t) => this.createDocFile(f, revisionsFilter, revisionsPagination, hardcopiesFilter, hardcopiesPagination) ) || [],

      categories:  d['categories'] && d['categories'].map( (t: object_t) => this.taxonomy.create(t) ) || [],
      tags:        ( d['tags'] && d['tags'].map( (t: object_t) => t['name'] ) ) || [],

      references:  d['references']  && d['references'] || null,
      referred_by: d['referred_by'] && d['referred_by'] || null,

      documentable_type: d['documentable_type'] || null,
      documentable_id:   d['documentable_id'] || 0,

      schedules: d['schedules'] && d['schedules'].map( (s: object_t) => this.createDocSchedule(s) ) || [],

      uploader_id:  d['uploader_id'] || null,
      uploader:     d['uploader'] || null,

      approver_id:  d['approver_id'] || null,
      approver:     d['approver'] || null,
      approved_at:  d['approved_at'] && this.utils.dateTime_utils.browser(d['approved_at']) || null,

      reviewer_id:  d['reviewer_id'] || null,
      reviewer:     d['reviewer'] || null,
      reviewed_at:  d['reviewed_at'] && this.utils.dateTime_utils.browser(d['reviewed_at']) || null,

      publisher_id: d['publisher_id'] || null,
      publisher:    d['publisher'] || null,
      published_at: d['published_at'] && this.utils.dateTime_utils.browser(d['published_at']) || null,

      expire_date:  d['expire_date'] && this.utils.dateTime_utils.browser(d['expire_date']) || null,
      review_date:  d['review_date'] && this.utils.dateTime_utils.browser(d['review_date']) || null, // aka. next review
      //expiration:  d['expiration'] && this.utils.dateTime_utils.browser(d['expiration']) || null,
      //next_review: d['next_review'] && this.utils.dateTime_utils.browser(d['next_review']) || null,

      content_docs: [],

      mandatory_permitted_users: d['mandatory_permitted_users'] && d['mandatory_permitted_users'].map( (u:object_t) => this.userService.create(u) ) || [],
      optional_permitted_users:  d['optional_permitted_users'] && d['optional_permitted_users'].map( (u:object_t) => this.userService.create(u) ) || [],
    };

    // post process
    if ( ! doc.doc_code && ! doc.template_code ) {
      doc.doc_code = this.getDocCode(doc);
    }
    if ( ! doc.type ) {
      doc.type = doc.code!.f;
    }

    if ( doc.uploader && doc.uploader.avatar ) {
      doc.uploader.avatar_thumbUrl = doc.uploader.avatar.path_url || this.ui.random_profile_image;
    }

    if ( doc.uploader === null && !! doc.uploader_id ) {
      this.userService.get(doc.uploader_id, true)
      .then( (u: User) => {
        doc.uploader = u;
      });
    }

    // spw specific
    doc = this.extractSPWDocumentFile(doc);
    return this.server.createTimeStampedModel(doc, d) as Document;
  }

  // spw specific
  public extractSPWDocumentFile(doc: Document): Document {
    // spw specific attribute - ** doc.files are already created by the creator **
    doc.registration_doc = doc.files.filter( f => f.attr && f.attr['registration_form'] ).shift()
    doc.content_docs     = doc.files.filter( f => ! f.attr || ! f.attr['registration_form'] );
    return doc;
  }

  // mapping mime-type to Page
  protected pageMappings:object_t = {
    'opl': '/opl/view/:id'
  };
  public getDocPage(type: string): NavigatableLink|null {
    return this.pageMappings[type] || null;
  }

  // check, if document is confidential
  public is_confidential(doc: Document): boolean {
    return doc.code?.d == 'C';
  }

  public can_edit_confidential(doc: Document|null = null): boolean {
    /** @todo - confirm confidential doc editing permission name */
    if ( doc === null ) {
      return this.session.hasPermission(['core_admin', 'doc_reg_conf'])
    }
    return doc.mandatory_permitted_users.find( (u: User) => u.id == this.session.currentUser!.id ) !== undefined;
  }

  public can_read_confidential(doc: Document): boolean {
    return doc.mandatory_permitted_users.find( (u: User) => u.id == this.session.currentUser!.id ) !== undefined ||
           doc.optional_permitted_users.find( (u: User) => u.id == this.session.currentUser!.id ) !== undefined;
  }

  // check, if document is a publication
  public is_publication(doc: Document): boolean {
    // @TODO - implement logic!
    return true;
  }

  // check, if user can access this doc
  public can_download(doc: Document): boolean {
    return this.session.hasPermission(['doc_review', 'doc_manage', 'core_manage_group', 'core_access_group'], doc.categories.map( (c: Taxonomy) => c.id! ) );
  }

  // download file or jump to page if link is a linkable doc
  //public download(doc: Document, rev: Revision, file: Attachment, nav: NavController) {
  public download(doc: Document, rev: Revision, file: Attachment) {
    if ( ! this.can_download(doc) ) {
      this.ui.alert('Sorry, you are not permitted to download this file.');
      return;
    }
    /** @todo - link_id and type is depreciated and to be removed */
    if ( rev.attr && !! rev.attr['link_id']) {
      let link = this.getDocPage(rev.attr['type']);
      if ( !! link ) {
        this.nav.setRoot([link, { id: rev.attr['link_id'] }]);
      }
      else {
        this.ui.alert('Sorry, cannot determine the link.');
      }
    }
    else {
      this.server.request('attachment.download', {id: file.id});
    }
  }

  /**
   * document code!!!
   */

  // create document code from object
  public createDocCode(code: object_t|null = null): DocCode {
    return  {
      a:  ( code && code['a'] )  || 'ENG', // 'ENG'
      b:  ( code && code['b'] )  || 'SPW', // SPW, SPD, SPMT, SEC
      c:  ( code && code['c'] )  || (this.session.currentUser && this.getDepartmentCode(this.session.currentUser!.primary_domain!.path!)) || null,  // department
      d:  ( code && code['d'] )  || 'N',   // N/C
      e:  ( code && code['e'] )  || 'I',   // I/E
      f:  ( code && code['f'] )  || null,  // Document Type
      g:  ( code && code['g'] )  || '0',   // 0 / O / M / S / E
      id: ( code && code['id'] ) || null
    }
  }

  public parseDocCode(code: string, strict: boolean = true): DocCode {
    let regex = strict ?
                /(ENG)\-([0-9A-Z]+)\-([0-9A-Z]+)\-([NC])\-?([IE])\-([0-9A-Z]+)\-([OMSIX0])(\d+)/ :
                /(ENG)\-([0-9A-Z]+)\-([0-9A-Z]+)\-([NC])\-?([IE])\-([0-9A-Z]+)\-([OMSIX0]?)([\?\d]+)/;
    let parts = regex.exec(code)||[];
    return  {
      'a':  parts[1], // ENG
      'b':  parts[2], // SPW
      'c':  parts[3], // AV
      'd':  parts[4], // N
      'e':  parts[5], // I
      'f':  parts[6], // SOP
      'g':  parts[7], // 0|M}S|O|X
      'id': parts[8]  // 0002
    }
  }

  public getDocCode(d: Document): string {
    let id: string = d.code!.id && sprintf("%04d", parseInt(d.code!.id)) || '?????';
    return `${(d.code!.a || 'ENG')}-${(d.code!.b || '???')}-${(d.code!.c || 'ALL')}-${(d.code!.d || 'N')}${(d.code!.e || 'I')}-${(d.code!.f || '??')}-${d.code!.g || '?'}${id}`;
  }

  // get department part of the document code from domain path
  public getDepartmentCode(path: string): string {
    return this.names['doc_code_mappings'][path] && this.names['doc_code_mappings'][path][0].c || null;
  }

  // get department's domain path from department part of the document code
  public getDepartmentPath(dept: string, company: string = 'SPW'): string|null {
    for ( let path of Object.getOwnPropertyNames(this.names['doc_code_mappings']) ) {
      for ( let code of this.names['doc_code_mappings'][path] ) {
        if (
          code['b'] == company &&
          code['c'] == dept
        ) {
          return path;
        }
      }
    }
    return null;
  }

  public getDepartmentTerm(dept: string, company: string = 'SPW'): Taxonomy|null {
    let path = this.getDepartmentPath(dept, company);
    if ( ! path ) {
      return null;
    }
    return this.taxonomy.getNodeByPath(this.names['categories'], path);
  }

  /**
   * return the active revisions
   */
  public getActiveRevisions(file: DocFile): Revision[] {
    // @TODO load all revision from server? - need to be promise
    return file.revisions.data.filter( r => r.active );
  }

  public getFileIcon(mime: string) {
    return this.utils.mime_utils.getFileIcon(mime);
  }

  // ISBN search
  /*
  public isbn(isbn: string): Promise<ISBNResult> {
    let self = this;
    return new Promise( (resolve) => {
      self.http.get(`https://openlibrary.org/api/books?bibkeys=ISBN:${isbn}&jscmd=details&format=json`)
      .subscribe( (res: object_t) => {

        let data = res[`ISBN:${isbn}`];
        if ( ! data || ! data['details'] ) {
          return null;
        }

        let details = data['details'];

        let title: ISBNResult = {
          title:      details['title'] || null,
          genres:     (details['genres'] && details['genres'].join(', ')) || null,
          authors:    (details['authors'] && details['authors'].map((a: object_t) => a['name']).join(', ')) || null,
          revision:   details['revision']   || null,
          publishers: details['publishers'] && details['publishers'].join(', ') || null,
          pages:      details['number_of_pages'] || null,
          year:       details['publish_date']  || null
        };
        resolve(title);
      });
    });
  }
  */

  // -- Type Specific Attributes
  /**
   *
   * @param data - document data object (whold document)
   * @returns type-specific document attributes object (with type as key)
   */
  public createTypeSpecificAttribute(data: object_t|null = null): object_t {
    let attr: object_t = {};
    let type: string = data && data['code'] && data['code']['f'] as string || null;

    if ( ! type ) {
      return attr;
    }

    attr = Object.assign({}, data && data['attr'] && data['attr'][type] || null);

    switch ( type ) {
      case 'BOOK':
        attr = {
          type:   attr['type']   || 'book', // book or magazine
          isbn:   attr['isbn']   || null,
          genres: attr['genres'] || null,
          price:  attr['price']  || 0,
          authors:      attr['authors'] || null,
          publishers:   attr['publishers'] || null,
          publish_date: attr['publish_date'] || null
        };
      break;

      case 'PRJ':
        attr = {};
      break;

      case 'DWG':
        attr = {};
      break;

      case 'CODE':
      case 'VENDOR':
      case 'CONSULT':
      case '3RD':
        attr = {
          system: attr['system'] || null
        };
      break;

      default:
        attr = {};
        console.info(`Document type ${type} does not have type-specific attributes`);
      break;
    }
    return { [type]: attr };
  }
}