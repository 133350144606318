<full-page [title]="drawing.drawing_no || 'New Drawing'">
  <div page-content>
    <panel class="proj-lib-document">
      <div panel-body class="fluid">

        <h4 class="pad-btm bord-btm">
          <i class="pli-file icon-fw v-middle"></i>
          <span class="text-semibold">
            {{ drawing.drawing_no || 'New Drawing' }}
          </span>
        </h4>

        <form #mainForm="ngForm">

          <!-- section 1: Drawing information-->
          <div class="panel panel-bordered panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Drawing Information' | translate }}
              </h4>
            </div>

            <div class="panel-body" id="drawing-info">
              <div class="row">
                <div class="col-2">
                  <div class="form-group">
                    <label class="form-label">{{ 'Drawing No' | translate }}</label>
                    <input
                      type="text" class="form-control" id="drawing-no" name="drawing-no"
                      [attr.readonly]="firstDraft(drawing.status) ? null : '1'"
                      required
                      [unique]="{
                        id: drawing.id,
                        min: 5,
                        url: 'projlib.proj_drawing.validation.dwg_no.unique',
                        params: {
                          project_id: drawing.project_id
                        }
                      }"
                      placeholder="{{ firstDraft(drawing.status) ? null : ( 'Upload file to generate drawing number' | translate ) }}"
                      [(ngModel)]="drawing.drawing_no"
                      #nonStdDrawingNo="ngModel"
                    >
                    <validation-errors
                      [errors]="errors['duplicated_drawing_no']"
                      [models]="[nonStdDrawingNo]"
                      [messages]="{
                        required: 'Drawing number is required',
                        unique: 'Drawing number must be unique'
                      }"
                      [dirty]="true"
                    ></validation-errors>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label class="form-label" for="drawing_system">{{ 'System' | translate }}</label>
                    <select #drawing_system="ngModel"
                      class="form-control"
                      name="drawing_system"
                      [(ngModel)]="drawing.system"
                    >
                      <option *ngFor="let item of ( api.options['drawing_system'] || [] | keyvalue:originalOrder )"
                        value="{{ item.key }}"
                        [attr.selected]="drawing.system == item.key ? '1' : null"
                      >
                        {{ item.value }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label">{{ 'Drawing Title' | translate }}</label>
                    <input type="text" class="form-control" id="drawing_title" name="drawing-title"
                      required
                      [attr.readonly]="firstDraft(drawing.status) ? null : '1'"
                      [(ngModel)]="drawing.title"
                      #drawing_title="ngModel"
                    >
                    <validation-errors
                      [models]="drawing_title"
                      [messages]="{
                        required: 'project drawing title is required'
                      }"
                      [dirty]="true"
                    ></validation-errors>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label class="form-label">{{ 'Status' | translate }}</label>
                    <input type="text" id="status" name="status" class="form-control" disabled [(ngModel)]="drawing.status">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form-label">{{ 'Description' | translate }}</label>
                    <textarea class="form-control" id="description" name="description"
                      [attr.readonly]="firstDraft(drawing.status) ? null : '1'"
                      [(ngModel)]="drawing.description"
                      #drawing_desc="ngModel"
                    ></textarea>
                    <!--
                    <input type="text" class="form-control" id="description" name="description"
                      [attr.readonly]="firstDraft(drawing.status) ? null : '1'"
                      [(ngModel)]="drawing.description" #drawing_desc="ngModel"
                    >
                    -->
                    <!--
                    <validation-errors
                      [models]="drawing_desc"
                      [messages]="{
                        required: 'project drawing description is required'
                      }"
                      [dirty]="true"
                    ></validation-errors>
                    -->
                  </div>
                  <div class="form-group">
                    <label class="form-label">{{ 'Team' | translate }}</label>
                    <select #doc_team="ngModel"
                      class="form-control"
                      name="doc_team"
                      required
                      [attr.disabled]="firstDraft(drawing.status) ? null : 'disabled'"
                      (change)="docTeamChanged()"
                      [(ngModel)]="document_team"
                    >
                      <option *ngFor="let item of teamOptions"
                        value="{{ item.key }}"
                        [attr.selected]="document_team == item.key ? '1' : null"
                      >
                        {{ item.value }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label class="form-label">{{ 'Drawing Type' | translate }}</label>
                        <select #drawing_type="ngModel"
                          class="form-control"
                          title="{{ 'Drawing Type' | translate }}"
                          name="drawing-type"
                          required
                          [attr.disabled]="firstDraft(drawing.status) ? null : 'disabled'"
                          (change)="markDirty()"
                          [(ngModel)]="drawing.type"
                        >
                          <option *ngFor="let item of api.options['drawing_type'] | keyvalue:originalOrder"
                            value="{{ item.key }}"
                            title="{{ item.value }}"
                            [attr.selected]="drawing.stage == item.key ? '1' : null"
                          >
                            {{ item.value }}
                          </option>
                        </select>
                        <validation-errors
                          [models]="drawing_type"
                          [messages]="{
                            required: 'project drawing type is required'
                          }"
                          [dirty]="true"
                        ></validation-errors>
                      </div>
                      <div class="form-group">
                        <label class="form-label">{{ 'Stage' | translate }}</label>
                        <select #drawing_stage="ngModel"
                          class="form-control"
                          title="{{ 'Drawing Stage' | translate }}"
                          name="drawing-stage"
                          required
                          [attr.disabled]="firstDraft(drawing.status) ? null : 'disabled'"
                          (change)="drawingStageChange()"
                          [(ngModel)]="drawing.stage"
                        >
                          <option *ngFor="let item of api.options['drawing_stage'] | keyvalue:originalOrder"
                            value="{{ item.key }}"
                            title="{{ item.value }}"
                            [attr.selected]="drawing.stage == item.key ? '1' : null"
                          >
                            {{ item.value }}
                          </option>
                        </select>
                        <validation-errors
                          [models]="drawing_stage"
                          [messages]="{
                            required: 'project drawing stage is required'
                          }"
                          [dirty]="true"
                        ></validation-errors>
                      </div>
                      <!--
                      <div *ngIf="!!drawing.attr" class="form-group">
                        <label class="form-label">{{ 'Hard Copies' | translate }}</label>
                        <input type="number" id="hard-copies" name="hard-copies" class="form-control" min=0 [(ngModel)]="drawing.attr['hardcopies']">
                      </div>
                      -->
                    </div>


                    <!-- masters -->
                    <div class="col pad-no" id="doc-master-drawing" *ngIf="drawing.stage == 'as_built_drawing'">
                      <div *ngIf="drawing.masters.length == 0" id="no-master"
                        title="Click to add master drawing"
                        (click)="showMaster()"
                      >
                        - No master drawing avaialble, click to add one. -
                        <div class="btn-group">
                          <button class="btn btn-default btn-icon" stop-propagation (click)="showMaster()" title="Add master drawing location">
                            <i class="pli-geo-plus"></i>
                          </button>
                        </div>
                      </div>

                      <div *ngIf="drawing.masters.length > 0" id="drawing-masters" class="carousel slide">
                        <div class="carousel-inner">

                          <div *ngFor="let master of drawing.masters; let i = index"
                            id="master-{{i}}"
                            class="carousel-item"
                            [ngClass]="{ 'active': i == 0 }"
                          >
                            <ng-container *ngIf="!! master.iip_name">
                            <iip-viewer
                              [src]="master.iip_name"
                              [path]="iip_base_path"
                              [navButtons]="['reset', 'zoomIn', 'zoomOut']"
                              [showNavigation]="false"
                              [showCoords]="false"
                              [showScale]="false"
                              [drawingTools]="false"
                              [polygons]="master.pivot_polygons"
                            ></iip-viewer>
                            <div class="btn-group">
                              <button class="btn btn-default btn-icon" (click)="showMaster()" title="Add additional master drawing location">
                                <i class="pli-geo-plus"></i>
                              </button>
                              <button class="btn btn-default btn-icon" (click)="showMaster(master)" title="Edit current master drawing location">
                                <i class="pli-pencil"></i>
                              </button>
                              <button class="btn btn-danger btn-icon" (click)="detachMaster(master)" title="Remove master drawing location">
                                <i class="pli-trash"></i>
                              </button>
                            </div>
                            </ng-container>
                          </div>

                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#drawing-masters" data-bs-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#drawing-masters" data-bs-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
              <!--
              <div class="row">
                <div class="col" id="reviewer">
                  <label class="form-label">{{ 'Reviewer' | translate }}</label>
                  <user-card *ngIf="drawing.reviewer"
                    [user]="drawing.reviewer"
                  ></user-card>
                  <div *ngIf="! drawing.reviewer" class="text-thin text-center text-danger">
                    - {{ 'Reviewer is not assigned' | translate }} -
                  </div>
                </div>
                <div class="col" id="approver">
                  <label class="form-label">{{ 'Approver' | translate }}</label>
                  <user-card *ngIf="drawing.approver"
                    [user]="drawing.approver"
                  ></user-card>
                  <div *ngIf="! drawing.approver" class="text-thin text-center text-danger">
                    - {{ 'Approver is not assigned' | translate }} -
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label">{{ 'Drawing Type' | translate }}</label>
                    <select #drawing_type="ngModel"
                      class="form-control"
                      title="{{ 'Drawing Type' | translate }}"
                      name="drawing-type"
                      required
                      [attr.disabled]="firstDraft(drawing.status) ? null : 'disabled'"
                      (change)="markDirty()"
                      [(ngModel)]="drawing.type"
                    >
                      <option *ngFor="let item of api.options['drawing_type'] | keyvalue:originalOrder"
                        value="{{ item.key }}"
                        title="{{ item.value }}"
                        [attr.selected]="drawing.stage == item.key ? '1' : null"
                      >
                        {{ item.value }}
                      </option>
                    </select>
                    <validation-errors
                      [models]="drawing_type"
                      [messages]="{
                        required: 'project drawing type is required'
                      }"
                      [dirty]="true"
                    ></validation-errors>
                  </div>
                </div>
              </div>
              -->
            </div>
          </div>
          <!-- end of section 1: Document Information -->

          <!-- section 2: Files List -->
          <div class="panel panel-bordered panel-default">

            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'File(s)' | translate }}
              </h4>
            </div>

            <div class="panel-body" id="files-list">
              <table class="table">
                <thead>
                  <tr>
                    <th class="text-center">File</th>
                    <th class="text-center">Revision</th>
                    <th class="text-center">Hardcopies</th>
                    <th class="text-center">Reviewer</th>
                    <th class="text-center">Approver</th>
                    <th class="text-center">Masters</th>
                    <th class="text-center">Date</th>
                    <th class="text-center"><!-- for trashcan --></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let dwg of drawing.revisions; let i = index">
                    <td>
                      <div *ngFor="let file of dwg.attachments" class="file-info">
                        <download [file]="file" #downloader>
                          <ng-template let-f="file" let-icon="icon">
                            <button class="template-btn" title="click to download {{ f.file_name}}" (click)="downloader.download()">
                              <i class="file-thumbnail fa-5x fa-fw {{ icon }}"></i>
                            </button>
                          </ng-template>
                        </download>
                        <div class="me-3">
                          <div class="text-bold file-name" title="{{ file.file_name }}">
                            {{ file.file_name }}
                          </div>
                          <div class="text-thin file-attr">
                            <span>{{ file.created_at | datetime }}</span>
                            <span>{{ file.file_size | memsize:'m' | number:'1.2-2' }} MB</span>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class="text-center">
                      {{ dwg.revision }}
                    </td>

                    <td class="text-right" title="remaining/total">

                      <div *ngIf="lastDraftRevision(dwg)" id="hard-copies" class="form-group">
                        <input type="number" name="hard-copies" class="form-control" min=0 [(ngModel)]="rev_hardcopies" #hard_copies="ngModel">
                      </div>

                      <span *ngIf="! lastDraftRevision(dwg)">
                        {{ dwg.remaining_hard_copies }}/{{ dwg.hard_copies }}
                      </span>
                    </td>

                    <td class="text-center">
                      {{ dwg.reviewer?.fullname }}
                      <!--
                      <user-card *ngIf="dwg.reviewer"
                        [user]="dwg.reviewer"
                      ></user-card>
                      -->
                      <div *ngIf="! dwg.reviewer" class="text-thin text-center text-danger">
                        - {{ 'Reviewer is not assigned' | translate }} -
                      </div>
                    </td>
                    <td class="text-center">
                      {{ dwg.approver?.fullname }}
                      <!--
                      <user-card *ngIf="dwg.approver"
                        [user]="dwg.approver"
                      ></user-card>
                      -->
                      <div *ngIf="! dwg.approver" class="text-thin text-center text-danger">
                        - {{ 'Approver is not assigned' | translate }} -
                      </div>
                    </td>
                    <td class="text-center">
                      <span *ngFor="let master of dwg.masters" class="delimited">
                        {{ master.title }}
                      </span>
                      <div *ngIf="dwg.masters.length == 0" class="text-thin">
                        - no master -
                      </div>
                    </td>
                    <td class="text-center">
                      {{ dwg.created_at | datetime }}
                    </td>
                    <td class="text-left">
                      <button *ngIf="lastDraftRevision(dwg)" class="btn btn-default btn-icon" (click)="deleteDrawing(dwg)">
                        <i class="fa-solid fa-trash-can"></i>
                      </button>
                    </td>
                  </tr>
                  <tr *ngIf="drawing.revisions.length == 0">
                    <td colspan="7" class="text-center text-thin">
                      {{ '- No drawing revisions avaialble at the moment -' }}
                    </td>
                  </tr>
                  <tr [ngStyle]="{ 'display': ( firstDraft(drawing.status) && drawing.revisions.length == 0 ) || drawing.status == 'approved' ? 'table-row' : 'none' }">
                    <td colspan="5">
                      <dropzone #dropzone
                        [dropMessage]="drawing.status == 'approved' ? 'Drop new revision to upload' : 'Drop files to upload'"
                        [clickMessage]="'or click to pick manually'"
                        [url]="dzURL"
                        [auto]="false"
                        [dz-config]="{
                          maxFiles: 1,
                          uploadMultiple: false,
                          timeout: 10 * 1000,
                          acceptedFiles: 'image/*,.pdf,.dwg',
                          maxFilesize: config('client.upload_max_filesize', 256*1000*1000)
                        }"
                        (file-removed)="onFileRemoved($event)"
                        (file-added)="onFileAdded($event)"
                        (file-success)="onFileUploadSuccess($event)"
                      >
                      </dropzone>
                    </td>
                    <td colspan="2" class="text-center">
                      <!--
                      <div id="hard-copies" class="form-group">
                        <label class="form-label">{{ 'Hard Copies' | translate }}</label>
                        <input type="number" name="hard-copies" class="form-control" min=0 [(ngModel)]="rev_hardcopies">
                      </div>
                      -->
                      <div class="reviewer">
                        <label class="form-label">{{ 'Reviewer' | translate }}</label>
                        <user-card *ngIf="reviewer"
                          [user]="reviewer"
                        ></user-card>
                        <div *ngIf="! reviewer" class="text-thin text-center text-danger">
                          - {{ 'Reviewer is not assigned' | translate }} -
                        </div>
                      </div>

                      <div class="approver">
                        <label class="form-label">{{ 'Approver' | translate }}</label>
                        <user-card *ngIf="approver"
                          [user]="approver"
                        ></user-card>
                        <div *ngIf="! approver" class="text-thin text-center text-danger">
                          - {{ 'Approver is not assigned' | translate }} -
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <!-- end of section 2: Files List -->

          <!-- section 3: Action History -->
          <div class="panel panel-bordered panel-default">

            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Action Hostory' | translate }}
              </h4>
            </div>

            <div class="panel-body" id="action-history">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ 'Date' | translate }}</th>
                    <th>{{ 'Action' | translate }}</th>
                    <th>{{ 'by' | translate }}</th>
                    <th>{{ 'Status' | translate }}</th>
                    <th>{{ 'Note' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="drawing.action_logs.length == 0">
                    <td colspan="5" class="text-center text-thin">- No actions log available -</td>
                  </tr>
                  <tr *ngFor="let action of drawing.action_logs">
                    <td>{{ action.created_at | datetime }}</td>
                    <td>{{ action.action }}</td>
                    <td>{{ action.user && action.user.fullname || '- unknown -' }}</td>
                    <td>{{ action.doc_status }}</td>
                    <td>{{ action.note }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- End of section 3: Action History -->

          <!-- section 4: Comments -->
          <div class="panel panel-bordered panel-default">

            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Comments' | translate }}
              </h4>
            </div>

            <div class="panel-body" id="action-history">
              <comments
                [content_type]="'project_drawing'"
                [content_id]="drawing.id!"
              ></comments>
            </div>
          </div>
          <!-- End of section 4: Comments -->

          <!-- section 5: Action Buttons -->
          <div class="text-right">

            <button *ngIf="is_submitable(drawing.status, drawing.revisions)" type="button"
              class="btn btn-labeled btn-warning"
              data-dismiss="modal"
              title="{{ 'Submit for Review' | translate }}"
              (click)="action('submit')"
            >
              <i class="btn-label pli-inbox-into"></i>{{ 'Submit' | translate }}
            </button>

            <button *ngIf="is_reviewable(drawing.status)" type="button"
              class="btn btn-labeled btn-warning"
              data-dismiss="modal"
              title="{{ 'Mark as Reviewed' | translate }}"
              (click)="action('review')"
            >
              <i class="btn-label fa fa-thumbs-up"></i>{{ 'Review' | translate }}
            </button>

            <button *ngIf="is_approvable(drawing.status)" type="button"
              class="btn btn-labeled btn-success"
              data-dismiss="modal"
              title="{{ 'Approve' | translate }}"
              (click)="action('approve')"
            >
              <i class="btn-label fa fa-solid fa-circle-xmark"></i>{{ 'Approve' | translate }}
            </button>

            <button *ngIf="is_reviewable(drawing.status) || is_approvable(drawing.status)" type="button"
              class="btn btn-labeled btn-danger"
              data-dismiss="modal"
              title="{{ 'Rework' | translate }}"
              (click)="action('rework')"
            >
              <i class="btn-label fa fa-thumbs-down"></i>{{ 'Rework' | translate }}
            </button>

            <button *ngIf="mainForm.form.dirty" type="button"
              class="btn btn-labeled btn-info"
              data-dismiss="modal"
              title="{{ 'Save' | translate }}"
              (click)="save()"
            >
              <i class="btn-label fa fa-save"></i> {{ 'Save' | translate }}
            </button>

            <button *ngIf="is_deletable(drawing.status)" type="button"
              class="btn btn-labeled btn-danger"
              data-dismiss="modal"
              title="{{ 'Delete' | translate }}"
              (click)="delete()"
            >
              <i class="btn-label fa fa-trash"></i> {{ 'Delete' | translate }}
            </button>

            <button type="button"
              class="btn btn-labeled btn-default"
              data-dismiss="modal"
              title="{{ 'Back' | translate }}"
              (click)="back()"
            >
              <i class="btn-label fa fa-solid fa-circle-xmark"></i>{{ 'Back' | translate }}
            </button>

          </div>
          <!-- end of secion 5: Action Buttons -->
        </form>

      </div>
    </panel>
  </div>
</full-page>


<modal template-id="master-drawing-search"
  size="xlarge"
  [hideCloseButton]="true"
  #masterSearchDialog
>
  <div modal-body id="iip">
    <panel>
      <div panel-heading>
        <h4>Select Master Drawings</h4>
      </div>
      <div panel-body>
        <div class="row">
          <div class="col-3">
            <div class="form-group">
              <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Title:</label>
              <input type="text" class="form-control" id="title" name="title" [(ngModel)]="search_input.title">
            </div>
            <div>
              <custom-properties [config]="master_buildings_config" [(data)]="search_input.attr['building']" (dataChange)="onMasterBuildingChange($event)"></custom-properties>
            </div>
            <div>
              <custom-properties [config]="master_floors_config" [(data)]="search_input.attr['floor']"></custom-properties>
            </div>
            <div class="m-3 text-right">
              <button title="Search" class="btn btn-info btn-labeled" (click)="searchMaster()" >
                <i class="btn-label icon-lg icon-fw pli-magnifi-glass"></i> {{ 'Search' | translate }}
              </button>
            </div>
          </div>
          <div class="col-9 py-2 master-drawings-list" id="search-result">
            <div class="row">
              <div *ngFor="let dwg of mastersList" class="col py-2">
                <div class="d-flex drawing-entry">
                  <div class="flex-shrink-0 download-button">
                    <download *ngFor="let f of dwg.attachments" [file]="f" #downloader>
                      <ng-template
                        let-file="file"
                        let-icon="icon"
                      >
                        <button class="btn btn-default btn-icon mar-all" title="click to download {{ file.file_name}}" (click)="downloader.download()">
                          <i class="btn-label icon-fw icon-3x fa fa-fw {{ icon }}"></i>
                        </button>
                      </ng-template>
                    </download>
                  </div>
                  <div class="flex-grow-1 details clickable" title="Click to edit {{ dwg.title }}" (click)="selectMaster(dwg)">
                    <div class="text-bold">
                      {{ dwg.title || 'Untitled' }}
                    </div>
                    <div class="drawing-badges">
                      <span class="badge" [ngClass]="{ 'badge-pink': !dwg.iip_name, 'badge-success': dwg.iip_name }" title="{{ dwg.iip_name ? 'Preview available' : 'Preview in progress' | translate }}">
                        <i [ngClass]="{ 'fa-regular fa-hourglass' : !dwg.iip_name, 'fa-solid fa-check': dwg.iip_name }"></i>
                      </span>
                      <span *ngIf="dwg.is_master" class="badge badge-mint" title="{{ 'Master Drawing' | translate }}">
                        <i class="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div class="drawing-attributes">
                      อาคาร {{ dwg.attr['building'] }} ชั้น {{dwg.attr['floor']}}
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="mastersList.length == 0" class="col mt-5 text-center" id="no-drawing">
                <span class="text-muted text-thin text-lg">
                  {{ 'Sorry, no drawing found, please try other criteria.' | translate }}
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </panel>
  </div>

  <div modal-footer>
    <button type="button" class="btn btn-labeled btn-default" data-dismiss="modal" title="{{ 'Back' | translate }}" (click)="masterSearchDialog.hide()">
      <i class="btn-label fa fa-solid fa-circle-xmark"></i>{{ 'Back' | translate }}
    </button>
  </div>

</modal>

<modal template-id="drawing-polygon-editor"
  [size]="'xlarge'"
  [hideCloseButton]="true"
  (onDismiss)="hidePolygonEditor()"
  #polygonEditor
>
  <div modal-body id="iip">

    <div *ngIf="!!master" class="row">

      <div class="col-3">
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Title:</label>
          <input type="text" class="form-control" id="title" name="title" [(ngModel)]="master.title" disabled>
        </div>
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Building:</label>
          <input type="text" class="form-control" id="title" name="title" [(ngModel)]="master.attr['building']" disabled>
        </div>
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Floor:</label>
          <input type="text" class="form-control" id="title" name="title" [(ngModel)]="master.attr['floor']" disabled>
        </div>
        <div class="form-group">
          <label class="form-label"><i class="icon fa fa-fw fa-building"></i>Polygons:</label>
          <ol *ngFor="let obj of polygons" class="coordinates">
            <li *ngFor="let node of obj.vertices"><span>{{ node.x | number:'1.2-2' }}</span>, <span>{{ node.y | number:'1.2-2' }}</span></li>
          </ol>
        </div>
      </div>

      <div class="col-9" id="right-pane">
        <span *ngIf="master.id && ! master.iip_name" class="text-muted text-thin text-lg">
          {{ 'Sorry, master drawing preview is not avaialble at the moment.' | translate }}
        </span>

        <iip-viewer *ngIf="master.iip_name !== null"
          [src]="master.iip_name"
          [path]="iip_base_path"
          [navButtons]="['reset', 'zoomIn', 'zoomOut']"
          [showCoords]="false"
          [showScale]="false"
          [drawingTools]="true"
          [polygons]="polygons"
          (polygonsChange)="markPolygonsDirty()"
          (onZoom)="onDrawingZoom($event)"
          [prototype]="polygonPrototype"
        ></iip-viewer>

      </div>
    </div>
  </div>

  <div modal-footer>

    <button title="Select other master" class="btn btn-primary btn-labeled" (click)="masterSearchDialog.show()">
      <i class="btn-label icon-lg icon-fw pli-magnifi-glass"></i> {{ 'Search' | translate }}
    </button>

    <button *ngIf="polygons && polygons.length > 0" title="Save" class="btn btn-success btn-labeled" (click)="savePolygon()">
      <i class="btn-label icon-lg icon-fw pli-disk"></i> {{ 'Save' | translate }}
    </button>

    <button type="button" class="btn btn-labeled btn-default" data-dismiss="modal" title="{{ 'Back' | translate }}" (click)="hidePolygonEditor()">
      <i class="btn-label fa fa-solid fa-circle-xmark"></i>{{ 'Back' | translate }}
    </button>

  </div>

</modal>
