/**
 * admin endpoints
 */

import { ServiceEndpointsMapping } from "@pinacono/common";

export const ENDPOINTS: ServiceEndpointsMapping = {
  training: {
    job: {
      user: '/training/job/user/{id}',
      self: '/training/job/user/self'
    },

    profile: {
      list: '/training/profiles/user/{uid}',
      self: '/training/profiles/user/self',
      add:  '/training/profiles/add/{uid}/{competency_id}/{expectation}',
      adjust: { method: 'POST', url: '/training/profiles/adjust/{profile_id}' }
    },

    course: {
      planned: '/training/courses/planned/{uid}',
      get:     '/training/courses/{id}',
    },

    exams: {
      list:     '/exam/exams',
      create:   '/exam/exams/create',
      save:     { method: 'POST', url: '/exam/exams'},
    },

    // user request/revoke for/from a batch of a course
    request: '/training/request/{course_id}',

    revoke:  '/training/revoke/{request_id}',

    // user apply/unapply (request/revoke) for/from an opened batch
    batch: {
      apply:   '/training/batch/apply/{batch_id}',
      unapply: '/training/batch/unapply/{batch_id}',
      cancel:  '/training/batch/cancel/{batch_id}',
      approve: '/training/request/approve/{request_id}/{approved}', // used to be 'request.approve' -> 'batch.approve'

      record: '/training/batch/record/{batch_id}/{user_id}', // get user training record on a batch

      // trainer open a batch (requests list required in POST)
      open: { method: 'POST', url: '/training/batch/open' },

      // trainer manually add/remove users into batch
      attendee: {
        add:    { method: 'POST', url: '/training/batch/attendee/add'    },
        remove: { method: 'POST', url: '/training/batch/attendee/remove' },
      }
    },

    // training reports
    reports: {
      /*
      user_exams:     { method: 'POST', url: '/reports/user_exams'      },
      competency_gap: { method: 'POST', url: '/reports/competency_gaps' },
      */
      type1: { method: 'POST', url: '/reports/competency_type1' },
      type2: { method: 'POST', url: '/reports/competency_type2' },
      type3: { method: 'POST', url: '/reports/competency_type3' },
      type4: { method: 'POST', url: '/reports/competency_type4' },
      type5: { method: 'POST', url: '/reports/competency_type5' },
      type6: { method: 'POST', url: '/reports/competency_type6' },
      type7: { method: 'POST', url: '/reports/competency_type7' },
      type8: { method: 'POST', url: '/reports/competency_type8' },
    }
  }
};
