<!-- Nifty Full Page -->
<div id="container" class="effect aside-float aside-bright mainnav-lg footer-fixed selectable">

  <!-- Top Nav Bar -->
  <header id="navbar">
    <div id="navbar-container" class="boxed">

      <!--Brand logo & name-->
      <div class="navbar-header">
        <a routeLink="/home" routeLinkActive="active" class="navbar-brand clickable">
          <img src="assets/images/logo.png" alt="{{ APP_INFO.title | translate }}" class="brand-icon">
          <div class="brand-title">
            <span class="brand-text">{{ APP_INFO.title | translate }}</span>
          </div>
        </a>
      </div>
      <!-- end of Brand logo & name -->

      <!-- Navbar Dropdown -->
      <div class="navbar-content">

        <!-- Navbar Left -->
        <ul class="nav navbar-top-links">

          <!--Navigation toogle button-->
          <li class="tgl-menu-btn">
            <a class="mainnav-toggle clickable">
              <i class="pli-list-view"></i>
            </a>
          </li>

          <!--Search-->
          <li *ngIf="false"> <!-- customer asked to hide this -->
            <div class="custom-search-form">
              <label class="btn btn-trans" for="search-input" data-bs-toggle="collapse" data-target="#nav-searchbox">
                <i class="pli-magnifi-glass"></i>
              </label>
              <form (keydown.enter)="search(keyword.value)">
                <div class="search-container collapse input-group" id="nav-searchbox">
                  <input id="search-input" type="text" #keyword class="form-control" placeholder="{{ 'Type for Search' | translate }}...">
                  <span class="input-group-btn">
                    <button class="btn btn-primary btn-lg" (click)="search(keyword.value)" type="button">
                      <i class="pli-magnifi-glass"></i>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </li>
        </ul>
        <!-- end of Navbar Left -->

        <!-- Navbar Right -->
        <ul class="nav navbar-top-links">

          <!-- language switcher -->
          <li id="nav-language-switcher" class="dropdown">
            <a class="dropdown-toggle clickable" data-bs-toggle="dropdown" data-bs-display="static">
              <span>{{ theme.currentLang.toUpperCase() }}</span>
            </a>
            <ul class="head-list dropdown-menu dropdown-menu-sm-end">
              <li *ngFor="let l of theme.languages" class="dropdown-item">
                <a class="clickable" stopPropagation (click)="theme.currentLang = l">{{ l.toUpperCase() }}</a>
              </li>
            </ul>
          </li>
          <!-- end of language switcher -->

          <!--Notification dropdown-->
          <li id="nav-notifications" class="dropdown" title="{{ theme.notifications.all.length | number:'1.0-0' }} message(s) available.">
            <a class="dropdown-toggle clickable" data-bs-toggle="dropdown" data-bs-display="static">
              <i class="pli-bell"></i>
              <span class="badge badge-header badge-danger" *ngIf="theme.notifications.unread.length > 0">{{ theme.notifications.unread.length }}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-lg">
              <div *ngIf="theme.notifications.groups.length == 0" class="notification-group" id="no-notification">
                <span class="label text-sm text-muted">{{ 'No notifications at the moment' | translate }}</span>
              </div>

              <div *ngFor="let group of theme.notifications.groups" class="notification-group">
                <div *ngIf="!!group" class="notification-group-label">{{ group }}</div>
                <div class="nano scrollable">
                  <ul class="head-list nano-content">
                    <li *ngFor="let n of theme.notifications.filter(group)" class="animated notification-items" id="notification-{{n.id}}">
                      <!-- <a class="media clickable" stopPropagation (click)="theme.notifications.read(n) && !!n.link && nav.setRoot(n.link)"> -->
                      <a class="media clickable" stopPropagation>
                        <span class="label label-danger pull-right" *ngIf="! n.read" stopPropagation  (click)="theme.notifications.read(n)" title="{{ 'Mark as read' | translate }}">{{ 'New' | translate}}</span>
                        <div *ngIf="n.user" class="media-left">
                          <avatar [user]="n.user"></avatar>
                        </div>
                        <div *ngIf="(! n.user) && n.icon" class="media-left">
                          <i class="{{n.icon}} icon-2x text-main"></i>
                        </div>
                        <div class="media-body">
                          <p class="mar-no text-main text-semibold" [innerHTML]="n.message | safe"></p>
                          <small>{{ n.timestamp | date:'d MMM y - H:mm:ss' }}</small>
                        </div>
                        <!-- <button class="close" title="{{ 'Remove this entrty' | translate }}" stopPropagation (click)="theme.removeNotification(n)"><i class="fa fa-regular fa-times-circle"></i></button> -->
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <!--Dropdown footer-->
              <!--
              <div class="pad-all bord-top">
                <a class="btn-link text-main box-block">
                  <i class="pci-chevron chevron-right pull-right"></i>Show All Notifications
                </a>
              </div>
              -->
            </div>
          </li>
          <!--end of notifications dropdown-->

          <!--User dropdown-->
          <li id="nav-user" class="dropdown" *ngIf="!! session.currentUser">

            <a class="dropdown-toggle text-right clickable" data-bs-toggle="dropdown" data-bs-display="static">
              <div *ngIf="config.show_avatar.navbar" class="ic-user pull-right">
                <avatar [user]="session.currentUser" title="{{ session.currentUser.fullname }}"></avatar>
              </div>
              <span *ngIf="! config.show_avatar.navbar" class="ic-user pull-right">
                <i class="pli-male"></i>
              </span>
            </a>

            <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end panel-default">
              <ul class="head-list">
                <li *ngFor="let m of user_menu">
                  <a class="clickable" title="{{ m.description || '' | translate }}" stopPropagation (click)="m.link && nav.setRoot(m.link)"><i class="{{ m.icon }} icon-lg icon-fw"></i> {{ m.label | translate }}</a>
                </li>
              </ul>
            </div>
          </li>

          <li id="nav-user" class="dropdown" *ngIf="! session.currentUser">
            <a data-bs-toggle="dropdown" data-bs-display="static" class="dropdown-toggle text-right clickable">
              <span class="ic-user pull-right">
                <i class="pli-male"></i>
              </span>
            </a>

            <div class="dropdown-menu dropdown-menu-sm-end panel-default">
              <ul class="head-list">
                <li *ngFor="let m of login_menu">
                  <a class="clickable" title="{{ m.description || '' | translate }}" stopPropagation (click)="m.link && nav.setRoot(m.link)">
                    <i class="{{ m.icon }} icon-lg icon-fw"></i> {{ m.label | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <!-- end user dropdown-->

          <!-- aside toggle -->
          <li>
            <a class="aside-toggle clickable" *ngIf="has_aside">
              <i class="pli-dot-vertical"></i>
            </a>
          </li>
          <!-- end of aside-toggle -->

        </ul>
        <!-- end of Navbar Right -->
      </div>
      <!-- end of Navbar Dropdown-->

    </div>
  </header>
  <!-- end of Top Nav Bar -->

  <!-- Outer Content Container -->
  <div class="boxed">

    <!--Main Side Navigation -->
    <nav id="mainnav-container">
      <div id="mainnav">

        <!--OPTIONAL : ADD YOUR LOGO TO THE NAVIGATION-->
        <!--It will only appear on small screen devices.-->
        <div class="mainnav-brand">
          <a stopPropagation (click)="nav.setRoot('home')" class="brand clickable">
            <img src="assets/images/logo.png" alt="{{ APP_INFO.title | translate }}" class="brand-icon">
            <span class="brand-text">{{ APP_INFO.title | translate }}</span>
          </a>
          <a class="mainnav-toggle"><i class="pci-cross pci-circle icon-lg clickable"></i></a>
        </div>

        <!-- Menu -->
        <div id="mainnav-menu-wrap">
          <div class="nano has-scrollbar">
            <div class="nano-content">

              <!-- User Profile Widget -->
              <div id="mainnav-profile" class="mainnav-profile" *ngIf="!! session.currentUser">
                <div class="profile-wrap text-center">
                  <a href="#profile-nav" class="box-block" data-bs-toggle="collapse" data-bs-target="#profile-nav" aria-expanded="false">
                    <avatar class="pad-btm img-circle img-md" [user]="session.currentUser"></avatar>
                    <p class="mnp-name">{{ session.currentUser.fullname }}</p>
                    <span class="mnp-desc">{{ session.currentUser.email }}</span>
                    <img *ngIf="!! session.currentUser['badge']" id="user-profile-badge" class="{{ session.currentUser['badge_class'] }}" src="{{ session.currentUser['badge'] }}">
                  </a>
                </div>
                <div id="profile-nav" class="collapse list-group bg-trans">
                  <a class="clickable list-group-item" *ngFor="let m of user_menu" title="{{ m.description || '' | translate }}" stopPropagation (click)="m.link && nav.setRoot(m.link)">
                    <i class="{{ m.icon }} icon-lg icon-fw"></i> {{ m.label | translate}}
                  </a>
                </div>
              </div>
              <!-- end of User Profile Widget -->

              <!--Shortcut Buttons-->
              <!--
              <div id="mainnav-shortcut">
                <ul class="list-unstyled shortcut-wrap">
                  <li *ngFor="let m of user_menu; let i = index" class="col-xs-3" attr.data-content="{{ m.description | translate }}">
                    <a class="shortcut-grid" href="#" stopPropagation (click)="nav.setRoot( m.page.component, m.page.params )">
                      <div class="icon-wrap icon-wrap-sm icon-circle {{ circular(['bg-mint', 'bg-warning', 'bg-success', 'bg-purple'], i) }}">
                      <i class="{{ m.icon }} icon-lg icon-fw"></i>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              -->
              <!--
              <div id="mainnav-shortcut" class="hidden">
                <ul class="list-unstyled shortcut-wrap">
                  <li class="col-xs-3" data-content="My Profile">
                    <a class="shortcut-grid" href="#">
                      <div class="icon-wrap icon-wrap-sm icon-circle bg-mint">
                      <i class="pli-male"></i>
                      </div>
                    </a>
                  </li>
                  <li class="col-xs-3" data-content="Messages">
                    <a class="shortcut-grid" href="#">
                      <div class="icon-wrap icon-wrap-sm icon-circle bg-warning">
                      <i class="pli-speech-bubble-3"></i>
                      </div>
                    </a>
                  </li>
                  <li class="col-xs-3" data-content="Activity">
                    <a class="shortcut-grid" href="#">
                      <div class="icon-wrap icon-wrap-sm icon-circle bg-success">
                      <i class="pli-thunder"></i>
                      </div>
                    </a>
                  </li>
                  <li class="col-xs-3" data-content="Lock Screen">
                    <a class="shortcut-grid" href="#">
                      <div class="icon-wrap icon-wrap-sm icon-circle bg-purple">
                      <i class="pli-lock-2"></i>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              -->
              <!--end of Shortcut Buttons-->

              <!-- Main Menu : id 'mainnav-manu' is required -->
              <ul id="mainnav-menu" [menu-items]="main_menu"></ul>
              <!-- end of Main Menu -->

            </div>
          </div>
        </div>
        <!-- end of Menu -->

      </div>
    </nav>
    <!--end of Main Side Navigation -->

    <!-- Inner Content Container -->
    <div id="content-container">

      <!-- Page Head  -->
      <div id="page-head">

        <!-- to allow :empty pseudo select work, we must put them in the same line, put no space inside!! -->
        <div #page_head_content id="page-head-content"><ng-content select="[page-head]"></ng-content></div>

        <div *ngIf="page_head_content.childNodes.length === 0 && page_title" id="page-title">
          <h1 class="page-header text-overflow">{{ page_title | translate }}</h1>
        </div>

        <!-- breadcrumb -->
        <ol *ngIf="page_head_content.childNodes.length === 0 && breadcrumb.length > 0" class="breadcrumb">
          <li *ngFor="let b of breadcrumb">
            <a class="clickable" title="{{ b.label | translate }}" stopPropagation (click)="b.link && nav.setRoot(b.link)">
              <span>{{ (b.label || '???') | translate }}</span>
            </a>
          </li>
        </ol>
        <!-- end of breadcrumb -->

      </div>
      <!-- end of Page Head -->

      <!-- Page Content -->
      <div id="page-content">
        <ng-content select="[page-content]"></ng-content>
      </div>
      <!-- end of Page Content -->

    </div>
    <!-- end of Inner Content Container -->

    <!-- aside -->
    <aside id="aside-container">
      <div id="aside">
        <div class="nano">
          <div class="nano-content" #aside_content>
            <ng-content select="[page-aside]"></ng-content>
          </div>
        </div>
      </div>
    </aside>
    <!--end of aside -->

  </div>
  <!-- end of Outer Content Container -->

  <!-- Footer -->
  <page-footer></page-footer>
  <!-- end of Footer -->

  <!-- Scroll Page Button -->
  <button class="scroll-top btn">
    <i class="pci-chevron chevron-up"></i>
  </button>
  <!-- end of Scroll Page Button -->

</div>
<!-- end of Nifty Full Page -->