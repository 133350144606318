import {
  Component,
  ViewChild,
  ElementRef,
  OnInit
} from '@angular/core';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  ESResult,
  FileUtilsService,
  NavigationService,
  object_t,
  PaginatedESResult,
  ServerService,
  SessionService,
  Taxonomy,
  TaxonomyService,
  TreeUtilsService,
  User,
  UserService
} from '@pinacono/common';

import {
  InterpolatbleErrorMessage,
  LookupEvent,
  LookupItem,
  SupportedErrorTypes,
  TreeComponent,
  UIService
} from '@pinacono/ui';

/*
import {
  PinaconoValidatorsService
} from '../../modules/validators/validators.service';
*/

import { DocLibService } from '../doclib.service';
import { Document } from '../types';
import { AppUser } from 'src/app/types';

@Component({
  selector: 'page-doclib-reserve',
  templateUrl: 'reserve.html',
  styleUrls: [ 'reserve.scss' ]
})
export class DocLibReservePage implements OnInit {

  // forms
  @ViewChild('categoriesTree') categoriesTreeComponent!: TreeComponent;
  @ViewChild('reserveButton') reserveButton!: ElementRef;

  public docCodeByType: string[] = [];

  // for template casting
  public User!: User;
  public Taxonomy!: Taxonomy;

  // tree configuration
  public categories_options = {
    core: {
      themes: {
        name: 'proton',
        icons: false,
        dots: true
      },
      multiple: true
    },
    checkbox: {
      three_state: false,
      cascade: 'up+down' // up, down, undetermined join with '+', e.g., down+undetermined
    }
  };

  // enable/disable accordion layout
  public use_accordion: boolean = false;

  // data
  public document: Document;
  //public categories: Taxonomy[];
  public errors: {
    [name: string]: InterpolatbleErrorMessage | InterpolatbleErrorMessage[] | string | string[]
  } = {};

  // ----------------------------------------------------
  // -- life cycle
  // ----------------------------------------------------

  // constructor
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    //public validator: PinaconoValidatorsService,
    public api: DocLibService,
    protected server: ServerService,
    protected taxonomy: TaxonomyService,
    protected user: UserService,
    protected treeUtils: TreeUtilsService,
    protected fileUtils: FileUtilsService,
    protected el: ElementRef
  ) {
    this.document = this.api.createDocument();
  }

  public ngOnInit(): void {
    // new document
    this.has_code = true;
    if ( ! this.can_manage ) {
      this.document = this.api.createDocument({categories: [this.session.currentUser!.primary_domain]});
    }
    else {
      this.document = this.api.createDocument();
    }
  }

  // ----------------------------------------------------
  // -- Document Workflow
  // ----------------------------------------------------

  /**
   * lock and reserve document code
   */
   public lockID() {
    if ( ! this.can_reserve ) {
      return;
    }

    if ( ! this.validate() ) {
      this.ui.alert('Registration is incompleted. Please check!');
      return;
    }

    this.ui.confirm('The document code will no longer editable!', null, () => {
      const categories = this.api.is_confidential(this.document) ? [] : this.document.categories.map( (c:Taxonomy) => c.id );

      this.server.rejectOnError().request('doclib.code.lock', null, {
        prefix: this.document.attr['title']['prefix'] || null,
        title: this.document.title,
        suffix: this.document.attr['title']['suffix'] || null,
        has_softcopy: this.document.attr['has_softcopy'],
        has_hardcopy: this.document.attr['has_hardcopy'],
        code: this.api.getDocCode(this.document),
        domain_id: this.document.domain_id,
        categories: categories,
        mandatory_permitted_users: this.api.is_confidential(this.document) ? this.document.mandatory_permitted_users.map( (u: User) => u.id ) : [],
        optional_permitted_users: this.api.is_confidential(this.document) ? this.document.optional_permitted_users.map( (u: User) => u.id ) : [],
      })
      .then(
        (res: object_t) => {
          this.nav.goto(['/doc/edit/', res['id']]);
        },
        (err: Error) => {
          this.ui.alert('Error lock document code - {{reason}}', { reason: err });
        }
      );
    });
  }

  /**
   * lock form id (for memo)
   */
  public lockForm() {
    if ( ! this.can_lock_form ) {
      return;
    }
    if ( ! this.validate() ) {
      this.ui.alert('Registration is incompleted. Please check!');
      return;
    }

    this.ui.confirm('The document template will no longer editable!', undefined, () => {
      this.server //.silent()
      .request('doclib.template.lock', null, {
        title: this.document.title,
        template_code: this.document.template_code,
        domain: this.document.domain_id,
        categories: this.document.categories.map( (c:Taxonomy) => c.id )
      })
      .then( (res: object_t) => {
        /*
        this.params.data = { id: res['id'] };
        this.refresh();
        */
        this.nav.goto(['/doc/edit/', res['id']]);
      });
    });
  }


  // ----------------------------------------------------
  // -- validations
  // ----------------------------------------------------

  /**
   * validation logic based on action and document status
   * trig error message or pop error message
   */
  protected async validate(): Promise<boolean> {
    this.errors = {};

    // common validations
    if ( this.has_prefix && ! this.document.attr['title']['prefix'] ) {
      this.errors['prefix'] = 'Document title prefix is required';
    }
    if ( ! this.document.title ) {
      this.errors['title'] = 'Document title is required';
    }
    if ( this.has_suffix && ! this.document.attr['title']['suffix'] ) {
      this.errors['suffix'] = 'Document title suffix is required';
    }

    const dup_id: number = await this.server.silent().request('doclib.unique.title', null, {
      prefix: this.document.attr['title']['prefix'],
      title: this.document.title,
      suffix: this.document.attr['title']['suffix'],
      type: this.document.code?.f
    })
    if ( dup_id != 0 ) {
      const code = this.api.getDocCode(this.api.createDocument(await this.server.show('docs', dup_id)));
      this.errors['duplicated_title'] = `Title is duplicated with ${code} (id: ${dup_id})`;
    }

    if ( ! this.document.domain_id ) {
      this.errors['domain'] = 'Approval Domain is required';
    }

    if ( this.document.code && this.document.code.d == 'C' ) {
      delete this.errors['categories'];
    }
    else if ( this.document.categories.length == 0 ) {
      this.errors['categories'] = 'Allowed Group(s) is required';
    }

    /*
    const doc_code_fields = {
      a: 'สายงาน (A)',
      b: 'บริษัท (B)',
      c: 'หน่วยงาน (C)',
      d: 'ชั้นความลับ (D)',
      e: 'แหล่งเอกสาร (E)',
      f: 'ประเภทเอกสาร (F)',
      g: 'Flag (G) - 0-OMSE',
      id: 'หมายเลขเอกสาร (ID)'
    };

    let doc_code_errors: string[] = [];
    for ( let n in doc_code_fields ) {
      if ( n != 'id' && ! (this.document.code as object_t)[n] ) {
        doc_code_errors.push(`Code segment "${ (doc_code_fields as object_t)[n] }" is missing.`);
      }
    }

    if ( doc_code_errors.length > 0 ) {
      this.errors['doc_code'] = doc_code_errors;
    }
    */

    return Object.getOwnPropertyNames(this.errors).length == 0;
  }

  // ----------------------------------------------------
  // -- document flags and control attributes
  // ----------------------------------------------------

  public has_code: boolean = true;

  /**
   * if the doc is ready for code reservation
   */
  public get can_reserve(): boolean {
    return this.document.status == 'draft'// && this.validate();
  }

  /**
   * if document is a memo and form template is selected
   */
  public get can_lock_form(): boolean {
    return !!this.document.template_code// && this.validate();
  }

  /**
   * if user can manage this document (aka. DCC Manager)
   */
  public get can_manage(): boolean {
    return this.session.hasPermission(['doc_manage']);
  }

  /**
   * does the title require prefix?
   */
  public get has_prefix(): boolean {
    if ( ! this.document.code || ! this.document.code.f ) {
      return false;
    }
    let prefix = this.api.names['doc_prefix'][this.document.code.f];
    return  !! prefix && ! Array.isArray(prefix) && Object.getOwnPropertyNames(prefix).length > 0;
  }

  /**
   * does the title require suffix?
   */
   public get has_suffix(): boolean {
    return this.document.code && this.document.code.f && this.api.names['doc_suffix'][this.document.code.f];
  }

  /**
   * mark the document is 'dirty" (aka. being edited)
   * always return true to allow the template process
   * the next statement
   */
  public dirty: boolean = false;
  public markDirty() {
    this.dirty = true;
    this.validate();
  }

  // ----------------------------------------------------
  // -- form actions and event handlers
  // ----------------------------------------------------

  public onCategoriesChanged() {
    //this.document.categories = data;
    this.markDirty();
  }

  public doc_domain: string = '';
  public setDomain() {
    // ngx-select bug? domain value not immediately updated
    setTimeout( async () => {
      // map domain code to domain id
      const domain = this.api.getDepartmentTerm(this.doc_domain, this.document.code?.b);
      if ( !! domain ) {
        this.document.domain_id = domain.id || 0;
        this.document.domain = domain;
        this.document.mandatory_permitted_users = [];

        if ( domain.id ) {
          const managers = await this.server.show('groups', domain.id, { permissions: 'core_manage_group' });
          this.document.mandatory_permitted_users = managers.map( (u: object_t) => this.user.create(u));
        }
        this.document.mandatory_permitted_users.push(this.session.currentUser!);
      }
      else {
        this.ui.alert(`There is no domain id for [${this.document.code?.b || 'unknown'}]/[${this.doc_domain}]`);
      }
      this.markDirty();
    });
  }

   /**
    * lookup for a form
    */
  public forms_list: LookupItem<string>[] = [];
  public lookupForm(keyword: string) {
    /**
     * --Note--
     *
     * by some currently unknown reason, the filter on "status" field have to be search
     * against the property 'keyword'. Otherwise, the length of the search term will
     * not be able to longer than 7 characters. Need to re-check the mappings
     */
    this.server.search('docs', `(attributes.code.f:FORM) AND ${keyword}`, { 'status.keyword': 'published' }, { perpage: 5 })
    .then( (res: PaginatedESResult<Document>) => {
      this.forms_list = res.data.map( (r: ESResult<Document>) => {
        let d: Document = r.doc;
        return {
          label: d['doc_code'] + ' : ' + ( d['attr']['title'].prefix || '' ) + d.title + ( d['attr']['title'].suffix || ''),
          value: d['doc_code']
        };
      })
    });
  }

  /**
   * select a form as the master of the document
   */
  public selectForm(item: LookupEvent<string>) {
    if ( item.value.value !== null ) {
      this.document.template_code = item.value.value;
    }
  }

  /**
   * Reaction on document code copying
   */
  public onCodeCopied(text: string) {
    this.ui.alert('Document code: {{ code }} is successfully copied to clipboard', { code: text });
  }

  public docCodeHasError(hasError: boolean) {
    this.reserveButton.nativeElement.disabled = hasError;
    console.log('haserror', hasError);
  }

  protected throttleValidationTimer: number = 0;
  public async docCodeChange(segment: string) {
    // change event raised before value in the model get updated
    // therefore, we validate it on the next cycle with setTimeout
    if ( this.throttleValidationTimer > 0 ) {
      clearTimeout(this.throttleValidationTimer);
    }

    this.throttleValidationTimer = setTimeout( () => {
      this.throttleValidationTimer = 0;
      this.document.type = this.document.code!.f;

      if ( segment == 'f' ) {
        this.document.attr['title']['prefix'] = null;
        this.document.attr['title']['suffix'] = null;
      }

      if ( this.api.is_confidential(this.document) ) {
        this.errors['categories'] && delete this.errors['categories'];
      }
      else {
        // adjust document allowed group according to c-segment
        if ( this.document.code?.c && this.can_manage ) {
          let node = this.api.getDepartmentTerm(this.document.code.c, this.document.code.b);
          if ( node !== null ) {
            this.categoriesTreeComponent.unselectAll();
            this.categoriesTreeComponent.select([node.id!]);
          }
        }
      }

      this.markDirty();
    });
  }

  // -- confidential document
  public permitUser(user: AppUser) {
    this.document.optional_permitted_users.push(user);
  }

  public revokeUserWrapper = this.revokeUser.bind(this);
  public async revokeUser(user: AppUser) {
    const index = this.document.optional_permitted_users.findIndex( u => u.id == user.id );
    if ( index < 0 ) return;
    if ( await this.ui.confirm(`Remove ${user.fullname} from permitted users?`) ) {
      this.document.optional_permitted_users.splice(index, 1);
    }
    /*
    if ( await this.ui.confirm(`Remove ${user.fullname} from ${member.project_role}?`) ) {
      try {
        await this.server
        .rejectOnError(true)
        .destroy('projects/roles/internal', member.id!)
        .then( (res: number) => {
          this.project.internal_project_roles.splice(index, 1);
        });
      }
      catch ( e: any ) {
        console.error('exception', e);
        if ( e.info ) {
          this.ui.alert('Server reject the request with message: "{{message}}"', { message: e.info }, 'Error!');
        }
      }
    }
    */
  }
}
