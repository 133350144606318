<full-page [title]="document.title">
  <div page-content>
    <panel class="doc-lib">
      <div panel-body class="fluid">

        <h4 class="pad-btm bord-btm">
          <i class="pli-file icon-fw v-middle"></i>
          <span class="text-semibold">
            {{ has_prefix ? document.attr['title']['prefix'] + ' ' : '' }}
            {{ document.title || 'New Document' }}
            {{ has_suffix ? document.attr['title']['suffix'] : ''}}
          </span>
          <span *ngIf="!! document.id" class="text-thin small"> - {{ document.template_code ? '(Form ID: ' + document.template_code +' )': document.doc_code }}</span>
        </h4>

        <form #mainForm="ngForm">

          <!-- upper block -->
          <div class="row">
            <!-- Left Panel -->
            <div class="col-xs-12 col-sm-4" id="allowed-groups">
              <ng-container *ngIf="api.is_confidential(document)">
                <div class="pad-btm">
                  <div class="text-bold pad-btm">{{ 'Mandatory Permitted Persons' | translate }}:</div>
                  <validation-errors
                    id="allow-persons-error"
                    [errors]="errors['allow-persons']"
                  >
                  </validation-errors>
                  <user-card *ngFor="let user of document.mandatory_permitted_users" [user]="user!"></user-card>
                </div>
                <div class="pad-btm">
                  <div class="text-bold pad-btm">{{ 'Optional Permitted Persons' | translate }}:</div>
                  <div *ngIf="document.optional_permitted_users.length == 0" class="text-thin text-muted pad-btm">
                    {{ 'No optional permitted persons is assigned.' | translate }}
                  </div>
                  <user-card *ngFor="let user of document.optional_permitted_users" [user]="user!" [buttons]="[{
                    class: 'btn-danger',
                    icon: 'fa-regular fa-circle-xmark',
                    label: 'Remove',
                    closure: user,
                    action: revokeUserWrapper
                  }]"></user-card>
                  <user-lookup
                    [delay]="3000"
                    (onSelect)="permitUser($event)"
                  ></user-lookup>
                </div>
              </ng-container>

              <ng-container *ngIf="! api.is_confidential(document)" >
                <div class="text-bold pad-btm">{{ 'Allowed Groups' | translate }}:</div>
                <validation-errors
                  id="categories-error"
                  [errors]="errors['categories']"
                ></validation-errors>
                <tree #categoriesTree
                  [options]="categoriesOptions"
                  [data]="api.names['categories']"
                  [expand]="true"
                  [(selected)]="document.categories"
                  (selectedChange)="onSelectedCategoriesChange()"
                ></tree>
              </ng-container>
            </div>

            <!-- Right Panel -->
            <div class="col-xs-12 col-sm-8">

              <!-- accordion : require 'use_accordion = true' to enable -->
              <div class="panel-group" [ngClass]="{ use_accordion : 'accordion' }" id="accordion">

                <!-- section 1: id reservation / form locking -->
                <div class="panel panel-bordered panel-default">

                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-parent="#accordion" data-toggle="collapse" href="{{ use_accordion ? '#doc-registration' : null }}" aria-expanded="false" [ngClass]="{ use_accordion: 'collapsed' }">
                        {{ 'Document Registration' | translate }}
                      </a>
                    </h4>
                  </div>

                  <div [ngClass]="{ use_accordion: 'panel-collapse collapse in' }" id="doc-registration" aria-expanded="true">
                    <div class="panel-body">
                      <!-- status -->
                      <div class="row">
                        <div class="col-xs-12">

                          <div id="status-badge">
                            <span *ngIf="document.status == 'draft'"     class="label label-default text-thin">{{ 'Draft' | translate }}</span>
                            <span *ngIf="document.status == 'reserved'"  class="label label-primary text-thin">{{ 'Reserved' | translate }}</span>
                            <span *ngIf="document.status == 'submitted'" class="label label-warning text-thin">{{ 'Submitted' | translate }}</span>
                            <span *ngIf="document.status == 'reviewed'"  class="label label-info text-thin">{{ 'Approved' | translate }}</span>
                            <span *ngIf="document.status == 'published'" class="label label-success text-thin">{{ 'Published' | translate }}</span>
                            <span *ngIf="document.status == 'cancelled'" class="label label-danger text-thin">{{ 'Cancelled' | translate }}</span>
                            <span *ngIf="document.status == 'expired'"   class="label label-dark text-thin">{{ 'Expired' | translate }}</span>
                            <span *ngIf="document.status == 'deleted'"   class="label label-dark text-thin text-muted">{{ 'Deleted' | translate }}</span>
                          </div>

                        </div>
                      </div>

                      <!-- document title -->
                      <div class="pad-top row" id="doc-title">
                        <div class="col-xs-12 form-group">
                          <label class="form-label text-bold">{{ 'Document Title' | translate }}:</label>
                          <div class="row">
                            <!-- document prefix -->
                            <div *ngIf="has_prefix" class="col-xs-12 col-sm-4" style="padding-left: 0px;">
                              <div>
                                <select #prefix="ngModel"
                                  class="form-control"
                                  title="{{ 'Select document title prefix' | translate }}"
                                  name="doc-title-prefix"
                                  required
                                  [attr.disabled]="document.status == 'draft' ? null : 1"
                                  (change)="markDirty()"
                                  [(ngModel)]="document.attr['title']['prefix']"
                                >
                                  <option *ngFor="let item of api.names['doc_prefix'][document.code!.f] | keyvalue"
                                    value="{{ item.value }}"
                                    [attr.selected]="document.attr['title']['prefix'] == item.value ? '' : null"
                                  >
                                    {{ item.value }}
                                  </option>
                                </select>
                                <validation-errors
                                  [models]="prefix"
                                  [messages]="{ required: 'Document title prefix is required' }"
                                  [dirty]="true"
                                ></validation-errors>
                              </div>
                            </div>

                            <!-- document title -->
                            <div class="col-xs-12 pad-no"
                              [ngClass]="{
                                'col-sm-4'  : has_prefix && has_suffix,
                                'col-sm-12' : ! has_prefix && ! has_suffix,
                                'col-sm-8'  : ( has_prefix && ! has_suffix ) || ( ! has_prefix && has_suffix )
                              }"
                            >
                              <input #title="ngModel"
                                type="text"
                                class="form-control"
                                required
                                name="title"
                                (change)="markDirty()"
                                [(ngModel)]="document.title"
                              >
                              <validation-errors
                                [models]="title"
                                [messages]="{ required: 'Document title is required' }"
                                [dirty]="true"
                              ></validation-errors>
                            </div>

                            <!-- document suffix -->
                            <div *ngIf="has_suffix" class="col-xs-12 col-sm-4">
                              <input #suffix="ngModel"
                                type="text"
                                class="form-control"
                                name="title-suffix"
                                [attr.disabled]="document.status == 'draft' ? null : 1"
                                (change)="markDirty()"
                                [(ngModel)]="document.attr['title']['suffix']"
                              >
                            </div>
                          </div>
                          <validation-errors
                            [errors]="errors['duplicated_title']"
                            [dirty]="true"
                          ></validation-errors>
                          <validation-errors
                            [errors]="errors['title']"
                            [dirty]="true"
                          ></validation-errors>

                        </div>
                      </div>

                      <!-- registration form attributes -->
                      <div class="pad-top row" id="doc-type">

                        <!-- domain -->
                        <div class="col-xs-12 col-sm-6">
                          <div class="text-sm" [title]="'Domain'" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="domain">
                            <label class="text-bold form-label">{{ 'Domain' | translate }}:</label>
                            <ngx-select #domain="ngModel"
                              class="full-width"
                              [items]="api.names['doc_code_formatter'].c[document.code?.b || 'SPW']"
                              required name="domain"
                              [(ngModel)]="doc_domain"
                              (selectionChanges)="setDomain()"
                            >
                              <ng-template ngx-select-option-selected let-option>
                                {{ option.data.value }}
                              </ng-template>
                              <ng-template ngx-select-option let-option>
                                {{ option.data.data.label }}
                              </ng-template>
                            </ngx-select>
                            <!--
                            <span class="text-thin">{{document.domain?.name || 'n/a'}}</span>
                            <collapse-button
                              [target]="'domain'"
                              [collapsed]="true"
                            ></collapse-button>
                            -->
                          </div>
                        </div>

                        <!-- registration request attribute info -->
                        <div class="col-xs-12 col-sm-6">
                          <div class="form-group">
                            <label class="form-checkbox-label" for="has_softcopy">
                              {{'Soft-copies Available' | translate}}
                            </label>
                            <input #has_soft_copy type="checkbox"
                              class="form-checkbox-input"
                              [(ngModel)]="document.attr['has_softcopy']"
                              (change)="updateDocHasSoftCopy()"
                              name="has_softcopy"
                              id="has_softcopy"
                            >
                          </div>
                          <div *ngIf="! api.is_confidential(document)" class="form-group">
                            <label class="form-checkox-label" for="has_hardcopy">
                              {{'Hard-copies Available' | translate}}
                            </label>
                            <input #has_hard_copy type="checkbox"
                              class="form-checkbox-input"
                              [(ngModel)]="document.attr['has_hardcopy']"
                              (change)="updateDocHasHardCopy()"
                              name="has_hardcopy"
                              id="has_hardcopy"
                            >
                          </div>
                          <!--
                          <div *ngIf="document.attr['has_hardcopy']" class="form-group">
                            <label class="form-checkbox-label" for="this_is_publication">
                              <input #lendable type="checkbox"
                                class="form-checkbox-input"
                                [(ngModel)]="document.attr['lendable']"
                                (change)="updateDocLendable()"
                                name="lendable"
                                id="this_is_publication"
                              >
                              {{'Lendable' | translate}}
                            </label>
                          </div>
                          -->
                          <validation-errors
                            [errors]="errors['registration_attr']"
                          ></validation-errors>
                        </div>

                      </div>

                      <!-- document code - locked on first save  -->
                      <div class="pad-top row">
                        <div class="col-xs-12 col-sm-12 form-group">

                          <!-- document code display -->
                          <div>
                            <label *ngIf=" ! document.template_id" class="form-label text-bold">{{ 'Document Code' | translate }}:</label>
                            <label *ngIf="!! document.template_id" class="form-label text-bold">{{ 'Form Template' | translate }}:</label>
                            <span class="text-thin" #doccode>{{ document.doc_code || document.template_code }}</span>
                            <copy-button
                              [src]="doccode"
                              (copied)="onCodeCopied($event)"
                            ></copy-button>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>

                </div>
                <!-- end of section 1 -->

                <!-- section 2: document common attributes -->
                <div class="panel panel-bordered panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-parent="#accordion" data-toggle="collapse" href="{{ use_accordion ? '#doc-info' : null }}" aria-expanded="false" [ngClass]="{ use_accordion: 'collapsed' }">
                        {{ 'Document General Information' | translate }}
                      </a>
                    </h4>
                  </div>

                  <div [ngClass]="{ user_accordion: 'panel-collapse collapse' }" id="doc-info" aria-expanded="true">
                    <div class="panel-body">
                      <!-- common attributes -->
                      <div class="pad-top row">

                        <!-- uploader -->
                        <div class="col-xs-12 col-sm-6">
                          <label class="form-label text-bold">{{ 'Uploader' | translate }}</label>
                          <user-card *ngIf="!! document.uploader"
                            [user]="document.uploader"
                          ></user-card>
                        </div>
                        <!-- end of uploader -->

                        <div class="col-xs-12 col-sm-6">
                          <div class="row pad-no">
                            <!-- related locations -->
                            <div class="col-sm-12 form-group" id="related-locations">
                              <label class="form-label text-bold">{{ 'Related Building' | translate }}:</label>
                              <select class="form-control" #buildings="ngModel"
                                required
                                multiple
                                title="Select related building..."
                                name="locations"
                                [chosen]="{ disable_search: true }"
                                (change)="markDirty()"
                                [(ngModel)]="document.attr['locations']"
                              >
                                <option *ngFor="let item of api.names['locations'] | keyvalue"
                                  value="{{ item.value }}"
                                  [attr.selected]="document.attr['locations'] && document.attr['locations'].indexOf(item.value) >= 0 ? '' : null"
                                >
                                  {{ item.value }}
                                </option>
                              </select>
                              <validation-errors
                                [models]="buildings"
                                [messages]="{ required: 'Related building is required' }"
                                [dirty]="true"
                              ></validation-errors>
                            </div>
                            <!-- end of related locations -->

                            <!-- document approval date -->
                            <div *ngIf="! api.is_confidential(document)" class="col-sm-12 form-group">
                              <label class="coltrol-label text-bold">{{ 'Document Approve Date' | translate }}:</label>
                              <input #docdate="ngModel"
                                required
                                type="date"
                                class="form-control datepicker"
                                name="doc_date"
                                value="{{ document.attr['doc_date'] | date:'yyyy-MM-dd' }}"
                                (change)="markDirty()"
                                [(ngModel)]="document.attr['doc_date']"
                              >
                              <validation-errors
                                [models]="docdate"
                                [messages]="{ required: 'Document Approve Date is required' }"
                              ></validation-errors>
                            </div>
                            <!-- end of document date -->
                          </div>
                        </div>
                      </div>

                      <div class="pad-top row">
                        <!-- @TODO - implement document schedules -->
                        <!-- expiry date -->
                        <div class="col-xs-12 form-group">
                          <label class="form-label text-bold">
                            Expiration Date:
                          </label>
                          <div class="row pad-no" id="expiration">
                            <div class="col-xs-12 col-md-3">
                              <label class="form-checkbox-label" for="noexpire">
                                {{'No Expiration' | translate}}
                              </label>
                              <input #noexpire type="checkbox"
                                class="form-checkbox-input"
                                (change)="doc_noexpire = noexpire.checked ; markDirty()"
                                [attr.checked]="!! doc_noexpire ? 1 : null"
                                name="noexpire"
                                id="noexpire"
                              >
                            </div>

                            <div *ngIf="! doc_noexpire"
                              class="col-xs-12 col-md-9"
                              id="expire-date-picker"
                            >
                              <div class="row">
                                <div class="col-xs-8 col-md-8">
                                  <date-calculator
                                    [(data)]="document.expire_date!"
                                    [format]="'YYYY-MM-DD'"
                                    (change)="markDirty()"
                                  ></date-calculator>
                                </div>
                                <div class="col-xs-1 col-md-1">
                                  <i class="fa far-regular fa-hand-point-right"></i>
                                </div>
                                <div class="col-xs-3 col-md-3">
                                  <input #expiration="ngModel"
                                    type="date"
                                    class="form-control datepicker"
                                    name="expiration"
                                    (change)="markDirty()"
                                    value="{{ document.expire_date | date:'YYYY-MM-DD }}"
                                    [(ngModel)]="document.expire_date"
                                    required
                                  >
                                  <validation-errors
                                    [models]="expiration"
                                    [messages]="{ required: 'Expiration Date is required' }"
                                  ></validation-errors>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- synopsis -->
                      <div class="pad-top row">
                        <div class="col-xs-12 form-group">
                          <label class="form-label text-bold">{{ 'Synopsis' | translate }}:</label>
                          <textarea id="synopsis" #synopsis="ngModel"
                            name="synopsis"
                            class="form-control"
                            autosize
                            style="resize:none;"
                            required
                            minlength=50
                            (change)="markDirty()"
                            [(ngModel)]="document.content"
                          ></textarea>
                          <validation-errors
                            [models]="synopsis"
                            [messages]="{
                              required: 'Synopsis is required.',
                              minlength: 'Atleast 50 characters are required.'
                            }"
                          ></validation-errors>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end of section 2 -->

                <!-- section 3: type-specific atrributes -->
                <div *ngIf="has_type_specific_attributes" class="panel panel-bordered panel-default">

                  <!-- OPL: One-Point Lesson -->
                  <ng-container *ngIf="document.code!.f == 'OPL'">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-parent="#accordion" data-toggle="collapse" href="{{ use_accordion ? '#type-info' : null }}" aria-expanded="false" [ngClass]="{ user_accordion: 'collapsed' }">
                          {{ 'OPL' | translate }}
                        </a>
                      </h4>
                    </div>

                    <div [ngClass]="{ use_accordion: 'panel-collapse collapse'}" id="type-info" aria-expanded="false">
                      <div class="panel-body">
                        <div *ngIf="this.commonApi.hasModuleContent(document)" class="row">
                        <div class="col form-group">
                          <label class="form-label">OPL: </label>
                          <button class="download btn btn-labeled file-link" stop-propagation (click)="gotoContent()" title="{{ 'Click to go to this OPL' | translate }}">
                            <i class="fa-fw icon-fw btn-default icon-label text-success {{ api.getFileIcon('link') }}"></i>
                            <span class="text-thin filename">{{ document.title }}</span>
                          </button>
                        </div>
                       </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- BOOK: publication attributes -->
                  <ng-container *ngIf="document.code!.f == 'BOOK'">

                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-parent="#accordion" data-toggle="collapse" href="{{ use_accordion ? '#type-info' : null }}" aria-expanded="false" [ngClass]="{ use_accordion: 'collapsed' }">
                          {{ 'Book Information' | translate }}
                        </a>
                      </h4>
                    </div>

                    <div [ngClass]="{ use_accordion: 'panel-collapse collapse' }" id="type-info" aria-expanded="false">
                      <div class="panel-body">
                        <div class="row">
                          <div class="col-xs-12 col-md-3 form-group">
                            <label class="form-label text-bold">{{ 'Type' | translate }}:</label>
                            <select
                              class="form-control"
                              title="Publication Type..."
                              name="publication_type"
                              (change)="markDirty()"
                              [(ngModel)]="document.attr['BOOK']['type']"
                            >
                              <option value="book"     [attr.selected]="document.attr['BOOK']['type'] == 'book'     ? '' : null">{{ 'Book' | translate }}</option>
                              <option value="magazine" [attr.selected]="document.attr['BOOK']['type'] == 'magazine' ? '' : null">{{ 'Magazine' | translate }}</option>
                            </select>
                          </div>
                          <div class="col-xs-12 col-md-3 form-group">
                            <label class="form-label text-bold">{{ 'ISBN/ISSN' | translate }}:</label>
                            <input name="isbn"
                              type="text"
                              class="form-control"
                              (change)="markDirty()"
                              [(ngModel)]="document.attr['BOOK']['isbn']"
                            >
                          </div>
                          <!--
                          <div class="col-xs-12 col-md-3 form-group">
                            <label class="form-label text-bold">{{ 'Generes' | translate }}:</label>
                            <input name="genres"
                              type="text"
                              class="form-control"
                              (change)="markDirty()"
                              [(ngModel)]="document.attr['BOOK']['genres']"
                            >
                          </div>
                          -->
                          <div class="col-xs-12 col-md-3 form-group">
                            <label class="form-label text-bold">{{ 'Price' | translate }}:</label>
                            <input name="price"
                              type="number"
                              class="form-control full-width"
                              (change)="markDirty()"
                              [(ngModel)]="document.attr['BOOK']['price']"
                            >
                          </div>

                          <div class="col-xs-12 col-md-3 form-group">
                            <label class="form-label text-bold">{{ 'Author' | translate }}:</label>
                            <input name="publication_author"
                              type="text"
                              class="form-control"
                              (change)="markDirty()"
                              [(ngModel)]="document.attr['BOOK']['authors']"
                            >
                          </div>
                          <div class="col-xs-12 col-md-3 form-group">
                            <label class="form-label text-bold">{{ 'Publisher' | translate }}:</label>
                            <input name="publisher"
                              type="text"
                              class="form-control"
                              (change)="markDirty()"
                              [(ngModel)]="document.attr['BOOK']['publishers']"
                            >
                          </div>
                          <div class="col-xs-12 col-md-3 form-group">
                            <label class="form-label text-bold">{{ 'Publish Date' | translate }}:</label>
                            <input name="publish_date"
                              type="text"
                              class="form-control"
                              (change)="markDirty()"
                              [(ngModel)]="document.attr['BOOK']['publish_date']"
                            >
                          </div>
                        </div>
                        <!-- Covers: Book and other lendable documents -->
                        <div *ngIf="has_cover" class="row">
                          <div class="col">
                            <label class="form-label text-bold">{{ 'Covers' | translate }}</label>
                            <attachments #covers_attachment id="covers-uploader"
                              [(files)]="covers"
                              (change)="markDirty()"
                              [accepted-file-types]="'image/*'"
                              [master-content-type]="'docs'"
                              [master-id]="document.id"
                              [defer-key]="document.attachment_defer_key"
                              [common-meta-data]="{is_cover: true}"
                            ></attachments>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- PRJ: Project -->
                  <ng-container *ngIf="document.code!.f == 'PRJ'">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-parent="#accordion" data-toggle="collapse" href="{{ use_accordion ? '#type-info' : null }}" aria-expanded="false" [ngClass]="{ user_accordion: 'collapsed' }">
                          {{ 'Project' | translate }}
                        </a>
                      </h4>
                    </div>

                    <div [ngClass]="{ use_accordion: 'panel-collapse collapse'}" id="type-info" aria-expanded="false">
                      <div class="panel-body">
                       <div *ngIf="this.commonApi.hasModuleContent(document)" class="row">
                        <div class="col form-group">
                          <label class="form-label">Project: </label>
                          <button class="download btn btn-labeled file-link" stop-propagation (click)="gotoContent()" title="{{ 'Click to go to project page' | translate }}">
                            <i class="fa-fw icon-fw btn-default icon-label text-success {{ api.getFileIcon('link') }}"></i>
                            <span class="text-thin filename">{{ document.title }}</span>
                          </button>
                        </div>
                       </div>

                        <div class="row">
                          <extended-slickgrid *ngIf="!! projectDocumentsHandOverGridOptions" gridId="project-doc-table"
                            class="row-selectable"
                            [columnDefinitions]="projectDocumentsHandOverColumnDefinitions"
                            [gridOptions]="projectDocumentsHandOverGridOptions"
                            (onAngularGridCreated)="onProjectDocumentsHandOverGridReady($event)"
                            (onClick)="onProjectDocumentsHandOverGridSelectRow($event)"
                          >
                          </extended-slickgrid>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- DWG: Drawings -->
                  <ng-container *ngIf="document.code!.f == 'DWG'">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-parent="#accordion" data-toggle="collapse" href="{{ use_accordion ? '#type-info' : null }}" aria-expanded="false" [ngClass]="{ use_accordion: 'collapsed' }">
                          {{ 'Drawing Information' | translate }}
                        </a>
                      </h4>
                    </div>

                    <div [ngClass]="{ use_accordion: 'panel-collapse collapse' }" id="type-info" aria-expanded="false">
                      <div class="panel-body">
                        <div class="row">
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- CODE: Code and Standard -->
                  <ng-container *ngIf="document.code!.f == 'CODE'">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-parent="#accordion" data-toggle="collapse" href="{{ use_accordion ? '#type-info' : null }}" aria-expanded="false" [ngClass]="{ use_accordion: 'collapsed' }">
                          {{ 'Code Information' | translate }}
                        </a>
                      </h4>

                      <div [ngClass]="{ use_accordion: 'panel-collapse collapse' }" id="type-info" aria-expanded="false">
                        <div class="panel-body">
                          <div class="row">
                            <div class="col-xs-12 form-group">
                              <label class="form-label text-bold">{{ 'System' | translate }}:</label>
                              <select
                                class="form-control"
                                title="System name..."
                                name="system_name"
                                (change)="markDirty()"
                                [(ngModel)]="document.attr[document.code!.f].system"
                              >
                                <option *ngFor="let item of api.names['doc_attributes'][document.code!.f].system | keyvalue"
                                  value="{{ item.value }}"
                                  [attr.selected]="document.attr[document.code!.f].system == item.value ? '' : null"
                                >
                                  {{ item.value }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- VENDOR: Vendor -->
                  <ng-container *ngIf="document.code!.f == 'VENDOR'">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-parent="#accordion" data-toggle="collapse" href="{{ use_accordion ? '#type-info' : null }}" aria-expanded="false" [ngClass]="{ use_accordion: 'collapsed' }">
                          {{ 'Vendor Information' | translate }}
                        </a>
                      </h4>

                      <div [ngClass]="{ use_accordion: 'panel-collapse collapse' }" id="type-info" aria-expanded="false">
                        <div class="panel-body">
                          <div class="row">
                            <div class="col-xs-12 form-group">
                              <label class="form-label text-bold">{{ 'System' | translate }}:</label>
                              <select
                                class="form-control"
                                title="System name..."
                                name="system_name"
                                (change)="markDirty()"
                                [(ngModel)]="document.attr[document.code!.f].system"
                              >
                                <option *ngFor="let item of api.names['doc_attributes'][document.code!.f].system | keyvalue"
                                  value="{{ item.value }}"
                                  [attr.selected]="document.attr[document.code!.f].system == item.value ? '' : null"
                                >
                                  {{ item.value }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- CONSULT: Consultant -->
                  <ng-container *ngIf="document.code!.f == 'CONSULT'">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-parent="#accordion" data-toggle="collapse" href="{{ use_accordion ? '#type-info' : null }}" aria-expanded="false" [ngClass]="{ use_accordion: 'collapsed' }">
                          {{ 'Consultant Information' | translate }}
                        </a>
                      </h4>

                      <div [ngClass]="{ use_accordion: 'panel-collapse collapse' }" id="type-info" aria-expanded="false">
                        <div class="panel-body">
                          <div class="row">
                            <div class="col-xs-12 form-group">
                              <label class="form-label text-bold">{{ 'System' | translate }}:</label>
                              <select
                                class="form-control"
                                title="System name..."
                                name="system_name"
                                (change)="markDirty()"
                                [(ngModel)]="document.attr[document.code!.f].system"
                              >
                                <option *ngFor="let item of api.names['doc_attributes'][document.code!.f].system | keyvalue"
                                  value="{{ item.value }}"
                                  [attr.selected]="document.attr[document.code!.f].system == item.value ? '' : null"
                                >
                                  {{ item.value }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- 3RD: 3rd Party -->
                  <ng-container *ngIf="document.code!.f == '3RD'">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-parent="#accordion" data-toggle="collapse" href="{{ use_accordion ? '#type-info' : null }}" aria-expanded="false" [ngClass]="{ use_accordion: 'collapsed' }">
                          {{ '3rd Party Information' | translate }}
                        </a>
                      </h4>

                      <div [ngClass]="{ use_accordion: 'panel-collapse collapse' }" id="type-info" aria-expanded="false">
                        <div class="panel-body">
                          <div class="row">
                            <div class="col-xs-12 form-group">
                              <label class="form-label text-bold">{{ 'System' | translate }}:</label>
                              <select
                                class="form-control"
                                title="System name..."
                                name="system_name"
                                (change)="markDirty()"
                                [(ngModel)]="document.attr[document.code!.f].system"
                              >
                                <option *ngFor="let item of api.names['doc_attributes'][document.code!.f].system | keyvalue"
                                  value="{{ item.value }}"
                                  [attr.selected]="document.attr[document.code!.f].system == item.value ? '' : null"
                                >
                                  {{ item.value }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                </div>
                <!-- end of section 3 -->

                <!-- section 4: document relations -->
                <div *ngIf="! api.is_confidential(document)" class="panel panel-bordered panel-default">

                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-parent="#accordion" data-toggle="collapse" href="{{ use_accordion ? '#doc-relation' : null }}" aria-expanded="false" [ngClass]="{ use_accordion: 'collapsed' }">
                        {{ 'Document Relations' | translate }}
                      </a>
                    </h4>
                  </div>

                  <div [ngClass]="{ use_accordion: 'panel-collapse collapse' }" id="doc-relation" aria-expanded="false">
                    <div class="panel-body">
                      <!-- related documents -->
                      <div class="pad-top row">
                        <div class="col-xs-12 form-group">
                          <label class="form-label text-bold">{{ 'Related Documents' | translate }}</label>
                          <related-documents id="related-doc" name="related-docs"
                            [(ngModel)]="document.references"
                            [min-char]="4" [max-items]="7"
                            (change)="markDirty()"
                          >
                          </related-documents>
                        </div>
                      </div>

                      <!-- referred by documents -->
                      <div class="pad-top row">
                        <div class="col-xs-12 form-group">
                          <label class="form-label text-bold">{{ 'Referred By' | translate }}</label>
                          <span class="text-muted text-very-thin text-xs">(Read-Only)</span>
                          <textarea name="referred_by" id="referred_by"
                            readonly
                            class="form-control"
                            autosize
                            [(ngModel)]="document.referred_by"
                            style="resize:none;"
                            [attr.rows]="5"
                          ></textarea>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
                <!-- end of section 4 -->

              </div>
              <!-- end of accordion -->

            </div>
            <!-- end of right panel -->

          </div>
          <!-- end of upper block -->

          <!-- section 5: files -->
          <div class="panel panel-bordered panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Document Files' | translate }}
              </h4>
            </div>

            <div class="panel-body">

              <div class="row">
                <!-- section 5.1: Registration Document -->
                <div *ngIf="! api.is_confidential(document)" id="registration-request" class="col-xs-12">
                  <label class="text-bold pad-rgt pad-btm" data-toggle="collapse">{{ 'Registration Request' | translate }}:</label>
                  <div class="panel panel-bordered panel-default">
                    <div class="pabel-body pad-all">
                      <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-9">
                          <form #newRegistrationForm="ngForm" class="form-horizontal">
                            <table class="table revisions">
                              <thead>
                                <tr>
                                  <th class="revision">{{ 'Date' | translate }}</th>
                                  <!-- <th class="file-name">{{ 'File Name' | translate }}</th> -->
                                  <th class="file-name">{{ 'Title' | translate }}</th>
                                  <th class="file-size">{{ 'File Size' | translate }} </th>
                                  <th class="download">{{ 'Download' | translate }}</th>
                                  <th class="actions">&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>

                                <!-- existing revisions -->
                                <ng-container *ngFor="let rev of ( document.registration_doc?.revisions?.data) || []; let i = index">
                                <ng-container *ngIf="rev.attachments.length > 0">
                                  <tr>
                                    <td class="revision">{{ rev.created_at | datetime:'d MMM yy HH:mm:ss' }}</td>
                                    <!-- <td class="file-name">{{ rev.attachments[0].file_name }}</td> -->
                                    <td class="file-name">{{ document.registration_doc?.title || '- no title -' }}</td>
                                    <td class="file-size">{{ ( rev.attachments[0].file_size | memsize | number:'1.2-2') + ' kB'  }}</td>
                                    <td class="download">
                                      <button class="btn btn-labeled file-link" stop-propagation (click)="download(rev.attachments[0])" title="{{ '{{filename}} - Click to download' | translate:{filename: rev.attachments[0].file_name} }}">
                                        <i class="fa-fw icon-fw fa-2x icon-label {{ api.can_download(document) ? 'text-success' : 'text-muted' }} {{ api.getFileIcon(rev.attachments[0].content_type) }}"></i>
                                      </button>
                                    </td>
                                    <td class="actions">
                                      <button class="btn btn-icon btn-default" (click)="deleteRevision(document.registration_doc!, rev)" title="{{ 'Delete' | translate}}">
                                        <i class="pli-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </ng-container>
                                </ng-container>
                                <!-- end of existing revisions -->

                                <!-- revision pagination -->
                                <tr *ngIf="!! document.registration_doc && !! document.registration_doc.revisions" class="pagination-container">
                                  <td colspan="5" class="text-center">
                                    <pagination
                                      [pageno]="document.registration_doc.revisions.pageno"
                                      [perpage]="document.registration_doc.revisions.perpage"
                                      [total]="document.registration_doc.revisions.total"
                                      (pagenoChange)="loadPaginatedRevisions(document.registration_doc, $event)"
                                      ></pagination>
                                  </td>
                                </tr>

                                <!-- no-registration message -->
                                <tr *ngIf=" ! document.registration_doc || ( document.registration_doc && document.registration_doc.revisions && document.registration_doc.revisions.data && document.registration_doc.revisions.data.length == 0 )">
                                  <td colspan="5" class="text-center">
                                    <span class="text-sm text-thin text-muted">- {{ 'no registration file available' | translate }}-</span>
                                  </td>
                                </tr>
                                <!-- end of no-registration message-->
                              </tbody>
                            </table>
                          </form>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                          <!-- create new registration document -->
                          <form #attachmentsForm="ngForm">
                            <dropzone #registrationFormUploader
                              [auto]="false"
                              [dz-config]="dzConfig"
                              (file-added)="onRegistrationRequestFileAdded(registrationFormUploader, $event)"
                              (file-success)="onRegistrationRequestUploadFinished($event)"
                              (file-error)="onRegistrationRequestUploadError(registrationFormUploader, $event)"
                            ></dropzone>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end of section 5.1 -->

                <!-- section 5.2: documents -->
                <div id="document-files" class="col-xs-12">
                  <label class="text-bold pad-rgt pad-btm" data-toggle="collapse">{{ 'Document' | translate }}:</label>

                  <div class="panel panel-bordered panel-default">
                    <div class="pabel-body pad-all">
                      <form #filesForm="ngForm" class="form-horizontal">
                        <table class="table">
                          <thead>
                            <tr>
                              <th class="title">{{ 'Title' | translate }}</th>
                              <th class="desc">{{ 'Description' | translate }}</th>
                              <th class="actions">&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>

                            <!-- add new document -->
                            <tr>
                              <!-- new document title -->
                              <td class="title">
                                <input #fileTitle="ngModel"
                                  id="file-title" name="file-title"
                                  type="text"
                                  class="form-control"
                                  required
                                  placeholder="Document title"
                                  [(ngModel)]="new_file.title"
                                >
                                <!--
                                <validation-errors
                                  [models]="fileTitle"
                                  [messages]="{ required: 'Required' }"
                                ></validation-errors>
                                -->
                              </td>

                              <!-- new document description -->
                              <td class="desc">
                                <input #fileDesc="ngModel"
                                  id="file-desc" name="file-desc"
                                  type="text"
                                  class="form-control"
                                  placeholder="Document description"
                                  [(ngModel)]="new_file.description"
                                >
                                <!--
                                <validation-errors
                                  [models]="fileDesc"
                                  [messages]="{ required: 'Required' }"
                                ></validation-errors>
                                -->
                              </td>

                              <!-- new file buttons -->
                              <td class="actions">
                                <button type="button"
                                  class="btn btn-icon btn-default"
                                  data-dismiss="model"
                                  title="{{ 'Create new document' | translate }}"
                                  (click)="addFile()"
                                >
                                  <i class="pli-add"></i>
                                </button>
                              </td>
                            </tr>
                            <!-- end of add new file -->

                            <!-- existing files -->
                            <ng-container *ngFor="let file of document.content_docs; let i = index">
                              <tr>
                                <td class="title">
                                  <input #fileTitle="ngModel"
                                    id="file-title-{{ i }}" name="file-title-{{ i }}"
                                    type="text"
                                    class="form-control"
                                    required
                                    placeholder="Document title"
                                    [(ngModel)]="file.title"
                                    (change)="updateFile(file)"
                                  >
                                  <validation-errors
                                    [models]="fileTitle"
                                    [messages]="{ required : 'Required' }"
                                  ></validation-errors>
                                </td>
                                <td class="desc">
                                  <input #fileDesc="ngModel"
                                    id="file-desc-{{ i }}" name="file-desc-{{ i }}"
                                    type="text"
                                    class="form-control"
                                    required
                                    placeholder="Document description"
                                    [(ngModel)]="file.description"
                                    (change)="updateFile(file)"
                                  >
                                  <validation-errors
                                    [models]="fileDesc"
                                    [messages]="{ required : 'Required' }"
                                  ></validation-errors>
                                </td>
                                <td class="actions">
                                  <!--
                                  <button class="btn btn-icon btn-default" title="{{ 'Access Control List' | translate }}" (click)="showFileACL(file)">
                                    <i class="pli-security-block"></i>
                                  </button>
                                  -->
                                  <button class="btn btn-icon btn-default" (click)="deleteFile(file)" title="{{ 'Delete' | translate}}">
                                    <i class="pli-trash"></i>
                                  </button>
                                  <collapse-button
                                    [target]="'file-' + file.id"
                                    [collapsed]="true"
                                  ></collapse-button>
                                </td>
                              </tr>

                              <!-- section 5.3.1: revisions -->
                              <tr id="file-{{ file.id }}">
                                <td colspan="3">

                                  <div class="panel panel-bordered panel-info">

                                    <div class="panel-body">
                                      <form #newRevisionForm="ngForm">
                                        <table class="table revisions">
                                          <thead>
                                            <tr>
                                              <th class="revision">{{ 'Rev' | translate }}</th>
                                              <th class="updated">{{ 'Date' | translate }}</th>
                                              <th class="description">{{ 'Description' | translate }}</th>
                                              <th class="status">{{ 'Status' | translate }}</th>
                                              <th class="actions">&nbsp;</th>
                                            </tr>
                                          </thead>
                                          <tbody>

                                            <!-- create new revision -->
                                            <tr>
                                              <td class="revision text-xs text-thin text-muted">{{ 'New' | translate }}</td>
                                              <td class="updated text-xs text-thin text-muted">n/a</td>
                                              <td class="description">
                                                <input #revisionDesc="ngModel"
                                                  id="revision-desc" name="revision-desc"
                                                  type="text"
                                                  class="form-control"
                                                  required
                                                  placeholder="Revision description"
                                                  [(ngModel)]="new_revision.description"
                                                >
                                                <validation-errors
                                                  [models]="revisionDesc"
                                                  [messages]="{ required: 'Revision description is required' }"
                                                ></validation-errors>
                                              </td>
                                              <td class="status text-xs text-thin text-muted">n/a</td>
                                              <td class="actions">
                                                <button type="button"
                                                  class="btn btn-icon btn-default"
                                                  data-dismiss="model"
                                                  title="{{ 'Add new revision' | translate }}"
                                                  (click)="addRevision(file)"
                                                >
                                                  <i class="pli-add"></i>
                                                </button>
                                              </td>
                                            </tr>
                                            <!-- end of create new revision -->

                                            <!-- existing revisions -->
                                            <ng-container *ngFor="let rev of file.revisions?.data; let i = index">
                                            <tr>
                                              <td class="revision">{{ rev.revision }}</td>
                                              <td class="updated">{{ rev.created_at | datetime:'d MMM yy HH:mm:ss' }}</td>
                                              <td class="description">
                                                <input #revisionDesc="ngModel"
                                                  id="revision-desc-{{ i }}" name="revision-desc-{{ i }}"
                                                  type="text"
                                                  class="form-control"
                                                  required
                                                  placeholder="Revision description"
                                                  (change)="updateRevision(file, rev)"
                                                  [(ngModel)]="rev.description"
                                                >
                                                <validation-errors
                                                  [models]="revisionDesc"
                                                  [messages]="{ required: 'Required' }"
                                                ></validation-errors>

                                              </td>
                                              <td class="status" [ngClass]="rev.active ? 'text-bold' : 'text-muted'">{{ rev.active ? 'active' : 'inactive' }}</td>
                                              <td class="actions">
                                                <button *ngIf="!rev.active" (click)="publishRevision(file, rev)" class="btn btn-icon btn-default" title="{{ 'Publish' | translate}}">
                                                  <i class="pli-like"></i>
                                                </button>
                                                <!--
                                                <button *ngIf="!rev.active" (click)="activateRevision(file, rev)" class="btn btn-icon btn-default" title="{{ 'Activate' | translate}}">
                                                  <i class="pli-like"></i>
                                                </button>
                                                <button *ngIf="rev.active" (click)="deactivateRevision(file, rev)" class="btn btn-icon btn-default" title="{{ 'Deactivate' | translate}}">
                                                  <i class="pli-unlike"></i>
                                                </button>
                                                -->
                                                <button class="btn btn-icon btn-default" (click)="deleteRevision(file, rev)" title="{{ 'Delete' | translate}}">
                                                  <i class="pli-trash"></i>
                                                </button>
                                                <collapse-button
                                                  [target]="'rev-' + rev.id"
                                                  [collapsed]="true"
                                                ></collapse-button>
                                              </td>
                                            </tr>

                                            <tr id="rev-{{ rev.id }}">
                                              <td colspan="5">

                                                <!-- section 5.3.1.1: attachments (i.e., including undownloadable soft-copies)  -->
                                                <div class="panel panel-bordered panel-success">

                                                  <div class="panel-body">
                                                    <label class="text-bold">{{ 'Soft-Copies' | translate }}</label>

                                                    <div class="row">
                                                      <div class="col-xs-12 col-sm-6 col-md-9">
                                                        <form #attachmentsForm="ngForm">
                                                          <table class="table soft-copies">
                                                            <thead>
                                                              <tr>
                                                                <th class="file-name">{{ 'File Name' | translate }}</th>
                                                                <th class="file-size">{{ 'File Size' | translate }} </th>
                                                                <th class="download">{{ 'Download' | translate }}</th>
                                                                <th class="status">{{ 'Downlodable' | translate }}</th>
                                                                <th class="actions">&nbsp;</th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>

                                                              <!-- list of existing attachments -->
                                                              <tr *ngFor="let attachment of rev.attachments">
                                                                <td class="file-name">{{ attachment.file_name }}</td>
                                                                <td class="file-size">{{ ( attachment.file_size | memsize:'k' | number:'1.2-2' ) + ' kB' }}</td>
                                                                <td class="download">
                                                                  <button class="btn btn-labeled file-link" stop-propagation (click)="download(attachment)" title="{{ 'Click to download' | translate }}">
                                                                    <i class="fa-fw icon-fw fa-2x icon-label {{ api.can_download(document) ? 'text-success' : 'text-muted' }} {{ api.getFileIcon(attachment.content_type) }}"></i>
                                                                  </button>
                                                                </td>
                                                                <td class="status">
                                                                  <i class="text-bold text-2x fa-solid"
                                                                    [ngClass]="(attachment.meta && attachment.meta['downloadable']) ? 'fa-check text-success' : 'fa-xmark text-danger'"
                                                                  ></i>
                                                                </td>
                                                                <td class="actions">
                                                                  <button *ngIf="! attachment.meta || ! attachment.meta['downloadable']" (click)="markDownloadable(rev, attachment, true)" class="btn btn-icon btn-default" title="{{ 'Mark as downloadable' | translate}}">
                                                                    <i class="pli-like"></i>
                                                                  </button>
                                                                  <button *ngIf="attachment.meta && attachment.meta['downloadable']" (click)="markDownloadable(rev, attachment, false)" class="btn btn-icon btn-default" title="{{ 'Mark as undownlodable' | translate}}">
                                                                    <i class="pli-unlike"></i>
                                                                  </button>
                                                                  <button class="btn btn-icon btn-default" (click)="removeAttachment(rev, attachment)" title="{{ 'Delete' | translate}}">
                                                                    <i class="pli-trash"></i>
                                                                  </button>
                                                                </td>
                                                              </tr>

                                                              <!-- no-soft-copies message -->
                                                              <tr  *ngIf="rev.attachments.length == 0" class="text-thin">
                                                                <td colspan="5" class="text-muted text-center">
                                                                  -{{ 'no soft-copy avaialble.' | translate }}-
                                                                </td>
                                                              </tr>
                                                            </tbody>

                                                          </table>
                                                        </form>
                                                      </div>

                                                      <!-- create new soft-copies -->
                                                      <div class="col-xs-12 col-sm-6 col-md-3">
                                                        <dropzone #attachmentUploader
                                                          [url]="getAttachmentUploadURL(rev)"
                                                          [requestParams]="{ 'master-id': rev.id }"
                                                          [dz-config]="dzConfig"
                                                          [auto]="true"
                                                          (finish)="onAttachmentUploadFinished(rev)"
                                                          (file-error)="onAttachmentUploadError(attachmentUploader, $event)"
                                                        ></dropzone>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <!-- section 5.3.1.2: hardcopies  -->
                                                <div *ngIf="! api.is_confidential(document)" class="panel panel-bordered panel-success">

                                                  <div class="panel-body">
                                                    <label class="text-lg text-bold">{{ 'Hard-Copies' | translate }}</label>

                                                    <form #hardCopiesForm="ngForm">
                                                      <table class="table hard-copies">
                                                        <thead>
                                                          <tr>
                                                            <th class="barcode">{{ 'Bar Code' | translate }}</th>
                                                            <th class="location">{{ 'Location' | translate }}</th>
                                                            <th class="cabinet">{{ 'Cabinet' | translate }}</th>
                                                            <th class="status">{{ 'Status' | translate }}</th>
                                                            <th class="actions">&nbsp;</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>


                                                          <!-- new hard-copies -->
                                                          <tr class="text-thin align-top">
                                                            <!-- bar-code -->
                                                            <td class="barcode align-top">
                                                              <input name="new-barcode"
                                                                type="text"
                                                                class="form-control inline barcode-input"
                                                                placeholder="{{ 'Enter barcode' | translate }}"
                                                                required
                                                                minlength="13"
                                                                maxlength="13"
                                                                [unique]="{ min: 10, url: 'doclib.hardcopy.unique' }"
                                                                [(ngModel)]="new_hardcopy.code"
                                                                #new_barcode="ngModel"
                                                              >
                                                              <validation-errors
                                                                [models]="new_barcode"
                                                                [messages]="{
                                                                  required:  'Barcode is required',
                                                                  minlength: 'Barcode must be 13 characters long',
                                                                  maxlength: 'Barcode must be 13 characters long',
                                                                  unique:    'Barcode must be unique'
                                                                }"
                                                              ></validation-errors>
                                                              <barcode *ngIf="!! new_hardcopy.code"
                                                                [value]="new_hardcopy.code"
                                                                [format]="'CODE128'"
                                                                [width]="1"
                                                                [height]="30"
                                                                >
                                                              </barcode>

                                                            </td>

                                                            <!-- location -->
                                                            <td class="location align-top">
                                                              <select name="new-location"
                                                                class="form-control"
                                                                required
                                                                title="Location"
                                                                [(ngModel)]="new_hardcopy.location.branch"
                                                                #new_location="ngModel"
                                                              >
                                                                <option *ngFor="let location of api.libraries_locations"
                                                                  [attr.value]="location"
                                                                  [attr.selected]="new_hardcopy.location.branch == location ? '' : null"
                                                                >
                                                                  {{ location }}
                                                                </option>
                                                              </select>
                                                              <validation-errors
                                                                [models]="new_location"
                                                                [messages]="{ required: 'Required' }"
                                                              ></validation-errors>
                                                            </td>

                                                            <!-- cabinet -->
                                                            <td class="cabinet align-top">
                                                              <!--
                                                              <input name="new-cabinet"
                                                                type="text"
                                                                required
                                                                class="form-control"
                                                                [(ngModel)]="new_hardcopy.location.cabinet"
                                                                placeholder="{{ 'Cabinet' | translate }}"
                                                                #new_cabinet="ngModel"
                                                              >
                                                              -->
                                                              <select name="new-cabinet"
                                                                class="form-control"
                                                                required
                                                                title="Cabinet"
                                                                [(ngModel)]="new_hardcopy.location.cabinet"
                                                                #new_cabinet="ngModel"
                                                              >
                                                                <option *ngFor="let cabinet of api.names['cabinets'][new_hardcopy.location.branch]"
                                                                  [attr.value]="cabinet"
                                                                  [attr.selected]="new_hardcopy.location.cabinet == cabinet ? '' : null"
                                                                >
                                                                  {{ cabinet }}
                                                                </option>
                                                              </select>

                                                              <input name="new-shelf"
                                                                type="text"
                                                                required
                                                                class="form-control"
                                                                [(ngModel)]="new_hardcopy.location.shelf"
                                                                placeholder="{{ 'Shelf' | translate }}"
                                                                #new_shelf="ngModel"
                                                              >
                                                              <validation-errors
                                                                [models]="[ new_cabinet, new_shelf ]"
                                                                [messages]="{ required: 'Required' }"
                                                              ></validation-errors>
                                                            </td>

                                                            <!-- status -->
                                                            <td class="status align-top">
                                                              <select name="new-status"
                                                                class="form-control"
                                                                required
                                                                [(ngModel)]="new_hardcopy.status"
                                                                #new_status="ngModel"
                                                              >
                                                                <option *ngFor="let status of api.HardcopyStatus"
                                                                  [attr.value]="status"
                                                                  [attr.selected]="new_hardcopy.status == status ? '' : null"
                                                                >
                                                                  {{ status | translate }}
                                                                </option>
                                                              </select>
                                                              <validation-errors
                                                                [models]="new_status"
                                                                [messages]="{ required: 'Required' }"
                                                              ></validation-errors>
                                                            </td>

                                                            <!-- actions -->
                                                            <td class="actions align-top">
                                                              <button type="button"
                                                                class="btn btn-icon btn-default"
                                                                data-dismiss="model"
                                                                title="{{ 'Add new hardcopy' | translate }}"
                                                                (click)="addHardCopy(rev, hardCopiesForm)"
                                                              >
                                                                <i class="pli-add"></i>
                                                              </button>
                                                            </td>

                                                          </tr>

                                                          <!-- existing hard-copies -->
                                                          <tr class="text-thin" *ngFor="let hardcopy of rev.hardcopies?.data; let i = index">

                                                            <!-- bar-code -->
                                                            <td class="barcode align-top">
                                                              <div>
                                                                <input type="checkbox"
                                                                  class="form-check-input me-1"
                                                                  title="{{ 'Export this code' | translate }}"
                                                                  id="exportThisBarcode"
                                                                  #bccb
                                                                  (change)="exportThisCode(hardcopy, bccb.checked)"
                                                                >
                                                                <label class="form-check-label" for="exportThisBarcode">{{ hardcopy.code }}</label>
                                                              </div>
                                                              <barcode
                                                                [value]="hardcopy.code"
                                                                [format]="'CODE128'"
                                                                [width]="1"
                                                                [height]="30"
                                                                >
                                                              </barcode>
                                                            </td>

                                                            <!-- location -->
                                                            <td class="location align-top">
                                                              <select name="location-{{i}}"
                                                                class="form-control"
                                                                required
                                                                title="test test test"
                                                                #location="ngModel"
                                                                [(ngModel)]="hardcopy.location.branch"
                                                                (change)="updateHardcopy(rev, hardcopy)"
                                                              >
                                                                <option *ngFor="let location of api.libraries_locations"
                                                                  [attr.value]="location"
                                                                  [attr.selected]="hardcopy.location.branch == location ? '' : null"
                                                                >
                                                                  {{ location }}
                                                                </option>
                                                              </select>
                                                              <validation-errors
                                                                [models]="location"
                                                                [messages]="{ required: 'Required' }"
                                                              ></validation-errors>
                                                            </td>

                                                            <!-- cabinet -->
                                                            <td class="cabinet align-top">
                                                              <div>
                                                                <!--
                                                                <input name="cabinet-{{i}}"
                                                                  type="text"
                                                                  required
                                                                  class="form-control"
                                                                  placeholder="{{ 'Cabinet' | translate }}"
                                                                  (change)="updateHardcopy(rev, hardcopy)"
                                                                  #cabinet="ngModel"
                                                                  [(ngModel)]="hardcopy.location.cabinet"
                                                                >
                                                                -->
                                                                <select name="cabinet-{{i}}"
                                                                  class="form-control"
                                                                  required
                                                                  title="Cabinet"
                                                                  (change)="updateHardcopy(rev, hardcopy)"
                                                                  #cabinet="ngModel"
                                                                  [(ngModel)]="hardcopy.location.cabinet"
                                                                >
                                                                  <option *ngFor="let cabinet of api.names['cabinets'][hardcopy.location.branch]"
                                                                    [attr.value]="cabinet"
                                                                    [attr.selected]="hardcopy.location.cabinet == cabinet ? '' : null"
                                                                  >
                                                                    {{ cabinet }}
                                                                  </option>
                                                                </select>

                                                                <input name="shelf-{{i}}"
                                                                  type="text"
                                                                  required
                                                                  class="form-control"
                                                                  placeholder="{{ 'Shelf' | translate }}"
                                                                  (change)="updateHardcopy(rev, hardcopy)"
                                                                  #shelf="ngModel"
                                                                  [(ngModel)]="hardcopy.location.shelf"
                                                                >
                                                              </div>
                                                              <validation-errors
                                                                [models]="[ cabinet, shelf ]"
                                                                [messages]="{ required: 'Required' }"
                                                              ></validation-errors>
                                                            </td>

                                                            <!-- status -->
                                                            <td class="status align-top">
                                                              <select name="status-{{i}}"
                                                                class="form-control"
                                                                required
                                                                (change)="updateHardcopy(rev, hardcopy)"
                                                                #status="ngModel"
                                                                [(ngModel)]="hardcopy.status"
                                                              >
                                                                <option *ngFor="let status of api.HardcopyStatus"
                                                                  [attr.value]="status"
                                                                  [attr.selected]="hardcopy.status == status ? '' : null"
                                                                >
                                                                  {{ status | translate }}
                                                                </option>
                                                              </select>
                                                              <validation-errors
                                                                [models]="status"
                                                                [messages]="{ required: 'Required' }"
                                                              ></validation-errors>
                                                            </td>

                                                            <!-- actions -->
                                                            <td class="actions align-top">
                                                              <button class="btn btn-icon btn-default" (click)="removeHardcopy(rev, hardcopy)" title="{{ 'Delete' | translate}}">
                                                                <i class="pli-trash"></i>
                                                              </button>
                                                            </td>
                                                          </tr>

                                                          <!-- hard-copies pagination -->
                                                          <tr *ngIf="!! rev.hardcopies">
                                                            <td colspan="5" class="text-center">
                                                              <pagination
                                                                [pageno]="rev.hardcopies.pageno"
                                                                [perpage]="rev.hardcopies.perpage"
                                                                [total]="rev.hardcopies.total"
                                                                (pagenoChange)="loadPaginatedHardcopies(rev, $event)"
                                                              ></pagination>
                                                            </td>
                                                          </tr>

                                                          <!-- no hard-copies message -->
                                                          <tr  *ngIf=" ! rev.hardcopies?.data || rev.hardcopies.data.length == 0" class="text-thin">
                                                            <td colspan="5" class="text-muted text-center">
                                                              -{{ 'No hard-copy avaialble.' | translate }}-
                                                            </td>
                                                          </tr>

                                                        </tbody>
                                                      </table>

                                                    </form>

                                                    <div class="text-right pad-top">
                                                      <button class="btn btn-labeled btn-default" title="Generate new hard-copies with barcode(s)." (click)="addNewHardCopies(rev)">
                                                        <i class="btn-label fa fa-solid fa-barcode"></i>{{ 'Generate Hard-Copies with barcode' | translate }}
                                                      </button>
                                                      <button class="btn btn-labeled btn-default" title="Export selected barcodes" (click)="exportBarcodes()">
                                                        <i class="btn-label pli-printer"></i>{{ 'Export Selected Codes' | translate }}
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>

                                              </td>
                                            </tr>
                                            </ng-container>
                                            <!-- end of existing revisions -->

                                            <!-- revision pagination -->
                                            <tr *ngIf="!! file.revisions">
                                              <td colspan="5" class="text-center">
                                                <pagination
                                                  [pageno]="file.revisions.pageno"
                                                  [perpage]="file.revisions.perpage"
                                                  [total]="file.revisions.total"
                                                  (pagenoChange)="loadPaginatedRevisions(file, $event)"
                                                  ></pagination>
                                              </td>
                                            </tr>

                                            <!-- no-revisions message -->
                                            <tr *ngIf=" ! file.revisions?.data || file.revisions.data.length == 0">
                                              <td colspan="5" class="text-center">
                                                <span class="text-sm text-thin text-muted">- {{ 'no revision available' | translate }}-</span>
                                              </td>
                                            </tr>
                                            <!-- end of no-revisions message-->

                                          </tbody>
                                        </table>
                                        <validation-errors
                                          [errors]="errors['revisions']"
                                        ></validation-errors>
                                      </form>
                                    </div>

                                  </div>

                                </td>
                              </tr>
                              <!-- end of section 5.3.1: revisions -->
                            </ng-container>
                            <!-- end of existing files -->

                            <!-- no-file message -->
                            <tr *ngIf="document.content_docs.length == 0" >
                              <td colspan="3" class="text-center">
                                <span class="text-sm text-thin text-muted">- {{ 'no file available' | translate }}-</span>
                              </td>
                            </tr>

                          </tbody>
                        </table>
                        <validation-errors
                          [errors]="errors['files']"
                        ></validation-errors>
                      </form>

                    </div>
                  </div>
                  <!-- end of existing files -->

                </div>
                <!-- end of section 5.2 -->

              </div>
            </div>
          </div>
          <!-- end of section 5 -->

          <!-- section 6 - comments -->
          <div class="panel panel-bordered panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Comments' | translate }}
              </h4>
            </div>
            <div class="panel-body">
              <comments
                [content]="document.comments"
                [content_id]="document.id"
                [content_type]="'doc'"
                [attributes]="{ status: document.status }"
                [readonly]="false"
              >
                <!--
                <ng-template let-comment="comment">
                  <span class="status"><i class=""></i>{{ comment.attr && comment.attr['status'] || 'n/a' }}</span>
                </ng-template>
                -->
              </comments>
            </div>
          </div>
          <!-- end of section 6 -->

          <!-- section 7: document action buttons -->
          <div class="pad-top row">
            <div class="col-xs-12 text-right">

              <button type="button"
                class="btn btn-labeled btn-default"
                data-dismiss="modal"
                title="{{ 'Back to previouse page' | translate }}"
                (click)="back()"
              >
                <i class="btn-label fa fa-solid fa-circle-chevron-left"></i>{{ 'Back' | translate }}
              </button>

              <button *ngIf="dirty && ( api.is_confidential(document) || ( document.status != 'draft' && document.status != 'published' ) )"
                type="button"
                class="btn btn-labeled btn-dark"
                data-dismiss="modal"
                title="{{ 'Save this document.' | translate }}"
                (click)="save()"
              >
                <i class="btn-label fa fa-save"></i> {{ 'Save' | translate }}
              </button>

              <button *ngIf="! api.is_confidential(document) && can_edit && ( document.status == 'reserved' || document.status == 'published' )"
                type="button"
                class="btn btn-labeled btn-success"
                data-dismiss="modal"
                title="{{ 'Submit this document to DCC review.' | translate }}"
                [disabled]="has_error(errors)"
                (click)="submit()"
              >
                <i class="btn-label fa fa-arrow-circle-right"></i> {{ 'Submit' | translate }}
              </button>

              <button *ngIf="! api.is_confidential(document) && ( can_manage || can_review ) && ( document.status == 'submitted' || document.status == 'reviewed' )"
                type="button"
                class="btn btn-labeled btn-warning"
                data-dismiss="modal"
                title="{{ 'Reject this document for rework.' | translate }}"
                (click)="reject()"
              >
                <i class="btn-label fa fa-thumbs-down"></i> {{ 'Rework' | translate }}
              </button>

              <button *ngIf="! api.is_confidential(document) && ( can_manage || can_review ) && document.status == 'submitted'"
                type="button"
                class="btn btn-labeled btn-success"
                data-dismiss="modal"
                title="{{ 'Mark this document as [{{status}}].' | translate:{status: 'approved'} }}"
                (click)="review()"
              >
                <i class="btn-label fa fa-thumbs-up"></i> {{ 'Approve' | translate }}
              </button>

              <button *ngIf="can_manage && ( document.status == 'reviewed' || ( api.is_confidential(document) && document.status != 'published' ) )"
                type="button"
                class="btn btn-labeled btn-success"
                data-dismiss="modal"
                title="{{ 'Mark this document as [{{status}}].' | translate: {status: 'published'} }}"
                (click)="publish()"
              >
                <i class="btn-label fa fa-bullhorn"></i> {{ 'Publish' | translate }}
              </button>

              <button *ngIf="is_deletable(document) && can_manage && document.status != 'deleted'"
                type="button"
                class="btn btn-labeled btn-danger"
                data-dismiss="modal"
                title="{{ 'Mark this document as [{{status}}].' | translate: {status: 'deleted'} }}"
                (click)="delete()"
              >
                <i class="btn-label fa fa-trash-alt"></i> {{ 'Delete' | translate }}
              </button>

              <button *ngIf="can_manage && document.status == 'deleted'"
                type="button"
                class="btn btn-labeled btn-info"
                data-dismiss="modal"
                title="{{ 'Restore this document.' | translate }}"
                (click)="restore()"
              >
                <i class="btn-label fa fa-restore-alt"></i> {{ 'Restore' | translate }}
              </button>

              <!--
              <button *ngIf="document.id && can_manage() && document.status == 'deleted'"
                type="button"
                class="btn btn-labeled btn-danger"
                data-dismiss="modal"
                (click)="purge()"
              >
                <i class="btn-label fa fa-bomb"></i> {{ 'Purge' | translate }}
              </button>
              -->

            </div>
          </div>
          <!-- end of section 7 -->

        </form>

      </div>
    </panel>
  </div>
</full-page>
