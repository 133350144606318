import {
  Component,
  Input,
  OnInit,
  OnChanges, SimpleChanges,
  Output, EventEmitter,
  ViewEncapsulation,
  ChangeDetectorRef,
} from "@angular/core";

import { ServerService, SessionService, Taxonomy, User } from "@pinacono/common";
import { Tree, UIService } from "@pinacono/ui";

@Component({
  selector: 'select-users-by-groups',
  templateUrl: 'select-users-by-groups.html',
  styleUrls: [ './select-users-by-groups.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class SelectUsersByGroupsComponent implements OnInit, OnChanges {
  @Input() selected: User[] = [];
  @Input() autoSelectMembers: boolean = true;
  @Output() selectedChange = new EventEmitter<User[]>();

  public groups: Taxonomy[] = [];
  public members: User[] = [];

  // -- lifecycle

  constructor(
    public server: ServerService,
    public session: SessionService,
    public ui: UIService,
    protected cdr: ChangeDetectorRef,
  ) {
  }

  public selectedGroups: Tree.Node[] = [];
  public ngOnInit(): void {
    this.members = [];
    this.selected = [];
    this.selectedChange.emit(this.selected);

    let promise: Promise<Taxonomy[]>;
    if ( this.session.hasPermission(['core_admin', 'manage_all_teams']) ) {
      promise = this.server.show('terms', 1);
      this.loadGroup(1);
    }
    else {
      let domain_id = this.session.currentUser?.primary_domain?.id;
      if ( ! domain_id ) {
        return;
      }
      promise = this.server.show('terms', domain_id);
      this.loadGroup(domain_id);
    }

    promise.then( ( terms: Taxonomy[]) => {
      this.groups = terms;
      if ( this.autoSelectMembers ) {
        this.selectedGroups = terms.map( (t:Taxonomy) => ({
          id: t.id,
          text: t.name,
          original: t
        }));
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ( changes['selected'] ) {
      this.cdr.detectChanges();
    }
  }

  // -- data service
  protected async loadGroup(id: number | number[]) {
    let ids: string;
    if ( Array.isArray(id) ) {
      ids = id.join(',');
    }
    else {
      ids = id.toString();
    }
    this.members = await this.server.get('/groups/{gid}', { gid: ids, direct: 0 });
  }

  // -- template API

  public selectGroup(nodes: Tree.Node[]) {
    this.members = [];
    if ( nodes.length > 0 ) {
      this.loadGroup(nodes.map( n => n.id || 0));
    }
  }

  public getChecked(user: User): 1|null {
    return this.selected.findIndex( u => u.id == user.id ) >= 0 ? 1 : null;
  }

  public toggleSelected(user: User, checkbox: HTMLInputElement) {
    const i = this.selected.indexOf(user);
    const checked = !! checkbox.checked; // new state
    if ( checked && i < 0 ) {
      this.selected.push(user);
    }
    else if ( ! checked && i >= 0 ) {
      this.selected.splice(i, 1);
    }
    this.selectedChange.emit(this.selected);
  }

  public toggleSelectAllUser(el: HTMLInputElement) {
    if ( el.checked ) {
      this.members.forEach( m => this.selected.find( u => u.id == m.id ) || this.selected.push(m) );
    }
    else {
      this.members.forEach( m => {
        const i = this.selected.findIndex( u => u.id == m.id );
        if ( i >= 0 ) {
          this.selected.splice(i, 1);
        }
      });
    }
    this.selectedChange.emit(this.selected);
  }

  public getAllMembersSelected(): 1|null {
    return ( this.members.reduce( (prev, member) => (prev && this.selected.findIndex( u =>  u.id == member.id ) >= 0), true ) ? 1 : null );
  }
}