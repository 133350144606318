<table id="charts-container">
  <thead>
    <tr>
      <th id="job-description" class="text-thin text-muted">
        <job-description *ngIf="!!job && !!job.id" [id]="job.id"></job-description>
        <div *ngIf="! job || ! job.id" class="text-center empty">
          {{ 'No job description for this user' | translate }}
        </div>
      </th>
      <th *ngFor="let candidate of candidates; let i = index"
        class="radar-container"
        [ngClass]="i%2 == 0 ? 'even' : 'odd'"
      >
        <maximizable
          (onMaximizedChange)="updateChart(radarChart)"
        >
          <radar-chart #radarChart
            [data]="chartData[i]"
            [levels]="4" [max]="4"
            [valueFormatter]="renderValue" [tooltipFormatter]="renderTooltip"
            [smooth]="false"
            [colors]="[ '#EDC951', '#00833F', '#00A0B0', '#CC333F' ]"
            [config]="{
              labelFontSize: 10,
              legendFontSize: 10,
              wrapWidth: 90,
              margin: 15
            }"
          >
          </radar-chart>
        </maximizable>

        <user-profile *ngIf="candidate" class="p-2"
          [user]="candidate.staff"
          [editable]="[]"
          [disable]="['company', 'staff-id', 'extension', 'mobile', 'mobile2', 'join-date', 'last-login', 'agreements']"
        >
          <ng-container *ngIf="target == 'edit'">
            <button (click)="addCompetency(candidate.staff.id)" class="btn btn-default btn-icon" title="{{ 'Add Competency' | translate }}">
              <i class="pli-diploma-2"></i>
            </button>
            <button *ngIf="session.hasPermission(['core-admin'])" (click)="changeJob(candidate.staff)" class="btn btn-default btn-icon" title="{{ 'Change Job Description' | translate }}">
              <i class="pli-worker"></i>
            </button>
          </ng-container>
        </user-profile>

      </th>
    </tr>
  </thead>

</table>

<extended-slickgrid *ngIf="gridOptions !== null"
  [gridId]="'competency-profile-table'"
  [columnDefinitions]="columnDefinitions"
  [gridOptions]="gridOptions"
  (onAngularGridCreated)="onGridReady($event)"
  (onSelectedRowsChanged)="onSelectedRowsChanged($event)"
>
</extended-slickgrid>

<modal template-id="targets-editor" size="medium" [fade]="true" [hideCloseButton]="true" #targetsEditor>
  <div modal-title>
    <h5>{{ editingProfile?.competency?.name || editingProfileCompetency?.name ||'Unknown Competency' }} ({{ editingProfile?.competency?.code || editingProfileCompetency?.code || '...' }})</h5>
  </div>

  <div modal-body>

    <div *ngIf="editingProfile && ! editingProfile.competency_id || editingProfileCompetency ">
      <div class="form-group row">
        <div class="col-sm-4">
          <label class="control-label" for="search-form">
            {{ 'Competency' }}:
          </label>
        </div>
        <div class="col-sm-8">
          <input #searchForm type="text" placeholder="{{ 'Enter competency title or code here...' | translate }}..." name="search-form" class="form-control" [(ngModel)]="competency_name">
          <lookup
            [input]="searchForm"
            [minChar]="3"
            [autoClose]="true"
            [items]="competencies_list"
            (onLookup)="lookupCompetency($event)"
            (onSelect)="selectCompetency($event)"
            class="lookup"
          >
          </lookup>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-4">
          <label class="control-label" for="expectation">
            {{ 'Exlected Level' | translate }}:
          </label>
        </div>
        <div class="col-sm-8">
          <input type="number" min="1" max="4" placeholder="{{ 'Enter expected level' | translate }}" name="expectation" class="form-control" [(ngModel)]="editingProfileExpectation">
        </div>
        <!--
        <div *ngIf="editingProfile && editingProfile.competency_id" class="col-sm-1">
          <button title="{{ 'Save this plan' | translate }}" (click)="addProfileEntry()" class="btn btn-default btn-icon">
            <i class="icon-sm pli-disk"></i>
          </button>
        </div>
        -->
      </div>
    </div>

    <div>
      <div *ngIf="editingProfile && editingProfile.competency_id"
        class="row"
      >
        <div class="col-sm-2 form-group">
          <label class="coltrol-label text-bold">{{ 'Target' | translate }}</label>
        </div>
        <div class="col-sm-4 form-group">
          <label class="coltrol-label text-bold">{{ 'Deadline' | translate }}</label>
        </div>
        <div class="col-sm-6">
          <label class="coltrol-label text-bold">{{ 'Status' | translate }}</label>
        </div>
      </div>

      <ng-container *ngIf="editingProfile && editingProfile.competency_id || editingProfileCompetency">
        <div *ngFor="let plan of editingProfile?.plans"
          class="row"
        >
          <div class="col-sm-2 form-group">
            {{ plan.target }}
          </div>
          <div class="col-sm-4 form-group">
            <input *ngIf="target == 'edit'"
              class="form-control"
              type="date"
              name="dead_line"
              min="{{ dateTimeUtils.browser() }}"
              [required]="plan.status != 'completed'"
              [attr.disabled]="( plan.status != 'unplanned' && ! session.hasPermission(['training-admin']) ) ? true : null"
              [(ngModel)]="plan.dead_line"
            >
            <span *ngIf="target != 'edit' && plan.status != 'unplanned'">{{ plan.dead_line | date }}</span>
            <span *ngIf="target != 'edit' && plan.status == 'unplanned'" class="text-italic">- n/a -</span>
          </div>
          <div class="col-sm-6">
            <span class="status status-{{ plan.status }}">{{ plan.status }}</span>
            <button *ngIf="target == 'edit' && ( plan.status == 'unplanned' || session.hasPermission(['training-admin']) )" title="{{ 'Save this plan' | translate }}" (click)="savePlan(plan)" class="btn btn-default btn-icon">
              <i class="icon-sm pli-disk"></i>
            </button>
            <button *ngIf="target == 'edit' && ( plan.status == 'planned' || session.hasPermission(['training-admin']) )" title="{{ 'Cancel and replan' | translate }}" (click)="replan(plan)" class="btn btn-warning btn-icon">
              <i class="icon-sm pli-trash"></i>
            </button>
            <button title="{{ 'View history' | translate }}" (click)="viewHistory(plan)" class="btn btn-info btn-icon">
              <i class="icon-sm pli-eye"></i>
            </button>
          </div>
        </div>
      </ng-container>
    </div>

  </div>

</modal>

<modal template-id="plan-histories" size="large" [fade]="true" #planHistories>
  <div modal-title>
    <h5>{{ 'Plan History' | translate }}</h5>
  </div>

  <div modal-body>

    <table class="table">
      <thead>
        <tr>
          <th>
            <label class="coltrol-label text-bold">{{ 'Target' | translate }}</label>
          </th>
          <th>
            <label class="coltrol-label text-bold">{{ 'Deadline' | translate }}</label>
          </th>
          <th>
            <label class="coltrol-label text-bold">{{ 'Status' | translate }}</label>
          </th>
          <th>
            <label class="coltrol-label text-bold">{{ 'Last Updated' | translate }}</label>
          </th>
          <th>
            <label class="coltrol-label text-bold">{{ 'Note' | translate }}</label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let plan of plans">
          <td>
            {{ plan.target }}
          </td>
          <td>
            {{ plan.dead_line | date }}
          </td>
          <td>
            {{ plan.status }}
          </td>
          <td>
            {{ plan.updated_at | date }}
          </td>
          <td>
            {{ plan.attr && plan.attr['note'] || 'n/a' }}
          </td>
        </tr>
      </tbody>
    </table>

  </div>

</modal>

<modal template-id="change-job"
  size="medium"
  [hideCloseButton]="true"
  [fade]="true"
  #jobChange
>
  <div modal-title>
    <h5>{{ 'Change Job' | translate }}</h5>
  </div>
  <div modal-body>
    <div class="input-group">
      <input #searchForm
        type="text"
        placeholder="{{ 'Search' | translate }}..."
        name="form-name"
        class="form-control"
        [(ngModel)]="job_keyword"
      >
      <span class="input-group-btn">
        <button class="btn btn-primary" type="button"
          (click)="lookupJob(job_keyword)"
        >
          {{ 'Search' | translate }}
        </button>
      </span>
      <lookup class="lookup"
        [input]="searchForm"
        [minChar]="3"
        [items]="jobs_list"
        (onLookup)="lookupJob($event)"
        (onSelect)="selectJob($event)"
      >
      </lookup>
    </div>
  </div>
  <div modal-footer>
    <button type="button" class="btn btn-labeled btn-default"(click)="assignJob()">
      <i class="btn-label pli-add"></i> {{ 'Assign' | translate }}
    </button>
    <button type="button" class="btn btn-labeled btn-default"(click)="jobChange.hide()">
      <i class="btn-label pli-add"></i> {{ 'Cancel' | translate }}
    </button>
  </div>
</modal>