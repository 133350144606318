import {
  Component,
  Input,
  OnInit,
  OnChanges, SimpleChanges,
  Output, EventEmitter,
  ViewEncapsulation,
  ChangeDetectorRef,
} from "@angular/core";

import { ServerService, SessionService, Taxonomy, User } from "@pinacono/common";
import { Tree, UIService } from "@pinacono/ui";

@Component({
  selector: 'select-users',
  templateUrl: 'select-users.html',
  styleUrls: [ './select-users.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class SelectUsersComponent implements OnInit, OnChanges {
  @Input() users: User[] = [];
  @Input() selected: User[] = [];
  @Output() selectedChange = new EventEmitter<User[]>();

  // -- lifecycle

  constructor(
    public server: ServerService,
    public session: SessionService,
    public ui: UIService,
    protected cdr: ChangeDetectorRef,
  ) {
  }

  public selectedGroups: Tree.Node[] = [];
  public ngOnInit(): void {
    this.selected = [];
    this.selectedChange.emit(this.selected);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ( changes['selected'] ) {
      this.cdr.detectChanges();
    }
  }

  // -- template API

  public getChecked(user: User): 1|null {
    return this.selected.findIndex( u => u.id == user.id ) >= 0 ? 1 : null;
  }

  public toggleSelected(user: User, checkbox: HTMLInputElement) {
    const i = this.selected.indexOf(user);
    const checked = !! checkbox.checked; // new state
    if ( checked && i < 0 ) {
      this.selected.push(user);
    }
    else if ( ! checked && i >= 0 ) {
      this.selected.splice(i, 1);
    }
    this.selectedChange.emit(this.selected);
  }

  public toggleSelectAllUser(el: HTMLInputElement) {
    if ( el.checked ) {
      this.users.forEach( m => this.selected.find( u => u.id == m.id ) || this.selected.push(m) );
    }
    else {
      this.users.forEach( m => {
        const i = this.selected.findIndex( u => u.id == m.id );
        if ( i >= 0 ) {
          this.selected.splice(i, 1);
        }
      });
    }
    this.selectedChange.emit(this.selected);
  }

  public getAllSelected(): 1|null {
    return ( this.users.reduce( (prev, member) => (prev && this.selected.findIndex( u =>  u.id == member.id ) >= 0), true ) ? 1 : null );
  }
}