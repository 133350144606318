import { Component, Input } from "@angular/core";
import { BookInstance } from "../types";

@Component({
  selector: 'assets',
  templateUrl: 'assets.html',
  styleUrls: ['assets.scss']
})
export class AssetsComponent {
  @Input() title: string = 'Assets';
  @Input() description: string|null = null;
  @Input() items?: BookInstance[] = [];
}