import {
  Component,
  Input, Output, EventEmitter,
  OnInit,
  OnChanges, SimpleChanges, ViewChild
} from '@angular/core';

import {
  SlickGrid
} from '@slickgrid-universal/common';

import {
  AngularGridInstance,
  GridOption,
  Column,
  FieldType,
  OnEventArgs,
  Filters
} from 'angular-slickgrid';

import {
  DateTimeUtilsService,
  ObjectUtilsService,
  object_t,
  PaginatedResults,
  ServerService,
  SessionService,
  User
} from '@pinacono/common';

import {
  RadarChartComponent,
  RadarChartData
} from '@pinacono/d3';

import {
  SummaryColumn,
  LocalServerService,
  ProxyBackendResult,
  ProxyBackendService
} from '@pinacono/slickgrid-extension';

import {
  LookupEvent,
  LookupItem,
  ModalComponent,
  UIService
} from '@pinacono/ui';

import { TrainingService } from '../../training.service';
import {
  Candidate, GapsAnalyzerData,
  Competency, CompetencyProfile,
  JobDescription,
  TrainingPlan,
  JobCompetency
} from '../../types';


const RADAR_CHART_WIDTH = 400;

@Component({
  selector: 'gaps-analyzer',
  templateUrl: 'gaps-analyzer.html',
  styleUrls: [ 'gaps-analyzer.scss' ]
})
export class GapsAnalyzerComponent implements OnInit, OnChanges {

  //@Input('candidates') candidates: CompetencyProfile[][] = []; // candidates' profiles - each candidate has more than one profile
  @Input('candidates') candidates: Candidate[] = []; // candidates' profiles - each candidate has more than one profile
  @Input('job') job!: JobDescription;
  @Input('target') target: 'show' | 'hide' | 'edit' = 'hide';
  @Input('max-competencies') maxCompetencies?: number = 5; // -1 = unlimited

  @Output('onPlanChange') onPlanChange = new EventEmitter<CompetencyProfile>();
  @Output('onJobChange')  onJobChange  = new EventEmitter<JobDescription>();
  @Output('onCompetencyClick') onCompetencyClick = new EventEmitter<number>();

  @ViewChild('radarChart') radarChart!: RadarChartComponent;
  @ViewChild('targetsEditor') targetsEditorModal!: ModalComponent;
  @ViewChild('planHistories') planHistoriesModal!: ModalComponent;
  @ViewChild('jobChange') jobChangeModal!: ModalComponent;

  // chart
  public chartData: RadarChartData[][][] = [];
  public selectedCompetencies: Competency[] = [];

  // grid
  protected gridInstance: AngularGridInstance|null = null;
  public gridOptions: GridOption|null = null;
  public columnDefinitions: Column[] = [];
  public tableData: GapsAnalyzerData[] = []
  public summaryColumns: SummaryColumn[] = [];

  // -- initialization
  constructor(
    public session: SessionService,
    public dateTimeUtils: DateTimeUtilsService,
    protected ui: UIService,
    protected api: TrainingService,
    protected server: ServerService,
    protected dataServer: LocalServerService,
    protected objectUtils: ObjectUtilsService
  ) {
  }

  // -- lifecycle
  public ngOnInit() {
    this.initGrid();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ( changes['candidates'] || changes['job'] ) {
      // generate data for table and reset competencies selection
      let data: GapsAnalyzerData[] = [];

      //if ( this.job && this.candidates && this.candidates.length > 0 ) {
      if ( this.candidates && this.candidates.length > 0 ) {

        // at this row, assume, we have valid job description, at least one candidate with
        // at least on competency profile

        let next_id: number = 1;

        // iterate profiles of each candidates to
        // populate the list of competency
        for ( let c = 0; c < this.candidates.length; c++ ) {
          const candidate = this.candidates[c];

          // merge all competency and make it unique
          const profiles: CompetencyProfile[] = Array.from(candidate.profiles);

          /*
          // by sorting based on last update and pick only one.
          const profiles: CompetencyProfile[] = Array.from(candidate.profiles).sort((a, b) => {
            // First, compare the 'competency_id'
            if (a.competency_id < b.competency_id) {
              return -1;
            }
            if (a.competency_id > b.competency_id) {
              return 1;
            }

            // Convert the 'updated_at' strings to Date objects and get their timestamps
            const dateA = a.updated_at && new Date(a.updated_at).getTime() || new Date('1970-01-01').getTime();
            const dateB = b.updated_at && new Date(b.updated_at).getTime() || new Date('1970-01-01').getTime();

            // Sort in descending order
            return dateB - dateA;
          });;
          */

          // populate profiles those are in JD but currently no in candidate's profile
          this.job.competencies.forEach( c => {
            const profile = candidate.profiles.find( p => p.competency_id == c.competency.id );
            if ( ! profile ) {
              profiles.push(this.api.createCompetencyProfile({
                user_id: candidate.staff.id,
                user: candidate.staff,
                competency_id: c.competency.id!,
                competency: c.competency,
                expectation: c.expectation,
              }));
            }
            else {
              profile.expectation = c.expectation; // force using Job Description's expectation, if competency is in JD
            }
          });

          profiles.filter( (p, i, ps) => ps.findIndex( _p => _p.competency_id == p.competency_id ) === i )
          .forEach( profile => {
            (profile.competency as any).by_job = ( this.job.competencies.findIndex( c => c.competency.id == profile.competency_id ) >= 0 ) ? 'Y' : 'N';

            // check, if the competency is already in list
            let row: GapsAnalyzerData|undefined = data.find( d => d.competency.id == profile.competency.id );

            // add to chart data list, if not already in list
            if ( ! row ) {
              const cat =
              row = {
                id: next_id++,
                competency: profile.competency,
                //category: profile.competency.categories[0]?.name || 'Uncategorized',
                category: profile.competency.categories,
                profiles: []
              }
              data.push(row);
            }

            // scan all candidates for this competency
            for ( let i = 0; i < this.candidates.length; i++ ) {

              /* why these line in this code?
              if ( i == c ) {
                row.profiles.push(profile);
              };
              */

              let p = this.candidates[i].profiles.find( p => p.competency_id == profile.competency.id );
              if ( ! p ) {
                p = this.api.createCompetencyProfile({
                  user_id: this.candidates[i].staff.id,
                  user: this.candidates[i].staff,
                  competency: profile.competency,
                  actual: 0,
                  expectation: 0
                });
              }
              row.profiles.push(p);
            }
          });
        }
      }

      //console.debug('gaps-analyzer', data);
      this.dataServer.setData(data);

      // trig grid updating
      if ( !! this.gridInstance ) {
        this.gridInstance.extensionService.refreshBackendDataset();
      }
    }
  }

  // -- utilities

  // -- grid interfaces
  public expectaions: number[] = [];
  public actuals: number[]     = [];
  public tgaps: number[]       = [];
  public egaps: number[]       = [];
  public targets: number[]     = [];

  protected initGrid() {
    const datasetName = 'gaps';

    // define summary row
    this.summaryColumns = [
      {
        id: 'padding',
        colspan: 3,
        cssClass: 'text-right text-bold',
        formatter: (): string => {
          return 'Total:';
        }
      }
    ];

    for ( let i = 0; i < this.candidates.length;  i++ ) {
      this.summaryColumns = this.summaryColumns.concat(this.getProfileSummaryColumns(i));
    }

    // calculate summary
    this.dataServer.onFiltered.subscribe( (filtered: object[]) => {
      //console.log('filtered data = ', filtered);
      for ( let i = 0; i < this.candidates.length;  i++ ) {
        this.expectaions[i] = (filtered as GapsAnalyzerData[] ).map( d => d.profiles[i].expectation ).reduce( (a, b) => a+b, 0 );
        this.actuals[i]     = (filtered as GapsAnalyzerData[] ).map( d => d.profiles[i].actual ).reduce( (a, b) => a+b, 0 );
        this.tgaps[i]       = (filtered as GapsAnalyzerData[] ).map( d => d.profiles[i].tgap ).reduce( (a, b) => (a||0)+(b||0), 0 ) || 0;
        this.egaps[i]       = (filtered as GapsAnalyzerData[] ).map( d => d.profiles[i].egap ).reduce( (a, b) => (a||0)+(b||0), 0 ) || 0;
        this.targets[i]     = (filtered as GapsAnalyzerData[] ).map( d => d.profiles[i].target ).reduce( (a, b) => a+b, 0 );
      }
    });

    // grid option
    this.gridOptions = {
      backendServiceApi: {
        service: new ProxyBackendService(),
        options: {
          datasetName: datasetName,
          columnDefinitions: this.columnDefinitions
        },
        //preProcess: ():void => {},
        process: this.dataServer.process.bind(this.dataServer),
        postProcess: (response: ProxyBackendResult) => {
          this.selectedCompetencies = [];
          this.tableData = response.data[datasetName].nodes as GapsAnalyzerData[];

          let count = Math.min(this.maxCompetencies||0, this.tableData.length);
          if ( count > 0 ) {
            let selectedRows = Array.from(Array(count).keys());
            setTimeout(() => {
              this.gridInstance && this.gridInstance.slickGrid.setSelectedRows(selectedRows);
            });
          }
        }
      },

      dataItemColumnValueExtractor: (item: any, columnDef: Column): any => {
        // to extract data from array using index key as
        // property name, e.g., profiles.0.id, etc.
        return this.objectUtils.get(item, columnDef.field);
      },
      // sizing
      rowHeight: 60,
      autoHeight: true,
      forceFitColumns: true,
      enableAutoResize: true,
      autoResize: {
        calculateAvailableSizeBy: 'container',
        bottomPadding: 85,
        minHeight: 40 * 20 // row height x no of rows
      },
      // rows selection
      enableRowSelection: false,
      enableCheckboxSelector: true,
      checkboxSelector: {
        columnId: '_checkbox_selector',
        hideSelectAllCheckbox: true
      },
      // pagination
      enablePagination: true,
      pagination: {
        pageSizes: [20, 40, 60, 100],
        pageSize: 20,
        totalItems: 0
      },
      // sorting
      enableSorting: true,
      // presets
      presets: {
        /*
        columns: [
          // column preset state = see gridState.interface.ts
        ],
        filters: [
          // you can also type operator as string, e.g.: operator: 'EQ'
          //{ columnId: 'gender', searchTerms: ['male'], operator: OperatorType.equal },
        ],
        sorters: [
          // direction can be written as 'asc' (uppercase or lowercase) and/or use the SortDirection type
          //{ columnId: 'modelname', direction: 'asc' }
        ],
        */
        pagination: {
          pageNumber: 1, pageSize: 60
        }
      },
      enableFiltering: true,
      enableAsyncPostRender: true
    };

    this.columnDefinitions = [
      {
        id: 'competency_code', name: 'Competency Code',
        field: 'competency.code',
        //fields: [ 'competency.code', 'competency.name' ],
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 150, maxWidth: 150,
        sortable: true,
        filterable: true,
        filter: {
          operator: 'StartsWith'
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          return dataContext.competency.code;
        },
        onCellClick: (event: Event, args: OnEventArgs) => {
          this.onCompetencyClick.emit(args.dataContext.competency.id);
        }
      },

      {
        id: 'competency_category', name: 'Category',
        field: 'category',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 100, maxWidth: 100,
        sortable: true,
        filterable: true,
        filterSearchType: FieldType.string,
        filter: {
          model: Filters.multipleSelect,
          collection: [
            { value: 'Elective',   label: 'Elective'   },
            { value: 'Functional', label: 'Functional' },
            { value: 'Common',     label: 'Common'     },
            { value: 'Soft Skill', label: 'Soft Skill' }
          ]
        },
        /*
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          let competency = dataContext.competency as Competency;
          return competency.categories.map( c => c.name ).join(', ');
        },
        */
        onCellClick: (event: Event, args: OnEventArgs) => {
          this.onCompetencyClick.emit(args.dataContext.competency.id);
        }
      },

      {
        id: 'competency_by_job', name: 'JD',
        field: 'competency.by_job',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 60, maxWidth: 60,
        sortable: true,
        filterable: true,
        filterSearchType: FieldType.string,
        filter: {
          model: Filters.multipleSelect,
          collection: [
            { value: 'Y', label: 'In Job Description'    },
            { value: 'N', label: 'Additional Competency' }
          ]
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          if ( dataContext.competency.by_job == 'Y' ) {
            //return '<i class='text-success fa fas fa-check'></i>';
            return 'IN';
          }
          //return '<i class='text-danger fa fas fa-times'></i>';
          return 'ADD';
        }
      },

      {
        id: 'competency_name', name: 'Competency Name',
        field: 'competency.name',
        fields: [ 'competency.code', 'competency.name' ],
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 70,
        sortable: true,
        filterable: true,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string => {
          return dataContext.competency.name;
        },
        onCellClick: (event: Event, args: OnEventArgs) => {
          this.onCompetencyClick.emit(args.dataContext.competency.id);
        }
      }
    ];

    for ( let i = 0; i < this.candidates.length;  i++ ) {
      this.columnDefinitions = this.columnDefinitions.concat(this.getProfileColumns(i));
    }

    // initialize local data server
    this.dataServer.init({
      datasetName: datasetName,
      columnDefinitions: this.columnDefinitions
    });
  }

  protected getProfileSummaryColumns(index: number): SummaryColumn[] {
    const css = ( index % 2 == 0 ) ? 'even' : 'odd';
    return [
      {
        id: 'expectation-' + index,
        cssClass: 'text-right text-bold ' + css,
        formatter: (column: SummaryColumn): string => {
          return this.expectaions[index].toString();
        }
      },

      {
        id: 'actual-' + index,
        cssClass: 'text-right text-bold ' + css,
        formatter: (column: SummaryColumn): string => {
          return this.actuals[index].toString();
        }
      },

      {
        id: 'target-' + index,
        cssClass: 'text-right text-bold ' + css,
        formatter: (column: SummaryColumn): string => {
          return this.targets[index].toString();
        }
      },

      {
        id: 'egap-' + index,
        cssClass: 'text-right text-bold ' + css,
        formatter: (column: SummaryColumn): string => {
          return this.egaps[index].toString();
        }
      },

      {
        id: 'gap',
        cssClass: 'text-right text-bold ' + css,
        formatter: (column: SummaryColumn): string => {
          return this.tgaps[index].toString();
        }
      }
    ];
  }

  protected getProfileColumns(index: number): Column[] {
    const w = RADAR_CHART_WIDTH / 5; // competency levels' column width = radar chart width / 5 columns
    const css = ( index % 2 == 0 ) ? 'even' : 'odd';
    return [
      {
        id: 'expectation-' + index, name: 'Expect',
        field: `profiles.${index}.expectation`,
        type: FieldType.number,
        cssClass: 'text-right ' + css, minWidth: w, maxWidth: w,
        headerCssClass: 'text-rotate',
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.compoundInputNumber,
          minValue: 0,
          maxValue: 4
        }
      },

      {
        id: 'actual-' + index, name: 'Actual',
        field: `profiles.${index}.actual`,
        type: FieldType.number,
        cssClass: 'text-right ' + css, minWidth: w, maxWidth: w,
        headerCssClass: 'text-rotate',
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.compoundInputNumber,
          minValue: 0,
          maxValue: 4
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: GapsAnalyzerData, grid: SlickGrid): string => {
          let actual = dataContext.profiles[index].actual;
          // @TODO - enable checking below
          return actual.toString() + ( this.target == 'edit' && actual > 0 ? ` <i class='clickable text-sm psi-chevron-down' title='Adjust actual level'></i>` : '');
          //return actual.toString() + ( this.target == 'edit' ? ` <i class='clickable text-sm psi-chevron-down' title='Adjust actual level'></i>` : '');
        },
        onCellClick: async (event: Event, args: OnEventArgs) => {
          const profile = args.dataContext.profiles[index];
          if ( profile.id <= 0 ) return;

          let reason: string = await this.ui.prompt('You are about to adjust the level of this competency down for 1 level. Please specify the reason', null, undefined, null, 'textarea');
          if ( ! reason ) return;

          const adjusted = Math.max(profile.actual - 1, 0);
          const p = await this.server.request('training.profile.adjust', { profile_id: profile.id }, {
            new_level: adjusted,
            reason: reason
          });

          profile.actual = p.level;
          this.gridInstance?.slickGrid.invalidate();
          this.gridInstance?.slickGrid.render();
        }
      },

      {
        id: 'target-' + index, name: 'Target',
        field: `profiles.${index}.target`,
        type: FieldType.number,
        cssClass: 'text-right ' + css, minWidth: w, maxWidth: w,
        headerCssClass: 'text-rotate',
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.compoundInputNumber,
          minValue: 0,
          maxValue: 4
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: GapsAnalyzerData, grid: SlickGrid): string => {
          const plan: TrainingPlan | undefined = (dataContext.profiles[index].plans || [])
                                                  .filter( p => p.status !== 'unplanned' )
                                                  //.sort( (a, b) => Date.parse(b.dead_line) - Date.parse(a.dead_line) ) // descending by deadline
                                                  .sort( (a, b) => b.target - a.target ) // descending by target level
                                                  .shift();
          const target = ( plan && plan.target ) || 0;
          return target.toFixed(0) + ' ' + (
            ( this.target == 'edit' )  ? `<i class='clickable text-sm psi-pencil' title='Edit Plans'></i>` : `<i class='clickable text-sm psi-eye' title='View Plans'></i>`
          );
        },
        onCellClick: (event: Event, args: OnEventArgs) => {
          let profile = args.dataContext.profiles[index];
          this.editTargets(profile);
        }
      },

      {
        id: 'egap-' + index, name: 'E-Gap',
        field: `profiles.${index}.egap`,
        type: FieldType.number,
        cssClass: 'text-right ' + css, minWidth: w, maxWidth: w,
        headerCssClass: 'text-rotate',
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.compoundInputNumber,
          minValue: 0,
          maxValue: 4
        }
      },

      {
        id: 'tgap-' + index, name: 'T-Gap',
        field: `profiles.${index}.tgap`,
        type: FieldType.number,
        cssClass: 'text-right ' + css, minWidth: w, maxWidth: w,
        headerCssClass: 'text-rotate',
        sortable: true,
        filterable: true,
        filter: {
          model: Filters.compoundInputNumber,
          minValue: 0,
          maxValue: 4
        }
      }
    ];
  }

  // -- grid interfaces
  public onGridReady(event: Event) {
    this.gridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  private deselecting: boolean = false;
  public onSelectedRowsChanged(event: Event) {
    if ( this.deselecting ) {
      return;
    }
    let args = (event as CustomEvent).detail.args as OnEventArgs;
    this.selectedCompetencies = [];
    if ( this.selectedCompetencies.length >= ( this.maxCompetencies || 0 ) ) {
      this.ui.alert(`Only ${this.maxCompetencies} gaps can be selected at the same time.`, { max: this.maxCompetencies });
      this.deselecting = true;
      // @TODO - chcek what to be used rather than 'previousSelectedRows'
      this.gridInstance && this.gridInstance.slickGrid.setSelectedRows((<any>args).previousSelectedRows);
      this.deselecting = false;
    }
    else {
      (<any>args).rows.map( (i: number) => {
        this.selectedCompetencies.push(this.tableData[i].competency);
      });
    }
    this.drawChart();
  }

  /*
  public onCellChanged(event: CustomEvent) {
    this.updateFooter(event.detail.args.cell);
  }

  public onColumnsReordered(event: CustomEvent) {
    this.updateFooter(event.detail.args.cell);
  }

  protected footerResults: object = {};
  protected updateFooter(cell: number) {
    let columns = this.gridInstance && this.gridInstance.slickGrid.getColumns() || [];
    let column  = columns[cell];

    if ( column.params.footer )  {
      column.params.footer.init();
      for ( let i = 0; i < this.tableData.length; i++ ) {
        column.params.footer.accumulate(this.tableData[i]);
      }
      column.params.footer.storeResult(this.footerResults);
      if ( this.gridInstance ) {
        let el = this.gridInstance.slickGrid.getFooterRowColumn(column.id);
        $(el).html('Sum:  ' + this.footerResults['sum'][column.id]);
      }
    }
  }

  protected updateAllFooter() {
    let idx = this.gridInstance && this.gridInstance.slickGrid.getColumns().length || 0;
    while (idx--) {
      this.updateFooter(idx);
    }
  }
  */

  // -- template API

  // -- chart interfaces

  protected drawChart() {
    // prepare data
    console.log('Reset chart data');
    this.chartData = [];
    let actual: RadarChartData[][]   = [];
    let expected: RadarChartData[][] = [];
    let target: RadarChartData[][]   = [];

    for ( let competency of this.selectedCompetencies ) {
      let row = this.tableData.findIndex( (r: GapsAnalyzerData) => r.competency.name == competency.name );
      if ( row < 0 ) {
        console.error(`Cannot find table entry for ${competency.code}`);
        continue; // skip
      }

      for ( let col = 0; col < this.tableData[row].profiles.length; col++ ) {
          if ( ! actual[col] ) {
          actual[col] = [];
        }
        if ( ! expected[col] ) {
          expected[col] = [];
        }
        if ( ! target[col] ) {
          target[col] = [];
        }

        let profile = this.tableData[row].profiles[col];
        actual[col].push({
          axis: competency.name,
          value: profile.actual,
          tooltip: competency.name + ' - actual',
          legend: 'Actual',
          closure: profile
        });
        expected[col].push({
          axis: competency.name,
          value: profile.expectation,
          tooltip: competency.name + ' - expect',
          legend: 'Expectation',
          closure: profile
        });
        target[col].push({
          axis: competency.name,
          value: profile.target,
          tooltip: competency.name + ' - target',
          legend: 'Target',
          closure: profile
        });
      }
    }

    for ( let i = 0; i < expected.length; i++ ) {
      this.chartData.push([expected[i], target[i], actual[i]]);
    }
    console.log('chartData length', this.chartData.length);
  }

  public renderTooltip(data: RadarChartData): string {
    //return `${data.tooltip} (${data.value})`;
    return data.value.toString();
  }

  public renderValue(value: number): string {
    return value.toString();
  }

  public updateChart(radarChart: RadarChartComponent) {
    radarChart.drawChart();
  }

  public getDeadline(profile: CompetencyProfile): string {
    return (profile && profile.plans && profile.plans.length > 0 && profile.plans[0].dead_line) || 'n/a';
  }

  public addCompetency(uid: number) {
    this.editTargets(this.api.createCompetencyProfile({
      user_id: uid
    }));
  }

  public editingProfile: CompetencyProfile|null = null;
  public editTargets(profile: CompetencyProfile) {
    this.editingProfile = profile;
    this.targetsEditorModal.show();
  }

  // competency lookup
  public competencies_list: LookupItem<Competency>[] = [];
  public competency_name: string = '';
  public editingProfileCompetency: Competency|null = null;
  public editingProfileExpectation: number = 1;
  public lookupCompetency(keyword: string) {
    this.server.lookup('training/competencies', {
      keyword: keyword,
      exclude_user_competency: this.candidates[0].staff.id
    })
    .then( (res: object_t[]) => {
      this.competencies_list = res.map( o => {
        return {
          label: `${o['code']}: ${o['name']}`,
          value: this.api.createCompetency(o)
        }
      } );
    });
  }

  public selectCompetency(item: LookupEvent<Competency>) {
    //this.competency_name = item.value.value.name;
    this.editingProfileCompetency = item.value.value;
    this.editingProfile = this.api.createCompetencyProfile({
      user_id: this.editingProfile?.user_id || 0,
      competency_id: this.editingProfileCompetency && this.editingProfileCompetency.id || undefined
    });
  }

  public async savePlan(plan: TrainingPlan) {
    if ( ! this.editingProfile ) {
      return;
    }

    // validate
    let p = ( this.editingProfile.plans || []).findIndex( p => p.target == plan.target );
    if ( p < 0 ) {
      console.error(`Cannot find plan for level ${plan.target}`);
      return;
    }

    if ( p > 0 && Date.parse(this.editingProfile.plans![p-1].dead_line) >= Date.parse(plan.dead_line) ) {
      this.ui.alert('Higher target must be finished after the lower target');
      return;
    }

    // update
    const confirm = await this.ui.confirm('Set deadline for target level {{ level }} to {{ deadline }}?', {
      level: plan.target,
      deadline: plan.dead_line
    })

    if ( confirm ) {
      let res: object_t;

      plan.status = 'planned';
      if ( plan.id ) {
        // plan existing, then just update plan
        res = await this.server.silent().update('training/plans', plan.id, plan);
      }
      else if ( this.editingProfile!.id ) {
        // plan not existing, but profile existing then create new plan
        res = await this.server.silent().create('training/plans', plan)
      }
      else {
        // both profile and plan are not existing, create profile first
        // then the plan.
        await this.server.silent().request('training.profile.add', {
          uid: plan.user_id,
          competency_id: this.editingProfile!.competency_id,
          expectation: this.editingProfileExpectation
        });
        res = await this.server.silent().create('training/plans', plan);
      }

      if ( ! this.editingProfile ) {
        return;
      }

      plan = this.api.createTrainingPlan(res)
      this.editingProfile.plans![p] = plan;
      this.editingProfile.plans = this.editingProfile.plans!.slice();
      this.onPlanChange.emit(this.editingProfile);

      this.editingProfileCompetency = null;
      this.editingProfileExpectation = 1; // should be JD's ecpectation
      this.gridInstance!.extensionService.refreshBackendDataset();
    }
    this.targetsEditorModal.hide();
  }

  public replan(plan: TrainingPlan) {
    this.ui.prompt('Replan Note', undefined, (reason: string) => {
      if ( ! reason || reason.trim().length == 0 ) {
        this.ui.alert('Plese specify reason to replan');
        return;
      }

      plan.attr = Object.assign({}, plan.attr, {
        note: reason
      });

      if ( plan.status != 'missed' ) {
        plan.status = 'cancelled';
      }

      this.server.silent().update('training/plans', plan.id, plan)
      .then( (res: object) => {
        if ( ! this.editingProfile ) {
          return;
        }
        let i = this.editingProfile.plans!.findIndex( p => p.id == plan.id );
        this.editingProfile.plans![i] = this.api.createTrainingPlan(res)
        this.editingProfile.plans = this.editingProfile.plans!.slice();
        this.onPlanChange.emit(this.editingProfile);

        this.editingProfileCompetency = null;
        this.editingProfileExpectation = 1; // should be JD's expectation
      });
    }, null, 'textarea');
    this.targetsEditorModal.hide();
  }

  public plans: TrainingPlan[] = [];
  public viewHistory(plan: TrainingPlan) {
    this.plans = [];

    this.server.index('/training/plans', { skill_id: plan.competency_id, target: plan.target, user_id: plan.user_id })
    .then( (plans: PaginatedResults<TrainingPlan>) => {
      this.plans = plans.data.map( p => this.api.createTrainingPlan(p) );
      this.planHistoriesModal.show();
    });
  }

  /**
   * change job
   */
  public jobs_list: LookupItem<JobDescription>[] = [];
  public job_keyword: string|null = null;
  protected targetJobChangeUser: User|null = null ;
  protected selectedJob: JobDescription|null = null;

  public changeJob(user: User) {
    this.targetJobChangeUser = user;
    this.selectedJob = null;
    this.jobChangeModal.show();
  }

  public lookupJob(keyword: string|null) {
    if ( ! keyword ) {
      return;
    }
    this.server.lookup('training/jobs', { name: keyword }, 5)
    .then( (res: JobDescription[]) => {
      this.jobs_list = res.map( (j: JobDescription) => {
        return {
          label: `${j.title} (${j.code})`,
          value: j
        };
      })
    });
  }

  public selectJob(event: LookupEvent<JobDescription>) {
    this.selectedJob = event.value.value;
  }

  public assignJob() {
    if ( ! this.selectedJob || ! this.targetJobChangeUser ) {
      return;
    }
    this.ui.confirm('Assign Title {{ title }} to {{ fullname }}', {
      title: this.selectedJob.title,
      fullname: this.targetJobChangeUser.fullname
    })
    .then( (ok: boolean) => {
      if ( ok ) {
        if ( ! this.selectedJob || ! this.targetJobChangeUser ) {
          return;
        }
        //console.log(`assign job id ${this.selectedJob.id} to user id ${this.targetJobChangeUser.id}`);
        this.jobChangeModal.hide();
        this.server.get('training/job/{jid}/assign/{uid}', {
          jid: this.selectedJob.id,
          uid: this.targetJobChangeUser.id
        })
        .then( (job: object) => {
          this.onJobChange.emit(this.api.createJobDescription(job));
        });
      }
    });
  }
}
