import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
/* remove @fullcalendar
import { CalendarOptions, DateSelectArg, FullCalendarComponent } from '@fullcalendar/angular';
*/

import { OPL } from 'src/app/modules/opl/types';

import {
  MorrisChart,
  UIService
} from '@pinacono/ui';

import { AppUser } from 'src/app/types';

import {
  config,
  FileUtilsService,
  NavigationService,
  PaginatedResults,
  ServerService,
  SessionService
} from '@pinacono/common';
import { DomSanitizer } from '@angular/platform-browser';
/**
 * The Home Page Component
 */
@Component({
  selector: 'app-home-page',
  templateUrl: 'home.component.html'
})
export class HomeComponent implements OnInit, AfterViewInit {

  public dashboard_url: string|null  = null;

  //@ViewChild('campaignFrame') campaignFrame!: ElementRef<HTMLIFrameElement>;
  @ViewChild('dashboardFrame') dashboardFrame!: ElementRef<HTMLIFrameElement>;

  constructor(
    public nav: NavigationService,
    public fileUtils: FileUtilsService,
    protected activatedRoute: ActivatedRoute,
    protected session: SessionService,
    protected ui: UIService,
    protected server: ServerService,
    protected http: HttpClient,
    protected sanitizer: DomSanitizer,
  ) {
  }

  /**
   * load page's data - see nifty-page.component.ts
   */
  public async ngOnInit() {
    const url = config('client.dashboard.home');
    this.dashboard_url = this.sanitizer.bypassSecurityTrustResourceUrl(url) as string;
  }

  public iframe_height: number = 100;
  protected resizeObserver: ResizeObserver|null = null;
  public async ngAfterViewInit() {
    const aspect_ratio = 16/9;
    this.resizeObserver = new ResizeObserver( (entries: ResizeObserverEntry[]) => {
      entries.forEach( (entry:ResizeObserverEntry) => {
        this.iframe_height = Math.floor(entry.contentRect.width / aspect_ratio);
      });
    });
    //this.campaignFrame && this.resizeObserver.observe(this.campaignFrame.nativeElement);
    this.dashboardFrame && this.resizeObserver.observe(this.dashboardFrame.nativeElement);
  }
}
