import {
  Component,
  Input,
  Output, EventEmitter,
  ViewChild,
  OnInit,
  OnChanges, SimpleChanges
} from "@angular/core";

import {
  LayoutOptions
} from 'elkjs';

import {
  config,
  object_t,
  Attachment,
  NavigationService,
  ServerService,
  SessionService,
  PaginatedResults,
  MIMEUtilsService
} from '@pinacono/common';

import {
  GraphNode,
  ILink
} from '@pinacono/diagram';

import {
  UIService,
  ModalComponent
} from '@pinacono/ui';

import {
  CourseActionEvent,
  CourseObjective,
  ExamAnswerSheet,
  TrainingCourse,
  TrainingBatch,
  TrainingBatchStatus,
  TrainingCourseRequest,
  TrainingRecordStatus,
  TrainingCourseRequestStatus,
  ExamTimerMode
} from '../../types';

import { TrainingService } from '../../training.service';

import { ExamComponent } from '../exam/exam.component';

@Component({
  selector: 'course',
  templateUrl: 'course.html',
  styleUrls: [ 'course.scss' ]
})
export class CourseComponent implements OnChanges, OnInit {
  @Input() id!: string;
  @Input() show_dependencies: boolean = false;

  @Output() onAfterAction = new EventEmitter<CourseActionEvent>();

  @ViewChild('examDialog') examDialog!: ModalComponent;
  @ViewChild('exam') examComponent!: ExamComponent;

  public examTimerMode = ExamTimerMode;

  public course: TrainingCourse|null = null;
  public examCourse: TrainingCourse|null = null;
  public objNeedsExam: CourseObjective|null = null;

  public related_opls: { id: string, title: string }[] = [];

  public nodes: GraphNode<TrainingCourse>[] = [];
  public links: ILink[] = [];

  public rootLayoutOptions: LayoutOptions = {
    'elk.spacing.edgeNode': '25',
    'elk.layered.spacing.edgeNodeBetweenLayers': '25',
    'elk.layered.spacing.edgeEdgeBetweenLayers': '25',
    'elk.layered.spacing.nodeNodeBetweenLayers': '150',
    'elk.spacing.nodeNode': '25'
  };

  public BatchStatus   = TrainingBatchStatus;
  public RecordStatus  = TrainingRecordStatus;
  public RequestStatus = TrainingCourseRequestStatus;

  public is_planned: boolean = false;
  public is_requested: boolean = false;
  public is_passed: boolean = false;
  public is_examable: boolean = false;

  public levels: number[] = [];
  public next_exam: number = 0;
  protected exam_silent_period: number = 0; // in seconds
  public report_exam_result: boolean = true;
  public hide_course_in_silent_period: boolean = false;

  public my_request: TrainingCourseRequest|null = null;
  public my_batch: TrainingBatch|null = null;
  public opened_batches: TrainingBatch[] = [];

  public media: Attachment[] = [];

  // -- lifecycle

  constructor(
    public session: SessionService,
    protected nav: NavigationService,
    protected server: ServerService,
    protected ui: UIService,
    protected mimeUtils: MIMEUtilsService,
    protected api: TrainingService,
  ) {}

  public ngOnInit() {
    this.exam_silent_period = this.session.hasPermission(['core_admin']) ? 0 : config('client.exam.exam_silent_period', 24 * 60 * 60) * 1000;
    this.report_exam_result = config('client.exam.report_exam_result', true);
    this.hide_course_in_silent_period = config('client.exam.hide_course_in_silent_period', false)
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ( changes['id'] && this.id ) {
      this.load();
    }
  }

  // -- component API
  public load() {
    let planned: number[] = [];
    this.media = [];

    this.server.request('training.course.planned', {uid: this.session.currentUser!.id}, { select: 'id'}, {perpage: -1})
    .then( (res: PaginatedResults<object_t>) => {
      planned = res.data.map( (d: object_t) => d['id'] as number);
      return this.server.show('training/courses', parseInt(this.id), {
        with: [
            'levels', 'levels.jobs', 'levels.competency',
            'objectives',
            'prerequisites.postrequisites',
            'postrequisites.prerequisites',
            'requests',
            'batches', 'batches.trainers'
          ].join(','),
        appends: [
            'related_opls', 'active_batches', 'active_batches', 'my_batch', 'my_request'
          ].join(',')
      });
    })
    .then( (c: object_t) => {
      this.course = this.api.createCourse(c);
      this.levels = this.course.levels!.map( l => l.level );
      this.is_planned  = (planned.findIndex( id => this.course!.id == id ) >= 0);

      this.media = this.course.attachments && this.course.attachments.filter( (f) =>
        this.mimeUtils.mime_isAudio(f.content_type) ||
        this.mimeUtils.mime_isVideo(f.content_type) ||
        this.mimeUtils.mime_isImage(f.content_type)
      ) || [];

      if ( this.course.related_opls && this.course.related_opls.length > 0 ) {
        this.related_opls = [];
        this.server.index('contents', {ids: this.course.related_opl_ids!.join(',')}, { perpage: -1 }, { select: 'id,title'})
        .then( (res: PaginatedResults<object_t>) => {
          for ( let d of res.data ) {
            this.related_opls.push({
              id: d['id'],
              title: d['title']
            });
          }
        });
      }

      this.my_batch = this.course.my_batch || null;

      this.next_exam = 0;
      if  ( this.my_batch && this.my_batch.my_record && this.my_batch.my_record.examinations && this.my_batch.my_record.examinations.length > 0 ) {
        let sorted = this.my_batch.my_record.examinations
                      .map( (e: ExamAnswerSheet) => Date.parse(e.created_at||'') )
                      .sort( (a, b) => b - a);
        this.next_exam = Math.max( 0, this.exam_silent_period - ( Date.now() - sorted[0] ) );
      }

      this.is_requested = !! ( this.course.my_request );
      this.is_passed    = !! ( this.my_batch && this.my_batch.my_record && this.my_batch.my_record.status == TrainingRecordStatus.PASSED );
      this.is_examable  = this.course.min_score > 0;

      this.opened_batches = this.course.active_batches!.filter( (b: TrainingBatch) => ( b.status == TrainingBatchStatus.OPEN ) || ( this.my_batch && this.my_batch.id == b.id ) );
      this.objNeedsExam = this.course.objectives.find( o => o.min_score > 0 ) || null;

      if ( ! this.show_dependencies ) {
        return;
      }

      this.nodes = [];
      this.links = [];

      this.nodes.push({
        id: this.course.id!.toString(),
        data: this.course
      });

      let next_link_id = 1;
      for ( let course of ( this.course.prerequisites || [] ) ) {
        this.nodes.push({
          id: course.id!.toString(),
          data: course
        });

        this.links.push({
          id: `link-${next_link_id++}`,
          source: course.id!.toString(),
          target: this.course.id!.toString()
        });
      }

      for ( let course of ( this.course.postrequisites || []) ) {
        this.nodes.push({
          id: course.id!.toString(),
          data: course
        });

        this.links.push({
          id: `link-${next_link_id++}`,
          source: this.course.id!.toString(),
          target: course.id!.toString()
        });
      }
    });
  }

  // -- template API
  public isMyBatch(batch: TrainingBatch): boolean {
    return batch.id == ( this.my_batch && this.my_batch.id );
  }

  /*
  public isMyRequest(batch: TrainingBatch): boolean {
    return this.my_request && this.my_request.batch_id == batch.id
  }
  */

  /**
   * check, if course associated with competency with the level specified
   */
  public hasLevel(level: number): boolean {
    return this.levels.length == 0 || this.levels.findIndex( l => l == level ) >= 0 ;
  }

  public edit() {
    if ( this.course ) {
      this.nav.push(['/training/courses/edit', this.course.id]);
    }
  }

  protected updateCourseSchedules(data: object_t) {
    if ( ! this.course ) return;
    this.course.requests = data['requests'].map( (r: object) => this.api.createCourseRequest(r) );
    this.course.batches  = data['batches'].map(  (b: object) => this.api.createTrainingBatch(b) );
    this.course.my_request = this.course.requests?.filter( r => r.user_id == this.session.currentUser!.id ).shift();
    this.course.my_batch   = this.course.batches?.filter( r => r.attendees.findIndex( a => a.id == this.session.currentUser!.id ) ).shift();
  }

  public async request()  {
    if ( ! this.course ) return;
    if ( ! await this.ui.confirm("Request to open a batch of '{{ name }}' ?", { name: this.course.name }) )  return;
    const data: object_t = await this.server.request('training.request', { course_id: this.course!.id });
    this.ui.alert("Your request has been submitted.");
    this.updateCourseSchedules(data);
    this.onAfterAction.emit({
      action: 'request',
      params: {
        course: this.course!
      }
    });
  }

  public async unrequest()  {
    if ( ! this.course ) return;
    if ( ! await this.ui.confirm("Cancel Previouse Request of '{{ name }}' ?", { name: this.course.name }) ) return;
    let request = this.course!.requests!.find( (r: TrainingCourseRequest) => r.user_id == this.session.currentUser!.id );
    if ( ! request ) return;
    const data: object_t = await this.server.request('training.revoke', { request_id: request.id });
    this.ui.alert("Your request has been cancelled.");
    this.updateCourseSchedules(data);
    this.onAfterAction.emit({
      action: 'unrequest',
      params: {
        course: this.course!
      }
    });
  }

  public async apply(batch: TrainingBatch)  {
    if ( ! this.course ) return;
    if ( ! await this.ui.confirm("Apply for '{{ name }}' ?", { name: this.course.name }) ) return;
    const data: object_t = await this.server.request('training.batch.apply', { batch_id: batch.id });
    this.updateCourseSchedules(data);
    this.ui.alert("Your request has been sent to the batch's mentor.");
    this.onAfterAction.emit({
      action: 'apply',
      params: {
        course: this.course!,
        batch: batch
      }
    });
  }

  /*
  public unapply(batch: TrainingBatch)  {
    let self = this;
    this.nifty.confirm("Remove request for '{{ name }}' ?", {
        name: this.course
      },
      () => {
        self.server.request('training.batch.unapply', { batch_id: batch.id })
        .then( (data: object) => {
          self.course.requests = data['reqyests'] && data['requests'].map( (r: object) => self.api.createCourseRequest(r) );
          self.course.batches  = data['batches']  && data['batches'].map(  (b: object) => self.api.createTrainingBatch(b) );
          self.nifty.alert("Your request has been cancelled.");
          self.onAfterAction.emit({
            action: 'unapply',
            params: {
              course: self.course,
              batch: batch
            }
          });
        });
      }
    );
  }
  */

  public exam()  {
    this.examCourse = this.course;

    setTimeout( () => {
      this.examComponent.resume();
      this.examDialog.show();
    });
  }

  protected new_score: number = -1;
  public onExamSaved(score: number) {
    this.new_score = score;
    this.dismissExam();
  }

  public dismissExam() {
    setTimeout( () => {
      //this.examCourse = null;
      this.examComponent.pause();
      this.examDialog.hide();
    });

    if ( this.course && this.new_score >= 0 ) {
      this.onAfterAction.emit({
        action: 'exam',
        params: {
          course: this.course,
          score: this.new_score
        }
      });
      this.new_score = -1;
    }
  }

  public selectCourse(id?: number) {
    if ( ! id ) return;
    this.nav.push([ '/training/courses/view', id]);
  }

  public onCourseAction(event: CourseActionEvent) {
    console.warn('@TODO - handle course action');
  }

  public selectCompetency(id?: number) {
    if ( ! id ) return;
    this.nav.push([ '/training/competencies/view', id ]);
  }

  public checkRecord(batch: TrainingBatch) {
    this.server.request('training.batch.record', {
      batch_id: batch.id,
      user_id: this.session.currentUser!.id
    })
    .then( (id: number) => {
      if ( id == 0 ) {
        this.ui.alert('Cannot find training record for batch {{ name }}', { name: batch.name });
      }
      else {
        this.nav.push([ '/training/records/view', id ]);
      }
    });
  }
}
