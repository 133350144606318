import {
  Component,
  OnDestroy,
  //OnInit,
  ViewChild
} from "@angular/core";

import {
  ActivatedRoute,
  Router
} from "@angular/router";

import * as moment from 'moment';

import {
  ApolloQueryResult
} from "@apollo/client/core"; // must include 'core', otherwise got ts type error on react subfolder

import {
  Apollo,
  gql
} from "apollo-angular";

import {
  NavigationService,
  object_t,
  ServerError,
  ServerService,
  SessionService,
  UtilsService
} from '@pinacono/common';

import {
  BootboxInputOption,
  ModalComponent,
  UIService
} from '@pinacono/ui';

import {
  SlickGrid
} from '@slickgrid-universal/common';

import {
  ExcelExportService
} from '@slickgrid-universal/excel-export';

import {
  GraphqlFilteringOption,
  GraphqlPaginatedResult
} from '@slickgrid-universal/graphql';

import {
  AngularGridInstance,
  AngularUtilService,
  Column,
  FieldType,
  Filters,
  GridOption
} from 'angular-slickgrid';

import {
  ComponentFormatter,
  DatetimeMomentFormatter,
  GraphQLServerService,
  GridUserComponent,
  LighthouseService
} from '@pinacono/slickgrid-extension';

import { BasePageComponent } from "src/app/classes/base-page.component";

import { HardcopyStatus } from 'src/app/modules/documents/types';

import {
  Book,
  BookInstance,
  BookReservation,
  InstanceStatusMapping,
  ReservationStatus
} from '../types';

import { LibraryService } from '../library.service';
import { Subscription } from "rxjs";
//import { GridBookComponent } from '../components/grid-book.component';

@Component({
  selector: 'library-books-browse',
  templateUrl: 'browse.html',
  styleUrls: [ 'browse.scss' ],
  providers: [ AngularUtilService ]
})
export class LibraryBooksBrowsePage extends BasePageComponent implements /* OnInit, */ OnDestroy {

  @ViewChild('modalBookInstances') modalBookInstances!: ModalComponent;
  @ViewChild('modalBookReservationHistory') modalBookReservationHistory!: ModalComponent;

  public data: Book[] = [];

  //public myReservations: BookReservation[] = [];
  public remainingQuota: number = 5;

  public InstanceStatusMapping = InstanceStatusMapping;

  // data grid
  protected readonly dataSetName: string = 'book';

  protected filters: GraphqlFilteringOption[] = [
    //{ field: 'menu', operator: 'EQ', value: 'published' }
  ];

  protected trashed: 'WITH'|'WITHOUT'|'ONLY' = 'WITHOUT';

  // grid interface
  protected gridComponent: AngularGridInstance|null = null;
  protected selectedRow: number = 0;
  public gridOptions: GridOption|null = null;
  public columnDefinitions: Column[] = [];

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    protected ngUtilService: AngularUtilService,
    protected nav: NavigationService,
    protected ui: UIService,
    protected utils: UtilsService,
    protected api: LibraryService,
    protected server: ServerService,
    protected graphqlServer: GraphQLServerService,
    protected session: SessionService,
    protected apollo: Apollo
  ) {
    super(router, activatedRoute);
  }

  /*
  public override async ngOnInit(): Promise<void> {
    this.initGrid();
    super.ngOnInit();
  }
  */

  public keyword: string|null = null;
  //protected silent: boolean = true;
  protected gqlSubscription: Subscription|null = null;
  public pagination = {
    pageno: 1,
    perpage: 20,
    total: 0
  };
  protected override async loadData(): Promise<void> {
    const data = `
      data {
        id, title,
        covers {
          id, thumb_url, path_url, caption,content_type, code
        },
        instances {
          status
        },
        content
        ,reservations {
          id,
          user {
            id, fullname, email
          },
          status, request_date, require_before
        },
        attr, code,doc_code
      }
    `;

    const pagination = `
      paginatorInfo {
        count, currentPage, firstItem, hasMorePages, lastItem, lastPage, perPage, total
      }
    `;

    if ( this.session.currentUser ) {
      const res = await this.server.request('library.reservation.user.active', { uid: this.session.currentUser.id });
      /*
      this.myReservations = res.map( (r: object_t) => this.api.createBookReservation(r) );
      this.remainingQuota = this.api.rules.quota - this.myReservations.filter( r => ['requesting', 'approved', 'delivered'].includes(r.status) ).length; // @TODO - replace 5 with config
      */
      this.remainingQuota = this.api.rules.quota - Array.from(res).length;
      //console.log('remainingQuota =', this.remainingQuota);
    }

    /* disabled by new browse mode
    if ( !! this.gridComponent ) {
      this.silent = false;
      this.gridComponent.extensionService.refreshBackendDataset();
    }
    */

    // new browse mode
    this.ui.loading.next(true);
    this.gqlSubscription = this.apollo
    .watchQuery({
      // alternative query: \"\\"${this.keyword}\\" AND ( attributes.code.f.keyword: \\"BOOK\\" )\"
      query: this.keyword ?
        gql`
          query {
            search_books (
              first: ${this.pagination['perpage']},
              page: ${this.pagination['pageno']},
              bookFirst: true
              query: \"( status: published) AND ( attributes.lendables: >0 ) AND ${this.keyword}\",
            ) {
              ${data},
              ${pagination}
            }
          }
        ` :
        gql`
          query {
            book (
              first: ${this.pagination['perpage']},
              page: ${this.pagination['pageno']},
              trashed: ${this.trashed},
              bookFirst: true
            ) {
              ${data},
              ${pagination}
            }
          }
        `,
    })
    .valueChanges.subscribe( ( { data, loading } ) => {
      this.ui.loading.next(loading);
      if ( (data as any).book ) {
        this.data = ( data as any ).book.data.map( (d: object_t) => {
          const b = this.api.createBook(d);
          b.covers = [ b.covers[0] ]; // reduce the covers to only first image
          return b;
        });
        this.pagination.total = ( data as any ).book.paginatorInfo.total;
      }
      else if ( (data as any).search_books) {
        this.data = ( data as any ).search_books.data.map( (d: object_t) => this.api.createBook(d) );
        this.pagination.total = ( data as any ).search_books.paginatorInfo.total;
      }
      else {
        this.ui.alert('No data returned from server!', undefined, 'Error!');
      }
    });
  }

  public ngOnDestroy(): void {
    this.gqlSubscription?.unsubscribe();
  }

  // -- grid interfaces

  public loadPage(pageno: number) {
    this.pagination.pageno = pageno;
    this.loadData();
  }

  public selectBook(book: Book) {
    this.nav.push(['library/book', book.id ]);
  }

  /* disabled by new browse mode
  protected createDataModel(data: object_t): Book {
    return this.api.createBook(data);
  }

  protected processGraphQLQuery(query: string): Promise<GraphqlPaginatedResult> {
    return new Promise( (resolve) => {
      const server = this.silent ? this.graphqlServer.silent() : this.graphqlServer;
      this.silent = true;

      server.sendQuery({query: query})
      .then(
        (res: object_t) => {
          // parse response
          let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
          this.data = re.data[this.dataSetName].nodes.map( d => this.createDataModel(d) );
          resolve(re);
        },
        (error: any) => {
          this.ui.alert(error.message, undefined, 'Error!');
          console.error('GrqphQL error', error);
        }
      );
    });
  }

  public onGridReady(event: Event) {
    //grid: AngularGridInstance
    this.gridComponent = (event as CustomEvent).detail as AngularGridInstance;
  }

  public onSelectRow(event: Event) {
    if ( (event as CustomEvent).detail.args['cell'] == 1 ) return; // ignore, if click on cover column

    this.selectedRow = (event as CustomEvent).detail.args['row'];
    const data = this.data[this.selectedRow];
    // @todo temp integration - to be commented out
    this.nav.push(['library/book', data.id ]);
  }


  protected initGrid() {
    this.columnDefinitions = [
      {
        id: 'id', name: 'ID',
        field: 'id',
        fields: [],
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 70, maxWidth: 70,
        sortable: true,
        filterable: true,
      },
      {
        id: 'title', name: 'Title',
        field: 'title',
        fields: [
          'title',
          'covers', 'covers.id', 'covers.thumb_url', 'covers.path_url', 'covers.caption', 'covers.content_type', 'covers.code',
          'instances', 'instances.status', // 'instances.id', 'instances.code', 'instances.location',
          'content',
          'reservations', 'reservations.id', 'reservations.user', 'reservations.user.id', 'reservations.user.fullname', 'reservations.user.email',  'reservations.status', 'reservations.request_date', 'reservations.require_before',
          'attr'
        ],
        cssClass: 'text-left', minWidth: 400 ,
        formatter: ComponentFormatter,
        type: FieldType.object,
        sortable: true,
        filterable: true,
        params: {
          //component: GridBookComponent,
          factory: () => this.ngUtilService.createAngularComponent(GridBookComponent),
          config: {
            browser: this,
            enableReserve: () => this.remainingQuota > 0
          }
        }
      },
      {
        id: 'pubdate', name: 'Publish Date',
        field: 'attr',
        fields: [ 'attr' ],
        cssClass: 'text-left', width: 40 ,
        formatter: (row: number, cell: number, value: object_t, columnDef: Column, dataContext: Book, grid: SlickGrid): string  => {
          return ( value['BOOK'] && value['BOOK']['publish_date'] ) || 'n/a'
        },
        type: FieldType.object,
        sortable: false,
        filterable: false,
      },
      {
        id: 'code', name: 'Book Code',
        field: 'code',
        fields: [ 'doc_code' ],
        type: FieldType.string,
        cssClass: 'doc-code text-left', minWidth: 180, maxWidth: 180,
        formatter: (row: number, cell: number, value: object_t, columnDef: Column, dataContext: Book, grid: SlickGrid): string  => {
          return dataContext.doc_code || 'n/a';
        },
        sortable: true,
        filterable: true,
      }
    ];

    this.gridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.columnDefinitions,
          datasetName: this.dataSetName,
          persistenceFilteringOptions: this.filters,
          paginationOptions: {
            first: 20
          },
          extraQueryArguments: [
            { field: 'trashed', value: this.trashed }
          ]
        },

        //preProcess: ():void => {},
        process: this.processGraphQLQuery.bind(this),
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      enableExcelExport: true,
      registerExternalResources: [
        new ExcelExportService()
      ],
      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'Books'
      },

      enableSorting: true,

      rowHeight: 400,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#main-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 10,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,

      presets: {
        // @TODO - initial grid preset option here
      }
    };
  }
  */

  // -- Template API

  public search(keyword: string|null) {
    if ( keyword === null ) return;
    if ( keyword != this.keyword ) {
      // reset pagination if keyword changed
      this.pagination = {
        pageno: 1,
        perpage: 20,
        total: 0
      };
    }
    this.keyword = keyword.length > 0 ? keyword : null;
    this.loadData();
  }

  public async reserve(book: Book) {
    const require_before: string = await this.ui.prompt(
      'When will you need this book?',
      null, undefined, null, // binding, callback, config
      'date', {
        min: this.utils.dateTime_utils.browser(moment().add(this.api.rules.require_before, 'days')),
        max: this.utils.dateTime_utils.browser(moment().add(this.api.rules.require_before + 7, 'days'))
      },
      this.utils.dateTime_utils.browser(moment().add(this.api.rules.require_before, 'days'))
    );
    if ( require_before === null ) {
      return;
    }

    //const reservations = await this.server.request('library/reservation/user')

    try {
      await this.server.rejectOnError(true).create('library/reservation/book', {
        book_id: book.id,
        user_id: this.session.currentUser!.id,
        /*
        request_date: moment().toISOString(),
        require_before: moment(require_before).toISOString()
        */
        request_date: this.utils.dateTime_utils.browser(),
        require_before: this.utils.dateTime_utils.browser(require_before)
      });
      //console.log('book reserved!');
      this.refresh();
    }
    catch (e: unknown) {
      if ( (e as any).code == 406 ) {
        this.ui.alert((e as ServerError).error.message, undefined, 'Error!')
      }
      console.error(e);
    }
  }

  public async cancel(book: Book) {
    const r = book.reservations && book.reservations.filter( r => r.id && r.user.id == this.session.currentUser!.id && ( r.status == ReservationStatus.requesting || r.status == ReservationStatus.approved ) ) || [];
    if ( r.length == 0 ) {
      return;
    }

    const options: BootboxInputOption[] = [];
    r.forEach((r: BookReservation, i: number) => {
      const req  = this.utils.dateTime_utils.browser(r.request_date);
      const need = this.utils.dateTime_utils.browser(r.require_before);
      options.push({
        text:  `Req: ${req}, Need: ${need}`,
        value: i.toFixed(0)
      });
    });

    const indexes = await this.ui.prompt('Please select the reservation to cancel.', null, undefined, null, 'checkbox', { inputOptions: options }, '0' )
    if ( !! indexes ) {
      indexes.forEach( async (index: string) => {
        const i = parseInt(index);
        if ( i !== undefined ) {
          await this.server.update('library/reservation/book', r[i].id!, { status: 'cancelled' });
        }
      });
      //console.log('book reservation cancelled!');
      this.refresh();
    }
  }

  public instances: BookInstance[] = [];
  public async locations(book: Book) {
    const res: object_t = await this.server.show('library/book', book.id, { appends: 'instances' });
    this.instances = (this.api.createBook(res).instances || []).filter( (instance: BookInstance) => {
      if ( this.session.hasPermission(['library_manage']) ) {
        return true;
      }
      return [
        HardcopyStatus.available,
        HardcopyStatus.checked_out,
        HardcopyStatus.reserved,
        HardcopyStatus.library_use_only,
        HardcopyStatus.other_location
      ].indexOf(instance.status) >= 0;
    })
    .sort( ( a: BookInstance, b: BookInstance) => {
      if ( a.location.branch < b.location.branch ) return -1;
      if ( a.location.branch > b.location.branch ) return 1;

      if ( a.location.cabinet < b.location.cabinet ) return -1;
      if ( a.location.cabinet > b.location.cabinet ) return 1;

      if ( a.location.shelf < b.location.shelf ) return -1;
      if ( a.location.shelf > b.location.shelf ) return 1;

      return 0;
    });

    this.modalBookInstances.show();
  }

  // -- Book Reservation History
  public history(book: Book) {
    this.reservations = [];
    this.modalBookReservationHistory.show();
    setTimeout( () => this.initReservationGrid(book) );
    /*
    if ( ! this.gridComponent ) {
      setTimeout( () => this.initReservationGrid(book) );
    }
    else {
      this.gridComponent.extensionService.refreshBackendDataset();
    }
    */
  }

  public closeHistory() {
    this.modalBookReservationHistory.hide();
    this.reservationGridOptions = null;
  }

  // reservation grid interface
  public reservations: BookReservation[] = [];
  protected reservationsFilters: GraphqlFilteringOption[] = [
    //{ field: 'menu', operator: 'EQ', value: 'published' }
  ];
  protected reservationGridComponent: AngularGridInstance|null = null;
  public reservationGridOptions: GridOption|null = null;
  public reservationGridColumnDefinitions: Column[] = [];

  protected processReservationGraphQLQuery(query: string): Promise<GraphqlPaginatedResult> {
    return new Promise( (resolve) => {
      this.graphqlServer
      .silent()
      .sendQuery({query: query})
      .then(
        (res: object_t) => {
          // parse response
          let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
          this.reservations = re.data['book_reservations'].nodes.map( r => this.api.createBookReservation(r) );
          resolve(re);
        },
        (error: any) => {
          this.ui.alert(error.message, undefined, 'Error!');
          console.error('GrqphQL error', error);
        }
      );
    });
  }

  public onReservationGridReady(event: Event) {
    //grid: AngularGridInstance
    this.reservationGridComponent = (event as CustomEvent).detail as AngularGridInstance;
  }

  protected initReservationGrid(book: Book) {
    this.reservationGridColumnDefinitions = [
      {
        id: 'staff', name: 'Requester',
        field: 'user',
        fields: [ 'user.id', 'user.avatar', 'user.avatar.thumb_url', 'user.email', 'user.fullname', 'user.profiles.staff_id' ],
        type: FieldType.object,
        cssClass: 'text-left',
        width: 120,
        filterable: true,
        formatter: ComponentFormatter,
        params: {
          factory: () => this.ngUtilService.createAngularComponent(GridUserComponent),
          config: {
            attribute: 'user'
          }
        }
      },


      {
        id: 'bar_code',  name: 'Code',
        field: 'instance.code',
        fields: [ 'instance.location', 'approved_by.id', 'approved_by.email', 'approved_by.fullname', 'approved_by.profiles.staff_id', 'approve_date' ],
        type: FieldType.object,
        cssClass: 'text-center',
        sortable: true,
        filterable: true,
        width: 30,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: BookReservation, grid: any): string => {
          return value || 'n/a';
        }
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'text-center',
        width: 40,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: BookReservation, grid: any): string => {
          const labels: object_t = {
            requesting: { label: 'Requesting', css: 'warning' },
            approved:   { label: 'Approved',   css: 'success' },
            rejected:   { label: 'Rejected',   css: 'danger' },
            delivered:  { label: 'Collected',  css: 'info' },
            returned:   { label: 'Returned',   css: 'info' },
            lost:       { label: 'Lost',       css: 'danger' },
            cancelled:  { label: 'Cancelled',  css: 'warning' }
          }
          const status = dataContext.status.toLowerCase();
          return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>`
        },
        //sortable: true,
        filterable: true,
        filter: {
          model: Filters.singleSelect,
          collection: [
            { value: 'requesting', label: 'Requesting' },
            { value: 'approved',   label: 'Approved'   },
            { value: 'rejected',   label: 'Rejected'   },
            { value: 'delivered',  label: 'Collected'  },
            { value: 'returned',   label: 'Returned'   },
            { value: 'lost',       label: 'Lost'       },
            { value: 'cancelled',  label: 'Cancelled'  }
          ]
        }
      },

      {
        id: 'request_date', name: 'Request Date',
        field: 'request_date',
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'require_before', name: 'Require Before',
        field: 'require_before',
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'deliver_date', name: 'Collecting Date',
        field: 'deliver_date',
        fields: [ 'delivered_by.id', 'delivered_by.email', 'delivered_by.fullname', 'delivered_by.profiles.staff_id' ],
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      },

      {
        id: 'return_date', name: 'Return Date',
        field: 'return_date',
        fields: [ 'return_date', 'returned_to.id', 'returned_to.email', 'returned_to.fullname', 'returned_to.profiles.staff_id' ],
        type: FieldType.dateUtc,
        cssClass: 'text-center',
        formatter: DatetimeMomentFormatter,
        params: 'D MMM YYYY',
        sortable: true,
        filterable: true,
        width: 50,
        filter: {
          model: Filters.compoundDate
        }
      }
    ];

    this.reservationGridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.reservationGridColumnDefinitions,
          datasetName: 'book_reservations',
          persistenceFilteringOptions: this.reservationsFilters,
          paginationOptions: {
            first: 20
          },
          extraQueryArguments: [
            { field: 'book_id', value: book.id },
            { field: 'trashed', value: 'WITHOUT' }
          ]
        },

        //preProcess: ():void => {},
        process: this.processReservationGraphQLQuery.bind(this),
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      enableExcelExport: true,
      registerExternalResources: [
        new ExcelExportService()
      ],
      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'Reservation'
      },

      enableSorting: true,

      rowHeight: 60,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#history-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'container',
        bottomPadding: 10,
        minHeight: 500,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,

      presets: {
        /** @TODO - initial grid preset option here */
      }
    };
  }

}