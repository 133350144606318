import {
  Component,
  ViewChild
} from '@angular/core';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  NgForm,
  ValidationErrors
} from '@angular/forms';

import {
  Attachment,
  DateTimeUtilsService,
  FileUtilsService,
  NavigationService,
  object_t,
  ServerService,
  SessionService,
  UserService,
  TaxonomyService,
  TreeUtilsService,
  MIMEUtilsService,
} from '@pinacono/common';

import {
  InterpolatbleErrorMessage,
  UIService,
} from '@pinacono/ui';

import { BasePageComponent } from 'src/app/classes/base-page.component';

import { DocLibService } from 'src/app/modules/documents/doclib.service';

import { ProjectLibService } from '../projects.service';
import {
  InternalProjectRole,
  Project,
  ProjectDocument,
  ProjectDocumentRevision
} from '../types';
import { AppUser } from 'src/app/types';

//import { TEST_DATA } from 'src/moc-data/projects';

@Component({
  selector: 'page-projlib-doc-edit',
  templateUrl: 'document.html',
  styleUrls: [ 'document.scss' ]
})
export class ProjectLibDocumentEditPage extends BasePageComponent {
  @ViewChild('mainForm') mainForm!: NgForm;

  // keyvalue pipe comparator
  public originalOrder(): number { return 0; };

  // data
  public document: ProjectDocument;

  protected project: Project|null = null;
  public errors: {
    [name: string]: InterpolatbleErrorMessage | InterpolatbleErrorMessage[] | string | string[];
  } | ValidationErrors = {};

  public teamOptions: { key: string, value: string }[] = [];

  public reviewer_id: number|null = null;
  public reviewer: AppUser|null   = null;

  public approver_id: number|null = null
  public approver: AppUser|null   = null;

  // ----------------------------------------------------
  // -- life cycle
  // ----------------------------------------------------

  // constructor
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: ProjectLibService,
    public docapi: DocLibService,
    protected server: ServerService,
    protected taxonomy: TaxonomyService,
    protected user: UserService,
    protected treeUtils: TreeUtilsService,
    protected fileUtils: FileUtilsService,
    protected dateTimeUtils: DateTimeUtilsService,
    protected mimeUtils: MIMEUtilsService
  ) {
    super(router, activatedRoute);
    this.document = this.api.createProjectDocument();
  }

  protected override loadData(): Promise<any> {
    this.document = this.api.createProjectDocument();

    let id = this.activatedRoute.snapshot.paramMap.get('id');
    if ( id === null ) {
      this.nav.goto('/projlib/reserve');
      return Promise.resolve();
    }

    if ( id === 'new' ) {
      const pid = this.activatedRoute.snapshot.queryParamMap.get('project_id');
      if ( ! pid || isNaN(parseInt(pid)) ) {
        this.ui.alert('Bad project ID - {{ project_id }}', { project_id: pid });
        this.back();
        return Promise.resolve();
      }

      const project_id = parseInt(pid);

      return this.server
      //.simulate(TEST_DATA.projlib.show)
      .rejectOnError(true)
      .show('projects', project_id, {
        with: [
          'internal_project_roles', 'internal_project_roles.user'
        ].join(','),
        appends: [].join(',')
      })
      .then( (res: object_t) => {
        this.document.project_id = project_id;
        this.project = this.api.createProject(res);
        this.document.project = this.project;
        this.teamOptions   = this.convertTeamsToOptions(this.api.getUserTeams(this.project, this.session.currentUser));
        const teams        = this.api.getUserTeams(this.project);
        this.document_team = teams.length > 0 ? teams[0] : this.api.core_team_name;
        this.docTeamChanged();
      })
      .catch( (e: Error) => {
        this.ui.alert('Error loading Project id {{ id }} {{ error }}', {
          id: id,
          error: e.message
        })
        .then( () => {
          this.back();
        });
      });
    }

    let nid: number = parseInt(id);
    if ( isNaN(nid) ) {
      this.ui.alert(`Project id "${id}" could not be found!`);
      this.nav.back();
      return Promise.resolve();
    }

    // reset form
    this.clearDirty();

    return this.server
    //.simulate(TEST_DATA.projlib.show)
    .rejectOnError(true)
    .show('projects/documents', nid, {
      with: [
        'project', 'project.internal_project_roles', 'project.internal_project_roles.user',
        'revisions',
        'revisions.attachments',
        'revisions.uploader', 'revisions.approver', 'revisions.reviewer',
        'action_logs', 'action_logs.user',
        'comments', 'comments.user'
      ].join(','),
      appends: [].join(',')
    })
    .then( (res: object_t) => {
      this.document = this.api.createProjectDocument(res);
      this.project  = this.document.project!;

      this.teamOptions   = this.convertTeamsToOptions(this.api.getUserTeams(this.project, this.session.currentUser));
      this.document_team = this.document.owner_team;
      this.docTeamChanged();
    })
    .catch( (e: Error) => {
      this.ui.alert('Error loading Project Document id {{ id }} {{ error }}', {
        id: id,
        error: e.message
      })
      .then( () => {
        this.back();
      });
    });
  }

  // ----------------------------------------------------
  // -- overriding
  // ----------------------------------------------------

  public override refresh() {
    // no refresh required, if new document and not save yet.
    if ( this.activatedRoute.snapshot.paramMap.get('id') != 'new' ) {
      super.refresh();
      return;
    }

    if ( this.document.id ) {
      this.nav.goto(`project/document/edit/${this.document.id}`);
    }
    else {
      super.refresh();
    }
  }

  // ----------------------------------------------------
  // -- validations
  // ----------------------------------------------------

  public markDirty() {
    this.mainForm && this.mainForm.form.markAsDirty();
  }

  public isDirty(): boolean {
    return this.mainForm && this.mainForm.form.dirty;
  }

  public clearDirty() {
    this.mainForm && this.mainForm.form.markAsPristine();
  }

  /**
   * validation logic based on action and document status
   * trig error message or pop error message
   */
  protected async validate(action: 'save'|'submit'|'review'|'rework'|'approve' = 'save'): Promise<boolean> {
    this.errors = {};

    switch ( action ) {
      case 'submit':
        if ( this.document.revisions[0].attachments.length == 0 ) {
          this.errors['revisions'] = 'Please attach at least one document';
          return false;
        }
      break;
    }

    this.errors = Object.assign(this.errors, await this.ui.validateForm(this.mainForm));
    // additional validations here

    return Promise.resolve(Object.keys(this.errors).length == 0);
  }

  public getFullProjectDocumentTitle(): object_t {
    return {
      prefix: this.document.prefix,
      title: this.document.title
    };
  }

  // ----------------------------------------------------
  // -- Internal utilities
  // ----------------------------------------------------

  protected addActionLog(action: string, note: string = ''): Promise<void> {
    if ( ! this.document.id ) {
      console.warn('Cannot add document action log, document is not saved yet');
      return Promise.resolve();
    }

    return this.server.request('projlib.proj_doc.add_log', {
      id: this.document.id
    }, {
      doc_status: this.document.status,
      user_id: this.session.currentUser!.id,
      action: action,
      note: note
    })
    .then( (log: object_t) => {
      this.document.action_logs.push(this.api.createProjectDocumentAction(log));
    });
  }

  // ----------------------------------------------------
  // -- template API
  // ----------------------------------------------------

  public isImage(mime: string): boolean {
    return this.mimeUtils.mime_isImage(mime);
  }

  public convertTeamsToOptions(teams: string[]): { key: string, value: string }[] {
    return teams.map( t => ({
      key: t,
      value: t
    }));
  }

  public is_submitable(status: string): boolean {
    return status == 'draft' && this.project !== null && this.document.revisions.length > 0 && this.document.revisions[0].attachments.length > 0 && this.api.can('submit', 'document', this.project, this.document);
  }

  public is_reviewable(status: string): boolean {
    return status == 'submitted' && this.project !== null && this.document.revisions.length > 0 && this.api.can('review', 'document', this.project, this.document);
  }

  public is_approvable(status: string): boolean {
    return status == 'reviewed' && this.project !== null && this.document.revisions.length > 0 && this.api.can('approve', 'document', this.project, this.document);
  }

  public is_deletable(status: string): boolean {
    return this.project !== null && this.document.revisions.length > 0 && this.api.can('delete', 'document', this.project, this.document);
  }

  /**
   * return to previous page
   */
   public back() {
    this.nav.pop(false, `/project/view/${this.document.project_id}`);
  }

  public document_team: string = '';
  public docTeamChanged() {
    const leaders: InternalProjectRole[]      = this.api.getUserWithRole(this.document.project!, this.document_team, 'leader');
    const coordinators: InternalProjectRole[] = this.api.getUserWithRole(this.document.project!, this.document_team, 'coordinator');

    this.approver    = null;
    this.approver_id = null;
    this.reviewer    = null;
    this.reviewer_id = null;

    // set leader as approver
    if ( leaders.length > 0 && leaders[0].user ) {
      this.approver    = leaders[0].user;
      this.approver_id = leaders[0].user.id;
    }

    // set coordinator or leader as reviewer
    if ( coordinators.length > 0 && coordinators[0].user ) {
      this.reviewer    = coordinators[0].user;
      this.reviewer_id = coordinators[0].user.id;
    }
    else if ( leaders.length > 0 && !! leaders[0].user ) {
      this.reviewer    = leaders[0].user;
      this.reviewer_id = leaders[0].user.id;
    }

    // bail out if no reviewer or approver found
    if ( ! this.reviewer || ! this.reviewer_id) {
      this.ui.alert('No reviewer found for this project!');
      return;
    }

    if ( ! this.approver || ! this.approver_id ) {
      this.ui.alert('No approver found for this project!');
      return;
    }

    // bail out if no document revision is available
    if ( this.document.revisions.length == 0 ) {
      console.warn('Document revision is not available');
      return;
    }

    // update last revision's reviwer, if document is not reviewed and approved yet
    if ( this.document.status != 'reviewed' && this.document.status != 'approved' ) {

      this.document.revisions[0].reviewer    = this.reviewer;
      this.document.revisions[0].reviewer_id = this.reviewer_id;
    }

    // update last revision's approver, if document is not approved yet
    if ( this.document.status != 'approved' ) {
      this.document.revisions[0].approver    = this.approver;
      this.document.revisions[0].approver_id = this.approver_id;
    }

    this.document.owner_team = this.document_team;
    this.markDirty();
  }

  public onFileAttached(attachments: Attachment[]) {
    console.log('onFileAttached', attachments, this.document.revisions[0].attachments);
    //this.document.revisions[0].attachments = this.document.revisions[0].attachments.concat(attachments);
  }

  public onFileDetached(event: Attachment[]) {
    this.document.revisions[0].attachments = this.document.revisions[0].attachments.filter( a => event.indexOf(a) < 0 );
  }

  public async onFilesChange() {
    if ( this.document.status == 'approved' ) {
      if ( await this.ui.confirm('Document has been approved, create new revision?') ) {
        this.document.status = 'draft';
        this.markDirty();
      }
    }
  }

  public async addNewRevision() {
    if ( ! this.document.id ) {
      return;
    }

    if ( await this.ui.confirm('Create new revision?') ) {
      const rev = this.api.createProjectDocumentRevision({
        revision: this.document.revisions.length,
        reviewer_id: this.document.revisions[0].reviewer_id,
      });
      rev.project_document_id = this.document.id;
      rev.project_document    = this.document;
      this.document.revisions.unshift(rev);
      this.document.status = 'draft';
      this.docTeamChanged();
    }

    this.markDirty();
  }

  public async deleteRevision(rev: ProjectDocumentRevision) {
    if ( ! await this.ui.confirm('Delete this draft revision?') ) return;

    if ( rev.id ) {
      await this.server.destroy('projects/documents/revisions', rev.id);
    }
    this.document.revisions = this.document.revisions.filter( r => r.id != rev.id );
    if ( this.document.revisions.length >= 1 ) {
      this.document.status = 'approved'; // rollback to approved on revision cycle
      await this.save();
    }
    this.refresh();
  }

  public async save() {
    if ( ! await this.validate() ) {
      const msg: string[] = Object.keys(this.errors).map( k => `${k}: ${this.errors[k]}`);
      this.ui.alert('Project document information is not completed. Please check.');
      return;
    }

    let promise: Promise<object_t>;
    const data: object_t = Object.assign({}, this.document);
    const new_rev = ( this.document.revisions.length > 0 && this.document.revisions[0].attachments.length > 0 ) ? Object.assign({}, data['revisions'][0]) : null;
    if ( new_rev === null ) {
      this.ui.alert('Please upload at least one file.');
      return;
    }
    // unlink revisions and project to avoid circular reference
    data['revisions'] && delete data['revisions'];
    new_rev['project_document'] && delete new_rev['project_document'];
    new_rev['attachments']      && delete new_rev['attachments'];

    let creation: boolean = false;

    if ( ! this.document.id ) {
      creation = true;
      promise  = this.server.create('projects/documents', data );
    }
    else {
      promise  = this.server.update('projects/documents', this.document.id, data);
    }

    this.document = this.api.createProjectDocument(await promise);
    if ( creation ) {
      await this.addActionLog('create', 'Document initialized');
    }

    if ( this.document.status == 'draft' && !! new_rev ) {
      if ( ! new_rev.id ) {
        new_rev.project_document_id = this.document.id;
        await this.server.create('projects/documents/revisions', new_rev);
      }
      else {
        await this.server.update('projects/documents/revisions', new_rev.id, new_rev);
      }
    }

    this.refresh();
  }

  public delete() {
    if ( ! this.document.id ) return;
    this.ui.confirm('Delete this project document?', null, async () => {
      await this.server.destroy('projects/documents', this.document.id!);
      this.back();
    });
  }

  // ----------------------------------------------------
  // -- Workflow action
  // ----------------------------------------------------
  public async action(action: 'submit'|'review'|'rework'|'approve') {
    if ( ! this.document.id ) {
      console.warn('Project Document ID is not available. Cannot perform workflow action.');
      return;
    }
    if ( ! await this.validate(action) ) {
      this.ui.alert(`Cannot ${action} the project document. Information is not completed. Please check.`);
      return;
    }
    if ( ! await this.ui.confirm(`Please confirm to ${action} the project document?`) ) {
      return;
    }

    if ( this.isDirty() ) {
      await this.save();
    }

    const note = await this.ui.prompt('Note:');

    try {
      const res: object_t = await this.server.request('projlib.proj_doc.action', {
        id: this.document.id,
        action: action
      });

      this.document = this.api.createProjectDocument(res);
      await this.addActionLog(action, note);
      //this.refresh();
      this.back();
    }
    catch ( error: any) {
      this.ui.alert(error.message);
    }
  }
}