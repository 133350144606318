/**
 * routes for 'common' module.
 *
 * NOTE: Register this route in /src/app/app-routing.config.ts
 */

// types and constants
import { NamedRoutes } from '@pinacono/common';

// guards
import { AppRoutingGuard } from 'src/app/app-routing.guard';

// system pages
import { Error403Component } from 'src/app/pages/common/error403/error403.component';
import { Error404Component } from 'src/app/pages/common/error404/error404.component';
import { Error500Component } from 'src/app/pages/common/error500/error500.component';
import { MaintenancePageComponent } from './maintainance/maintenance';

// common pages
import { LoginComponent } from 'src/app/pages/common/login/login.component';
import { HomeComponent } from 'src/app/modules/home/home.component';
import { ForgetPassComponent } from 'src/app/pages/common/forget-pass/forget-pass.component';
import { ResetPassComponent } from 'src/app/pages/common/reset-pass/reset-pass.component';
import { ChPassComponent } from 'src/app/pages/common/ch-pass/ch-pass.component';
import { OfflineComponent } from './offline/offline.component';
import { ProfileComponent } from 'src/app/pages/common/profile/profile.component';
import { SearchPageComponent } from 'src/app/pages/common/search/search.component';
import { VersionsComponent } from 'src/app/pages/common/versions/versions.component';

// -- configurations

// routes configurations { [name: string]: Route } - see https://angular.io/api/router/Route for more details about 'Route' type
export const ROUTES_REGISTRY: NamedRoutes = {
  e403: {
    path: '403',
    component: Error403Component
  },

  e404: {
    path: '404',
    component: Error404Component
  },

  e500: {
    path: '500',
    component: Error500Component
  },

  maintenance:  {
    path: 'maintenance',
    component: MaintenancePageComponent
  },

  // -- common pages

  offlie: {
    path: 'offline',
    component: OfflineComponent
  },

  login:  {
    path: 'login',
    component: LoginComponent
  },

  logout: {
    path: 'logout',
    //component: LoginComponent
    redirectTo: '/login?logout=true'
  },

  home: {
    path: 'home',
    component: HomeComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        },
        onFailed: 'login'
      }
    }
  },

  forget: {
    path: 'forget',
    component: ForgetPassComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isNotLoggedIn: true
        },
        onFailed: 'login'
      }
    }
  },

  resetpass: {
    path: 'resetpass',
    component: ResetPassComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isNotLoggedIn: true
        },
        onFailed: 'logout'
      }
    }
  },

  chpass: {
    path: 'chpass',
    component: ChPassComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        },
        onFailed: 'login'
      }
    }
  },

  versions:  {
    path: 'versions',
    component: VersionsComponent
  },

  me: {
    path: 'me',
    component: ProfileComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        },
        onFailed: 'login'
      }
    }
  },

  profile: {
    path: 'profile/:id',
    component: ProfileComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        },
        onFailed: 'login'
      }
    }
  },

  search: {
    path: 'search/:keyword',
    component: SearchPageComponent,
    canActivate: [AppRoutingGuard],
    data: {
      canActivate: {
        conditions: {
          isLoggedIn: true
        },
        onFailed: 'login'
      }
    }
  }
};
