import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  NavigationService,
  UserService
} from '@pinacono/common';

import {
  PasswordService,
  UIService
} from '@pinacono/ui';

@Component({
  selector: 'app-reset-pass',
  templateUrl: 'reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss']
})
export class ResetPassComponent implements OnInit {

  // template variables
  public email: string = '';
  public new_passwd: string = '';
  public cnf_passwd: string = '';

  // -- lifecycle
  constructor(
    protected activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
    protected user: UserService,
    protected password: PasswordService,
    protected ui: UIService
  ) { }

  protected token: string|null = null;
  public async ngOnInit(): Promise<void> {
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token') || null;
    this.email = this.activatedRoute.snapshot.queryParamMap.get('email') || '';
    if ( this.token === null || this.token.trim().length == 0 ) {
      await this.ui.alert('Password reset token is not available!', undefined, 'Error!');
      this.nav.goto('/login');
    }
  }

  public pwd_suggestions: string[] = [];
  public validate(e: KeyboardEvent) {
    this.pwd_suggestions = this.password.validate( (e.currentTarget as HTMLInputElement).value);
  }

  /** extract email from the form and perform reset logic. */
  public async reset()  {
    if ( ! this.token ) return;

    const res: string = await this.user.setpass(this.token, this.email, this.new_passwd)
    if ( res != 'OK' ) {
      this.ui.alert(res, undefined, 'Error!');
    }
    else {
      this.ui.alert('Password is updated. Next, please login with new password.');
    }
    this.nav.setRoot('login');
    return;
  }
}
