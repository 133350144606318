<full-page [title]="'Training Reports'">
  <div page-content>

    <form id="report-form" ngForm="reportForm">

      <div class="pad-all">
        <div class="row"> <!-- begin of container -->

          <!-- Report #1 -->
          <panel id="competency-report-1" class="col">
            <div panel-heading>Report Type 1 - Individual Training Record</div>
            <div panel-body>
              <p class="text-sm text-muted description">Individual Training Record by member in team(s) and record's date</p>

              <div class="row pad-top">
                <div class="col">
                  <label class="text-bold">{{ 'From' | translate }}:</label>
                  <input type="date" name="from" class="form-control"
                    [daterange]="'from'" [date-ref]="toDate"
                    [(ngModel)]="from"
                    #fromDate="ngModel"
                  >
                </div>
                <div class="col">
                  <label class="text-bold">{{ 'To' | translate }}:</label>
                  <input type="date" name="to" class="form-control"
                    [daterange]="'to'" [date-ref]="fromDate"
                    [(ngModel)]="to"
                    #toDate="ngModel"
                  >
                </div>
              </div>

              <div class="row pad-top">
                <div class="col">
                  <button class="btn btn-default btn-labelled" (click)="selectUsers(report1SelectedUsers)">{{'Select User(s)' | translate}}</button>
                </div>
              </div>
              <div class="row pad-top">
                <label class="text-bold">{{ 'Selected Users' | translate }}:</label>
                <div *ngFor="let user of report1SelectedUsers" class="col form-group user-card-container">
                  <user-card [user]="user"></user-card>
                  <button class="btn btn-icon" (click)="removeSelectedUser(report1SelectedUsers, user)">
                    <i class="pli-close"></i>
                  </button>
                </div>
                <div *ngIf="report1SelectedUsers.length == 0" class="empty">
                  - {{ 'No user(s) selected, assume all users are selected.' | translate }} -
                </div>
              </div>

            </div>

            <div panel-footer class="text-right">
              <button class="btn btn-info labeled-btn" (click)="run('type1', parseReport1Params())">
                <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
              </button>
            </div>

          </panel>

          <!-- Report #2 -->
          <panel id="competency-report-2" class="col">
            <div panel-heading>Report Type 2 - Actuals vs Plan</div>
            <div panel-body>
              <p class="text-sm text-muted description">Expected Competency Level vs Plan by member in team(s) and competency</p>

              <div class="row pad-top">
                <div class="col">
                  <label class="text-bold">{{ 'From' | translate }}:</label>
                  <input type="date" name="from" class="form-control"
                    [daterange]="'from'" [date-ref]="toDate"
                    [(ngModel)]="from"
                    #fromDate="ngModel"
                  >
                </div>
                <div class="col">
                  <label class="text-bold">{{ 'To' | translate }}:</label>
                  <input type="date" name="to" class="form-control" required
                    [daterange]="'to'" [date-ref]="fromDate"
                    [(ngModel)]="to"
                    #toDate="ngModel"
                  >
                </div>
              </div>

              <div class="row pad-top">
                <div class="col form-group">
                  <label class="text-bold">{{ 'Competency' | translate }}: <span class="text-thin text-muted">{{ report2Skill.name || '- no competency selected -'}}</span></label>
                  <lookup-input
                    [placeholder]="'Enter competency code or name'"
                    [minChar]="3"
                    [autoClose]="false"
                    [items]="lookupCompetencyItems['report2']"
                    [autoClose]="false"
                    (onLookup)="lookupCompetencies($event, 'report2')"
                    (onSelect)="selectCompetency($event, report2Skill)"
                  >
                  </lookup-input>
                </div>
              </div>

              <div class="row pad-top">
                <div class="col">
                  <button class="btn btn-default btn-labelled" (click)="selectUsers(report2SelectedUsers)">{{'Select User(s)' | translate}}</button>
                </div>
              </div>
              <div class="row pad-top">
                <label class="text-bold">{{ 'Selected Users' | translate }}:</label>
                <div *ngFor="let user of report2SelectedUsers" class="col form-group user-card-container">
                  <user-card [user]="user"></user-card>
                  <button class="btn btn-icon" (click)="removeSelectedUser(report2SelectedUsers, user)">
                    <i class="pli-close"></i>
                  </button>
                </div>
                <div *ngIf="report2SelectedUsers.length == 0" class="empty">
                  - {{ 'No user(s) selected, assume all users are selected.' | translate }} -
                </div>
              </div>

            </div>

            <div panel-footer class="text-right">
              <button class="btn btn-info labeled-btn" (click)="run('type2', parseReport2Params())">
                <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
              </button>
            </div>

          </panel>

          <!-- Report #3 -->
          <panel id="competency-report-3" class="col">
            <div panel-heading>Report Type 3 - Actuals vs Expectations</div>
            <div panel-body>
              <p class="text-sm text-muted description">Expected Competency Level vs Actual by member in team(s) and by Competency</p>

              <div class="row pad-top">
                <div class="col form-group">
                  <label class="text-bold">{{ 'Competency' | translate }}: <span class="text-thin text-muted">{{ report3Skill.name || '- no competency selected -'}}</span></label>
                  <lookup-input
                    [placeholder]="'Enter competency code or name'"
                    [minChar]="3"
                    [autoClose]="false"
                    [items]="lookupCompetencyItems['report3']"
                    [autoClose]="false"
                    (onLookup)="lookupCompetencies($event, 'report3')"
                    (onSelect)="selectCompetency($event, report3Skill)"
                  >
                  </lookup-input>
                </div>
              </div>

              <div class="row pad-top">
                <div class="col">
                  <button class="btn btn-default btn-labelled" (click)="selectUsers(report3SelectedUsers)">{{'Select User(s)' | translate}}</button>
                </div>
              </div>
              <div class="row pad-top">
                <label class="text-bold">{{ 'Selected Users' | translate }}:</label>
                <div *ngFor="let user of report3SelectedUsers" class="col form-group user-card-container">
                  <user-card [user]="user"></user-card>
                  <button class="btn btn-icon" (click)="removeSelectedUser(report3SelectedUsers, user)">
                    <i class="pli-close"></i>
                  </button>
                </div>
                <div *ngIf="report3SelectedUsers.length == 0" class="empty">
                  - {{ 'No user(s) selected, assume all users are selected.' | translate }} -
                </div>
              </div>

            </div>

            <div panel-footer class="text-right">
              <button class="btn btn-info labeled-btn" (click)="run('type3', parseReport3Params())">
                <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
              </button>
            </div>

          </panel>

          <!-- Report #4 -->
          <panel *ngIf="true" id="competency-report-4" class="col">
            <div panel-heading>Report Type 4 - Competency Comparation between Users</div>
            <div panel-body>
              <p class="text-sm text-muted description">Competencies comparation between users by job description.</p>

              <div class="row pad-top">
                <div class="col form-group">
                  <label class="text-bold">{{ 'Job Title' | translate }}: <span class="text-muted text-thin">{{ report4Job.title || '- no job title selected -'}}</span></label>
                  <lookup-input
                    [placeholder]="'Enter job code or job title'"
                    [minChar]="3"
                    [autoClose]="false"
                    [items]="lookupJobItems['report4']"
                    [autoClose]="false"
                    (onLookup)="lookupJobs($event, 'report4')"
                    (onSelect)="selectJob($event, report4Job)"
                  >
                  </lookup-input>
                </div>
              </div>

              <div class="row pad-top">
                <div class="col">
                  <button class="btn btn-default btn-labelled" (click)="selectUsers(report4SelectedUsers)">{{'Select User(s)' | translate}}</button>
                </div>
              </div>
              <div class="row pad-top">
                <label class="text-bold">{{ 'Selected Users' | translate }}:</label>
                <div *ngFor="let user of report4SelectedUsers" class="col form-group user-card-container">
                  <user-card [user]="user"></user-card>
                  <button class="btn btn-icon" (click)="removeSelectedUser(report4SelectedUsers, user)">
                    <i class="pli-close"></i>
                  </button>
                </div>
                <div *ngIf="report4SelectedUsers.length == 0" class="empty">
                  - {{ 'No user(s) selected.' | translate }} -
                </div>
              </div>

            </div>

            <div panel-footer class="text-right">
              <button class="btn btn-info labeled-btn" (click)="run('type4', parseReport4Params())">
                <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
              </button>
            </div>

          </panel>

          <!-- Report #5 -->
          <panel id="competency-report-5" class="col">
            <div panel-heading>Report Type 5 - Employee Name Wait for Approval</div>
            <div panel-body>
              <p class="text-sm text-muted description">List of students who are waiting for trainer's approval.</p>

              <div class="row pad-top">
                <div class="col form-group">
                  <label class="text-bold">{{ 'Competency' | translate }}: <span class="text-thin text-muted">{{ report5Skill.name || '- no competency selected -'}}</span></label>
                  <lookup-input
                    [placeholder]="'Enter competency code or name'"
                    [minChar]="3"
                    [autoClose]="false"
                    [items]="lookupCompetencyItems['report5']"
                    [autoClose]="false"
                    (onLookup)="lookupCompetencies($event, 'report5')"
                    (onSelect)="selectCompetency($event, report5Skill)"
                  >
                  </lookup-input>
                </div>
              </div>

              <div class="row pad-top">
                <div class="col form-group">
                  <label class="text-bold">{{ 'Level' | translate }}:</label>
                  <select name="level" class="form-control" [(ngModel)]="skill_level" #report_level="ngModel">
                    <option *ngFor="let l of [2, 3, 4]" [attr.value]="l">
                      {{ l - 1 }} &gt; {{ l }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row pad-top">
                <div class="col">
                  <button class="btn btn-default btn-labelled" (click)="selectTrainers(report5SelectedUsers)">{{'Select Trainers(s)' | translate}}</button>
                </div>
              </div>
              <div class="row pad-top">
                <label class="text-bold">{{ 'Selected Trainers' | translate }}:</label>
                <div *ngFor="let user of report5SelectedUsers" class="col form-group user-card-container">
                  <user-card [user]="user"></user-card>
                  <button class="btn btn-icon" (click)="removeSelectedUser(report5SelectedUsers, user)">
                    <i class="pli-close"></i>
                  </button>
                </div>
                <div *ngIf="report5SelectedUsers.length == 0" class="empty">
                  - {{ 'No trainer(s) selected, assume all trainers for the course are selected.' | translate }} -
                </div>
              </div>

            </div>

            <div panel-footer class="text-right">
              <button class="btn btn-info labeled-btn" (click)="run('type5', parseReport5Params())">
                <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
              </button>
            </div>

          </panel>

          <!-- Report #6 -->
          <panel id="competency-report-6" class="col">
            <div panel-heading>Report Type 6 - Employee Name who Passed Specific Competency Level</div>
            <div panel-body>
              <p class="text-sm text-muted description">List of employess who have already passed specific competencies's level.</p>

              <div class="row pad-top">
                <div class="col form-group">
                  <label class="text-bold">{{ 'Competency' | translate }}: <span class="text-thin text-muted">{{ report6Skill.name || '- no competency selected -'}}</span></label>
                  <lookup-input
                    [placeholder]="'Enter competency code or name'"
                    [minChar]="3"
                    [autoClose]="false"
                    [items]="lookupCompetencyItems['report6']"
                    [autoClose]="false"
                    (onLookup)="lookupCompetencies($event, 'report6')"
                    (onSelect)="selectCompetency($event, report6Skill)"
                  >
                  </lookup-input>
                </div>
              </div>
              <div class="row pad-top">
                <div class="col-auto form-group">
                  <label class="text-bold">{{ 'Operator' | translate }}:</label>
                  <select name="operator" class="form-control" [(ngModel)]="operator" #report_operator="ngModel">
                    <option *ngFor="let option of operator_options" [attr.value]="option.value">
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="col-auto form-group">
                  <label class="text-bold">{{ 'Level' | translate }}:</label>
                  <select name="level" class="form-control" [(ngModel)]="skill_level" #report_level="ngModel">
                    <option *ngFor="let l of [1, 2, 3, 4]" [attr.value]="l">{{ l }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div panel-footer class="text-right">
              <button class="btn btn-info labeled-btn" (click)="run('type6', parseReport6Params())">
                <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
              </button>
            </div>

          </panel>

          <!-- Report #7 -->
          <panel id="competency-report-7" class="col">
            <div panel-heading>Report Type 7 - Courses by Competency</div>
            <div panel-body>
              <p class="text-sm text-muted description">List of courses by competency</p>

              <div class="row pad-top">
                <div class="col form-group">
                  <label class="text-bold">{{ 'Competency' | translate }}: <span class="text-thin text-muted">{{ report7Skill.name || '- no competency selected -'}}</span></label>
                  <lookup-input
                    [placeholder]="'Enter competency code or name'"
                    [minChar]="3"
                    [autoClose]="false"
                    [items]="lookupCompetencyItems['report7']"
                    [autoClose]="false"
                    (onLookup)="lookupCompetencies($event, 'report7')"
                    (onSelect)="selectCompetency($event, report7Skill)"
                  >
                  </lookup-input>
                </div>
              </div>
            </div>

            <div panel-footer class="text-right">
              <button class="btn btn-info labeled-btn" (click)="run('type7', parseReport7Params())">
                <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
              </button>
            </div>

          </panel>

          <!-- Report #8 -->
          <panel *ngIf="true" id="competency-report-8" class="col">
            <div panel-heading>Report Type 8 - Competencies by Job Description</div>
            <div panel-body>
              <p class="text-sm text-muted description">List of competencies by Job Description.</p>

              <div class="row pad-top">
                <div class="col form-group">
                  <label class="text-bold">{{ 'Job Title' | translate }}: <span class="text-muted text-thin">{{ report8Job.title || '- no job title selected -'}}</span></label>
                  <lookup-input
                    [placeholder]="'Enter job code or job title'"
                    [minChar]="3"
                    [autoClose]="false"
                    [items]="lookupJobItems['report8']"
                    [autoClose]="false"
                    (onLookup)="lookupJobs($event, 'report8')"
                    (onSelect)="selectJob($event, report8Job)"
                  >
                  </lookup-input>
                </div>
              </div>
            </div>

            <div panel-footer class="text-right">
              <button class="btn btn-info labeled-btn" (click)="run('type8', parseReport8Params())">
                <i class="btn-label pli-running icon-fw"></i>{{ 'Run' | translate }}
              </button>
            </div>

          </panel>


        </div> <!-- end of container-->
      </div>
    </form>

  </div>
</full-page>

<modal #selectUsersByGroupModal
  template-id="select-groups"
  size="xlarge"
  [fade]="true"
>
  <div modal-title>
    <h5>{{ 'Select Group(s)' | translate }}</h5>
  </div>
  <div modal-body>
    <select-users-by-groups [autoSelectMembers]="false" [(selected)]="selectedUsers"></select-users-by-groups>
  </div>
</modal>

<modal #selectUsersFromListModal
  template-id="select-users-from-list"
  size="xlarge"
  [fade]="true"
>
  <div modal-title>
    <h5>{{ 'Select Users(s)' | translate }}</h5>
  </div>
  <div modal-body>
    <select-users [users]="usersList" [(selected)]="selectedUsers"></select-users>
  </div>
</modal>