<h4>{{ title | translate }}<span *ngIf="!!description" class="description text-very-thin">{{ description }}</span></h4>
<div class="table-responsive-lg">
  <table class="table">
    <thead>
      <tr>
        <th>{{ 'Bar Code' | translate }}</th>
        <th>{{ 'Title' | translate }}</th>
        <th>{{ 'Location' | translate }}</th>
        <th>{{ 'Status' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="! items || items.length == 0">
        <td colspan="4"><span class="text-center text-muted text-thin">{{ 'No such asset(s)!' | translate }}</span></td>
      </tr>
      <tr *ngFor="let asset of items">
        <td>{{ asset.code }}</td>
        <td>{{ asset.book?.title || '- no title -' }}</td>
        <td>{{ asset.location['branch'] }} / {{ asset.location['cabinet'] }} / {{ asset.location['shelf'] }}</td>
        <td>{{ asset.status }}</td>
      </tr>
    </tbody>
  </table>
</div>