/**
 * types for Project Library module
 */

import {
  Attachment,
  TimeStampedModel,
  User,
  object_t
} from '@pinacono/common';

import { IIP } from '@pinacono/iip-viewer';

import { AppUser } from 'src/app/types';
import { Comment } from 'src/app/common/types';
import { Document } from 'src/app/modules/documents/types';

// -- drawings

export type SupplementType = 'drawing'|'project'|'project_drawing'
export type Supplement = Drawing|Project|ProjectDrawing;

export interface SupplementData {
  pivot_id: number;
  type: SupplementType;
  document: Supplement
}

export interface Drawing extends TimeStampedModel {
  id?: number;
  iip_name: string|null;
  title: string;

  is_master: boolean;
  masters: MasterDrawing[];

  /**
   * note - 'supplements' is existing in server's model
   * but currently not in use. It's to link the drawing
   * to the drawing's master artifact, e.g, the project,
   * project_drawing, etc.
   */

  attachments: Attachment[];
  polygons: IIP.Polygon[];

  attachment_defer_key: string;
  attr: object_t;

  uploader_id?: number;
  uploader?: AppUser;
}

export interface MasterDrawing extends Drawing {
  pivot_id?: number;
  pivot_polygons: IIP.Polygon[];
}

export interface BuildingOption {
  value: string;
  floors: {
    value: string;
  }[];
}

export const IIPDrawingPrototypeShape: IIP.Coordinate[] = [
  { x: -50, y: -50 },
  { x: 50, y: -50 },
  { x:  0, y:  0 },
  { x: 50, y: 50 },
  { x: -50, y: 50 },
];

// -- projects

export const DefaultOptions = {
  status: {
    draft: 'Draft',
    open: 'Open',
    pending: 'Pending',
    cancelled: 'Cancelled',
    closed: 'Closed'
  },
  type: {
    'newbldg':   'New Building',
    'renovation': 'Renovate Building'
  },
  doc_prefix: {
    'Project Propasal - Internal': 'Project Propasal - Internal',
    'Term of Reference (TOR) - Internal': 'Term of Reference (TOR) - Internal',
    'เอกสารเปิดประมูล': 'เอกสารเปิดประมูล',
    'ใบเสนอราาคา + BOQ': 'ใบเสนอราาคา + BOQ',
    'Contract': 'Contract',
    'Preliminary Docs': 'Preliminary Docs',
    'RFI': 'RFI',
    'RFA': 'RFA'
  },
  doc_status: {
    'draft': 'Draft',
    'reviewer': 'Reviewer',
    'approved': 'Approved'
  },
  drawing_status: {
    'draft': 'Draft',
    'reviewed': 'Reviewed',
    'published': 'Approved and Published',
    'deleted': 'Deleted',
    'cancelled': 'Cancelled'
  },
  drawing_stage: {
    'conceptual_design': 'Conceptual Design',
    'form_permission': 'For Permission',
    'for_bidding': 'For Bidding',
    'for_construction': 'For Construction',
    'shop_drawing': 'Shop Drawing',
    'as_built_drawing': 'As-built Drawing'
  },
  drawing_system: {
    'EE': 'EE Drawing',
    'AC': 'AC Drawing',
    'ME': 'ME Drawing',
  },
  drawing_type: {
    'แบบงานระบบวิศวกรรมประกอบอาคาร': 'แบบงานระบบวิศวกรรมประกอบอาคาร',
    'แบบงานโครงสร้าง': 'แบบงานโครงสร้าง',
    'แบบงานสถาปัตยกรรม': 'แบบงานสถาปัตยกรรม',
    'แบบงานตกแต่งภายใน': 'แบบงานตกแต่งภายใน',
    'แบบงานระบบ Wifi & Mobile': 'แบบงานระบบ Wifi & Mobile',
    'แบบงาน Landscape': 'แบบงาน Landscape',
    'แบบงาน Signage & Graphic': 'แบบงาน Signage & Graphic',
    'แบบงาน Traffic': 'แบบงาน Traffic'
  },
  roles: {
    internal: {},
    external: {},
    vendor: {}
  }
}

// -- models

export interface DrawingCode {
  a: string;  // system name - 'AC'|'EE'|'FP'|'SN';
  b: string;  // sub-system id - 00-13
  c: string;  // page - 00-99
  d?: string; // optional supplimentary
};

/**
 * engineering external user
 * attr.company = SPW|ICON for OneSiam and IconSiam, and company name for others
 */
export interface ExternalContact extends TimeStampedModel {
  id?: number;
  fullname: string;
  email: string;
  job_title?: string;
  department?: string;
  company?: string;    // 'SPW' for all OneSiam / 'ICON' for all Icon Siam, other
  tel: string;
  attr: object_t;
}

export interface ProjectDistributionList extends TimeStampedModel {
  id?: number;
  title: string;
  description: string;
  status: 'draft'|'reviewed'|'approved';

  project_id: number;
  project?: Project;

  author_id: number;
  author?: AppUser;

  contacts: ExternalContact[];
  internals: InternalProjectRole[];

  attr: object_t;
}

/**
 * The project itself
 */
export interface Project extends TimeStampedModel {
  id?: number;

  title_prefix: string;
  title: string;
  title_suffix: string;

  //is_handing_over: boolean;

  project_status: string; // config('client.project.status')
  project_type: string;   // config('client.project.type')
  project_scope: string;
  locations: string[];    // config('client.doc.locations')

  schedule_start?: string;
  schedule_finish?: string;

  masters: MasterDrawing[];

  internal_project_roles: InternalProjectRole[];
  external_project_roles: ExternalProjectRole[];
  vendor_project_roles: ExternalProjectRole[];

  documentable: Document;
  documents: ProjectDocument[];
  drawings: ProjectDrawing[];
  handovers: ProjectHandOver[];
  comments: Comment[];
  attr: object_t;

  uploader_id: number;
  uploader?: AppUser;
}

/**
 * Project role for engineering external user
 */
export interface ExternalProjectRole extends TimeStampedModel {
  id?: number;
  project_id: number;
  project?: Project;
  contact_id: number;
  contact: ExternalContact;
  project_role?: string; // vendor_roles for vendor, free-text for eng external
  attr?: object_t|null;
}

/**
 * internal role config for view/editor to format the page
 */
export interface InternalRoleConfig {
  name: string; // team name
  roles: {
    leader: string;
    coordinator: string;
    member: string;
  }
}

/**
 * Project role for Engingeering user
 */
export interface InternalProjectRole extends TimeStampedModel {
  id?: number;
  is_leader: boolean;
  is_coordinator: boolean;
  team_name: string; /** team_name is case insensitive */
  project_id: number;
  project?: Project;
  user_id: number;
  user?: AppUser
  project_role: string; // config('client.project.roles.internal')
  attr?: object_t|null;
}

/**
 * valid actions over project's artifacts (e.g., ProjectDocument, ProjectDrawing, etc.)
 */
export type ProjectArtifactAction = 'save'|'submit'|'review'|'approve'|'delete'|'revision';

/**
 * Project Document Revision
 */
export interface ProjectDocumentRevision extends TimeStampedModel {
  id?: number;

  revision: number;
  hard_copies: number;
  remaining_hard_copies: number;

  hard_copies_by_handover: {
    handover_id: number;
    hard_copies: number;
    handlers: string
  }[];

  attachments: Attachment[];
  attachment_defer_key: string;

  project_document_id: number|null;
  project_document?: ProjectDocument;

  uploader_id?: number;
  uploader?: AppUser;

  reviewer_id?: number;
  reviewer?: AppUser;
  review_date?: string;

  approver_id?: number;
  approver?: AppUser;
  approve_date?: string;

  attr?: object_t|null;
}

/**
 * Project document - documents those are associated with a project
 */
export interface ProjectDocument extends TimeStampedModel {
  id?: number;
  prefix: string;
  title: string;
  status: string; // config('client.project.doc_status')

  revisions: ProjectDocumentRevision[];
  attr?: object_t|null;

  action_logs: ProjectDocumentAction[];
  comments: Comment[];

  project_id: number|null;
  project?: Project;

  owner_team: string;

  // computed fields - see creator function
  uploader_id?: number;
  uploader?: AppUser;

  reviewer_id?: number;
  reviewer?: AppUser;
  review_date?: string;

  approver_id?: number;
  approver?: AppUser;
  approve_date?: string;

  // type guard
  is_project_document: true;
}

/**
 * Project document actions log
 */
export interface ProjectDocumentAction extends TimeStampedModel {
  id?: number;
  action: 'uploaded'|'reviewed'|'approved';

  project_doc_id: number|null;
  project_document?: ProjectDocument;

  user_id: number|null;
  user?: AppUser;

  doc_status: string; // config('client.project.doc_status')
  note: string;

  attr?: object_t|null;
}

export interface ProjectDrawingRevision extends Drawing {
  revision: number;
  hard_copies: number;
  remaining_hard_copies: number;

  hard_copies_by_handover: {
    handover_id: number;
    hard_copies: number;
    handlers: string
  }[];

  project_drawing_id: number;
  project_drawing?: ProjectDrawing;

  // link to Drawing instance
  drawing_id: number;
  drawing?: Drawing;

  reviewer_id?: number;
  reviewer?: AppUser;
  review_date?: string;

  approver_id?: number;
  approver?: AppUser;
  approve_date?: string;
}

/**
 * Drawings those are associated to the project
 */
export interface ProjectDrawing extends TimeStampedModel {
  /**
   * design note:
   * - revision gather from count() of the same 'drawing_no'
   * - release_date gather from last_update
   */
  id?: number;
  drawing_no: string;
  system: string;
  title: string;
  status: string; // config('client.project.drawing_status')
  stage: string; // config('client.project.drawing_stage')
  type: string;   // config('client.project.drawing_type')
  description: string;

  project_id: number|null;
  project?: Project;

  attr?: object_t|null;

  revisions: ProjectDrawingRevision[];    // multiple revisions

  // `master' is mutated from server's model which is an array of masters,
  // this is according to user requirement
  master_ids: string[];
  masters: MasterDrawing[];

  action_logs: ProjectDocumentAction[];
  comments: Comment[];

  owner_team: string;

  /*
  // last revision infor
  last_pivot_id?: number;
  last_revision: number;
  */

  uploader_id?: number;
  uploader?: AppUser;
  reviewer_id?: number;
  reviewer?: AppUser;
  review_date?: string;
  approver_id?: number;
  approver?: AppUser;
  approve_date?: string;

  // type guard
  is_project_drawing: true;
}

// -- handover

export interface HandOverProjectDrawingRevision {
  id?: number;

  handover_id?: number;
  handover?: ProjectHandOver;

  revision_id: number;
  revision: ProjectDrawingRevision;

  soft_copy: boolean;
  hard_copies: number;
  accepted: boolean;
  comment: string;
  handlers: string;
}

export interface HandOverProjectDocumentRevision {
  id?: number;

  handover_id?: number;
  handover?: ProjectHandOver;

  revision_id: number;
  revision: ProjectDocumentRevision;

  soft_copy: boolean;
  hard_copies: number;
  accepted: boolean;
  comment: string;
  handlers: string;
}

type HandOverStatus = 'draft'|'submitted'|'approved'|'closed';

export interface ProjectHandOver extends TimeStampedModel {
  id?: number;
  project_id: number;
  project?: Project;

  documents: HandOverProjectDocumentRevision[];
  drawings: HandOverProjectDrawingRevision[];

  status: HandOverStatus;
  comments: Comment[];

  author_id?: number;
  author?: AppUser;
  approver_id?: number;
  approver?: AppUser;

  submitted_at?: string;
  approved_at?: string;
}

// -- release

export interface ReleaseBundleRecipient {
  email: string;
  token?: string;

  sent_at?: string;
  expired_at?: string;
  downloaded_at?: string;
  ip_addr?: string;

  attr?: object_t;

  // internal used only - flag, if the recipient is an
  // extra e-mail address which is not in the distribution list
  extra?: boolean;
  expired?: boolean;
}

/**
 * The release bundle
 */
export interface ReleaseBundle extends TimeStampedModel {
  id?: number;
  subject: string;
  recipients: ReleaseBundleRecipient[];
  message: string;
  official: boolean;
  attr?: object_t|null;

  files: Attachment[];

  project_id: number;
  project?: Project

  author_id: number;
  author?: AppUser;
}

/**
 * The release item
 */
export interface ReleaseItem extends TimeStampedModel {
  id?: number;
  email: string;
  token: string;

  ip_addr?: string;       // IP address of the downloader - need to check the X-Forward case
  downloaded_at?: string; // download is disabled, if not null
  expired_at: string;     // for clean-up

  bundle_content_id: number;
  bundle_content: ReleaseBundle;
  attr?: object_t|null;
}

/**
 * The release bundle template
 */
export interface ReleaseBundleTemplate extends TimeStampedModel {
  id?: number;
  subject: string;
  recipients: ReleaseBundleRecipient[];
  message: string;
  status: string;
  documents: ProjectDocument[];
  drawings: ProjectDrawing[];
  attr?: object_t|null;

  project_id: number;
  project?: Project

  author_id: number;
  author?: AppUser;
}
