<div class="pad-all course">

  <div *ngIf="!course" id="no-data">
    <span class="text-thin text-muted">{{ 'Course information not found!' | translate}}</span>
  </div>

  <div *ngIf="course" class="media">
    <!--
    <pre>{{ course | json }}</pre>
    -->
    <div class="media-left">
      <gallery *ngIf="course.covers.length > 0"
        class="img-responsive"
        [items]="course.covers"
        [thumbnail]="{ type: 'files', width: 400, height: 400, mode: 'auto' }"
      ></gallery>
      <img *ngIf="course.covers.length == 0" class="img-responsive" src="/assets/imgs/300x300.png">
    </div>
    <div class="media-body">
      <div id="course-title" class="title">
        <a (click)="selectCourse(course.id)" class="clickable">{{ course.name || 'Untitled' }} ({{ course.code || 'code not available' }})</a>
        <sup *ngIf="course.auto_batch" class="badge badge-warning"><i class="icon-sm pli-flash"></i> {{ 'Quick Apply' | translate }}</sup>

        <div *ngIf="session.hasPermission(['training-admin'])" class="actions">
          <button (click)="edit()" class="btn btn-primary btn-icon" [title]="'Edit' | translate ">
            <i class="btn-label pli-pencil icon-lg"></i>
          </button>
        </div>

      </div>

      <div class="description text-sm text-thin" [markdown]="course.description" title="{{ 'Course Description' | translate }}">
      </div>

      <div class="course-attachments">
        <h5>{{ 'Attachments' | translate }}</h5>
        <div>
          <download *ngFor="let f of course.attachments" [file]="f"></download>
          <p *ngIf="! course.attachments || course.attachments.length == 0"
            class="text-thin italic"
          >
            {{ 'Attachments not avaialble.' | translate }}
          </p>
        </div>
        <gallery *ngIf="media.length > 0" id="course-media" class="img-responsive" [items]="media"></gallery>
      </div>

      <div *ngIf="objNeedsExam" class="exam">
        <div class="row">
          <div class="col-xs-12 col-sm-6 exam-config">
            <h5>{{ 'Examinations' | translate }}</h5>
            <dl class="dl-horizontal">
              <dt>Number of Questions:</dt>
                <dd>{{ course!.attr!['exam_config'].no_of_questions }}</dd>
              <dt>Choices per Question:</dt>
                <dd>{{ course!.attr!['exam_config'].no_of_choices }}</dd>
            </dl>
          </div>

          <div class="col-xs-12 col-sm-6 scoring">
            <h5>{{ 'Scoring' | translate }}</h5>
            <dl class="dl-horizontal">
              <dt>Correct Answer:</dt>
                <dd>{{ course.attr!['scoring'].correct_answer | sign }} points</dd>
              <dt>Incorrect Answer:</dt>
                <dd>{{ course.attr!['scoring'].incorrect_answer | sign }} points</dd>
              <dt>No Answer:</dt>
                <dd>{{ course.attr!['scoring'].no_answer | sign }} points</dd>
              <dt>Time Limit Mode:</dt>
                <dd>{{
                  course.attr!['scoring'].timer_mode == examTimerMode.NO_TIMER     ? 'No Time Limit' :
                  course.attr!['scoring'].timer_mode == examTimerMode.TIME_LIMIT   ? 'Limited Time' :
                  course.attr!['scoring'].timer_mode == examTimerMode.TIME_PENALTY ? 'Exceeding Time Penalty' :
                  'Unknown Limit Mode'
                }}</dd>
              <dt *ngIf="course.attr!['scoring'].timer_mode == examTimerMode.TIME_LIMIT">Time Limit:</dt>
                <dd *ngIf="course.attr!['scoring'].timer_mode == examTimerMode.TIME_LIMIT">{{ course.attr!['scoring'].time_limit | number:'1.0-0' }} minute(s)</dd>
              <dt *ngIf="course.attr!['scoring'].timer_mode == examTimerMode.TIME_PENALTY">Time Exceed Penalty:</dt>
                <dd *ngIf="course.attr!['scoring'].timer_mode == examTimerMode.TIME_PENALTY">{{ course.attr!['scoring'].time_penalty | sign }} point(s) per minute</dd>
            </dl>
          </div>
        </div>
      </div>

      <div *ngIf="course.related_opls && course.related_opls.length > 0"
        class="related-ops"
      >
        <h5>{{ 'Related OPL' | translate }}</h5>
        <ul>
          <li *ngFor="let link of course.related_opls">
            <a [routerLink]="[ '/opls/view', link.id ]">{{ link.title }}</a>
          </li>
        </ul>
      </div>

      <div class="competencies">
        <h5>{{ 'Competencies' | translate }}</h5>
        <ul>
          <li *ngFor="let level of course.levels" class="text-sm text-thin" title="{{ 'Competency' | translate }}">
            <a (click)="selectCompetency(level.competency_id)" class="btn-link"><strong>{{ level.competency && level.competency.code }}</strong> - {{ level.competency?.name || 'Unknown Competency' }} (Level {{ ( level.level - 1 ) || 0 }} <i class="pli-right"></i> {{ level.level || 0 }})</a>
          </li>
        </ul>
      </div>

      <div  *ngIf="course.prerequisites && course.prerequisites.length > 0"
        class="prerequisites"
        title="{{ 'Prerequisites' | translate }}"
      >
        <h5>{{ 'Prerequisites' | translate }}:</h5>
        <ul>
          <li *ngFor="let c of course.prerequisites" class="text-sm text-thin">
            <a (click)="selectCourse(c.id)" class="btn-link"><strong>{{ c.code }}</strong> - {{ c.name }}</a>
          </li>
        </ul>
      </div>

      <div *ngIf="course.postrequisites && course.postrequisites.length > 0"
        class="postrequisites"
        title="{{ 'Required By' | translate }}"
      >
        <h5>{{ 'Required by' | translate }}:</h5>
        <ul>
          <li *ngFor="let c of course.postrequisites" class="text-sm text-thin">
            <a (click)="selectCourse(c.id)" class="btn-link"><strong>{{ c.code }}</strong> - {{ c.name }}</a>
          </li>
        </ul>
      </div>

      <div *ngIf="show_dependencies && nodes.length > 0"
        class="course-dependencies"
      >
        <h5 class="text-bold">{{ 'Course Dependencies' | translate }}:</h5>
        <diagram
          [(nodes)]="nodes"
          [(links)]="links"
          [readonly]="true"
          [scale]="0.5"
          [rootLayoutOptions]="rootLayoutOptions"
          [show-help]="true"
        >
          <ng-template
            let-node="node"
            let-selected="selected"
          >
            <div class="node-container"
              [ngClass]="{
                'selected': selected,
                'current-course': ( node.data.id == course.id )
              }"
              (click)="selectCourse(node.data.id)"
            >
              <div class="text-bold course-title">
                {{ node.data.code }}
              </div>
              <div class="course-details">
                <h4>{{ node.data.name }}</h4>
                <p [markdown]="node.data.description">{{ node.data.description }}</p>
              </div>
            </div>
          </ng-template>
          <dl diagram-help class="dl-horizontal">
            <dt>Drag mouse</dt>
              <dd>Pan the diagram.</dd>
            <dt>Mouse Wheel</dt>
              <dd>zoom in-out.</dd>
          </dl>
        </diagram>
      </div>

      <!-- BUTTONS -->
      <div class="batches">
        <div *ngIf="course.auto_batch" class="text-thin text-sm text-muted pad-btm">
          {{ 'This course is "Quick Apply" course, which you can immediately takes action after request.' | translate }}
        </div>

        <!-- if not auto batch and one or more batches available -->
        <ng-container *ngIf="! course.auto_batch">
        <div *ngFor="let batch of opened_batches" class="row">

          <div class="col-xs-12 batch">
            <div class="row">
              <!-- batch information -->
              <div class="col-xs-12 col-sm-6">

                <dl class="dl-horizontal">
                  <dt class="text-sm text-thin" title="{{ 'Batch Name' | translate }}">
                    <label>{{ 'Batch Name' | translate }}:</label>
                    <i class="pli-teacher icon-lg icon-fw"></i>
                  </dt>
                  <dd>
                    {{ batch.name || 'n/a' }}
                  </dd>

                  <dt class="text-sm text-thin" title="{{ 'Schedule' | translate }}">
                    <label>{{ 'Schedule' | translate }}:</label>
                    <i class="pli-clock icon-lg icon-fw"></i>
                  </dt>
                  <dd>
                    {{ ( batch.schedule_start_at | datetime ) || 'n/a' }} - {{ (batch.schedule_finish_at | datetime) || 'n/a' }}
                  </dd>

                  <dt class="text-sm text-thin" title="{{ 'Venue' | translate }}">
                    <label>{{ 'Venue' | translate }}:</label>
                    <i class="pli-location-2 icon-lg icon-fw"></i>
                  </dt>
                  <dd>
                    {{ batch.venue || 'n/a' }}
                  </dd>

                  <dt class="text-sm text-thin" title="{{ 'Status' | translate }}">
                    <label>{{ 'Status' | translate }}:</label>
                    <i class="pli-checkered-flag icon-lg icon-fw"></i>
                  </dt>
                  <dd>
                    {{ batch.status }}
                  </dd>
                </dl>

                <!-- current status -->
                <div *ngIf="my_batch && my_batch.id == batch.id">
                  <div class="text-bold text-success text-left">
                    <i class="icon-md pli-tag"></i> {{ 'You are an attendee of this batch' | translate }}
                  </div>
                  <div *ngIf="my_batch.my_record && my_batch.my_record.examinations && my_batch.my_record.examinations.length > 0"
                    class="text-thin text-muted text-left"
                  >
                  <i class="icon-md pli-tag"></i> {{ 'You have taken the examination for ' | translate }} {{ my_batch.my_record.examinations.length }} {{ 'time(s)' | translate }}
                  </div>
                </div>
                <div *ngIf="my_request && my_request.batch_id == batch.id && ( ! my_batch || my_batch.id != batch.id )"
                  class="text-thin text-bold text-pink text-center"
                >
                  <i class="icon-md pli-tag"></i> {{ 'You have already requested to join this batch' | translate }}
                </div>

              </div>

              <!-- batch trainer information -->
              <div class="col-xs-12 col-sm-6">
                <div *ngFor="let trainer of batch.trainers" class="text-sm text-thin" title="{{ 'Trainer(s)' | translate }}">
                  <i class="pli-teacher icon-lg icon-fw"></i>
                  <label>{{ 'Trainer' | translate }}:</label>
                  <user-card *ngFor="let trainer of batch.trainers" [user]="trainer"></user-card>
                </div>
              </div>
            </div>
          </div>

          <!-- list of actions on a batch -->
          <div class="col-xs-12 text-right pb-3">

            <!-- #1 user can apply if never apply before -->
            <button *ngIf="( hasLevel(1) || is_planned ) && ! isMyBatch(batch) && ! is_requested && batch.status == BatchStatus.OPEN"
              id="action-btn-1"
              class="btn btn-labeled btn-mint"
              title="{{ 'You have never requested or applied for this course. You may apply for this batch.' | translate }}"
              (click)="apply(batch)"
            >
              <i class="pli-pencil btn-label"></i>{{ 'Apply' | translate }}
            </button>

            <!-- #2 user can revoke if has applied before -->
            <!--
            <button *ngIf="isMyBatch(batch) && ( BatchStatus | in_array:[BatchStatus.PENDING, BatchStatus.OPEN, BatchStatus.FULL] )"
              id="action-btn-2"
              class="btn btn-labeled btn-mint"
              title="{{ 'You have applied for this batch. You may unapply from this batch.' | translate }}"
              (click)="unapply(batch)"
            >
              <i class="pli-pencil btn-label"></i>{{ 'Unapply' | translate }}
            </button>
            -->

            <button *ngIf="isMyBatch(batch) && report_exam_result"
              id="action-btn-2-1"
              class="btn btn-success clickable mar-lft"
              (click)="checkRecord(batch)"
            >
              {{ 'Check Training Record' | translate }}
            </button>

            <!-- #3 user can unrequest, if it is requesting for this batch -->
            <!--
            <button *ngIf="isMyRequest(batch) && ( batch.status | in_array:[BatchStatus.PENDING, BatchStatus.OPEN, BatchStatus.FULL] )"
            -->
            <button *ngIf="!! ( this.course && this.course.my_request && this.course.my_request.id )"
              id="action-btn-3"
              class="btn btn-labeled btn-warning"
              title="{{ 'Your request has already been sent to mentors. Please wait for the mentor to accept the request or your can revoke your request.' | translate }}"
              (click)="unrequest()"
            >
              <i class="pli-pencil btn-label"></i>{{ 'Cancel Request' | translate }}
            </button>

            <!-- #4 user can take exam, if already in batch -->
            <button *ngIf="isMyBatch(batch) && ! is_passed && is_examable && (next_exam <= 0 || ! hide_course_in_silent_period)"
              id="action-btn-4"
              class="btn btn-labeled btn-mint"
              title="{{ 'You have applied for this course. You may take the examination.' | translate }}"
              [attr.disabled]="next_exam > 0 ? 1 : null"
              (click)="exam()"
            >
              <i class="pli-pencil btn-label"></i>
              <ng-container *ngIf="next_exam <= 0">
                {{ 'Take Examination' | translate }}
              </ng-container>
              <ng-container *ngIf="next_exam > 0">
                {{ 'You will be able to take examination again in {{xx}} minutes' | translate: {xx: ( next_exam | interval_as:'humanize' | number:'1.0-0' )} }}
              </ng-container>
            </button>

          </div>
        </div>
        </ng-container>

        <!-- if auto batch or no batch avaialble -->
        <div *ngIf="course.auto_batch || opened_batches.length == 0" class="row">
          <div class="col-xs-12 pad-no">
            <!-- #5 if not auto batch and not request yet -->
            <div *ngIf="( hasLevel(1) || is_planned) && ! course.auto_batch && ! is_passed && ! is_requested && ! course.auto_batch">
              <!-- no available batch, let user make request -->
              <p class="text-bold text-mint">
                <em>** {{ 'Cannot find available batch. You may request to open a new batch.' | translate }} **</em>
              </p>
              <button
                id="action-btn-5"
                class="btn btn-labeled btn-mint"
                (click)="request()"
              >
                <i class="pli-pencil btn-label"></i>{{ 'Request' | translate }}
              </button>
            </div>

            <!-- #6 if user can request and create the batch automatically -->
            <button *ngIf="( hasLevel(1) || is_planned ) && ! is_passed && course.auto_batch && course.min_score == 0"
              id="action-btn-6"
              class="btn btn-labeled btn-mint"
              (click)="request()"
            >
              <i class="pli-pencil btn-label"></i>{{ 'Request' | translate }}
            </button>

            <!-- #7 if not auto batch and already requested -->
            <div *ngIf="is_requested && ! course.auto_batch">
              <p class="text-sm text-bold text-success text-center">
                <em>** {{ 'Your request has already been sent to mentors. Please wait for the mentor to open a batch.' | translate }} **</em>
              </p>
              <!-- unrequest button -->
              <button
                id="action-btn-7"
                class="btn btn-labeled btn-warning" (click)="unrequest()">
                <i class="pli-pencil btn-label"></i>{{ 'Cancel Request' | translate }}
              </button>
            </div>

            <!-- #8 if has exam and user still failed in the exam and can take examination by themselves -->
            <button *ngIf="( hasLevel(1) || is_planned ) && ! is_passed && course.auto_batch && is_examable && ( next_exam <= 0 || ! hide_course_in_silent_period )"
              id="action-btn-8"
              class="btn btn-labeled btn-mint"
              [attr.disabled]="next_exam > 0 ? 1 : null"
              (click)="exam()"
            >
              <i class="pli-pencil btn-label"></i>
              <ng-container *ngIf="next_exam <= 0">
                {{ 'Take Examination' | translate }}
              </ng-container>
              <ng-container *ngIf="next_exam > 0">
                {{ 'You will be able to take examination again in {minute} minutes' | translate: {minute: ( next_exam | interval_as:'min' | number:'1.0-0' )} }}
              </ng-container>
            </button>
          </div>
        </div>

        <!-- if user is already passed -->
        <div *ngIf="is_passed" class="row">
          <div class="col-xs-12">
            <div class="text-bold text-success text-left">
              <i class="icon-5x pli-diploma-2"></i> {{ 'You passed this course' | translate }}
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

</div>

<modal *ngIf="examCourse && objNeedsExam" [size]="'large'" (onDismiss)="dismissExam()" #examDialog>
  <div modal-body>
    <exam #exam
      [course]="examCourse"
      [objective]="objNeedsExam"
      (saved)="onExamSaved($event)"
    ></exam>
  </div>
</modal>