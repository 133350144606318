import {
  Injectable
} from "@angular/core";

import {
  config,
  object_t,
  ServerError,
  ServerService,
  SessionService,
  StringUtilsService,
  TimeStampedModel,
  UserService,
  UtilsService
} from "@pinacono/common";

import {
  UIService
} from "@pinacono/ui";

import { IIP } from "@pinacono/iip-viewer";

import { AppUser } from "src/app/types";
import { AppCommonService } from 'src/app/common/app-common.service';
import { DocLibService } from 'src/app/modules/documents/doclib.service';

import {
  CustomPropertyOption
} from 'src/app/common/types';

import {

  // drawings
  Drawing,
  BuildingOption,
  MasterDrawing,
  SupplementData,
  Supplement,

  // projects
  DefaultOptions,
  ExternalContact,
  ExternalProjectRole,
  InternalProjectRole,
  InternalRoleConfig,
  Project,
  ProjectDocument,
  ProjectDocumentAction,
  ProjectDocumentRevision,
  ProjectDistributionList,
  ProjectDrawing,
  ReleaseBundle,
  ReleaseBundleRecipient,
  ReleaseBundleTemplate,
  ProjectArtifactAction,
  ProjectDrawingRevision,
  ProjectHandOver,
  HandOverProjectDrawingRevision,
  HandOverProjectDocumentRevision
} from './types';

import * as _ from 'lodash';
import * as moment from "moment-timezone";

const DEFAULT_CORE_TEAM_NAME = 'Project Core Team';

/**
 * wrapper for document service
 */
@Injectable()
export class ProjectLibService {

  public readonly options: object_t;
  public readonly default_teams: string[] = [];

  public drawing_search_options: object_t = {};

  // ---------------------------
  // -- initialization

  constructor(
    protected user: UserService,
    protected session: SessionService,
    protected ui: UIService,
    protected server: ServerService,
    protected stringUtils: StringUtilsService,
    protected utils: UtilsService,
    protected doclib: DocLibService,
    protected app: AppCommonService
  ) {
    this.options = config('client.project.options', DefaultOptions);

    this.options['project_title_suffix'] = {};
    const current_year = (new Date()).getFullYear();
    for ( let i = -10; i <= 10; i++ ) {
      const year = current_year + i + 543;
      this.options['project_title_suffix'][`ปี ${year}`] = `ปี ${year}`;
    }

    this.default_teams = this.options['roles']['internal'].map( (r: object_t) => r['name'] );
  }

  // ---------------------------
  // -- drawing services
  // ---------------------------

  // -- Live buildings search parameters loader

  public loadBuildingSearchParam(): Promise<void> {
    return this.server
    //.simulate() // @TODO - remove when done dev
    .request('drawings.search_params')
    .then( (res: BuildingOption[]) => {
      this.drawing_search_options = res;
    });
  }

  public getBuildings(): CustomPropertyOption[] {
    return ( this.drawing_search_options['buildings'] || [] ).map( (b: object_t) => {
      return { id: b['value'], text: b['value'] };
    });
  }

  public getFloors(building: string): CustomPropertyOption[] {
    const bldg: object_t = this.drawing_search_options['buildings'].find( (b: object_t) => b['value'] == building ) || {floors: []};
    return bldg['floors'].map( (f: object_t) => {
      return { id: f['value'], text: f['value'] };
    });
  }

  // ---------------------------
  // -- drawing creators

  public createDrawing(data: object_t|null = null): Drawing {
    data = data || {};

    const d = {
      id:          data['id'] || undefined,
      attachments: data['attachments'] && data['attachments'].map( (a: object_t) => this.server.createAttachment(a) ) || [],
      iip_name:    data['iip_name'] || null,
      title:       data['title']    || '',
      is_master:   data['is_master'] || false,
      masters:     data['masters'] && data['masters'].map( (m: object_t) => this.createMasterDrawing(m)) || [],
      attr:        Object.assign({
                     building: data['building'] || '',
                     floor: data['floor']       || ''
                   }, data['attr']),

      attachment_defer_key: data['attachment_defer_key'] || this.stringUtils.random(16),

      building:    data['building'] || data['attr'] && data['attr']['building'] || '',
      floor:       data['floor']    || data['attr'] && data['attr']['floor']    || '',

      uploader_id: data['uploader_id'] || this.session.currentUser!.id,
      uploader:    data['uploader'] && this.user.create(data['uploader']) || this.session.currentUser,
    };

    return this.server.createTimeStampedModel(d  as TimeStampedModel, data) as Drawing;
  }

  public createPolygon(polygons_string: string[][][]): IIP.Polygon[] {
    const polygons:IIP.Polygon[] = polygons_string.map( (p: string[][]) => {
      return {
        vertices: p.map( (c) => {
          return {
            x: parseFloat(c[0]),
            y: parseFloat(c[1])
          }
        } )
      };
    });
    return polygons;
  }

  public packPolygon(polygons: IIP.Polygon[] ): string[][][] {
    const ps: string[][][] = [];
    polygons.forEach( (poly) => {
      ps.push( poly.vertices.map( (v) => [v.x.toString(), v.y.toString()] ) );
    });
    return ps;
  }

  public createMasterDrawing(data: object_t|null = null): MasterDrawing {
    data = data || {};
    const m: MasterDrawing = this.createDrawing(data) as MasterDrawing;
    m.pivot_polygons = this.createPolygon(data['pivot_polygons']||[]);
    m.pivot_id = data['pivot_id'];
    return m;
  }

  public createSupplement(data: SupplementData): Supplement {
    switch ( data.type ) {
      case 'project':
      return this.createProject(data.document);

      case 'project_drawing':
      return this.createProjectDrawing(data.document);

      case 'drawing':
      return this.createDrawing(data.document);
    }

    throw new Error(`Unknown supplement type - ${data['type']}`);
  }

  // ---------------------------
  // -- drawing searching service

  public searchMasters(drawing: Drawing): Promise<MasterDrawing[]> {
    return new Promise<MasterDrawing[]>( (resolve, reject) => {
      const data = {
        title: drawing.title.trim(),
        building: drawing.attr['building'].trim(),
        floor: drawing.attr['floor'].trim(),
        is_master: true
      };
      this.server.request('drawings.masters.search', null, data)
      .then( (res: object_t[]) => {
        resolve( res.map( r => this.createMasterDrawing(r)) );
      });
    });
  }

  public async search(master: Drawing, polygons: IIP.Polygon[], types: string[]|null = null): Promise<SupplementData[]> {
    const data = {
      types: types && Array.from(types).join(',') || null,
      polygons: polygons.map( p => p.vertices )
    };
    const res: object_t[] = await this.server.request('drawings.supplements.search', { master_id: master.id }, data);
    return res.map( (r: object_t) => {
      return {
        pivot_id: r['pivot_id'],
        type: r['type'],
        document: this.createSupplement(r as SupplementData)
      }
    });
  }

  public async loadMasters(doc_id: number, doc_type: string = 'drawing'): Promise<MasterDrawing[]> {
    const data = {
      type: doc_type,
      id: doc_id
    };

    let masters: MasterDrawing[] = [];
    try {
      masters = (await this.server.request('drawings.supplements.masters', null, data))
        .map( (o: object_t) => {
          return this.createMasterDrawing(o);
        });
    }
    catch ( err: unknown ) {
      if ( (err as ServerError).code !== 404 ) {
        console.error('Error loading masters', err);
      }
    }

    return masters;
  }

  public async loadSupplements(master: Drawing, types: string[]|null = null): Promise<Supplement[]> {
    return (await this.search(master, [], types))
      .map( r => this.createSupplement(r) );
  }

  // ---------------------------
  // -- creators
  // ---------------------------

  // ---------------------------
  // -- converters

  public getOptionsAsGridFilter(name: string): {value: any, label: string}[] {
    let keys = this.options[name] && Object.keys(this.options[name]) || [];
    return keys.map( (k:string) => {
      return { label: this.options[name][k], value: k };
    });
  }

  // ---------------------------
  // -- creators

  public createProject(d: object_t|null = null): Project {
    const current_year = (new Date()).getFullYear();
    d = d || {};
    d['code'] = d['code'] || { a: 'ENG', b: null, c: null, d: null, e: null, f: 'PRJ', g: null };
    const proj: Project = {
      id:    d['id'] || undefined,

      title_prefix: d['title_prefix'] || 'โครงการก่อสร้าง',
      title: d['title'] || '',
      title_suffix: d['title_suffix'] || `ปี ${current_year + 543}`,

      //is_handing_over: d['is_handing_over'] || false,

      project_status: d['project_status'] || 'draft',
      project_type:   d['project_type']   || 'renovate',
      project_scope:  d['project_scope']  || '',
      locations: d['locations'] || [],

      masters: d['masters'] && d['masters'].map( (m: object_t) => this.createMasterDrawing(m)) || [],

      schedule_start:  d['schedule_start'] && this.utils.dateTime_utils.browser(d['schedule_start']) || undefined,
      schedule_finish: d['schedule_finish'] && this.utils.dateTime_utils.browser(d['schedule_finish']) || undefined,

      uploader_id: d['uploader_id'] || this.session.currentUser!.id,
      uploader: d['uploader'] || this.session.currentUser,

      documentable:   this.doclib.createDocument(d['documentable'] || { code: { f: 'PRJ' } }),
      internal_project_roles: d['internal_project_roles'] && d['internal_project_roles'].map( (o: object_t) => this.createInternalProjectRole(o) ) || [],
      external_project_roles: d['external_project_roles'] && d['external_project_roles'].map( (o: object_t) => this.createExternalProjectRole(o) ) || [],
      vendor_project_roles:   d['vendor_project_roles']   && d['vendor_project_roles'].map( (o: object_t) => this.createExternalProjectRole(o) )   || [],

      documents: d['documents'] && d['documents'].map( (o: object_t) => this.createProjectDocument(o) ) || [],
      drawings:  d['drawings'] && d['drawings'].map( (o: object_t) => this.createProjectDrawing(o) ) || [],
      handovers: d['handovers'] && d['handovers'].map( (o: object_t) => this.createProjectHandOver(o) ) || [],
      comments:  d['comments'] && d['comments'].map( (o: object_t) => this.app.createComment(o) ) || [],

      attr: d['attr'] || {},
    }

    return this.server.createTimeStampedModel(proj, d) as Project;
  }

  public createProjectDocumentRevision(d: object_t|null = null): ProjectDocumentRevision {
    d = d || {};
    const rev: ProjectDocumentRevision = {
      id: d['id'] || undefined,
      revision:    d['revision'] || 0,
      hard_copies: d['hard_copies'] || 0,
      remaining_hard_copies: d['remaining_hard_copies'] || 0,
      hard_copies_by_handover: (d['hard_copies_by_handover'] || []).map( (h: object_t) => ({
        handover_id: h['handover_id'] || 0,
        hard_copies: h['hard_copies'] || 0,
        handlers: h['handlers'] || ''
      }) ),

      attachments: (d['attachments'] || []).map( (a: object_t) => this.server.createAttachment(a) ),
      attachment_defer_key: d['attachment_defer_key'] || this.utils.string_utils.random(16),

      project_document_id: d['project_document_id'] || null,
      project_document: d['project_document'] && this.createProjectDocument(d['project_document']) || undefined,

      attr:        d['attr'] || {},

      uploader_id: d['uploader_id'] || this.session.currentUser!.id,
      uploader: d['uploader'] && this.user.create(d['uploader']) || this.session.currentUser,

      reviewer_id: d['reviewer_id'] || undefined,
      reviewer:    d['reviewer'] && this.user.create(d['reviewer']) || undefined,
      review_date: d['review_date'] && this.utils.dateTime_utils.browser(d['review_date']) || undefined,

      approver_id:  d['approver_id'] || undefined,
      approver:     d['approver'] && this.user.create(d['approver']) || undefined,
      approve_date: d['approve_date'] && this.utils.dateTime_utils.browser(d['approve_date']) || undefined,

    };
    return this.server.createTimeStampedModel(rev, d) as ProjectDocumentRevision;
  }

  public createProjectDocument(d: object_t|null = null): ProjectDocument {
    d = d || {};

    const last_rev = d['revisions'] && d['revisions'][0] || null;

    // ensure that at-least one revision is available in object
    if ( ! d['revisions'] || Array.from(d['revisions']).length == 0 ) {
      d['revisions'] = [ null ];
    }

    const proj_doc: ProjectDocument = {
      id: d['id'] || undefined,
      prefix: d['prefix'] && d['prefix'].trim() || '',
      title:  d['title']  && d['title'].trim()  || '',
      status: d['status'] || 'draft',
      attr: d['attr'] as object_t || {
        hardcopies: 0
      },

      project_id: d['project_id'] || null,
      project: d['project'] && this.createProject(d['project']) || undefined,

      revisions: (d['revisions'] || []).map( (r: object_t) => this.createProjectDocumentRevision(r) ),

      action_logs: (d['action_logs'] || []).map( (a: object_t) => this.createProjectDocumentAction(a) ),
      comments: (d['comments'] || []).map( (c: object_t) => this.app.createComment(c) ),

      owner_team: d['owner_team'] || this.default_teams[0] || DEFAULT_CORE_TEAM_NAME,

      uploader_id: last_rev && last_rev['uploader_id'] || this.session.currentUser!.id,
      uploader:    last_rev && last_rev['uploader'] && this.user.create(d['uploader']) || this.session.currentUser,

      reviewer_id: last_rev && last_rev['reviewer_id'] || undefined,
      reviewer:    last_rev && last_rev['reviewer'] && this.user.create(d['reviewer']) || undefined,
      review_date: last_rev && last_rev['review_date'] && this.utils.dateTime_utils.browser(d['review_date']) || undefined,

      approver_id:  last_rev && last_rev['approver_id'] || undefined,
      approver:     last_rev && last_rev['approver'] && this.user.create(d['approver']) || undefined,
      approve_date: last_rev && last_rev['approve_date'] && this.utils.dateTime_utils.browser(d['approve_date']) || undefined,

      // type guard
      is_project_document: true
    };

    proj_doc.revisions.forEach( (r: ProjectDocumentRevision) => {
      r.project_document_id = proj_doc.id || null
      r.project_document = proj_doc;
    });

    return this.server.createTimeStampedModel(proj_doc, d) as ProjectDocument
  }

  public createProjectDocumentAction(d: object_t|null = null): ProjectDocumentAction {
    d = d || {};
    const action: ProjectDocumentAction = {
      id: d['id'] || undefined,
      action: d['action'] || 'uploaded',
      project_doc_id: d['project_doc_id'] || null,
      user_id: d['user_id'] || undefined, // @TODO use current user
      user: d['user'] && this.user.create(d['user']) || undefined, // @TODO use current user
      doc_status: d['doc_status'] || 'draft',
      note: d['note'] || ''
    }
    return this.server.createTimeStampedModel(action, d) as ProjectDocumentAction;
  }

  public extractProjectDrawingInfoFromFileName(filename: string): { system: string, no: string, description: string }|null {
    // note: regex to extract drawing info from file name:
    //
    //   EE 0-03_drawing_description.PDF
    //   ([^\s]+) \s ([^_]+) _ ([^\.]+) \.[A-Za-z0-9]+
    //   <system>    <no.>     <desc>   .???
    const matches = filename.match(/([^\s]+)\s([^_]+)_([^\.]+)\.[A-Za-z0-9]+/);

    if ( ! matches || matches.length != 4 ) {
      console.warn(`Drawing file name is in wrong format - [${filename}]`);
      return null;
    }

    return {
      system: matches[1],
      no: matches[2],
      description: matches[3]
    };
  }

  public createProjectDrawingRevision(d: object_t|null = null): ProjectDrawingRevision {
    d = d || {};
    const projectDrawingRevision: ProjectDrawingRevision = this.createDrawing(d) as ProjectDrawingRevision;

    projectDrawingRevision['revision'] = d['revision'] || 0;
    projectDrawingRevision['hard_copies'] = d['hard_copies'] || 0,
    projectDrawingRevision['remaining_hard_copies'] = d['remaining_hard_copies'] || 0,
    projectDrawingRevision['hard_copies_by_handover'] = (d['hard_copies_by_handover'] || []).map( (h: object_t) => ({
        handover_id: h['handover_id'] || 0,
        hard_copies: h['hard_copies'] || 0,
        handlers: h['handlers'] || ''
      }) ),
    projectDrawingRevision['project_drawing_id'] = d['project_drawing_id'] || null,
    projectDrawingRevision['project_drawing']    = d['project_drawing'] && this.createProjectDrawing(d['project_drawing']) || undefined,
    projectDrawingRevision['drawing_id']         = d['drawing_id'] || null,
    projectDrawingRevision['drawing']            = d['drawing'] && this.createDrawing(d['drawing']) || undefined,
    projectDrawingRevision['reviewer_id']        = d['reviewer_id'] || null;
    projectDrawingRevision['reviewer']           = d['reviewer'] && this.user.create(d['reviewer']) || undefined;
    projectDrawingRevision['review_date']        = d['review_date'] && this.utils.dateTime_utils.browser(d['review_date']) || undefined;
    projectDrawingRevision['approver_id']        = d['approver_id'] || null;
    projectDrawingRevision['approver']           = d['approver'] && this.user.create(d['approver']) || undefined;
    projectDrawingRevision['approve_date']       = d['approve_date'] && this.utils.dateTime_utils.browser(d['approve_date']) || undefined;

    /*
    projectDrawingRevision.pivot = {
      id:          d['pivot'] && d['pivot']['id'] || undefined,
      revision:    d['pivot'] && d['pivot']['revision'] || 0,
      project_drawing_id: d['pivot'] && d['pivot']['project_drawing_id'] || null,
      project_drawing: d['pivot'] && this.createProjectDrawing(d['pivot']['project_drawing']) || undefined,
      uploader_id: d['pivot'] && d['pivot']['uploader_id'] || undefined,
      uploader:    d['pivot'] && d['pivot']['uploader'] && this.user.create(d['pivot']['uploader']) || undefined,
      reviewer_id: d['pivot'] && d['pivot']['reviewer_id'] || undefined,
      reviewer:    d['pivot'] && d['pivot']['reviewer'] && this.user.create(d['pivot']['reviewer']) || undefined,
      approver_id: d['pivot'] && d['pivot']['approver_id'] || undefined,
      approver:    d['pivot'] && d['pivot']['approver'] && this.user.create(d['pivot']['approver']) || undefined
    };
    */
    return projectDrawingRevision;
  }

  public createProjectDrawing(d: object_t|null = null): ProjectDrawing {
    d = d ||{};

    /*
    let last_revision = parseInt(d['last_revision']);
    if ( isNaN(last_revision) ) last_revision = 0;
    */

    const last_rev = d['revisions'] && d['revisions'].length > 0 && d['revisions'][0] || null;

    const dwg: ProjectDrawing = {
      id: d['id'] || undefined,
      status: d['status'] || 'draft',
      stage: d['stage'] || 'conceptual_design',
      type:  d['type']  || 'แบบงานระบบวิศวกรรมประกอบอาคาร',
      drawing_no:  d['drawing_no']  || '',
      system: d['system'] || '',
      title: d['title'] || '',
      description: d['description'] || '',
      attr: d['attr'] as object_t || {
        hardcopies: 0
      },

      project_id: d['project_id'] || null,
      project: d['project'] && this.createProject(d['project']) || undefined,

      // on server, the master is 'masters' according to supplementable trait
      // we mutate it in the client side, according to user's requirement which
      // the master for project drawing has only one, then we mutate from
      // pural to singular to reflect the relation and avoid confusion in other
      // parts of code
      master_ids: d['masters'] && Array.isArray(d['masters']) && d['masters'].map( m => m['id'] ) || [],
      masters: d['masters'] && Array.isArray(d['masters']) && d['masters'].map( m => this.createMasterDrawing(m) ) || [],

      revisions: d['revisions'] && d['revisions'].map( (d: object_t) => this.createProjectDrawingRevision(d) ) || [],

      action_logs: (d['action_logs'] || []).map( (a: object_t) => this.createProjectDocumentAction(a) ),
      comments: (d['comments'] || []).map( (c: object_t) => this.app.createComment(c) ),

      owner_team: d['owner_team'] || this.default_teams[0] || DEFAULT_CORE_TEAM_NAME,

      /*
      last_pivot_id: d['last_pivot_id'] || undefined,
      last_revision: last_revision,

      uploader_id:  d['last_uploader_id'] || this.session.currentUser!.id,
      uploader:     d['last_uploader'] && this.user.create(d['last_uploader']) || this.session.currentUser,

      reviewer_id:  d['last_reviewer_id'] || undefined,
      reviewer:     d['last_reviewer'] && this.user.create(d['last_reviewer']) || undefined,
      review_date:  d['last_review_date'] && this.utils.dateTime_utils.browser(d['last_review_date']) || undefined,

      approver_id:  d['last_approver_id'] || undefined,
      approver:     d['last_approver'] && this.user.create(d['last_approver']) || undefined,
      approve_date: d['last_approve_date'] && this.utils.dateTime_utils.browser(d['last_approve_date']) || undefined,
      */

      uploader_id:  last_rev && last_rev['uploader_id'] || this.session.currentUser!.id,
      uploader:     last_rev && last_rev['uploader'] && this.user.create(last_rev['uploader']) || this.session.currentUser,

      reviewer_id:  last_rev && last_rev['reviewer_id'] || undefined,
      reviewer:     last_rev && last_rev['reviewer']    && this.user.create(last_rev['reviewer']) || undefined,
      review_date:  last_rev && last_rev['review_date'] && this.utils.dateTime_utils.browser(last_rev['review_date']) || undefined,

      approver_id:  last_rev && last_rev['approver_id']  || undefined,
      approver:     last_rev && last_rev['approver']     && this.user.create(last_rev['approver']) || undefined,
      approve_date: last_rev && last_rev['approve_date'] && this.utils.dateTime_utils.browser(last_rev['approve_date']) || undefined,

      // type guard
      is_project_drawing: true
    };

    // ensure that the most updated revision is the first - not necessary?
    dwg.revisions = dwg.revisions.sort( (a: ProjectDrawingRevision, b: ProjectDrawingRevision) => ( (new Date(b.updated_at!)).getTime() - (new Date(a.updated_at!)).getTime() ) );

    return this.server.createTimeStampedModel(dwg, d) as ProjectDrawing;
  }

  public createInternalProjectRole(d: object_t|null = null): InternalProjectRole {
    d = d || {};
    const internal: InternalProjectRole = {
      id: d['id'] || undefined,
      project_role: d['project_role'] || 'Project Team Member',
      team_name: d['team_name'] || this.default_teams[0] || DEFAULT_CORE_TEAM_NAME,
      is_leader: d['is_leader'] || false,
      is_coordinator: d['is_coordinator'] || false,
      project_id: d['project_id'] || undefined,
      project: d['project'] && this.createProject(d['project']) || undefined,
      user_id: d['user_id'] || d['user'] && d['user'].id,
      user: d['user'] || this.user.create(d['user']),
      attr: d['attr'] || {}
    };
    return this.server.createTimeStampedModel(internal, d) as InternalProjectRole
  }

  public createExternalProjectRole(d: object_t|null = null): ExternalProjectRole {
    d = d || {};
    const external: ExternalProjectRole = {
      id: d['id'] || undefined,
      project_role: d['project_role'],
      project_id: d['project_id'] || undefined,
      project: d['project'] && this.createProject(d['project']) || undefined,
      contact_id: d['contact_id'] || d['contact'] && d['contact'].id,
      contact: d['contact'] || this.createExternalContact(d['contact']),
      attr: d['attr'] || {}
    };
    return this.server.createTimeStampedModel(external, d) as ExternalProjectRole;
  }

  public createExternalContact(d: object_t|null = null): ExternalContact {
    d = d || {};
    const contact: ExternalContact = {
      id: d['id'] || undefined,
      fullname:   d['fullname'] || '',
      email:      d['email'] || '',
      job_title:  d['job_title'] || '',
      department: d['department'] || '',
      company:    d['company'] || '',
      tel:        d['tel'] || '',
      attr:       d['attr'] || {}
    };
    return this.server.createTimeStampedModel(contact, d) as ExternalContact;
  }

  public createDistributionList(d: object_t|null = null): ProjectDistributionList {
    d = d || {};
    const list: ProjectDistributionList = {
      id:          d['id'] || undefined,
      title:       d['title'] || 'new distribution list',
      description: d['description'] || 'new distribution list',
      status:      d['status'] || 'draft',

      project_id:  d['project_id'],
      project:     d['project'] && this.createProject(d['project']) || undefined,

      author_id:   d['author_id'] || this.session.currentUser!.id,
      author:      d['author'] && this.user.create(d['author']) || undefined,

      contacts:    d['contacts'] && d['contacts'].map( (d: object_t) => this.createExternalContact(d) ) || [],
      internals:   d['internals'] && d['internals'].map( (d:object_t) => this.createInternalProjectRole(d) ) || [],

      attr:        d['attr'] || {}
    }
    return this.server.createTimeStampedModel(list, d) as ProjectDistributionList;
  }

  public createReleaseBundleRecipient(d: object_t|null = null): ReleaseBundleRecipient {
    d = d || {};

    let expired = d['expired_at'] && (new Date()).getMilliseconds() > Date.parse(d['expired_at']);

    return {
      token: d['token'] || undefined,
      email: d['email'] || 'root@localhost',
      expired_at: d['expired_at'] || this.utils.dateTime_utils.browser(moment().add(7, 'days')),

      sent_at: d['sent_at'] || undefined,
      downloaded_at: d['downloaded_at'] || undefined,
      ip_addr: d['ip_addr'] || undefined,

      attr: d['attr'] || {},

      extra: d['extra'] || false,
      expired: expired
    };
  }

  public createReleaseBundle(d: object_t|null = null): ReleaseBundle {
    d = d || {};
    const bundle: ReleaseBundle = {
      id:         d['id'] || undefined,
      subject:    d['subject']  || 'Document Release',
      recipients: d['recipients'] && d['recipients'].map( (r: object_t) => this.createReleaseBundleRecipient(r) ) || [],
      message:    d['message']  || '',
      official:   d['official'] || false,
      attr:       d['attr'] || {},

      files:      (d['files'] || []).map( (a: object_t) => this.server.createAttachment(a) ),

      project_id: d['project_id'] || 0,
      project:    d['project'] && this.createProject(d['project']) || undefined,

      author_id:  d['author_id'] || this.session.currentUser!.id,
      author:     d['author'] && this.user.create(d['author']) || undefined
    };
    return this.server.createTimeStampedModel(bundle, d) as ReleaseBundle;
  }


  public isProjectDocument(d: ProjectDocument|ProjectDrawing): boolean {
    return !! (d as ProjectDocument).is_project_document;
  }

  public isProjectDrawing(d: ProjectDocument|ProjectDrawing): boolean {
    return !! (d as ProjectDrawing).is_project_drawing;
  }

  public createReleaseBundleTemplate(d: object_t|null = null): ReleaseBundleTemplate {
    d = d || {};
    const bundle: ReleaseBundleTemplate = {
      id:         d['id'] || undefined,
      subject:    d['subject']  || 'Document Release',
      recipients: d['recipients'] && d['recipients'].map( (r: object_t) => this.createReleaseBundleRecipient(r) ) || [],
      message:    d['message']  || '',
      status:     d['status'] || 'draft',
      documents:  (d['documents'] || []).map( (d: object_t) => this.createProjectDocument(d) ),
      drawings:   (d['drawings']  || []).map( (d: object_t) => this.createProjectDrawing(d) ),
      attr:       d['attr'] || {},

      project_id: d['project_id'] || 0,
      project:    d['project'] && this.createProject(d['project']) || undefined,

      author_id:  d['author_id'] || this.session.currentUser!.id,
      author:     d['author'] && this.user.create(d['author']) || undefined
    };
    return this.server.createTimeStampedModel(bundle, d) as ReleaseBundleTemplate;
  }

  // -- project hand over

  public createHandOverProjectDocumentRevision(d: object_t|null = null): HandOverProjectDocumentRevision {
    d = d || {};
    if ( ! d['revision'] ) {
      throw new Error('HandOverProjectDocument require document revision');
    };

    const doc: HandOverProjectDocumentRevision = {
      id: d['id'] || undefined,

      handover_id: d['handover_id'] || null,
      handover: d['handover'] && this.createProjectHandOver(d['handover']) || undefined,

      revision_id: d['revision_id'] || null,
      revision: this.createProjectDocumentRevision(d['revision']),

      accepted: ( !! d['accepted']  ) || false,
      soft_copy: ( !! d['soft_copy'] ) || true,
      hard_copies: d['hard_copies'] || 0,
      comment: d['comment'] || '',
      handlers: d['handlers'] || '',
    };
    return doc;
  }

  public createHandOverProjectDrawingRevision(d: object_t|null = null): HandOverProjectDrawingRevision {
    d = d || {};
    if ( ! d['revision'] ) {
      throw new Error('HandOverProjectDrawing require drawing revision');
    };
    const dwg: HandOverProjectDrawingRevision = {
      id: d['id'] || undefined,

      handover_id: d['handover_id'] || null,
      handover: d['handover'] && this.createProjectHandOver(d['handover']) || undefined,

      revision_id: d['revision_id'] || null,
      revision: this.createProjectDrawingRevision(d['revision']),

      accepted: ( !! d['accepted']  ) || false, // @todo - check the value, convert to boolean
      soft_copy: ( !! d['soft_copy'] ) || true,
      hard_copies: d['hard_copies'] || 0,
      comment: d['comment'] || '',
      handlers: d['handlers'] || '',
    }

    return dwg;
  }

  public createProjectHandOver(d: object_t|null = null): ProjectHandOver {
    d = d || {};
    const handover: ProjectHandOver = {
      id: d['id'] || undefined,
      project_id: d['project_id'] || null,
      project: d['project'] && this.createProject(d['project']) || undefined,
      status: d['status'] || 'draft',
      author_id: d['author_id'] || this.session.currentUser!.id,
      author: d['author'] && this.user.create(d['author']) || this.session.currentUser,

      submitted_at: d['submitted_at'] && this.utils.dateTime_utils.browser(d['submitted_at']) || undefined,
      approved_at: d['approved_at'] && this.utils.dateTime_utils.browser(d['approved_at']) || undefined,

      documents: d['documents'] && d['documents'].map( (d: object_t) => this.createHandOverProjectDocumentRevision(d) ) || [],
      drawings: d['drawings'] && d['drawings'].map( (d: object_t) => this.createHandOverProjectDrawingRevision(d) ) || [],

      comments: d['comments'] && d['comments'].map( (c: object_t) => this.app.createComment(c) ) || [],
    }
    return this.server.createTimeStampedModel(handover) as ProjectHandOver;
  }

  // ---------------------------
  // -- roles and teams utilities

  public get core_team_name(): string {
    return this.default_teams[0] || DEFAULT_CORE_TEAM_NAME;
  }

  public getUserWithRole(project: Project, team_name: string, role: string): InternalProjectRole[] {
    return project.internal_project_roles.filter( r => this.isSameTeam(r.team_name, team_name) && r.project_role == role );
  }

  public user_is(project: Project, team_name: string, role: string, user: AppUser|null = null ): boolean {
    user = user || this.session.currentUser;
    return project.internal_project_roles.filter( r => this.isSameTeam(r.team_name, team_name) && r.project_role == role && r.user_id == user!.id ).length > 0;
  }

  public is_author(project: Project, user: AppUser|null = null): boolean {
    user = user || this.session.currentUser;
    return !! user && project.uploader_id == user.id;
  }

  public is_leader(project: Project, user: AppUser|null = null, team: string = this.default_teams[0] || DEFAULT_CORE_TEAM_NAME): boolean {
    return this.user_is(project, team, 'leader', user)
  }

  public is_coordinator(project: Project, user: AppUser|null = null, team: string = this.default_teams[0] || DEFAULT_CORE_TEAM_NAME): boolean {
    return this.user_is(project, team, 'coordinator', user)
  }

  public is_member(project: Project, user: AppUser|null = null): boolean {
    user = user || this.session.currentUser;
    return !! user && ( project.internal_project_roles.filter( r => r.user_id == user!.id ).length > 0 );
  }

  /**
   * Determine the owner of the project, who have 'full' permissions
   *
   * @param project
   * @param user
   * @returns boolean
   */
  public is_owner(project: Project, user: AppUser|null = null): boolean {
    user = user || this.session.currentUser;

    if ( ! user ) return false;
    if ( this.session.hasPermission(['core_admin']) ) return true;

    const is_author = this.is_author(project, user);
    const is_leader = this.is_leader(project, user);
    const is_coordinator = this.is_coordinator(project, user);

    switch ( project.project_status.toLowerCase() ) {
      case 'draft':
      return is_author || is_leader || is_coordinator;

      case 'opened':
      //return is_leader;
      return is_leader || is_coordinator;

      case 'cancelled':
      return is_leader;

      case 'pending':
      return is_leader;

      case 'closed':
      return is_leader;
    }

    return false;
  }

  public isDefaultTeam(name: string): boolean {
    return config('client.project.options.roles.internal').findIndex( (t: { name: string, roles: any}) => t.name == name ) >= 0;
  }

  public isSameTeam(team1: string, team2: string): boolean {
    if ( ! team1 || ! team2 ) return false; // handle incomplete data - e.g., team_name is null, etc
    return team1 == '*' || team2 == '*' || team1.toLowerCase() == team2.toLowerCase();
  }

  public sortTeamsByTeamName(teams: string[]): string[] {
    return teams.sort( (a, b) => {
      const aIndex = this.default_teams.indexOf(a);
      const bIndex = this.default_teams.indexOf(b);

      if (aIndex !== -1 && bIndex !== -1) {
        // Both teams are in the predefined list, sort by their index
        return aIndex - bIndex;
      }
      else if (aIndex !== -1) {
        // Only team A is in the predefined list, it should come first
        return -1;
      }
      else if (bIndex !== -1) {
        // Only team B is in the predefined list, it should come first
        return 1;
      }
      else {
        // Neither team is in the predefined list, sort them alphabetically
        return a.localeCompare(b);
      }
    });
  }

  public getUserTeams(project: Project, user: AppUser|null = null): string[] {
    user = user || this.session.currentUser;
    return this.sortTeamsByTeamName(project.internal_project_roles.filter( r => r.user_id == user!.id ).map( r => r.team_name ));
  }

  public getAllProjectTeams(project?: Project): InternalRoleConfig[] {
    const roles: string[] = (project && project.internal_project_roles || [] ).map( r => r.team_name);

    this.options['roles'].internal
      .map( (r: InternalRoleConfig) => r.name )
      .forEach( (r: string) => {
        roles.indexOf(r) < 0 && roles.push(r);
      });

    return this.utils.array_utils.unique<string>( this.sortTeamsByTeamName(roles) )
            // reformat the object to InternalRoleConfig
            .map( name => ({
              name: name,
              roles: {
                leader: `${name} Leader`,
                coordinator: `${name} Coordinator`,
                member: `${name} Member`
              }
            })
          );
  }

  public getTeamMembers(internal_project_roles: InternalProjectRole[], team_name: string, role: string = 'all'): InternalProjectRole[] {
    return internal_project_roles.filter( r => {
              return this.isSameTeam(r.team_name, team_name) &&
                (
                  role == 'all' ||
                  ( role == 'leader' && r.is_leader )           ||
                  ( role == 'coordinator' && r.is_coordinator ) ||
                  ( role == 'member' && ! r.is_leader && ! r.is_coordinator )
                )
            });
  }

  /*
  public is_drawing_owner(drawing: ProjectDrawing, user: AppUser|null = null): boolean {
    user = user || this.session.currentUser;

    if ( ! user ) return false;
    if ( this.session.hasPermission(['core_admin']) ) return true;

    switch ( drawing.status ) {
      case 'draft':
      return ( drawing.uploader_id == user.id );

      case 'submitted':
      return ( drawing.reviewer_id == user.id ) || ( drawing.approver_id == user.id );

      case 'reviewed':
      case 'approved':
      return ( drawing.approver_id == user.id );
    }

    return false;
  }

  public is_document_owner(doc: ProjectDocument, user: AppUser|null = null): boolean {
    user = user || this.session.currentUser;

    if ( ! user ) return false;
    if ( this.session.hasPermission(['core_admin']) ) return true;

    const last_rev = doc.revisions[0] || null;

    switch ( doc.status ) {
      case 'draft':
      return ( last_rev && last_rev.uploader_id == user.id );

      case 'submitted':
      return last_rev && ( last_rev.reviewer_id == user.id  || last_rev.approver_id == user.id );

      case 'reviewed':
      case 'approved':
      return ( last_rev && last_rev.approver_id == user.id );
    }

    return false;
  }
  */

  /**
   * all project workflow permission logic come here
   */
  public can(
    actions: ProjectArtifactAction|ProjectArtifactAction[],
    type: 'project'|'document'|'document_revision'|'drawing'|'distribution_list'|'bundle'|'bundle_template',
    project: Project,
    content: ProjectDocument|ProjectDocumentRevision|ProjectDrawing|ProjectDistributionList|ReleaseBundleTemplate|null = null,
    user: AppUser|null = null
  ): boolean {

    user = user || this.session.currentUser;

    if ( ! user ) {
      return false;
    }

    actions = (Array.isArray(actions) ? actions : [actions]);

    const permitted = actions.map( (action) => {
      if ( ! user ) {
        return false;
      }

      if ( type == 'project' ) {
        if ( action == 'save' || action == 'delete' ) {
          return  ! project.deleted_at && ( this.is_owner(project) || this.session.hasPermission(['core_admin', 'project_gm_mecs']) );
        }
        return false;
      }

      if ( type == 'document' ) {

        const projDoc: ProjectDocument = content as ProjectDocument;

        if ( action == 'save' ) {
//          return ( project.project_status == 'opened' && this.is_member(project, user) || this.is_owner(project, user) ) &&
          return (
            project.project_status == 'opened' &&
            (
                this.is_member(project, user) ||
                user.permissions.includes('core_admin')
            ) &&
            (
              ! projDoc || projDoc.status === 'draft'
            )
          );
        }

        // content must be saved before.
        if ( ! projDoc || ! projDoc.id ) {
          return false;
        }

        const lastRev = projDoc.revisions.length > 0 && projDoc.revisions[0] || null;
        if ( ! lastRev ) {
          return false;
        }

        if ( action == 'submit' ) {
          return !! projDoc && projDoc.status == 'draft' && projDoc.revisions.length > 0 && projDoc.revisions[0].attachments.length > 0 &&
            (
              user.permissions.includes('core_admin') ||
              lastRev.uploader_id == user.id ||
              lastRev.reviewer_id == user.id ||
              lastRev.approver_id == user.id
            );
        }

        if ( action == 'review' ) {
          return !! projDoc && projDoc.status == 'submitted' && projDoc.revisions.length > 0 && projDoc.revisions[0].attachments.length > 0 &&
            (
              user.permissions.includes('core_admin') ||
              lastRev.reviewer_id == user.id ||
              lastRev.approver_id == user.id
            );
        }

        if ( action == 'approve' ) {
          return !! projDoc && projDoc.status == 'reviewed' && projDoc.revisions.length > 0 && projDoc.revisions[0].attachments.length > 0 &&
            (
              user.permissions.includes('core_admin') ||
              lastRev.approver_id == user.id
            );

        }

        if ( action == 'delete' ) {
          return !! projDoc && projDoc.status == 'draft' && projDoc.revisions.length <= 1 &&
          (
            user.permissions.includes('core_admin') ||
            lastRev.uploader_id == user.id
          );
        }

        if ( action == 'revision' ) {
          return !! projDoc && projDoc.status == 'approved' &&
          (
            this.is_member(project, user)           ||
            user.permissions.includes('core_admin') ||
            (
              lastRev.uploader_id == user.id ||
              lastRev.reviewer_id == user.id ||
              lastRev.approver_id == user.id
            )
          );
        }

        return false;
      }

      if ( type == 'drawing' ) {

        const projDrawing: ProjectDrawing = content as ProjectDrawing;

        if ( action == 'save' ) {
//          return ( project.project_status == 'opened' && this.is_member(project, user) || this.is_owner(project, user) ) &&
          return (
            project.project_status == 'opened' &&
            (
                this.is_member(project, user) ||
                user.permissions.includes('core_admin')
            ) &&
            (
              projDrawing === null ||
              projDrawing.status === 'draft'
            )
          );
        }

        // content must be saved before.
        if ( content === null || ! content.id ) {
          return false;
        }

        if ( action == 'submit' ) {
          return projDrawing.status == 'draft' &&
            (
              user.permissions.includes('core_admin') ||
              projDrawing.uploader_id == user.id ||
              projDrawing.reviewer_id == user.id ||
              projDrawing.approver_id == user.id
            );
        }

        if ( action == 'review' ) {
          return projDrawing.status == 'submitted' &&
            (
              user.permissions.includes('core_admin') ||
              projDrawing.reviewer_id == user.id ||
              projDrawing.approver_id == user.id
            );
        }

        if ( action == 'approve' ) {
          return projDrawing.status == 'reviewed' &&
            (
              user.permissions.includes('core_admin') ||
              projDrawing.approver_id == user.id
            );

        }

        if ( action == 'delete' ) {
          return projDrawing.status == 'draft' &&
          (
            user.permissions.includes('core_admin') ||
            projDrawing.uploader_id == user.id
          );
        }

        if ( action == 'revision' ) {
          return projDrawing.status == 'approved' &&
          (
            this.is_member(project, user)           ||
            user.permissions.includes('core_admin') ||
            projDrawing.uploader_id == user.id ||
            projDrawing.reviewer_id == user.id ||
            projDrawing.approver_id == user.id
          );
        }

        return false;
      }

      if ( type == 'distribution_list' ) {

        const list = content as ProjectDistributionList;

        if ( !! list ) {
          if ( action === 'save'    ) return !  list.id && list.status == 'draft'    && this.is_owner(project);
          if ( action === 'review'  ) return !! list.id && list.status == 'draft'    && this.is_owner(project);
          if ( action === 'approve' ) return !! list.id && list.status == 'reviewed' && this.is_owner(project);
        }

        if ( action == 'save' ) {
          return this.is_member(project, user) && project.project_status == 'opened' || this.is_owner(project, user);
        }

        return this.is_owner(project, user);
      }

      if ( type == 'bundle' ) {
        if ( action == 'save' ) {
          return this.is_member(project, user) && project.project_status == 'opened' || this.is_owner(project, user);
        }
        return this.is_owner(project, user);
      }

      if ( type == 'bundle_template' ) {
        if ( action == 'save' ) {
          return this.is_member(project, user) && project.project_status == 'opened' || this.is_owner(project, user);
        }
        return this.is_owner(project, user);
      }

      return false;
    });

    return permitted.reduce( (p, c) => (p || c), false );
  }
}
