import moment from "moment-timezone";

import {
  Component,
  ViewChild
} from "@angular/core";

import {
  NgForm
} from '@angular/forms';

import {
  ActivatedRoute,
  Router
} from "@angular/router";

import {
  object_t,
  NavigationService,
  ServerService,
  SessionService
} from '@pinacono/common';

import { UIService } from "@pinacono/ui";

import { BasePageComponent } from 'src/app/classes/base-page.component';

import { LibraryService } from '../library.service';
import { BookInstance } from '../types';

interface AuditResult {
  /** instances those are in place */
  ok?: BookInstance[];
  /** instances those are missing from the shelf */
  missings?: BookInstance[];
  /** instances those should not be on the shelf */
  outstandings?: BookInstance[];
  /** list of all instances on this shelf */
  assets?: BookInstance[];
}

@Component({
  selector: 'library-assets-audit',
  templateUrl: 'audit.html',
  styleUrls: ['audit.scss']
})
export class LibraryAuditPage extends BasePageComponent {
  @ViewChild('mainForm') mainForm!: NgForm;

  // -- models

  public selected = {
    branch: null,
    cabinet: null,
    shelf: null
  }

  public code: string = '';
  public codes: string[] = [];

  public results: AuditResult|null = null;
  public timestamp: Date|null = null;

  // -- initialization
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    public api: LibraryService,
    protected server: ServerService
  ) {
    super(router, activatedRoute);
  }

  // -- template API
  public changeLocation() {}

  public changeCabinet() {}

  public changeShelf() {}

  public codeInputChange(evt: KeyboardEvent) {
    this.code = this.code.trim();
    // to avoid addCode() to trig mouse click on remove button,
    // we need to add 'tabIndex' attribute to the code input field
    // to force the cursor to stay in the input field
    //if ( evt.key == 'Enter' || this.code.length >= 13 ) {
    if ( evt.key == 'Enter' || this.code.length >= 13 ) {
      this.addCode();
    }
  }

  public addCode() {
    if ( this.codes.indexOf(this.code) < 0 ) {
      this.codes.push(this.code);
    }
    this.code = '';
  }

  public async removeCode(evt: MouseEvent, i: number) {
    if ( evt.type == 'click' && evt.x == 0 && evt.y == 0 ) {
      // to fix barcode reader generate fake click event
      // we check this by value x and y are both 0

      // **note: the non-numeric enter is also generate this
      // we can use this key to debug
      console.debug('bouncing!');
      return;
    }
    if ( await this.ui.confirm("Remove code {{ code }}?", { code: this.codes[i]}) ) {
      this.codes.splice(i, 1);
    }
  }

  public async submit() {
    const errors = await this.ui.validateForm(this.mainForm);
    if ( Object.keys(errors).length > 0 ) {
      this.ui.alert("Please enter the missing information.");
      return;
    }

    if ( this.codes.length <= 0 ) {
      this.ui.alert("Please enter code.");
      return;
    }

    let data: object_t = {};

    data['location'] = (! this.selected.branch || this.selected.branch == 'none') ? null : {
      branch: this.selected.branch,
      cabinet: this.selected.cabinet,
      shelf: this.selected.shelf
    }
    data['codes'] = this.codes;
    this.timestamp = new Date();
    this.results = await this.server.post('/library/audit', null, data);
  }

  public print() {
    window.print();
  }

  public reset() {
    this.results = null;
    this.codes = [];
    this.code = '';
  }
}