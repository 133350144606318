import {
  Component,
  OnInit
} from '@angular/core';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  NavigationService,
  object_t,
  ServerService,
  SessionService
} from '@pinacono/common';

import {
  UIService
} from '@pinacono/ui';

import {
  SlickGrid
} from '@slickgrid-universal/common';

import {
  GraphqlFilteringOption,
  GraphqlSortingOption,
  GraphqlPaginatedResult
} from '@slickgrid-universal/graphql';

import {
  ExcelExportService
} from '@slickgrid-universal/excel-export';

import {
  AngularGridInstance,
  Column,
  FieldType,
  Filters,
  GridOption
} from 'angular-slickgrid';

import {
  ButtonsFormatter,
  DataGridButton,
  DatetimeMomentFormatter,
  GraphQLServerService,
  LighthouseService,
  LighthouseServiceOption,
} from '@pinacono/slickgrid-extension';

import { BasePageComponent } from 'src/app/classes/base-page.component';

import {
  Project
} from '../types';

import {
  ProjectLibService
} from '../projects.service';

import { BrowserProfiles } from './profiles';
import { DocLibService } from '../../documents/doclib.service';

@Component({
  selector: 'page-projlib-browse',
  templateUrl: 'browse.html',
  styleUrls: ['browse.scss']
})
export class ProjectLibBrowsePage extends BasePageComponent implements OnInit {

  // data
  public projects: Project[] = [];

  // -- template variables
  public keyword: string|null = null;

  public title: string = 'Project Document Library';
  public enableSearch: boolean = true;
  public trashed: 'WITH'|'WITHOUT'|'ONLY' = 'WITHOUT';

   /**
    * graphQL presets
    */
  protected filters: GraphqlFilteringOption[] = [
    { field: 'menu', operator: 'EQ', value: 'published' }
  ];
  protected sorters: GraphqlSortingOption[] = [
    { field: 'id', direction: 'DESC' }
  ]

  /**
   * columns to be hidden by default
   */
  protected hiddenColumns: string[] = [ 'id', 'uploader', 'project_status', 'created_at' ];

  /**
   * list of button show in grid
   */
  protected avaiableButtons: string[] = [];

  // grid interface
  protected gridComponent: AngularGridInstance|null = null;
  protected selectedRow: number = 0;
  public gridOptions: GridOption|null = null;
  public columnDefinitions: Column[] = [];

  // -- lifecycle

  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
    protected ui: UIService,
    protected api: ProjectLibService,
    protected docapi: DocLibService,
    protected server: ServerService,
    protected graphqlServer: GraphQLServerService,
    protected session: SessionService
  ) {
    super(router, activatedRoute);
  }

  public override ngOnInit(): void {
    const name = this.activatedRoute.snapshot.paramMap.get('name') || 'default';
    const profile = BrowserProfiles[name];

    if ( ! profile ) {
      this.nav.goto('/404');
    }

    this.title           = profile.title;
    this.enableSearch    = profile.enableSearch;
    this.trashed         = profile.trashed || 'WITHOUT';
    this.filters         = profile.filters || this.filters;
    this.sorters         = profile.sorters || this.sorters;
    this.hiddenColumns   = profile.hiddenColumns;
    this.avaiableButtons = profile.avaiableButtons;

    this.initGrid();
    super.ngOnInit();
  }

  // -- overloading
  protected override loadData(): Promise<any> {
    if ( !! this.gridComponent ) {
      this.gridComponent.extensionService.refreshBackendDataset();
    }
    return Promise.resolve();
  }

  // -- grid interfaces

  protected processGraphQLQuery(query: string): Promise<GraphqlPaginatedResult> {
    return new Promise( (resolve) => {
      this.graphqlServer.sendQuery({query: query})
      .then(
        (res: object_t) => {
          // parse response
          let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
          // @TODO - check the structure of nodes below.
          this.projects = re.data['projects'].nodes.map( (p: object_t) => {
            return this.api.createProject(p);
          });
          resolve(re);
        },
        (error: any) => {
          this.ui.alert(error.message, undefined, 'Error!');
          console.error('GrqphQL error', error);
        }
      );
    });
  }

  public onGridReady(event: Event) {
    //grid: AngularGridInstance
    this.gridComponent = (event as CustomEvent).detail as AngularGridInstance;
  }

  protected initGrid() {
    /**
     * user requirements:
     * - Project Code
     * - Project Name
     * - Related Building
     * - Year
     * - Project Status
     *
     * system requirements:
     * - id
     * - action
     * - doc status?
     */
    this.columnDefinitions = [
      {
        id: 'id', name: 'ID',
        field: 'id',
        fields: [
          // other atrributes for condition checking
          'uploader_id',
          'internal_project_roles', 'internal_project_roles.project_role',
          'internal_project_roles.team_name', 'internal_project_roles.is_leader', 'internal_project_roles.is_coordinator',
          'internal_project_roles.user_id', 'internal_project_roles.user', 'internal_project_roles.user.id',
          'deleted_at'
        ],
        type: FieldType.string,
        cssClass: 'text-right', width: 20,
        sortable: true,
        filterable: true,
      },

      {
        id: 'code', name: 'Project Code',
        field: 'documentable.doc_code',
        fields: [ 'documentable', 'documentable.doc_code' ],
        type: FieldType.object,
        cssClass: 'doc-code text-left', minWidth: 200, maxWidth: 220,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: Project, grid: SlickGrid): string => {
          return dataContext.documentable.doc_code || 'n/a';
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: Project, grid: SlickGrid): string  => {
          let label: string = '-n/a-';

          if ( !! dataContext.documentable ) {
            label = dataContext.documentable.doc_code;
          }
          /*
          if ( dataContext.deleted_at ) {
            label += ` <span class="badge badge-default">Deleted</span>`;
          }
          */

          return label;
        },

        sortable: true,
        filterable: true,
      },

      {
        id: 'title', name: 'Title',
        field: 'title',
        fields: [ 'title_prefix', 'title', 'title_suffix' ],
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 150 ,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: SlickGrid): string  => {
          return dataContext.title_prefix + value + dataContext.title_suffix;
        },
        sortable: true,
        filterable: true,
      },

      {
        id: 'dept', name: 'Dept',
        field: 'documentable.doc_code',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 50,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: Project, grid: SlickGrid): string  => {
          return dataContext.documentable.doc_code.split('-')[2] || 'n/a';
        },
        sortable: true,
        filterable: true,
      },

      {
        id: 'locations', name: 'Locations',
        field: 'locations',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 50,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: Project, grid: SlickGrid): string  => {
          return value.join(', ');
        },
        sortable: true,
        filterable: true,
      },

      {
        id: 'schedule', name: 'Year',
        field: 'schedule_start',
        fields: [ 'schedule_start', 'schedule_finish' ],
        type: FieldType.dateIso,
        cssClass: 'text-center', minWidth: 100, maxWidth: 120,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: Project, grid: SlickGrid): string => {
          let s = DatetimeMomentFormatter(row, cell, value || null, columnDef, dataContext, grid);
          return s.toString();
        },
        //formatter: Formatters.dateTimeMoment,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: Project, grid: SlickGrid): string  => {
          let s = DatetimeMomentFormatter(row, cell, value || null, columnDef, dataContext, grid);
          return ( s == 'n/a' ) ? s : ( parseInt(s as string) + 543 ).toString() ;
        },
        params: 'YYYY', // for dateTimeMoment
        sortable: true,
        filterable: true,
        filter: {
          //model: CompoundDateFilter
          model: Filters.compoundDate
        }
      },
      {
        id: 'project_status', name: 'Status',
        field: 'project_status',
        //fields: [ 'project_status', 'is_handing_over' ],
        type: FieldType.string,
        cssClass: 'doc-status text-center', minWidth: 100, maxWidth: 100,
        sortable: true,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: Project, grid: SlickGrid): string => {
          return value as string;
        },
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: Project, grid: SlickGrid): string => {
          const labels: {[name:string]: { label: string, css: string} } = {
            draft:     { label: 'Draft',     css: 'default' },
            pending:   { label: 'Pending',   css: 'info'    },
            opened:    { label: 'Opened',    css: 'success' },
            cancelled: { label: 'Cancelled', css: 'dark'    },
            closed:    { label: 'Closed',    css: 'dark'    }
          }

          /*
          if ( dataContext.is_handing_over ) {
            return '<span class="badge badge-mint">Handing Over</span>';
          }
          */

          const status = dataContext.project_status.toLowerCase();
          if ( dataContext.deleted_at ) {
            return `<span class="badge badge-default">${labels[status].label} (deleted)</span>`;
          }

          return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>`
        },

        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: [
            { value: 'draft',     label: 'Draft'     },
            { value: 'pending',   label: 'Pending'   },
            { value: 'open',      label: 'Open'      },
            { value: 'cancelled', label: 'Cancelled' },
            { value: 'closed',    label: 'Closed'    }
          ]
        }
      },
    ];

    // extract avaialble buttons
    let component = this;
    let buttons: DataGridButton[] = [
      {
        name: 'edit',
        title: 'Edit this document',
        //css: 'btn-primary',
        css: 'btn-default',
        icon: 'pli-pencil',
        visible: function(row: number, cell: number, dataContext: Project, columnDef: Column, grid: SlickGrid): boolean {
          return  ! dataContext.deleted_at && ( component.api.is_owner(dataContext) || component.session.hasPermission(['core_admin', 'project_gm_mecs']) );
        },
        //this.editButtonVisible.bind(this),
        click: (row: number, col: number, dataContext: any, config: DataGridButton, grid: SlickGrid) => {
         const proj = dataContext as Project;
         this.nav.goto('project/edit/' + proj.id);
        }
      },
      /*
      {
        name: 'review',
        title: 'Mark this project as reviewed',
        css: 'btn-pink',
        icon: 'pli-magnifi-glass',
        visible: this.reviewButtonVisible.bind(this),
        click: this.reviewButtonClick.bind(this)
      },

      {
        name: 'approve',
        title: 'Approve this document',
        css: 'btn-success',
        icon: 'pli-megaphone',
        visible: this.approveButtonVisible.bind(this),
        click: this.approveButtonClick.bind(this)
      },
      */
      {
        name: 'delete',
        title: '(Soft) Delete this project',
        //css: 'btn-warning',
        css: 'btn-default',
        icon: 'pli-trash',
        //icon: 'fa-solid fa-trash-can',
        visible: function(row: number, cell: number, dataContext: Project, columnDef: Column, grid: SlickGrid): boolean {
          return ! dataContext.deleted_at && component.api.is_owner(dataContext);
          /*
          (
            component.session.hasPermission(['core_admin']) ||
            dataContext.internal_project_roles
              .filter( r => r.project_role == 'Project Leader')
              .map( r => r.user && r.user.id || 0 )
              .filter( uid => uid == component.session.currentUser!.id )
              .length > 0
          ) &&  ! dataContext.deleted_at;
          */
        },
        click: async (row: number, col: number, dataContext: any, config: DataGridButton, grid: SlickGrid) => {
          if ( await this.ui.confirm('Mark this project as deleted (soft delete)?') ) {
            await this.server.destroy('projects', (dataContext as Project).id!)
            this.refresh();
          }
        }
      },
      {
        name: 'restore',
        title: 'Restore this project',
        css: 'btn-default',
        icon: 'pli-recycling-2',
        //icon: 'fa-solid fa-recycle',
        visible:  function(row: number, cell: number, dataContext: Project, columnDef: Column, grid: SlickGrid): boolean {
          return !! dataContext.deleted_at && component.session.hasPermission(['core_admin']);
        },
        click: (row: number, col: number, dataContext: any, config: DataGridButton, grid: SlickGrid) => {
          this.ui.confirm('Retstore this project?', null, () => {
            this.server.restore('projects', (dataContext as Project).id!)
            .then( () => this.refresh() );
          });
        }
      },

      {
        name: 'trash',
        title: '(Hard) Delete this document',
        css: 'btn-default',
        icon: 'fa-solid fa-bomb',
        visible:  function(row: number, cell: number, dataContext: Project, columnDef: Column, grid: SlickGrid): boolean {
          return !! dataContext.deleted_at && component.session.hasPermission(['core_admin']);
        },
        click: (row: number, col: number, dataContext: any, config: DataGridButton, grid: SlickGrid) => {
          this.ui.confirm('Permanently delete (hard delete) this project?', null, () => {
            this.server.purge('projects', (dataContext as Project).id!)
            .then( () => this.refresh() );
          });
        },
      },
    ]
    .filter( b => !! this.avaiableButtons.find( name => name == b.name ) );

    if ( buttons.length > 0 ) {
      this.columnDefinitions.push({
        id: 'actions', name: 'Actions',
        field: '#action', // start with '#' will be skip by server service
        type: FieldType.unknown,
        cssClass: 'text-left', minWidth: 100, width: 100, maxWidth: 150,
        sortable: false,
        filterable: false,
        formatter: ButtonsFormatter,
        params: {
          buttons: buttons
        }
      });
    }

    this.gridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.columnDefinitions,
          datasetName: 'projects',
          persistenceFilteringOptions: this.filters,
          paginationOptions: {
            first: 20
          },
          extraQueryArguments: [
            { field: 'trashed', value: this.trashed }
          ],
          filteringOptions: [],
          sortingOptions: this.sorters,
        } as LighthouseServiceOption,

        //preProcess: ():void => {},
        process: this.processGraphQLQuery.bind(this),
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      enableExcelExport: true,
      registerExternalResources: [
        new ExcelExportService()
      ],
      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'Project'
      },

      enableSorting: true,

      rowHeight: 45,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#main-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,

      presets: {
        columns: this.columnDefinitions
                  .filter( col => ! this.hiddenColumns.find( id => id == col.id.toString() ) )
                  .map( col => {
                    return { columnId: col.id.toString() };
                  })
      }
    };
  }

  public onSelectRow(event: Event) {
    this.selectedRow = (event as CustomEvent).detail.args['row'];
    const project = this.projects[this.selectedRow];
    if ( this.docapi.is_confidential(project.documentable) ) {
      this.ui.alert('You do not have permission to access this document.');
    }
    else if ( !! project.deleted_at ) {
      this.ui.alert('Project was (soft) deleted. Please restore before view/editing.');
    }
    else {
      this.nav.push(['project/view', project.id ]);
    }
  }

  // -- tempalte API

  /*
  public search(keyword: string|null) {
    if ( keyword === null ) return;
    this.nav.push([ 'project/search', keyword ]);
  }
  */
}
