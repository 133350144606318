import { Component } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { APP_CONFIG } from './app.config';

import {
  User,NavigationService,
  PagesQueueService,
  ServerService,
  SessionService,
} from '@pinacono/common';

import { UIService } from '@pinacono/ui';
import { AppRoutingGuard } from './app-routing.guard';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Engineering Portal 3.0';

  constructor(
    protected server: ServerService,
    protected session: SessionService,
    protected ui: UIService,
    protected router: Router,
    protected nav: NavigationService,
    protected queue: PagesQueueService,
    protected guard: AppRoutingGuard
  ) {
    //this.queue.debug = true;

    // set landing sequence
    this.queue.set(APP_CONFIG.navigation.landing_queue.slice()); // detach to protect modification

    // -- parsing initial URL

    // this.router.url does not return the user requested URL,
    // we have to parse it ourselves
    const url = new URL(document.URL);
    const target = url.hash.substring( url.hash.indexOf('#') + 1 );

    const token = url.searchParams.get('ott');
    if ( token !== null ) {
      console.debug('request with access token = ', token);
      // @TODO implement access token, then bypass landing if token is valid
      this.queue.clear();
    }

    /**
     * exclude pages those have 'login' in the url
     * @todo - better approach to exclude logout?
     * */
    if ( target.indexOf('login') < 0 ) {
      this.queue.push(target);
    }

    /*
    this.guard.onCannotActivate.subscribe( (route:ActivatedRouteSnapshot) => {
      console.debug('Route guard permission denied, page queue = ', this.queue.get());
    });
    */

    /*
    this.server.ACCESS_DENIED.subscribe( () => {
    });
    */

    this.session.initialize()
    .then( () => {
    });

    this.ui.initialize()
    .then( () => {
    });


    // session checking
    this.session.SESSION_INVALID.subscribe( () => {
      if ( ! this.guard.canActivate(this.router.routerState.snapshot.root.children[0], this.router.routerState.snapshot) ) {
        this.nav.setRoot('logout'); // redirect to login with force logout
      }
    });

    this.session.LOGGEDIN.subscribe( (u: User|null) => {
      if ( u === null ) return;
      //console.debug('Start landing sequence!');
      this.queue.start(); // start landings sequence
    });

    this.session.SESSION_VALID.subscribe( (u: User) => {
      this.queue.clear();
      if ( target.indexOf('login') < 0 ) {
        this.queue.push(target);
      }
    });

    this.session.LOGGEDOUT.subscribe( () => {
      this.queue.set(APP_CONFIG.navigation.landing_queue.slice());
      this.nav.setRoot('login');
    });

    this.session.validate();
  }
}
