import {
  Component,
  ViewChild,
  OnInit,
  AfterViewInit
} from '@angular/core';

import {
  Router,
  ActivatedRoute
} from '@angular/router';

import {
  NgForm,
  ValidationErrors
} from '@angular/forms';

import {
  config,
  NavigationService,
  object_t,
  ServerService,
  SessionService,
  UserService,
  UtilsService,
} from '@pinacono/common';

import {
  InterpolatbleErrorMessage,
  LookupEvent,
  LookupItem,
  ModalComponent,
  UIService,
} from '@pinacono/ui';

import {
  SlickGrid
} from '@slickgrid-universal/common';

import {
  GraphqlPaginatedResult
} from '@slickgrid-universal/graphql';

import {
  AngularGridInstance,
  Column,
  FieldType,
  Filters,
  GridOption
} from 'angular-slickgrid';

import {
  ButtonsFormatter,
  DataGridButton,
  DatetimeMomentFormatter,
  GraphQLServerService,
  LighthouseService
} from '@pinacono/slickgrid-extension';

import {
  IIP
} from '@pinacono/iip-viewer';

import { BasePageComponent } from 'src/app/classes/base-page.component';
import { AppUser } from 'src/app/types';

import { DocLibService } from 'src/app/modules/documents/doclib.service';
import { GridFilterOption } from 'src/app/modules/documents/types';

import { ProjectLibService } from '../projects.service';
import {
  MasterDrawing,

  ExternalContact,
  ExternalProjectRole,
  InternalProjectRole,
  InternalRoleConfig,
  Project,
  ProjectDistributionList,
  ProjectDrawing,
  ProjectDocument,
  ProjectHandOver
} from '../types';

//import { TEST_DATA } from 'src/moc-data/projects';

@Component({
  selector: 'page-projlib-edit',
  templateUrl: 'edit.html',
  styleUrls: [ 'edit.scss' ]
})
export class ProjectLibEditPage extends BasePageComponent implements OnInit, AfterViewInit {

  // view child
  @ViewChild('mainForm') mainForm!: NgForm;

  @ViewChild('selectContactDialog') selectContactDialog!: ModalComponent;
  @ViewChild('createContactForm') createContactForm!: NgForm;

  @ViewChild('distributionListForm') distributionListForm!: NgForm;
  @ViewChild('distributionListDialog') distributionListDialog!: ModalComponent;

  @ViewChild('masterDrawingEditor') masterDrawingEditor!: ModalComponent;

  // keyvalue pipe comparator
  public originalOrder(): number { return 0; };

  // data
  public current_user_roles: string[] = [];
  public project: Project;
  public errors: {
    [name: string]: InterpolatbleErrorMessage | InterpolatbleErrorMessage[] | string | string[];
  } | ValidationErrors = {};

  // master drawing
  public iip_base_path: string = '';
  public mastersList: MasterDrawing[] = [];
  public selectedMaster: MasterDrawing;

  // roles
  public teams: InternalRoleConfig[] = [];
  public new_ext_role: ExternalProjectRole;
  public new_team_name: string = '';

  // ----------------------------------------------------
  // -- life cycle
  // ----------------------------------------------------

  // constructor
  constructor(
    public override router: Router,
    public override activatedRoute: ActivatedRoute,
    public nav: NavigationService,
    public session: SessionService,
    public ui: UIService,
    //public validator: PinaconoValidatorsService,
    public api: ProjectLibService,
    public docapi: DocLibService,
    protected server: ServerService,
    protected user: UserService,
    /*
    protected taxonomy: TaxonomyService,
    protected treeUtils: TreeUtilsService,
    protected fileUtils: FileUtilsService,
    protected dateTimeUtils: DateTimeUtilsService,
    */
    protected utils: UtilsService,
    protected graphqlServer: GraphQLServerService,
  ) {
    super(router, activatedRoute);
    this.project  = this.api.createProject();
    this.new_ext_role = this.api.createExternalProjectRole();

    // master searching
    this.selectedMaster = this.api.createMasterDrawing();
    this.iip_base_path  = config('client.drawing.iip_base_path')
  }

  //protected project_documents: ProjectDocument[] = [];
  //protected project_drawings: ProjectDrawing[] = [];
  //protected distribution_lists: ProjectDistributionList[] = [];
  public domains:GridFilterOption[] = [];
  public override async ngOnInit() {
    super.ngOnInit();
    this.external_departments = await this.loadDataList('departments');
    this.external_companies   = await this.loadDataList('companies');
    for (let org in this.docapi.names['doc_code_formatter'].c) {
      const domains = ( this.docapi.names['doc_code_formatter'].c[org] as GridFilterOption[] )
                      .filter( (item, index) => this.domains.findIndex( d => d.value == item.value ) < 0  );
      this.domains = this.domains.concat(domains);
    }
  }

  public ngAfterViewInit(): void {
    this.validate();
  }

  protected with = [
    'documentable',
    'internal_project_roles', 'internal_project_roles.user',
    'external_project_roles', 'external_project_roles.contact',
    'vendor_project_roles',   'vendor_project_roles.contact',
    //'documents', 'drawings',
    'comments'
  ];
  protected appends = [
    'masters'
  ];
  protected override loadData(): Promise<any> {
    this.project = this.api.createProject();

    let id = this.activatedRoute.snapshot.paramMap.get('id');
    if ( id === null ) {
      this.nav.goto('/project/register');
      return Promise.resolve();
    }

    let nid: number = parseInt(id);
    if ( isNaN(nid) ) {
      this.ui.alert(`Project id "${id}" could not be found!`);
      this.nav.back();
      return Promise.resolve();
    }

    // reset form
    this.clearDirty();

    // reset grid
    this.distributionListGridOptions = null;
    this.projectDocumentsHandOverGridOptions = null;

    return this.server
    //.simulate(TEST_DATA.projlib.show)
    .rejectOnError(true)
    .show('projects', nid, {
      with: this.with.join(','),
      appends: this.appends.join(','),
      //withtrashed: true
    })
    .then( (res: object_t) => {
      this.project = this.api.createProject(res);
      if ( this.project.documentable.domain ) {
        this.doc_domain = this.docapi.getDepartmentCode(this.project.documentable.domain.path!);
      }
      this.current_user_roles = this.project.internal_project_roles
                                .filter( r => r.user_id == this.session.currentUser!.id )
                                .map( r => r.project_role );
      this.teams = this.api.getAllProjectTeams(this.project);
      this.initDistributionListGrid();
      this.initProjectDocumentsHandOverGrid();

      if ( this.project.masters.length > 0 ) {
        this.selectedMaster = this.project.masters[0];
      }
    })
    .catch( (e: Error) => {
      this.ui.alert('Error loading Project id {{ id }} {{ error }}', {
        id: id,
        error: e.message
      })
      .then( () => {
        this.back();
      });
    });
  }

  protected loadDataList(name: string): Promise<string[]> {
    return this.server
    .request('projlib.external_contact.data_list', {name: name})
    .then( (res: string[]) => {
      return res;
    });
  }

  // ----------------------------------------------------
  // -- common actions
  // ----------------------------------------------------

  /**
   * return to previous page
   */
   public back() {
    this.nav.pop(false, '/project/browse/mine');
  }

  /**
   * delete this document
   */
  public delete() {
    if ( ! this.project.id ) return;

    this.ui.confirm('Delete this project?', undefined, () => {
      this.server
      //.simulate(1)
      .destroy('projects', this.project.id!)
      .then( () => {
        //this.nav.setRoot('/project/browse', null, null, this.navDone);
        this.back();
      });
    });
  }

  /**
   * restore this document
   */
  public restore() {
    if ( ! this.project.id ) return;
    this.ui.confirm('Restore this project?', undefined, () => {
      this.server
      //.simulate(1)
      .restore('projects', this.project.id!)
      .then( () => {
        //this.nav.setRoot('/project/browse', null, null, this.navDone);
        this.loadData();
       });
    });
  }

  /**
   * permanently remove this document
   */
  public purge() {
    if ( ! this.project.id ) return;
    this.ui.confirm('Permanently delete this project? This will not be able restore anymore!', undefined, () => {
      this.server
      //.simulate(1)
      .purge('projects', this.project.id!)
      .then( () => {
        //this.nav.setRoot('projlib-browse', null, null, this.navDone);
        this.back();
      });
    });
  }

  /**
   * save this document
   */
  public async save() {
    if ( ! this.project.id ) return;

    if ( ! await this.validate() ) {
      const errors = Object.keys(this.errors);
      console.log(errors);
      this.ui.alert('Some value is invalid. Please check!');
      return;
    }
    if ( await this.ui.confirm('Save this project?') ) {
      const project: object_t = Object.assign({}, this.project);
      delete project['masters']; // dont save masters - we already use attach
      await this.server.update('projects', this.project.id!, project);
      this.loadData();
    }
  }

  // ----------------------------------------------------
  // -- validations
  // ----------------------------------------------------

  public markDirty() {
    this.mainForm && this.mainForm.form.markAsDirty();
  }

  public clearDirty() {
    this.mainForm && this.mainForm.form.markAsPristine();
  }


  /**
   * validation logic based on action and document status
   * trig error message or pop error message
   */
  protected async validate(action: 'save'|'open'|'pending'|'cancel'|'close'|'reopen' /*|'handover'*/ = 'save'): Promise<boolean> {
    this.errors = await this.ui.validateForm(this.mainForm);

    if ( action == 'save' ) {
      return Promise.resolve(true); // nothing to validate at the moment
    }

    // additional validations
    if ( ! this.project.documentable.domain_id ) {
      this.errors['domain'] = 'Owner Domain is required';
    }

    return Promise.resolve(Object.keys(this.errors).length == 0);
  }

  public getFullProjectTitle(): () => object_t {
    return (): object_t => {
      return {
        prefix: this.project.title_prefix,
        title:  this.project.title,
        suffix: this.project.title_suffix
      }
    }
  }

  // ----------------------------------------------------
  // -- document flags and control attributes
  // ----------------------------------------------------

  /**
   * check, if project is openable
   */
  public is_openable(deps: unknown): boolean {
    return /* ! this.project.is_handing_over && */ Object.keys(this.errors).length == 0 &&
            this.api.getUserWithRole(this.project, this.api.core_team_name, 'leader').length > 0 &&
            this.project.project_status == 'draft' &&
            ( this.api.user_is(this.project, this.api.core_team_name, 'leader') || this.session.hasPermission(['core_admin']) );
  }

  public is_closable(deps: unknown): boolean {
    return /* ! this.project.is_handing_over && */ Object.keys(this.errors).length == 0 &&
            this.project.project_status == 'opened' &&
            ( this.api.user_is(this.project, this.api.core_team_name, 'leader') || this.session.hasPermission(['core_admin']) );
  }

  public is_pendable(deps: unknown): boolean {
    return /* ! this.project.is_handing_over && */ Object.keys(this.errors).length == 0 &&
            this.project.project_status == 'opened' &&
            ( this.api.user_is(this.project, this.api.core_team_name, 'leader') || this.session.hasPermission(['core_admin']) );
  }

  public is_reopenable(deps: unknown): boolean {
    return /* ! this.project.is_handing_over && */ Object.keys(this.errors).length == 0 &&
            ( this.project.project_status == 'pending' || this.project.project_status == 'cancelled' || this.project.project_status == 'closed' ) &&
            ( this.api.user_is(this.project, this.api.core_team_name, 'leader') || this.session.hasPermission(['core_admin']) );
  }

  public is_cancellable(deps: unknown): boolean {
    return /* ! this.project.is_handing_over && */ Object.keys(this.errors).length == 0 &&
            this.project.project_status == 'opened' &&
            ( this.api.user_is(this.project, this.api.core_team_name, 'leader') || this.session.hasPermission(['core_admin']) );
  }

  public is_handoverable(deps: unknown): boolean {
    return this.project.project_status != 'draft' && (
      this.api.user_is(this.project, this.api.core_team_name, 'leader') ||
      //this.api.user_is(this.project, this.api.core_team_name, 'coordinator') ||
      this.session.hasPermission(['core_admin', 'project_gm_mecs'])
    );
  }

  /**
   * by the current status, is the document editable?
   */
  public is_editable(deps: unknown): boolean {
    return /* ! this.project.is_handing_over && */ (
            this.project.project_status == 'draft' ||
            this.api.user_is(this.project, this.api.core_team_name, 'leader') ||
            this.session.hasPermission(['core_admin'])
    );
  }

  /**
   * check, if the project can be delete
   */
  public is_deletable(deps: unknown): boolean {
    return  /* ! this.project.is_handing_over && */ ! this.project.deleted_at && this.project.project_status == 'cancelled' &&
            ( this.api.user_is(this.project, this.api.core_team_name, 'leader') || this.session.hasPermission(['core_admin']) );
  }

  /**
   * check, if the project can be restored
   */
  public is_restorable(deps: unknown): boolean {
    return  /* ! this.project.is_handing_over && */ !! this.project.deleted_at &&
            ( this.api.user_is(this.project, this.api.core_team_name, 'leader') || this.session.hasPermission(['core_admin']) );
  }

  /**
   * check, if the project is purgable
   */
  public is_purgable(deps: unknown): boolean {
    return this.is_restorable(deps);
  }

  // ----------------------------------------------------
  // -- form actions and event handlers
  // ----------------------------------------------------

  public prototype: IIP.Coordinate[] = Array.from(IIP.DefaultPrototypePolygon);
  public async onDrawingZoom(view: IIP.ViewPort) {
    this.prototype = IIP.DefaultPrototypePolygon.map( (v: IIP.Coordinate) => ({
      x: ( v.x / view.scale.w ) * 0.75,
      y: ( v.y / view.scale.h ) * 0.75
    }));
    console.debug('rescale prototype to', this.prototype);
  }

  public async searchMasterDrawing() {
    const list: object_t[] = await this.api.searchMasters(this.selectedMaster)
    this.mastersList = list.map( d => this.api.createMasterDrawing(d) );
    this.selectedMaster = this.api.createMasterDrawing(); // reset
  }

  public async detachMasterDrawing(master: MasterDrawing) {
    if ( await this.ui.confirm('Detach this project from this master drawing?') ) {
      const i = this.project.masters.findIndex( m => m.id == master.id );
      if ( i >= 0 ) {
        this.project.masters.splice(i, 1);
      }
      await this.server.request('projlib.detach_master', {id: this.project.id, pivot_id: master.pivot_id});
      this.refresh();
    }
  }

  public showMasterDrawing(master: MasterDrawing|null = null) {
    this.selectMasterDrawing(master || this.api.createMasterDrawing());
    this.masterDrawingEditor.show();
  }

  public selectMasterDrawing(dwg: MasterDrawing) {
    this.selectedMaster = dwg;
    console.debug('select new master - pivot =', this.selectedMaster.pivot_polygons);
  }

  public async saveMasterDrawing() {
    const res = await this.server.rejectOnError(true)
    .request('projlib.attach_master', { id: this.project.id }, {
      master_id: this.selectedMaster.id,
      polygons: this.selectedMaster.pivot_polygons.map( p => p.vertices )
    })
    .catch( err => {
      this.ui.alert(`Cannot save location on master drawing, ${err.error.message} (code ${err.error.code})`);
      return;
    });
    if ( !! res ) {
      this.masterDrawingEditor.hide();
      this.refresh();
    }
  }

  public has_location(v: any): boolean {
    return this.project.locations.includes(v as string);
  }

  public doc_domain: string = '';
  public setDomain() {
    // ngx-select bug? domain value not immediately updated
    setTimeout( () => {
      let domain = this.docapi.getDepartmentTerm(this.doc_domain);
      if ( !! domain ) {
        this.project.documentable.domain_id = domain.id || 0;
        this.project.documentable.domain = domain;
      }
    });
  }

  /**
   * Reaction on document code copying
   */
  public onCodeCopied(text: string) {
    this.ui.alert('Document code: {{ code }} is successfully copied to clipboard', { code: text });
  }

  // ----------------------------------------------------
  // -- Section 3: Project Organization
  // ----------------------------------------------------

  public async addNewTeam() {
    if ( this.teams.findIndex( t => t.name == this.new_team_name) >= 0 ) {
      this.ui.alert('Team "{{ name }}" is already existing.', { name: this.new_team_name });
      return;
    }
    if ( await this.ui.confirm('Add new internal team: {{ name }}?', { name: this.new_team_name }) ) {
      this.teams.push({
        name: this.new_team_name,
        roles: {
          'leader':      `${this.new_team_name} Leader`,
          'coordinator': `${this.new_team_name} Coordinator`,
          'member':      `${this.new_team_name} Member`
        }
      });
      this.new_team_name = '';
    }
  }

  public async addTeamMember(team: InternalRoleConfig, role: string, user: AppUser) {
    if ( ! this.project.id ) {
      this.ui.alert(`Please save the project before add ${role}.`);
      return;
    }

    // only one leader and coordinate for each team
    if ( role == 'leader' || role == 'coordinator' ) {
      const current = this.api.getUserWithRole(this.project, team.name, role);
      if ( current.length > 0 ) {
        const current_user:AppUser = current[0].user!;
        if ( ! await this.ui.confirm(`Replace current ${role}, ${current_user.fullname}, with ${user.fullname}?`) ) return;
        const index = this.project.internal_project_roles.findIndex( r => r.team_name == team.name && r.project_role == role && r.user_id == current_user.id );
        if ( index < 0 ) {
          console.warn(`Unexpectedly, unable to find ${current_user.fullname} as ${role} for this project!`);
          return;
        }
        // remove previous one
        await this.server.destroy('projects/roles/internal', current[0].id!)
        this.project.internal_project_roles.splice(index, 1);
      }
    }

    // add only if user is not in the role
    if ( this.project.internal_project_roles.filter( r => r.user_id == user.id && this.api.isSameTeam(r.team_name, team.name) && r.project_role == role ).length > 0 ) {
      return;
    }

    const member = this.api.createInternalProjectRole({
      project_role: role,
      user_id: user.id,
      team_name: team.name,
      is_leader: role == 'leader',
      is_coordinator: role == 'coordinator',
      project_id: this.project.id
    });

    this.server
    .create('projects/roles/internal', member)
    .then( (res: object_t) => {
      res['user'] = user;
      this.project.internal_project_roles.push(this.api.createInternalProjectRole(res));
    });
  }

  public removeMemberWrapper = this.removeMember.bind(this);
  public async removeMember(user: AppUser, member: InternalProjectRole) {
    const index = this.project.internal_project_roles.findIndex( r => r.id == member.id );
    if ( index < 0 ) return;
    if ( await this.ui.confirm(`Remove ${user.fullname} from ${member.project_role}?`) ) {
      try {
        await this.server
        .rejectOnError(true)
        .destroy('projects/roles/internal', member.id!)
        .then( (res: number) => {
          this.project.internal_project_roles.splice(index, 1);
        });
      }
      catch ( e: any ) {
        console.error('exception', e);
        if ( e.info ) {
          this.ui.alert('Server reject the request with message: "{{message}}"', { message: e.info }, 'Error!');
        }
      }
    }
  }

  // ----------------------------------------------------
  // -- section 4: Distribution List
  // ----------------------------------------------------
  protected distribution_lists: ProjectDistributionList[] = [];
  public distributionListColumnDefinitions: Column[] = [];
  public distributionListGridOptions: GridOption|null = null;
  public distributionListGridInstance: AngularGridInstance|null = null;

  protected initDistributionListGrid() {
    const component = this;

    this.distributionListColumnDefinitions = [
      {
        id: 'id', name: 'ID',
        field: 'id',
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 40,
        sortable: true,
        filterable: true,
      },

      {
        id: 'title', name: 'Distribution List Title',
        field: 'title',
        type: FieldType.string,
        cssClass: 'text-left', minWidth: 300,
        sortable: true,
        filterable: true,
      },

      {
        id: 'description', name: 'Description',
        field: 'description',
        type: FieldType.string,
        cssClass: 'text-left'
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'doc-status text-center', minWidth: 100, maxWidth: 100,
        sortable: true,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectDistributionList, grid: SlickGrid): string => {
          const labels: {[name:string]: { label: string, css: string} } = {
            draft:    { label: 'Draft',     css: 'default' },
            reviewed: { label: 'Reviewed',  css: 'warning' },
            approved: { label: 'Approved',  css: 'success' }
          }
          let status = ( dataContext.status && dataContext.status.toLowerCase() ) || 'draft'; // for backward compat
          return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>`
        },

        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: [
            { value: 'draft',     label: 'Draft'     },
            { value: 'reviewed',  label: 'Reviewed'  },
            { value: 'approved',  label: 'Approved'  }
          ]
        }
      },

      {
        id: 'actions', name: 'Actions',
        field: '#action', // start with '#' will be skip by server service
        type: FieldType.unknown,
        cssClass: 'text-left', minWidth: 100, width: 100, maxWidth: 150,
        sortable: false,
        filterable: false,
        formatter: ButtonsFormatter,
        params: {
          buttons: [
            {
              name: 'edit',
              title: 'Edit this list',
              css: 'btn-default',
              icon: 'pli-pencil',
              visible: function(row: number, cell: number, dataContext: ProjectDistributionList, columnDef: Column, grid: SlickGrid): boolean {
                return component.api.can(['save', 'review', 'approve'], 'distribution_list', component.project, dataContext);
              },
              click: async (row: number, col: number, dataContext: ProjectDistributionList, config: DataGridButton, grid: SlickGrid) => {
                await this.editDistributionList(dataContext);
              }
            },
            {
              name: 'remove',
              title: 'Remove this list',
              css: 'btn-warning',
              icon: 'pli-trash',
              visible: function(row: number, cell: number, dataContext: ProjectDistributionList, columnDef: Column, grid: SlickGrid): boolean {
                return component.api.can('delete', 'distribution_list', component.project, dataContext);
              },
              click: async (row: number, col: number, dataContext: ProjectDistributionList, config: DataGridButton, grid: SlickGrid) => {
                if ( ! await this.ui.confirm('Delete this contact list?') || ! dataContext.id ) return;
                await component.server.silent().destroy('projects/distribution_lists', dataContext.id);
                component.distributionListGridInstance && component.distributionListGridInstance.extensionService.refreshBackendDataset();
              }
            }
          ]
        }
      }
    ];

    this.distributionListGridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.distributionListColumnDefinitions,
          datasetName: 'project_distribution_lists',
          persistenceFilteringOptions: [
            { field: 'project_id', operator: 'EQ', value: this.project.id?.toString() || null }
          ],
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: (query: string) => {
          return new Promise( (resolve) => {
            const server = this.graphqlServer;

            server.sendQuery({query: query})
            .then(
              (res: object_t) => {
                // parse response
                let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
                this.distribution_lists = re.data['project_distribution_lists'].nodes.map( (d: object_t) => this.api.createDistributionList(d) );
                resolve(re);
              },
              (error: any) => {
                this.ui.alert(error.message, undefined, 'Error!');
                console.error('GrqphQL error', error);
              }
            );
          });

        },
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'DistributionList'
      },

      enableSorting: true,

      rowHeight: 45,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#distribution-list-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,
    };
  }

  public onDistributionListGridReady(event: Event) {
    this.distributionListGridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public async onDistributionListGridSelectRow(event: Event) {
    const selectedRow: number = (event as CustomEvent).detail.args['row'];
    const list = this.distributionListGridInstance?.dataView.getItemByIdx<ProjectDistributionList>(selectedRow);
    if ( ! list ) {
      console.warn(`Distribution List on row ${selectedRow} could not be found!`);
      return;
    }
    await this.editDistributionList(list);
  }

  public async editDistributionList(list: ProjectDistributionList) {
    this.distributionList = this.api.createDistributionList( await this.server.show('projects/distribution_lists', list.id!, {
      with: 'internals,internals.user,contacts'
    }) );
    this.distributionListDialog.show();
  }

  public addNewDistributionList(): void {
    if ( ! this.project.id ) {
      this.ui.alert('Please save the project before add new distribution list..');
      return;
    }
    this.distributionList = this.api.createDistributionList({ project_id: this.project.id });
    this.distributionListDialog.show();
  }

  public async updateInternalDistributionList(event: Event, member: InternalProjectRole) {
    if ( ! this.distributionList ) {
      console.warn('Active distribution list is null!');
      return;
    }
    if ( (event.target as HTMLInputElement)['checked'] ) {
      this.distributionList.internals.push(member);
      this.distributionList.internals = this.distributionList.internals.filter( (r, i, a) => a.indexOf(r) === i ); // make it unique

      // add member, if the list is already existing in db, otherwise, added after creation below
      if ( this.distributionList.id ) {
        await this.server.silent().request('projlib.distribution_list.add_internal', {
          list_id: this.distributionList.id,
          role_id: member.id
        });
      }

    }
    else {
      const index = this.distributionList.internals.indexOf(member);
      if ( index >= 0 ) {
        this.distributionList.internals.splice(index, 1);
      }

      // remove member, if the list is already existing in db
      if ( this.distributionList.id ) {
        await this.server.silent().request('projlib.distribution_list.remove_internal', {
          list_id: this.distributionList.id,
          role_id: member.id
        });
      }
    }
    if ( this.distributionList.id && this.distributionList.status != 'draft' ) {
      this.distributionList.status = 'draft';
      await this.server.update('projects/distribution_lists', this.distributionList.id, this.distributionList);
      this.distributionListGridInstance?.extensionService.refreshBackendDataset();
    }
  }

  public async updateExternalDistributionList(event: Event, member: ExternalProjectRole) {
    if ( ! this.distributionList ) {
      console.warn('Active distribution list is null!');
      return;
    }
    if ( (event.target as HTMLInputElement)['checked'] ) {
      this.distributionList.contacts.push(member.contact);
      this.distributionList.contacts = this.distributionList.contacts.filter( (r, i, a) => a.indexOf(r) === i ); // make it unique

      // add member, if the list is already existing in db, otherwise, added after creation below
      if ( this.distributionList.id ) {
        await this.server.silent().request('projlib.distribution_list.add_external', {
          list_id: this.distributionList.id,
          contact_id: member.contact_id
        });
      }
    }
    else {
      const index = this.distributionList.contacts.indexOf(member.contact);
      if ( index >= 0 ) {
        this.distributionList.contacts.splice(index, 1);
      }
      // remove member, if the list is already existing in db
      if ( this.distributionList.id ) {
        await this.server.silent().request('projlib.distribution_list.remove_external', {
          list_id: this.distributionList.id,
          contact_id: member.contact_id
        });
      }
    }
    if ( this.distributionList.id && this.distributionList.status != 'draft' ) {
      this.distributionList.status = 'draft';
      await this.server.update('projects/distribution_lists', this.distributionList.id, this.distributionList);
      this.distributionListGridInstance?.extensionService.refreshBackendDataset();
    }
  }

  public distributionList: ProjectDistributionList|null = null;
  public async reviewDistributionList() {
    if ( ! this.distributionList ) {
      console.warn('Active distribution list is null!');
      return;
    }
    if ( ! this.distributionList.id ) {
      console.warn('Active distribution list is not saved yet!');
      return;
    }
    if ( ! await this.ui.confirm('Mark this list as reviewed?') ) {
      return;
    }
    this.distributionList.status = 'reviewed';
    await this.server.update('projects/distribution_lists', this.distributionList.id, this.distributionList);
    this.distributionListGridInstance?.extensionService.refreshBackendDataset();
    this.distributionListDialog.hide();
  }

  public async approveDistributionList() {
    if ( ! this.distributionList ) {
      console.warn('Active distribution list is null!');
      return;
    }
    if ( ! this.distributionList.id ) {
      console.warn('Active distribution list is not saved yet!');
      return;
    }
    if ( ! await this.ui.confirm('Approve this list?') ) {
      return;
    }
    this.distributionList.status = 'approved';
    await this.server.update('projects/distribution_lists', this.distributionList.id, this.distributionList);
    this.distributionListGridInstance?.extensionService.refreshBackendDataset();
    this.distributionListDialog.hide();
  }

  public async saveDistributionList() {
    if ( ! this.project.id ) {
      this.ui.alert('Please save project first.');
      return;
    }
    const errors = await this.ui.validateForm(this.distributionListForm);
    if ( ! this.distributionList || Object.keys(errors).length > 0 ) {
      this.ui.alert('Distribution List information is not completed! Please check.');
      return;
    }

    let res: object_t
    if ( ! this.distributionList.id ) {
      res = await this.server.create('projects/distribution_lists', {
        title: this.distributionList.title,
        description: this.distributionList.description,
        project_id: this.project.id,
        author_id: this.session.currentUser!.id
      });

      // add members
      this.distributionList.internals.forEach( async (r) => {
        await this.server.request('projlib.distribution_list.add_internal', {
          list_id: res['id'],
          role_id: r.id
        });
      });
      this.distributionList.contacts.forEach( async (c) => {
        await this.server.request('projlib.distribution_list.add_external', {
          list_id: res['id'],
          contact_id: c.id
        });
      });

    }
    else {
      res = await this.server.update('projects/distribution_lists', this.distributionList.id, {
        title: this.distributionList.title,
        describe: this.distributionList.description
      });
    }

    // refresh
    this.distributionList = await this.api.createDistributionList(this.server.show('projects/distribution_lists', res['id']));
    this.distributionListDialog.hide();
    this.distributionListGridInstance?.extensionService.refreshBackendDataset();
  }

  public indexOfInternalRole(value: InternalProjectRole, array: InternalProjectRole[]): number {
    return array.findIndex( (v) => v.user_id == value.user_id );
  }

  public indexOfExternalRole(value: ExternalProjectRole, array: ExternalProjectRole[]): number {
    return array.findIndex( (v) => v.contact_id == value.contact_id );
  }

  public indexOfContact(value: ExternalContact, array: ExternalContact[]): number {
    return array.findIndex( (v) => v.id == value.id );
  }

  // -- external contact

  public external_departments: string[] = [];
  public external_companies: string[] = [];
  public contactsList: ExternalContact[] = [];
  public selectedContact: ExternalContact|null = null;
  public contactsLookupItemList: LookupItem<ExternalContact>[] = [];
  public external_is_spw: boolean = false;

  public async saveSelectedExternalContact() {
    const errors = await this.ui.validateForm(this.createContactForm);
    if ( Object.keys(errors).length > 0 ) {
      await this.ui.alert("Role information is not completed. Please recheck.");
      return;
    }

    this.selectContactDialog.hide();

    const contact = this.api.createExternalContact(this.new_ext_role.contact);
    if ( ! contact.id ) {
      if ( ! await this.ui.confirm('Create new contact?') ) {
        return;
      }
      // create new contact

      // set company for SPW/ICON
      if ( this.external_is_spw ) {
        contact.company = 'SPW';
      }

      // create new contact
      const res: object_t = await this.server.rejectOnError().create('projects/contacts', contact);
      this.new_ext_role.contact = this.api.createExternalContact(res);
    }
    else {

      // update current contat
      if ( ! await this.ui.confirm('Save and add this contact to project?') ) {
        return;
      }

      // update contact
      this.new_ext_role.contact = this.api.createExternalContact(await this.server.update('projects/contacts', contact.id, contact));
    }

    // add role to project
    this.new_ext_role = this.api.createExternalProjectRole(await this.server.request('projlib.roles.add', { id: this.project.id },{
      project_id: this.project.id,
      role: this.new_ext_role.project_role,
      contact_id: this.new_ext_role.contact.id
    }));

    // update UI
    const list: ExternalProjectRole[] = this.external_is_spw ? this.project.external_project_roles : this.project.vendor_project_roles;
    const index = list.findIndex( r => r.contact.id == this.new_ext_role.contact.id );
    if ( index < 0 ) {
      list.push(this.new_ext_role);
    }
    else {
      list[index].contact = this.new_ext_role.contact;
    }
  }

  public async updateExternalContact(cid: number, name: string, value: any) {
    const data: object_t = {};
    data[name] = value;
    this.server.update('projects/contacts', cid, data);
  }

  public addNewExternalRole(is_spw: boolean) {
    this.external_is_spw = is_spw;
    this.new_ext_role = this.api.createExternalProjectRole();
    this.selectContactDialog.show();
  }

  public async lookupContacts(keyword: string) {
    keyword = keyword.trim();
    const lookup: object_t = {
      fullname: keyword,
      email: keyword,
      job_title: keyword,
      department: keyword,
      tel: keyword
    };

    if ( ! this.external_is_spw ) {
      lookup['company'] = keyword;
    }

    this.contactsLookupItemList = (await this.server.lookup('projects/contacts', lookup, 5, { is_spw: this.external_is_spw }))
    .map( (item: object_t) => {
      const contact = this.api.createExternalContact(item);
      const label: string = `${contact.fullname} (${contact.email})` + (
        this.external_is_spw ?
        ` - ${contact.job_title || '(- no job title -)'}` :
        ` - ${contact.department || '(- no department -)'}, ${contact.company || '(- no company -)'}`
      );
      return {
        label: label,
        value: contact
      }
    });
  }

  public selectContact(event: LookupEvent<object_t>) {
    this.new_ext_role.contact = this.api.createExternalContact(event.value.value);
  }

  public async removeRole(rid: number, is_spw: boolean) {
    if ( ! await this.ui.confirm('Delete this contact from project?') ) {
      return;
    }

    const res = await this.server.rejectOnError(true).request('projlib.roles.remove', { id: this.project.id, role_id: rid });

    if ( is_spw ) {
      this.project.external_project_roles = this.project.external_project_roles.filter( r => r.id != rid );
    }
    else {
      this.project.vendor_project_roles = this.project.vendor_project_roles.filter( r => r.id != rid );
    }
  }

  public async updateVendorRole(role: ExternalProjectRole) {
    this.server.request('projlib.roles.update', { id: this.project.id, role_id: role.id }, {
      project_role: role.project_role
    });
  }

  /** project document and project drawings were removed after v.3.3.3 */

  // ----------------------------------------------------
  // -- section 5: Project Documents Hand Over
  // ----------------------------------------------------

  public projectDocumentsHandOverColumnDefinitions: Column[] = [];
  public projectDocumentsHandOverGridOptions: GridOption|null = null;
  public projectDocumentsHandOverGridInstance: AngularGridInstance|null = null;

  protected initProjectDocumentsHandOverGrid() {

    const component = this;

    this.projectDocumentsHandOverColumnDefinitions = [
      {
        id: 'row', name: 'No.',
        field: 'id',
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 60,
        sortable: true,
        filterable: true,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectHandOver, grid: SlickGrid): string => {
          return (row + 1).toString();
        },
      },

      {
        id: 'id', name: 'ID',
        field: 'id',
        type: FieldType.string,
        cssClass: 'text-right', minWidth: 40, maxWidth: 60,
        sortable: true,
        filterable: true,
      },

      {
        id: 'created_at', name: 'Create Date',
        field: 'created_at',
        type: FieldType.dateIso,
        cssClass: 'text-center', minWidth: 100,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectHandOver, grid: SlickGrid): string => {
          return DatetimeMomentFormatter(row, cell, dataContext.created_at || null, columnDef, dataContext, grid).toString();
        },
        //formatter: Formatters.dateTimeMoment,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectHandOver, grid: SlickGrid): string  => {
          return DatetimeMomentFormatter(row, cell, dataContext.created_at || null, columnDef, dataContext, grid).toString();
        },
        params: 'D MMM YYYY', // for dateTimeMoment
        sortable: true,
        filterable: true,
        filter: {
          //model: CompoundDateFilter
          model: Filters.compoundDate
        }
      },

      {
        id: 'approved_at', name: 'Approve Date',
        field: 'approved_at',
        type: FieldType.dateIso,
        cssClass: 'text-center', minWidth: 100,
        exportCustomFormatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectHandOver, grid: SlickGrid): string => {
          let s = DatetimeMomentFormatter(row, cell, dataContext.approved_at || null, columnDef, dataContext, grid);
          return s.toString();
        },
        //formatter: Formatters.dateTimeMoment,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectHandOver, grid: SlickGrid): string  => {
          let s = DatetimeMomentFormatter(row, cell, dataContext.approved_at || null, columnDef, dataContext, grid);
          return s.toString();
        },
        params: 'D MMM YYYY', // for dateTimeMoment
        sortable: true,
        filterable: true,
        filter: {
          //model: CompoundDateFilter
          model: Filters.compoundDate
        }
      },

      {
        id: 'status', name: 'Status',
        field: 'status',
        type: FieldType.string,
        cssClass: 'doc-status text-center',
        sortable: true,
        formatter: (row: number, cell: number, value: any, columnDef: Column, dataContext: ProjectHandOver, grid: SlickGrid): string => {
          const labels: {[name:string]: { label: string, css: string} } = {
            draft:     { label: 'Draft',      css: 'default' },
            submitted: { label: 'Submitted',  css: 'mint'    },
            approved:  { label: 'Approved',   css: 'warning' },
            closed:    { label: 'Closed',     css: 'success' }
          }
          let status = ( dataContext.status && dataContext.status.toLowerCase() ) || 'draft'; // for backward compat
          return `<span class="badge badge-${labels[status].css}">${labels[status].label}</span>`
        },

        filterable: true,
        filter: {
          //model: SingleSelectFilter,
          model: Filters.singleSelect,
          collectionOptions: {
            addBlankEntry: true
          },
          collection: [
            { value: 'draft',     label: 'Draft'     },
            { value: 'reviewed',  label: 'Reviewed'  },
            { value: 'approved',  label: 'Approved'  }
          ]
        }
      },

      {
        id: 'actions', name: 'Actions',
        field: '#action', // start with '#' will be skip by server service
        type: FieldType.unknown,
        cssClass: 'text-left', minWidth: 100, width: 100, maxWidth: 150,
        sortable: false,
        filterable: false,
        formatter: ButtonsFormatter,
        params: {
          buttons: [
            {
              name: 'remove',
              title: 'Remove this handover',
              css: 'btn-warning',
              icon: 'pli-trash',
              visible: function(row: number, cell: number, dataContext: ProjectHandOver, columnDef: Column, grid: SlickGrid): boolean {
                return dataContext.status == 'draft';
              },
              click: async (row: number, col: number, dataContext: ProjectHandOver, config: DataGridButton, grid: SlickGrid) => {
                if ( ! await this.ui.confirm('Delete this handover?') || ! dataContext.id ) return;
                await component.server.destroy('project_handovers', dataContext.id);
                component.projectDocumentsHandOverGridInstance && component.projectDocumentsHandOverGridInstance.extensionService.refreshBackendDataset();
              }
            }
          ]
        }
      }
    ];

    this.projectDocumentsHandOverGridOptions = {
      backendServiceApi: {
        service: new LighthouseService(),
        options: {
          columnDefinitions: this.projectDocumentsHandOverColumnDefinitions,
          datasetName: 'project_handovers',
          persistenceFilteringOptions: [
            { field: 'project_id', operator: 'EQ', value: this.project.id?.toString() || null }
          ],
          sorters: [
            { columnId: 'id', direction: 'ASC' }
          ],
          paginationOptions: {
            first: 20
          }
        },

        //preProcess: ():void => {},
        process: (query: string) => {
          return new Promise( (resolve) => {
            this.graphqlServer
            //.simulate(TEST_DATA.projlib.browse.docs) // @TODO - remove this block
            .silent()
            .sendQuery({query: query})
            .then(
              (res: object_t) => {
                // parse response
                let re: GraphqlPaginatedResult = LighthouseService.parseResponse(res);
                //this.project_documents = re.data['project_handovers'].nodes.map( (d: object_t) => this.api.createProjectDocument(d) );
                resolve(re);
              },
              (error: any) => {
                this.ui.alert(error.message, undefined, 'Error!');
                console.error('GrqphQL error', error);
              }
            );
          });

        },
        //postProcess?: (response: GraphqlResult | any) => void;
      },

      excelExportOptions: {
        exportWithFormatter: true,
        filename: 'Project'
      },

      enableSorting: true,

      //rowHeight: 45,
      enableAutoResize: true,
      autoHeight: true,
      autoResize: {
        container: '#project-doc-table',
        applyResizeToContainer: true,
        calculateAvailableSizeBy: 'window',
        bottomPadding: 85,
        minHeight: 300,
        minWidth: 300,
        rightPadding: 0
      },
      //forceFitColumns: true,
      alwaysShowVerticalScroll: false,

      pagination: {
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        totalItems: 0
      },
      enableFiltering: true,
      enableAsyncPostRender: true,

      presets: {
        columns: [
          { columnId: 'row' },
          { columnId: 'created_at' },
          { columnId: 'approved_at' },
          { columnId: 'status' },
          { columnId: 'actions' }
        ],
        sorters: [
          { columnId: 'id', direction: 'ASC' }
        ],
      }
    };
  }

  public onProjectDocumentsHandOverGridReady(event: Event) {
    this.projectDocumentsHandOverGridInstance = (event as CustomEvent).detail as AngularGridInstance;
  }

  public onProjectDocumentsHandOverGridSelectRow(event: Event) {
    const selectedRow: number = (event as CustomEvent).detail.args['row'];
    const doc = this.projectDocumentsHandOverGridInstance?.dataView.getItemByIdx<ProjectDocument>(selectedRow);
    if ( ! doc ) {
      console.warn(`Project hand over on row ${selectedRow} could not be found!`);
      return;
    }
    this.nav.push(`/project/handover/${doc.id}`);
  }

  public addNewProjectDocumentsHandOver(): void {
    if ( ! this.project.id ) {
      this.ui.alert('Please save the project before create new document hand over.');
      return;
    }
    this.nav.push({
      commands: ['/project/handover/new'],
      extras: {
        queryParams: {
          project_id: this.project.id
        }
      }
    });
  }

  // ----------------------------------------------------
  // -- Workflow action
  // ----------------------------------------------------
  public async action(action: 'open'|'pending'|'cancel'|'close'|'reopen'/*|'handover'*/) {
    if ( ! this.project.id ) {
      console.warn('Project ID is not available. Cannot perform workflow action.');
      return;
    }
    if ( ! await this.validate(action) ) {
      this.ui.alert(`Cannot ${action} the project. Information is not completed. Please check.`);
      return;
    }
    if ( ! await this.ui.confirm(`Please confirm to ${action} the project?`) ) {
      return;
    }

    // save project first, if edited
    if ( this.mainForm && this.mainForm.dirty ) {
      await this.server.update('projects', this.project.id, Object.assign({}, this.project));
    }

    try {
      await this.server.rejectOnError()
      .request('projlib.action', {
        id: this.project.id,
        action: action
      });
    }
    catch ( error ) {
      console.error(`Cannot perform action ${action} - `, error);
    }
    this.refresh();
  }
}
