import { APP_CONFIG } from 'src/app/app.config';
import { Injectable, Component } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, Subject } from "rxjs";

import {
  User,
  NavigationService,
  SessionService,
  PagesQueueService
} from '@pinacono/common';

import { GuardCondition } from "./types";

@Injectable()
export class AppRoutingGuard implements CanActivate, CanDeactivate<Component> {

  public onCanActivate: Subject<ActivatedRouteSnapshot> = new Subject();
  public onCannotActivate: Subject<ActivatedRouteSnapshot> = new Subject();
  public onCanDeactivate: Subject<ActivatedRouteSnapshot> = new Subject();
  public onCannotDeactivate: Subject<ActivatedRouteSnapshot> = new Subject();

  constructor(
    protected session: SessionService,
    protected queue: PagesQueueService,
    protected router: Router,
    protected nav: NavigationService
  ) {
    /*
    router.events.subscribe((event) => {
      console.log('router event', event);
    });
    */
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean|UrlTree> | Promise<boolean|UrlTree> {
    //console.debug(`canActivate guard conditions checking on url [${route.url}]`);

    const conditions = route.data && route.data['canActivate'] && route.data['canActivate']['conditions'] || true;

    if ( conditions === true ) {
      this.onCanActivate.next(route);
      return true;
    }

    if ( conditions === false ) {
      this.onCannotActivate.next(route);
      //console.debug('- guard condition is empty - denined by default. (shall we?)')
      return false; // deny by default
    }

    const res = this.checkConditions(conditions as GuardCondition, route, state);
    //console.debug(`- guard condition check result = ${res}`, conditions);
    if ( res ) {
      this.onCanActivate.next(route);
      return true;
    }

    if ( route.data['canActivate']['onFailed'] ) {
      this.onCanActivate.next(route);
      //console.debug(`- redirect to [${route.data['canActivate']['onFailed']}]`);
      return this.router.parseUrl(route.data['canActivate']['onFailed']);
    }

    this.onCannotActivate.next(route);

    if ( ! APP_CONFIG.navigation.logout_on_403 ) {
      //console.debug('- canActivate guard check failed, show 403');
      return this.router.parseUrl('403');
    }
    //console.debug('- canActivate guard check failed, logout');
    return this.router.parseUrl('logout');
  }

  public canDeactivate(
    component: Component,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean|UrlTree> | Promise<boolean|UrlTree> {
    //console.debug(`canDeactivate guard conditions checking on exit from url [${currentRoute.url}]`);

    let conditions = currentRoute.data['canDeactivate']['conditions'] || false;

    if ( conditions === false ) {
      this.onCannotDeactivate.next(currentRoute);
      //console.debug('- guard condition is empty - denined by default. (shall we?)')
      return false; // deny by default
    }

    let res = this.checkConditions(conditions as GuardCondition, currentRoute, currentState, component, nextState);
    //console.debug(`- guard condition check result = ${res}`, conditions);
    if ( res ) {
      this.onCanDeactivate.next(currentRoute);
      return true;
    }

    this.onCannotDeactivate.next(currentRoute);

    if ( currentRoute.data['canDeactivate']['onFailed'] ) {
      //console.debug(`- redirect to [${currentRoute.data['canDeactivate']['onFailed']}]`);
      return this.router.parseUrl(currentRoute.data['canDeactivate']['onFailed']);
    }
    else {
      if ( ! APP_CONFIG.navigation.logout_on_403 ) {
        //console.debug('- canDeactivate guard check failed, show 403');
        return this.router.parseUrl('403');
      }
      return this.router.parseUrl('logout');
    }
  }

  protected checkConditions(conditions: GuardCondition, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, component?: Component, nextState?: RouterStateSnapshot): boolean {

    // -- logical operations

    if ( conditions.and ) {
      return conditions.and.reduce( (prev: boolean, current: GuardCondition): boolean => { return prev && this.checkConditions(current, route, state, component, nextState); }, true );
    }

    if ( conditions.or ) {
      return conditions.or.reduce( (prev: boolean, current: GuardCondition) => { return prev || this.checkConditions(current, route, state, component, nextState); }, false );
    }

    if ( conditions.not ) {
      return ! this.checkConditions(conditions.not, route, state, component, nextState);
    }

    // -- functions

    if ( conditions.isLoggedIn ) {
      return this.session.isLoggedIn ? conditions.isLoggedIn : false;
    }

    if ( conditions.isNotLoggedIn ) {
      return this.session.isLoggedIn ? false :  conditions.isNotLoggedIn;
    }

    if ( conditions.hasPermissions ) {
      return this.session.hasPermission(conditions.hasPermissions);
    }

    if ( conditions.hasGroupPermissions ) {
      for ( let p in conditions.hasGroupPermissions ) {
        if ( this.session.hasPermission([p], conditions.hasGroupPermissions[p]) ) {
          return true;
        }
      }
    }

    if ( conditions.hasMaximoUser ) {
      return ( this.session.currentUser && this.session.currentUser['maximo_user'] ) ? conditions.hasMaximoUser : false;
    }

    return false; // deny by default
  }
}