<full-page [title]="document.title || 'New Document'">
  <div page-content>
    <panel class="proj-lib-document">
      <div panel-body class="fluid">

        <h4 class="pad-btm bord-btm">
          <i class="pli-file icon-fw v-middle"></i>
          <span class="text-semibold">
            {{ document.prefix }} {{ document.title || 'New Document' }}
          </span>
        </h4>

        <form #mainForm="ngForm">

          <!-- section 1: Document information-->
          <div class="panel panel-bordered panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Document Information' | translate }}
              </h4>
            </div>

            <div class="panel-body" id="doc-info">
              <div class="row">
                <div class="col-8">
                  <label class="form-label">{{ 'Document Title' | translate }}</label>
                  <div class="row">
                    <div class="col">
                      <select #doc_prefix="ngModel"
                        class="form-control"
                        title="{{ 'Document Prefix' | translate }}"
                        name="document-prefix"
                        required
                        [attr.disabled]="document.status != 'draft' || document.revisions.length > 1 ? 'disabled' : null"
                        (change)="markDirty()"
                        [(ngModel)]="document.prefix"
                      >
                        <option *ngFor="let item of api.options['doc_prefix'] | keyvalue:originalOrder"
                          value="{{ item.key }}"
                          title="{{ item.value }}"
                          [attr.selected]="document.prefix == item.key ? '1' : null"
                        >
                          {{ item.key }}
                        </option>
                      </select>
                      <validation-errors
                        [models]="doc_prefix"
                        [messages]="{
                          required: 'project document prefix is required'
                        }"
                        [dirty]="true"
                      ></validation-errors>
                    </div>
                    <div class="col">
                      <input type="text" class="form-control"
                        id="title" name="title"
                        required
                        [unique]="{
                          id: document.id,
                          min: 5,
                          url: 'projlib.proj_doc.validation.title.unique',
                          params: getFullProjectDocumentTitle()
                        }"
                        [attr.disabled]="document.status != 'draft' || document.revisions.length > 1 ? 'disabled' : null"
                        [(ngModel)]="document.title"
                        #doc_title="ngModel"
                      >
                      <validation-errors
                        [models]="doc_title"
                        [messages]="{
                          required: 'Project document title is required',
                          unique: 'Project document title must be unique'
                        }"
                        [dirty]="true"
                      ></validation-errors>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group">
                      <label class="form-label">{{ 'Team' | translate }}</label>
                      <select #doc_team="ngModel"
                        class="form-control"
                        name="doc_team"
                        required
                        [attr.disabled]="document.status != 'draft' || document.revisions.length > 1 ? 'disabled' : null"
                        (change)="docTeamChanged()"
                        [(ngModel)]="document_team"
                      >
                        <option *ngFor="let item of teamOptions"
                          value="{{ item.key }}"
                          [attr.selected]="document_team == item.key ? '1' : null"
                        >
                          {{ item.value }}
                        </option>
                      </select>
                    </div>
                    <!--
                    <div class="row" id="approvers">
                      <div class="col">
                        <label class="form-label">{{ 'Reviewer' | translate }}</label>
                        <user-card *ngIf="reviewer"
                         [user]="reviewer"
                        ></user-card>
                        <div *ngIf="! reviewer" class="text-thin text-center text-danger">
                          - {{ 'Reviewer is not assigned' | translate }} -
                        </div>
                      </div>
                      <div class="col">
                        <label class="form-label">{{ 'Approver' | translate }}</label>
                        <user-card *ngIf="approver"
                         [user]="approver"
                        ></user-card>
                        <div *ngIf="! approver" class="text-thin text-center text-danger">
                          - {{ 'Approver is not assigned' | translate }} -
                        </div>
                      </div>
                    </div>
                    -->
                  </div>
                </div>
                <div class="col-4">
                  <!--
                  <div *ngIf="!!document.attr" class="form-group">
                    <label class="form-label">{{ 'Hard Copies' | translate }}</label>
                    <input
                      type="number"
                      id="hard-copies"
                      name="hard-copies"
                      class="form-control"
                      min=0
                      [attr.disabled]="document.status != 'draft' || document.revisions.length > 1 ? 'disabled' : null"
                      [(ngModel)]="document.attr['hardcopies']"
                    >
                  </div>
                  -->
                  <div class="form-group">
                    <label class="form-label">{{ 'Status' | translate }}</label>
                    <input type="text" id="status" name="status" class="form-control" disabled [(ngModel)]="document.status">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end of section 1: Document Information -->

          <!-- section 2: Revisions -->
          <div class="panel panel-bordered panel-default">

            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Revision(s)' | translate }}
              </h4>
              <div class="panel-control">
                <button *ngIf="document.status == 'approved'"
                  type="button"
                  class="btn btn-icon btn-info"
                  title="{{ 'Add new revision' | translate }}"
                  (click)="addNewRevision()"
                >
                  <i class="pli-add"></i>
                </button>
              </div>
            </div>

            <div class="panel-body" id="files-list">
              <div class="row">
                <div class="col-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-center">{{ 'Revision' | translate }}</th>
                        <th class="text-center">{{ 'Hardcopies' | translate }}</th>
                        <th class="text-center">{{ 'Contents' | translate }}</th>
                        <th class="text-center">{{ 'Actors' | translate }}</th>
                        <th class="text-center">{{ 'Date' | translate }}</th>
                        <th class="text-center"><!-- for trashcan --></th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let rev of document.revisions; let i = index">
                        <tr>
                          <td class="text-center">{{ rev.revision }}</td>
                          <td class="text-right" title="remaining/total">
                            <span *ngIf="! ( i == 0 && document.status == 'draft' )">
                              {{ rev.remaining_hard_copies }}/{{ rev.hard_copies }}
                            </span>
                            <div *ngIf="i == 0 && document.status == 'draft'" id="hard-copies" class="form-group">
                              <input type="number" name="hard-copies" class="form-control" min=0 [(ngModel)]="rev.hard_copies" #hard_copies="ngModel">
                            </div>

                          </td>
                          <td class="revision-content">
                            <!-- latest revision -->
                            <ng-container *ngIf="i == 0 && document.status == 'draft'">
                              <attachments #attachments class="col-12"
                                [(files)]="rev.attachments"
                                [master-content-type]="'projects/documents/revisions'"
                                [master-id]="rev.id"
                                [defer-key]="rev.attachment_defer_key"
                                (filesChange)="onFilesChange()"
                                (attached)="onFileAttached($event)"
                                (detached)="onFileDetached($event)"
                              >
                              </attachments>
                            </ng-container>

                            <!-- older approved revisions -->
                            <div *ngIf="i > 0 || document.status != 'draft'" class="col">
                              <download *ngFor="let file of rev.attachments" [file]="file" #downloader>
                                <ng-template let-f="file" let-icon="icon">
                                  <div class="d-flex">
                                    <button class="template-btn me-2" title="click to download {{ f.file_name}}" (click)="downloader.download()">
                                      <img *ngIf="isImage(f.content_type)"
                                        class=" file-thumbnail"
                                        title="{{ f.caption || f.file_name }}"
                                        src="{{ f.thumb_url || f.path_url || f.data_url || null }}"
                                      >
                                      <i *ngIf="! isImage(f.content_type)"
                                        class="file-thumbnail fa-5x fa-fw {{ icon }}"
                                      ></i>
                                    </button>
                                    <div class="flex-grow-1">
                                      <div class="text-bold file-name" title="{{ f.file_name }}">
                                        {{ f.file_name }}
                                      </div>
                                      <div class="text-thin file-attr">
                                        <span>{{ f.created_at | datetime }}</span>
                                        <span>{{ f.file_size | memsize:'m' | number:'1.2-2' }} MB</span>
                                      </div>
                                      <div class="caption">
                                        {{ f.caption }}
                                      </div>
                                    </div>
                                  </div>
                                </ng-template>
                              </download>
                              <div *ngIf="rev.attachments.length == 0 " class="col-12 text-center text-thin text-danger">
                                - No attachments available -
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <label class="form-label text-bold">{{ 'Uploader' | translate }}</label>
                              <!--
                              <user-card *ngIf="rev.uploader" [user]="rev.uploader"></user-card>
                              -->
                              <div *ngIf="!! rev.uploader" class="text-thin">{{ rev.uploader.fullname }}</div>
                              <div *ngIf="! rev.uploader" class="text-thin text-center text-danger">
                                - {{ 'Uploader is not assigned' | translate }} -
                              </div>
                            </div>

                            <div>
                              <label class="form-label text-bold">{{ 'Reviewer' | translate }}</label>
                              <!--
                              <user-card *ngIf="rev.reviewer" [user]="rev.reviewer"></user-card>
                              -->
                              <div *ngIf="!! rev.reviewer" class="text-thin">{{ rev.reviewer.fullname }}</div>
                              <div *ngIf="! rev.reviewer" class="text-thin text-center text-danger">
                                - {{ 'Reviewer is not assigned' | translate }} -
                              </div>
                            </div>

                            <div>
                              <label class="form-label text-bold">{{ 'Approver' | translate }}</label>
                              <!--
                              <user-card *ngIf="rev.approver" [user]="rev.approver"></user-card>
                              -->
                              <div *ngIf="!! rev.approver" class="text-thin">{{ rev.approver.fullname }}</div>
                              <div *ngIf="! rev.approver" class="text-thin text-center text-danger">
                                - {{ 'Approver is not assigned' | translate }} -
                              </div>
                            </div>
                          </td>
                          <td class="revision-timestamp">
                            <div>
                              <label class="form-label text-bold">{{ 'Created' | translate }}</label>
                              <div>{{ rev.created_at | datetime }}</div>
                            </div>
                            <div>
                              <label class="form-label text-bold">{{ 'Reviewed' | translate }}</label>
                              <div>{{ rev.review_date && ( rev.review_date | datetime ) || 'n/a' }}</div>
                            </div>
                            <div>
                              <label class="form-label text-bold">{{ 'Approved' | translate }}</label>
                              <div>{{ rev.approve_date && ( rev.approve_date | datetime ) || 'n/a' }}</div>
                            </div>
                          </td>
                          <td class="text-left">
                            <button *ngIf="i == 0 && document.status == 'draft' && document.revisions.length > 1"
                              title="{{ 'Delete this draft revision' | translate }}"
                              class="btn btn-default btn-icon"
                              (click)="deleteRevision(rev)"
                            >
                              <i class="fa-solid fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- end of section 2: Revisions -->

          <!-- section 3: Action History -->
          <div class="panel panel-bordered panel-default">

            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Action Hostory' | translate }}
              </h4>
            </div>

            <div class="panel-body" id="action-history">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ 'Date' | translate }}</th>
                    <th>{{ 'Action' | translate }}</th>
                    <th>{{ 'by' | translate }}</th>
                    <th>{{ 'Status' | translate }}</th>
                    <th>{{ 'Note' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="document.action_logs.length == 0">
                    <td colspan="5" class="text-center text-thin">- No actions log available -</td>
                  </tr>
                  <tr *ngFor="let action of document.action_logs">
                    <td>{{ action.created_at | datetime }}</td>
                    <td>{{ action.action }}</td>
                    <td>{{ action.user && action.user.fullname || '- unknown -' }}</td>
                    <td>{{ action.doc_status }}</td>
                    <td>{{ action.note }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- End of section 3: Action History -->

          <!-- section 4: Comments -->
          <div class="panel panel-bordered panel-default">

            <div class="panel-heading">
              <h4 class="panel-title">
                {{ 'Comments' | translate }}
              </h4>
            </div>

            <div class="panel-body" id="action-history">
              <comments
                [content_type]="'project_document'"
                [content_id]="document.id!"
              ></comments>
            </div>
          </div>
          <!-- End of section 4: Comments -->

          <!-- section 5: Action Buttons -->
          <div class="text-right">

            <button *ngIf="is_submitable(document.status)" type="button"
              class="btn btn-labeled btn-warning"
              data-dismiss="modal"
              title="{{ 'Submit for Review' | translate }}"
              (click)="action('submit')"
            >
              <i class="btn-label pli-inbox-into"></i>{{ 'Submit' | translate }}
            </button>

            <button *ngIf="is_reviewable(document.status)" type="button"
              class="btn btn-labeled btn-warning"
              data-dismiss="modal"
              title="{{ 'Mark as Reviewed' | translate }}"
              (click)="action('review')"
            >
              <i class="btn-label fa fa-thumbs-up"></i>{{ 'Review' | translate }}
            </button>

            <button *ngIf="is_approvable(document.status)" type="button"
              class="btn btn-labeled btn-success"
              data-dismiss="modal"
              title="{{ 'Approve' | translate }}"
              (click)="action('approve')"
            >
              <i class="btn-label fa fa-solid fa-circle-xmark"></i>{{ 'Approve' | translate }}
            </button>

            <button *ngIf="is_reviewable(document.status) || is_approvable(document.status)" type="button"
              class="btn btn-labeled btn-danger"
              data-dismiss="modal"
              title="{{ 'Rework' | translate }}"
              (click)="action('rework')"
            >
              <i class="btn-label fa fa-thumbs-down"></i>{{ 'Rework' | translate }}
            </button>

            <button *ngIf="mainForm.form.dirty" type="button"
              class="btn btn-labeled btn-info"
              data-dismiss="modal"
              title="{{ 'Save' | translate }}"
              (click)="save()"
            >
              <i class="btn-label fa fa-save"></i> {{ 'Save' | translate }}
            </button>

            <button *ngIf="is_deletable(document.status)" type="button"
              class="btn btn-labeled btn-danger"
              data-dismiss="modal"
              title="{{ 'Delete' | translate }}"
              (click)="delete()"
            >
              <i class="btn-label fa fa-trash"></i> {{ 'Delete' | translate }}
            </button>


            <button type="button"
              class="btn btn-labeled btn-default"
              data-dismiss="modal"
              title="{{ 'Back' | translate }}"
              (click)="back()"
            >
              <i class="btn-label fa fa-solid fa-circle-xmark"></i>{{ 'Back' | translate }}
            </button>

          </div>
          <!-- end of secion 5: Action Buttons -->
        </form>

      </div>
    </panel>
  </div>
</full-page>
