import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(
  ) {}

  // boot (by module)
  public async initialize(): Promise<void> {
  }
}
