import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AngularSlickgridModule } from 'angular-slickgrid';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

import {
  ServerService,
  SessionService,
  PinaconoCommonModule
} from '@pinacono/common';

import { PinaconoUIModule } from '@pinacono/ui';
import { PinaconoIIPViewerModule } from '@pinacono/iip-viewer';
import { SlickgridExtensionModule } from '@pinacono/slickgrid-extension';

import { ThemesModule } from 'src/app/themes/module';

import { AppCommonModule } from 'src/app/common/module';
import { DocumentsModule } from '../documents/module';

// constants and interfaces
import { AppUser } from 'src/app/types';

// services
import { ENDPOINTS } from './endpoints';
import { ProjectLibService } from './projects.service';

// components, directives and pipes

// pages
import { DrawingMasterPage } from './drawings/master/master';
import { DrawingSearchPage } from './drawings/search/search';

import { ProjectLibBrowsePage } from './browse/browse';
import { ProjectLibContactsPage } from './contacts/contacts';
import { ProjectLibEditPage } from './edit/edit';
import { ProjectLibRegisterPage } from './register/register';
import { ProjectLibViewPage } from './view/view';
import { ProjectLibDrawingEditPage } from './view/drawing';
import { ProjectLibDocumentEditPage } from './view/document';
import { ProjectLibReleaseBrowsePage } from './reports/releases/releases';

// pipes
import { ExtractAttachmentsFromProjectDocumentPipe } from './pipes/extract_attachments.pipe';

// components
//import { DrawingCodeEditorComponent } from './components/drawing-code/drawing-code-editor.component';

// test data - @TODO - remove
//import { TEST_DATA } from '../../../../moc-data/projects';
import { ProjectLibHandOverEditPage } from './handover/edit';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,

    TranslateModule.forChild(),
    AngularSlickgridModule.forRoot(),
    NgxSelectModule,
    NgbCarousel,

    PinaconoCommonModule,
    PinaconoIIPViewerModule,
    PinaconoUIModule,
    SlickgridExtensionModule,

    ThemesModule,
    AppCommonModule,
    DocumentsModule
  ],
  declarations: [
    // components, directives and pipes

    // pages
    DrawingMasterPage,
    DrawingSearchPage,

    ProjectLibBrowsePage,
    ProjectLibContactsPage,
    ProjectLibEditPage,
    ProjectLibRegisterPage,
    ProjectLibViewPage,

    ProjectLibDocumentEditPage,
    ProjectLibDrawingEditPage,

    ProjectLibHandOverEditPage,
    ProjectLibReleaseBrowsePage,

    // pipes
    ExtractAttachmentsFromProjectDocumentPipe,

    // components
    //DrawingCodeEditorComponent
  ],
  providers: [
    ProjectLibService
  ],
  exports: [
    //TranslateModule,

    // components, directives and pipes

    // pages
    ProjectLibBrowsePage,
    ProjectLibContactsPage,
    ProjectLibEditPage,
    ProjectLibRegisterPage,
    ProjectLibViewPage,
    ProjectLibReleaseBrowsePage,

    ProjectLibDocumentEditPage,
    ProjectLibDrawingEditPage
  ]
})
export class ProjectLibModule {
  constructor(
    server: ServerService,
    session: SessionService,
    api: ProjectLibService
    //notification: NotificationService
  ) {
    server.registerEndpoints(ENDPOINTS);

    session.LOGGEDIN.subscribe( (u: AppUser|null) => {
      // -- Module login
      if ( u === null ) return;
      // loggedin - acquire protected master data after logged-in
      /*
      Promise.all([
        server.request('opl.domains')
        .then( (res: Taxonomy[]) => {
          oplService.domains = res;
        }),

        server.request('opl.categories')
        .then( (res: Taxonomy[]) => {
          oplService.categories = res;
        }),

        server.request('opl.properties')
        .then( (res: CustomPropertyConfig[]) => {
          oplService.properties = res;
        })
      ]);
      */
    });

    session.LOGGEDOUT.subscribe( () => {
      // -- Module logout
    });

    // notification message parser
    /*
    notification.registerParser('PROJECT', (n:IrisNotification): NotificationMessage => {
      return {
        group: 'project',
        link: `/project/view/${n.closure['doc_id']}`
      };
    });
    */

    /*
    // -- Module boot
    Promise.all([
      // load configurations on BOOT
      server.getconfig('opl')
      .then( (cfg: OPLConfig) => {
        oplService.config = cfg;
      }),

      // add other bootstrap here
    ]);
    */

    // @TODO - remove when finish
    //server.registerSimulatedData(TEST_DATA);

    session.LOGGEDIN.subscribe( (u: AppUser|null) => {
      if ( u !== null ) {
        api.loadBuildingSearchParam();
      }
    });
  }
}
