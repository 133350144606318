<full-page title="'Reports'">
  <div page-content>
    <form id="report-form" ngForm="reportForm">
      <div class="pad-all">
        <div class="row">

          <!-- report #1 - List all OPLs -->
          <panel id="report-1" class="col">
            <div panel-heading>
              All Approved OPLs
            </div>
            <div panel-body>
              <p class="description">
                List all approved OPLs with basic information.
              </p>
              <form class="row" #form="ngForm">
              </form>
              <div class="text-right pt-1">
                <button class="btn btn-default btn-labeled" (click)="run('report1', form)">
                  <i class="btn-label pli-running"></i>{{ 'Run' | translate }}
                </button>
              </div>
            </div>
          </panel>

          <!-- report #2 - OPLs by team and time period -->
          <panel id="report-2" class="col">
            <div panel-heading>
              List of OPLs by Team and Time Period
            </div>
            <div panel-body>
              <p class="description">
                List of approved OPLs by team name and time period.
              </p>
              <form class="row" #form="ngForm">
                <input type="hidden" name="users" [(ngModel)]="form.value.users">
                <div class="row pad-top">
                  <div class="col">
                    <label class="text-bold">{{ 'From' | translate }}:</label>
                    <input type="date" name="from" class="form-control"
                      [daterange]="'from'" [date-ref]="toDate"
                      [(ngModel)]="form.value.from"
                      #fromDate="ngModel"
                    >
                  </div>
                  <div class="col">
                    <label class="text-bold">{{ 'To' | translate }}:</label>
                    <input type="date" name="to" class="form-control"
                      [daterange]="'to'" [date-ref]="fromDate"
                      [(ngModel)]="form.value.to"
                      #toDate="ngModel"
                    >
                  </div>
                </div>

                <div class="row pad-top">
                  <div class="col">
                    <button class="btn btn-default btn-labelled" (click)="selectGroups('report2')">{{'Select Group(s)' | translate}}</button>
                  </div>
                </div>
                <div class="row pad-top">
                  <label class="text-bold">{{ 'Selected Groups' | translate }}:</label>
                  <ul>
                    <li *ngFor="let group of selectedGroups['report2']" class="text-thin text-xs">
                      {{ group.path }}
                    </li>
                    <li *ngIf="selectedGroups['report2'].length == 0" class="empty">
                      - {{ 'No group(s) selected' | translate }} -
                    </li>
                  </ul>
                </div>

              </form>
              <div class="text-right py-2">
                <button class="btn btn-default btn-labeled" (click)="run('report2', form, { groups: getSelectedGroupIds(selectedGroups['report2']) })">
                  <i class="btn-label pli-running"></i>{{ 'Run' | translate }}
                </button>
              </div>
            </div>
          </panel>

          <!-- report #3 - Unread OPLs by user and approve date period -->
          <panel id="report-3" class="col">
            <div panel-heading>
              List of OPLs unread by User and Approve Date Period
            </div>
            <div panel-body>
              <p class="description">
                List all Unread OPLs by user and approve date period.
              </p>
              <form class="row" #form="ngForm">
                <input type="hidden" name="users">
                <div class="row pad-top">
                  <div class="col">
                    <label class="text-bold">{{ 'From' | translate }}:</label>
                    <input type="date" name="from" class="form-control"
                      [daterange]="'from'" [date-ref]="toDate"
                      [(ngModel)]="form.value.from"
                      #fromDate="ngModel"
                    >
                  </div>
                  <div class="col">
                    <label class="text-bold">{{ 'To' | translate }}:</label>
                    <input type="date" name="to" class="form-control"
                      [daterange]="'to'" [date-ref]="fromDate"
                      [(ngModel)]="form.value.to"
                      #toDate="ngModel"
                    >
                  </div>
                </div>

                <div class="row pad-top">
                  <div class="col">
                    <button class="btn btn-default btn-labelled" (click)="selectUsers(report3SelectedUsers)">{{'Select User(s)' | translate}}</button>
                  </div>
                </div>
                <div class="row pad-top">
                  <label class="text-bold">{{ 'Selected Users' | translate }}:</label>
                  <div *ngFor="let user of report3SelectedUsers" class="col form-group user-card-container">
                    <user-card [user]="user"></user-card>
                    <button class="btn btn-icon" (click)="removeSelectedUser(report3SelectedUsers, user)">
                      <i class="pli-close"></i>
                    </button>
                  </div>
                  <div *ngIf="report3SelectedUsers.length == 0" class="empty">
                    - {{ 'No user(s) selected, assume all users are selected.' | translate }} -
                  </div>
                </div>

              </form>
              <div class="text-right py-2">
                <button class="btn btn-default btn-labeled" (click)="run('report3', form, { users: getSelectedUserIds(report3SelectedUsers) })">
                  <i class="btn-label pli-running"></i>{{ 'Run' | translate }}
                </button>
              </div>
            </div>
          </panel>

          <!-- report #4 - OPL reader's status by document code -->
          <panel id="report-4" class="col">
            <div panel-heading>
              OPL Readers' status by Document Code
            </div>
            <div panel-body>
              <p class="description">
                List readers' status by OPL's document code.
              </p>
              <form class="row" #form="ngForm">
                <div class="col">
                  <label class="text-bold">{{ 'OPL Document Code' | translate }}:</label>
                  <input type="text" name="from" class="form-control" [(ngModel)]="form.value.doc_code" #docCode="ngModel">
                </div>
              </form>
              <div class="text-right pt-1">
                <button class="btn btn-default btn-labeled" (click)="run('report4', form)">
                  <i class="btn-label pli-running"></i>{{ 'Run' | translate }}
                </button>
              </div>
            </div>
          </panel>

        </div>
      </div>
    </form>
  </div>
</full-page>

<modal #selectGroupsModal
  template-id="select-groups"
  size="xlarge"
  [fade]="true"
>
  <div modal-title>
    <h5>{{ 'Select Group(s)' | translate }}</h5>
  </div>
  <div modal-body>
    <select-groups [(selected)]="selectedGroups[activeReport]"></select-groups>
  </div>
</modal>

<modal #selectMembersModal
  template-id="select-members"
  size="xlarge"
  [fade]="true"
>
  <div modal-title>
    <h5>{{ 'Select Group Member(s)' | translate }}</h5>
  </div>
  <div modal-body>
    <select-users-by-groups [autoSelectMembers]="false" [(selected)]="selectedUsers"></select-users-by-groups>
  </div>
</modal>
