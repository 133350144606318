import { ElementRef, Renderer2 } from '@angular/core';
import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Column, Formatters } from 'angular-slickgrid';

import {
  NavigationService,
  object_t, Taxonomy,
  ServerService
} from '@pinacono/common';

import {
  ExtendedSlickgridOptions,
  SummaryColumn
} from '@pinacono/slickgrid-extension';

import { Attachment } from '@pinacono/common';
import { DZEvent } from '@pinacono/ui';

import { fabric } from 'fabric';

import { IIP } from '@pinacono/iip-viewer';

import { domain } from 'src/../moc-data/taxonomies'
import { TreeComponent } from '@pinacono/ui';
import moment from 'moment-timezone';

@Component({
  selector: 'app-exp',
  templateUrl: 'exp.component.html',
  styleUrls: ['./exp.component.scss']
})
export class ExperimentalComponent implements OnInit, AfterViewInit, OnDestroy {

  public images = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/900/500`);
  /*
  @ViewChild('client') element!: ElementRef;
  @ViewChild('svg') svg!: ElementRef;
  */

  @ViewChild('categoriesTree')   categoriesTreeComponent!: TreeComponent;
  public domain = [domain];
  public selectedCategories: { id: number }[] = [ { id: 149 } ];
  public categoriesOptions = {
    core: {
      themes: {
        name: 'proton',
        icons: false,
        dots: true
      },
      multiple: true
    },
    checkbox: {
      three_state: false,
      cascade: 'up+down'
    }
  };
  public onSelectedCategoriesChange() {
    console.log(this.selectedCategories);
  }

  public title: string = 'EXP-1';
  public m: string[] = [];

  /*
  public iip_viewer_selected_control: string|null = null;
  public iip_viewer_selected_polygon: IIP.Polygon|null = null;
  public iip_viewer_polygons: IIP.Polygon[] = [
    {
      vertices: [
        { x:     0, y: -9000 },
        { x: -7000, y:  9000 },
        { x:  7000, y:  9000 },
      ]
    }
  ];
  public iip_polygon_prototype: IIP.Polygon = {
    vertices: [
      { x:  50, y: -50 },
      { x: -50, y: -50 },
      { x: -50, y:  50 },
      { x:  50, y:  50 },
    ]
  };
  */

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    protected nav: NavigationService,
    protected renderer: Renderer2,
    protected server: ServerService,
    protected datePipe: DatePipe
  ) {
    console.log('url = ', this.router.url);
    console.log('params = ', activatedRoute.snapshot.queryParams);

    /*
    this.router.events
    .subscribe( ( e : Event ) => {
      console.log('experimentalComponent event', e);
    });
    */
  }

  public ngOnInit(): void {
    console.log('experimentalComponent - onInit');
    /*
    this.gridDataset = this.mockData(20);

    const re = /(..)-(\d\d)-(\d\d)(?:_(\d{1,2}))?_(.*)(?:\.(\w+)$)/;
    const seg = 'EE-05-01_1_FFFF.pdf'.match(re);
    if ( seg ) {
      console.log('drawing_no = ', seg.slice(1, 5).filter( s => s && s.trim().length > 0 ).join('-'));
    }
    */
  }

  //public root: Taxonomy[] = [];
  public ngAfterViewInit(): void {
    console.log('experimentalComponent - afterViewInit');
    //console.log('nav.goto: exp2');
    //this.nav.goto('exp2');
    /*
    this.server.show('terms', 1)
    .then( (terms: Taxonomy[]) => {
      this.root = terms;
    });

    // -- fabric
    const canvasElement = document.getElementById('fabric-canvas') as HTMLCanvasElement;

    if ( canvasElement ) {
      const canvas = new fabric.Canvas(canvasElement, {
        width: 500,
        height: 500
      });
      const rect = new fabric.Rect({
        top: 10,
        left: 10,
        width: 100,
        height: 100,
        fill: 'transparent',
        stroke: 'red',
        strokeUniform: true,
        strokeWidth: 1
      });
      canvas.add(rect)
      canvas.renderAll().setActiveObject(rect);
    }
    */
  }

  public ngOnDestroy(): void {
    console.log('experimentalComponent - onDestroy');
  }

  public inputChange(e: Event) {
    console.log('inputChange', e);
    console.log('model', this.m);
  }

  public inputFocus(e: Event) {
    console.log('inputFocus', e);
  }

  public pipe_dt: string = '';
  public moment_dt: string = '';
  public formatChage(format: string) {
    this.pipe_dt = this.datePipe.transform(Date.now(), format) || '';
    this.moment_dt = moment().format(format);
  }

  /*
  public mockData(count: number): object_t[] {
    // mock a dataset
    const mockDataset = [];
    for (let i = 0; i < count; i++) {
      const randomYear = 2000 + Math.floor(Math.random() * 10);
      const randomMonth = Math.floor(Math.random() * 11);
      const randomDay = Math.floor((Math.random() * 29));
      const randomPercent = Math.round(Math.random() * 100);

      mockDataset[i] = {
        id: i,
        title: 'Task ' + i,
        duration: Math.round(Math.random() * 100) + '',
        percentComplete: randomPercent,
        start: new Date(randomYear, randomMonth + 1, randomDay),
        finish: new Date(randomYear + 1, randomMonth + 1, randomDay),
        effortDriven: (i % 5 === 0)
      };
    }

    return mockDataset;
  }
  */

  // -- grid

  /*
  public gridColumns: Column[] = [
    { id: 'title', name: 'Title', field: 'title', sortable: true },
    { id: 'duration', name: 'Duration (days)', field: 'duration', sortable: true },
    { id: '%', name: '% Complete', field: 'percentComplete', sortable: true },
    { id: 'start', name: 'Start', field: 'start', formatter: Formatters.dateIso },
    { id: 'finish', name: 'Finish', field: 'finish', formatter: Formatters.dateIso },
    { id: 'effort-driven', name: 'Effort Driven', field: 'effortDriven', sortable: true }
  ];
  public gridOptions: ExtendedSlickgridOptions = {
    enableAutoResize: false,
    enableSorting: true,
    gridHeight: 450,
    //gridWidth: 800,
    enablePagination: true,
    pagination: {
      pageSizes: [10, 20, 30, 40, 50],
      pageSize: 10,
      totalItems: 0
    },
    summaryRow: [
      { id: '%',      colspan: 3, formatter: (c: SummaryColumn): string => 'n/a' },
      { id: 'effort', colspan: 3, formatter: (c: SummaryColumn): string => 'xxx' }
    ]
  };
  public gridDataset: object_t[] = [];
  */

  // -- dodgable
  /*
  public clientX: number = -1;
  public clientY: number = -1;

  public mx: number = -1;
  public my: number = -1;

  public top: number = 0;
  public left: number = 0;

  private delayTimer: number = 0;
  private gap: number = 30;
  public onMouseMove(e: MouseEvent) {

    if ( this.delayTimer > 0 ) return;

    this.delayTimer = window.setTimeout( () => {
      if ( this.clientX < 0 && this.clientY < 0 ) {
        this.clientX = this.element.nativeElement.offsetLeft;
        this.clientY = this.element.nativeElement.offsetTop;
      }

      // calculate move position
      let self   = this.element.nativeElement;
      let parent = self.parentElement;

      let sw = self.clientWidth;
      let sh = self.clientHeight;

      let pw = parent.clientWidth;
      let ph = parent.clientHeight;

      let cx = pw/2;
      let cy = ph/2;

      // get mouse position related to the target
      let rect = parent.getBoundingClientRect();
      this.mx = Math.ceil(e.clientX - rect.left);
      this.my = Math.ceil(e.clientY - rect.top);

      // calculate direction of moving by
      // checking, which aspect as more
      // space
      if ( Math.abs(pw - sw) < Math.abs(ph - sh) ) {
        // width is fitter to parent, move vertically
        this.left = 0;
        // move up, if mouse is in lower quardrant
        if ( this.my > cy ) {
          this.top = this.my - sh - this.gap - this.clientY;
        }
        // otherwise, move down
        else {
          this.top  = this.my + this.gap - this.clientY;
        }
      }
      else {
        // height is fitter to parent, move horizontally
        this.top  = 0;
        // move left, if mouse is in in right quadrant
        if ( this.mx > cx ) {
          this.left = this.mx - sw - this.gap - this.clientX;
        }
        // otherwise, move right
        else {
          this.left = this.mx + this.gap - this.clientX;
        }
      }

      this.renderer.setStyle(this.element.nativeElement, 'position', 'relative');
      this.renderer.setStyle(this.element.nativeElement, 'top', `${this.top}px`);
      this.renderer.setStyle(this.element.nativeElement, 'right', null);
      this.renderer.setStyle(this.element.nativeElement, 'bottom', null);
      this.renderer.setStyle(this.element.nativeElement, 'left', `${this.left}px`);
      this.delayTimer = 0;
    }, 100);
  }
  */

  /*
  public files: Attachment[] = [];
  public onDZEvent(event: DZEvent) {
    console.log('dz event', event, this.files);
  }
  */

  /*
  // -- IIP
  public onIIPViewerSelectedControlChange(event: string|null) {
    this.iip_viewer_selected_control = event;
  }
  */

}
