import moment from "moment";

import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

import {
  object_t,
  SessionService,
  ServerService
} from '@pinacono/common';

import {
  NotificationMessage,
  NotificationService,
  IrisAlert,
  IrisBroadcast
} from '@pinacono/notifications';

import { UIService } from "@pinacono/ui";

import {
  Colors,
  Menu,
  //MenuBadgeInfo,
  MenuBadgeSetting,
  NotificationConfig
} from "./types";

// 3rd party library
declare let $: any; // to use jQuery

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  public activeMenu: Menu|null = null;

  constructor(
    public notifications: NotificationService,
    protected translate: TranslateService,
    protected session: SessionService,
    server: ServerService,
    ui: UIService
  ) {

    ui.loading.subscribe( (flag: boolean) => {
      this.loading(flag);
    });

    // handle loading overlay
    server.INPROGRESS.subscribe( (loading: boolean) => {
      this.loading(loading);
    });

    // handling logout
    session.LOGGEDOUT.subscribe( () => {
      this.activeMenu = null;
    });

    session.LOGGEDIN.subscribe( () => {
      this.activeMenu = null;
    })

    // handling notifications
    notifications.onBroadcastMessage.subscribe( (msg: IrisBroadcast) => {
      this.notify({
        class: 'info',
        title: 'Server Broadcasting',
        message: msg.msg,
        timer: 5000,
        closeBtn: true
      });
    });

    notifications.onAlertMessage.subscribe( (msg: IrisAlert) => {
      this.notify({
        class: this.notificationLevel2Class(msg.level),
        title: 'Alert',
        message: msg.msg,
        timer: 5000,
        closeBtn: true
      })
    });

    notifications.onIncomingMessage.subscribe( ( msg: NotificationMessage) => {
      this.notify({
        class: this.notificationLevel2Class(msg.type),
        title: msg.user && msg.user.fullname || 'Notification',
        icon:  msg.icon || 'pli-exclamation',
        message: msg.message || '- ??? -',
        timer: 3000,
        closeBtn: true
      });
    });

    this.setLanguage(translate.getBrowserLang() || 'en');
    translate.onLangChange.subscribe( (e: LangChangeEvent) => this.setLanguage(e.lang) );
  }

  // -- language and translation services

  protected setLanguage(lang: string) {
    if ( lang != this.translate.currentLang ) {
      this.translate.currentLang = ''; // bug?
      this.translate.use(lang);
    }
    moment.locale(lang);
    this.session.setPreference('lang', lang);
  }

  get languages(): string[] {
    /*
    const langs = this.translate.getLangs(); // not works!!
    if ( langs.length ) {
      return langs;
    }
    */
    return [ 'en', 'th' ]; // list all available languages here
  }

  get currentLang(): string {
    return this.translate.currentLang || 'en';
  }

  set currentLang(lang: string) {
    this.setLanguage(lang);
  }

  // --- common actions

  public removeNotification(n: NotificationMessage) {
  }

  // --- menu item badges

  public readonly badges: {
    [name: string]: MenuBadgeSetting
  } = {};

  public setMenuBadge(name: string, text: string, css_class?: string, tooltip?: string, onClick?: () => void) {
    const void_cb = () => {};
    this.badges[name] = {
      text:    text,
      class:   css_class || ( this.badges[name] && this.badges[name].class ) || 'info',
      tooltip: tooltip   || ( this.badges[name] && ( this.badges[name].tooltip || this.badges[name].text ) ) || '',
      onClick: onClick   || ( this.badges[name] && this.badges[name].onClick ) || void_cb
    };
  }

  // -- Theme API

  /**
   * Show loading overlay
   * @param visible show/hide the overlay
   */
  protected loading_icon_style: number = 0;
  protected loading_enabled: boolean = true;
  public loading(visible: boolean = true) {
    visible = visible && this.loading_enabled;
    $('#loading-overlay').css('display', (visible ? 'block' : 'none'));
    if ( ! visible ) {
      // change loading icon circulate between 1-8
      let prev = `load${this.loading_icon_style}`;
      this.loading_icon_style = (this.loading_icon_style+1)%7;
      let next = `load${this.loading_icon_style + 1}`;
      $('#loading-overlay .loading').removeClass(prev).addClass(next);
    }
  }

  /**
   * enable/disable loading overlay
   * @param enable boolen
   */
  public showLoading(enable: boolean = true) {
    this.loading_enabled = enable;
  }

  /**
   * maximize content area
   */
  public maximizeContentArea() {
    /** select the #container element and apply class 'mainnav-sm' */
    const container = document.querySelector('#container')
    if ( !! container ) {
      container.classList.remove('mainnav-lg');
      container.classList.add('mainnav-sm');
    }
  }

  // -- notifications

  protected notificationLevel2Class(level?: string): Colors {
    const mappings: object_t = {
      // alert level
      debug:   'info',
      info:    'info',
      warning: 'warning',
      error:   'error',

      // general user notification message
      default:   'info',
      primary:   'primary',
      secondary: 'secondary',
      success:   'success',
      danger:    'danger',
      mint:      'mint',
      purple:    'purple',
      pink:      'pink',
      dark:      'dark'
    };

    return ( ( level && mappings[level] ) || 'info' ) as Colors;
  }

  /**
   * Display notification message
   * @param config
   *
   * @todo translation support parameters
   */
  public notify(config: NotificationConfig, bindings: object = {}) {
    config.message = this.translate.instant(config.message, bindings);
    config.icon =  (config.icon || 'pli-exclamation' ) + ' icon-2x';

    $.niftyNoty(Object.assign({
      type: config.class || 'success',
      //container: 'floating',
      container: '#floating-top-right',
      closeBtn: config.closeBtn || ( config.timer !== undefined && config.timer <= 0 ),
      timer: 30000
    }, config));
  }
}