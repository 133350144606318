<full-page [title]="'Library Audit'">
  <div page-content>
    <div *ngIf="! results" class="panel">
      <form #mainForm="ngForm">
        <div class="row">

          <!--left -->
          <div class="col-5">
            <div class="p-3">
              <div class="form-group">
                <label for="branch" class="form-label">{{ 'Location' | translate }}</label>
                <select #location="ngModel"
                  id="branch" name="branch" class="select form-control" aria-describedby="branchHelpBlock"
                  title="{{ 'Select library br.' | translate }}"
                  tabindex="1"
                  [(ngModel)]="selected.branch"
                  (change)="changeLocation()"
                >
                  <option value="none">{{ 'Quick Check' | translate }}&hellip;</option>
                  <option *ngFor="let l of api.locations" value="{{ l }}" [attr.selected]="selected.branch == l ? '' : null">{{ l }}</option>
                </select>
                <!--
                <validation-errors
                  [models]="location"
                  [messages]="{
                    required: 'Audit Location is required'
                  }"
                  [dirty]="true"
                ></validation-errors>
                -->
              </div>

              <div class="form-group">
                <label for="cabinet" class="form-label">{{ 'Cabinet' | translate }}</label>
                <input  #cabinet="ngModel" type="text"
                  id="cabinet" name="cabinet" class="form-control" aria-describedby="cabinetHelpBlock"
                  title="{{ 'Specify cabinet code.' | translate }}"
                  tabindex="2"
                  [attr.disabled]="selected.branch == 'none' ? 1 : null"
                  [(ngModel)]="selected.cabinet"
                  (change)="changeCabinet()"
                >
                <!--
                <validation-errors
                  [models]="location"
                  [messages]="{
                    required: 'Audit Cabinet is required'
                  }"
                  [dirty]="true"
                ></validation-errors>
                -->
              </div>

              <div class="form-group">
                <label for="shelf" class="form-label">{{ 'Shelf' | translate }}</label>
                <input #shelf="ngModel" type="text"
                  id="shelf" name="shelf" class="form-control" aria-describedby="shelfHelpBlock"
                  title="{{ 'Specify the shelf id.' | translate }}"
                  tabindex="3"
                  [attr.disabled]="selected.branch == 'none' ? 1 : null"
                  [(ngModel)]="selected.shelf"
                  (change)="changeShelf()"
                >
                <!--
                <validation-errors
                  [models]="shelf"
                  [messages]="{
                    required: 'Audit Shelf is required'
                  }"
                  [dirty]="true"
                ></validation-errors>
                -->
              </div>
            </div>
          </div>

          <!-- right -->
          <div class="col-7">
            <div class="p-3">
              <div class="form-group">
                <label for="code-input" class="form-label">{{ 'Code' | translate }}</label>
                <p class="form-description">Enter the barcode of the book and press enter to add it to the audit list.</p>
                <div class="input-group">
                  <input #inputCode="ngModel" type="text"
                    id="code-input" name="code-input" class="form-control" aria-describedby="codeInputHelpBlock"
                    title="{{ 'Enter barcode' | translate }}"
                    [(ngModel)]="code"
                    (keyup)="codeInputChange($event)"
                    tabindex="0"
                  >
                  <button class="btn btn-default" type="button" id="add-code" (click)="addCode()">{{ 'Add' | translate }}</button>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ 'Bar Codes' | translate }}:</label>
                <p class="form-description">List of book's barcode(s) to be audited.</p>
                <div class="full-height">
                  <div class="fill-height p-2 border border-default rounded">
                    <button *ngFor="let c of codes; let i = index"
                      class="btn btn-rounded btn-default code-item"
                      title="Click to delete {{ c }} from list."
                      tabindex="-1"
                      (click)="removeCode($event, i)"
                    >
                      {{ c }}
                    </button>
                    <div *ngIf="codes.length == 0" class="list-empty">
                      - Please enter the barcode(s) to audit -
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>

      <!-- footer -->
      <div class="row pad-all">
        <div class="col-xs-12 text-right">
          <button type="button" class="btn btn-default" (click)="submit()">{{ 'Check' | translate }}</button>
        </div>
      </div>
    </div>

    <panel *ngIf="!! results">
      <div panel-heading>
        <h2 *ngIf="!! selected.branch && selected.branch != 'none'" class="selected-location">
          <span id="branch">{{ selected.branch }}</span><span *ngIf="!! selected.cabinet" id="cabinet">{{ selected.cabinet }}</span><span *ngIf="!! selected.shelf" id="shelf">{{ selected.shelf }}</span>
          <span id="timestamp" class="text-sm text-muted text-thin">{{ timestamp | date:'dd MMM YYYY HH:mm:ss' }}</span>
        </h2>
        <h2 *ngIf="selected.branch == 'none' || ! selected.branch">{{ 'Asset Code(s) Check Result' | translate }}</h2>
      </div>
      <div panel-body>

        <div *ngIf="! results" class="row">
          <p class="col text-center text-muted text-thin pad-all">{{ 'Please enter barcode(s) and click "Check" button!' | translate }}</p>
        </div>

        <!-- quick check -->
        <div *ngIf="! selected.branch || selected.branch == 'none'">
          <div class="row">
            <div class="col">
              <assets [items]="results.assets" [title]="'Assets Checking'"></assets>
            </div>
          </div>
        </div>

        <div *ngIf="!! selected.branch && selected.branch != 'none'">
          <!-- OK -->
          <div class="row">
            <div class="col">
              <assets [items]="results.ok" [title]="'OK'" [description]="'List of hard copies those are in thier place.'"></assets>
            </div>
          </div>

          <!-- missings -->
          <div class="row">
            <div class="col">
              <assets [items]="results.missings" [title]="'Missings'" [description]="'List of hardcopies those should be here but not.'"></assets>
            </div>
          </div>

          <!-- outstandings -->
          <div class="row">
            <div class="col">
              <assets [items]="results.outstandings" [title]="'Out Standings'" [description]="'List of hardcopies those should not be here.'"></assets>
            </div>
          </div>

        </div>
      </div>
      <div panel-footer class="text-right">
        <button (click)="print()" type="button" class="btn btn-default">{{ 'Print' | translate }}</button>
        <button (click)="reset()" type="button" class="btn btn-default">{{ 'Reset' | translate }}</button>
      </div>
    </panel>
  </div>
</full-page>